import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      display: "none",
    },
  },
  formControl: {
    width: "150px",
    background: "#000",
    borderRadius: "10px",
    "& .MuiInput-formControl": {
      margin: "0 !important",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      padding: "15px",
      borderRadius: "10px",
    },
    "& .MuiSelect-select:focus": {
      background: "#000",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
  },
  select: {
    background: `${theme.palette.primary.main} !important`,
  },
}));

export default useStyles;
