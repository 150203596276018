import React from "react";
import NumberFormat from "react-number-format";
import useStyles from "./styles";
import TextField from "@material-ui/core/TextField";
import { kMaxLength } from "buffer";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}

interface IFormattedInputs {
  customClass?: string;
  placeholerString: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: string;
}

const FormattedInputs: React.FC<IFormattedInputs> = ({
  customClass = "",
  placeholerString = "",
  values = "",
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        className={[classes.defaultClass, customClass].join(" ")}
        value={values}
        placeholder={placeholerString}
        onChange={handleChange}
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          inputProps: {
            maxlength: 20,
          },
        }}
      />
    </div>
  );
};

export default FormattedInputs;
