import gql from 'graphql-tag';

export const POOL_TVL = gql`
  query uniPoolDayDatas($startTime: Int!, $skip: Int!, $address: Bytes!) {
    uniPoolDayDatas(
      first: 1000
      skip: $skip
      where: { uniswapPool: $address, date_gt: $startTime }
      orderBy: date
      orderDirection: asc
      subgraphError: allow
    ) {
      date
      ulmTvlUSD
      uniswapPoolDayData {
        volumeUSD
      }
    }
  }
`;

export const POOL_VOLUME = gql`
  query poolDayDatas($startTime: Int!, $skip: Int!, $address: Bytes!) {
    poolDayDatas(
      first: 1000
      skip: $skip
      where: { pool: $address, date_gt: $startTime }
      orderBy: date
      orderDirection: asc
      subgraphError: allow
    ) {
      date
      volumeUSD
    }
  }
`;

export const PROTOCOL_TVL_FEE = gql`
  query uniPools($skip: Int!) {
    uniPools(first: 1000, skip: $skip, subgraphError: allow) {
      id
      ulmTvlUSD
      ulmFeesUSD
      apy
      rebaseTimestamp
      uniswapPool {
        token0 {
          id
          symbol
          name
          decimals
        }
        token1 {
          id
          symbol
          name
          decimals
        }
        feeTier
        volumeUSD
      }
    }
  }
`;

export const POOLS_FOR_ROI = gql`
  query uniPools {
    uniPools(first: 1000, where: { ulmTvlUSD_gt: 0 }) {
      id
      uniswapPool {
        token0 {
          id
          symbol
          name
          decimals
        }
        token1 {
          id
          symbol
          name
          decimals
        }
        feeTier
        volumeUSD
      }
    }
  }
`;

export const POOL_APY = gql`
  query pools($address: Bytes!) {
    uniPools(where: { uniswapPool: $address }, subgraphError: allow) {
      apy
    }
  }
`;

export const NFT_CLAIMED_REWARD = gql`
  query nfts($tokenIds: [String!]) {
    nfts(where: { tokenId_in: $tokenIds }, subgraphError: allow) {
      tokenId
      reward
    }
  }
`;

export const FARMING_POOLS_TVL = gql`
  query farmPools($poolAddresses: [String!]) {
    farmPools(where: { id_in: $poolAddresses }, subgraphError: allow) {
      id
      tvlTotal
    }
  }
`;

export const FARMING_PROTOCOL_DATA = gql`
  query protocols($id: String!) {
    protocols(where: { id: $id }, subgraphError: allow) {
      totalReward
      total
    }
  }
`;

export const FARMING_POOLS = gql`
  query farmPools {
    farmPools(where: { whiteListNew: true }, subgraphError: allow) {
      id
      token0 {
        id
        symbol
        name
        decimals
      }
      token1 {
        id
        symbol
        name
        decimals
      }
      fee
    }
  }
`;

export const LP_TOKENS = gql`
  query liquidityPositions($user: String!) {
    liquidityPositions(where: { user: $user }, subgraphError: allow) {
      pair {
        id
        token0 {
          id
          symbol
          name
          decimals
        }
        token1 {
          id
          symbol
          name
          decimals
        }
        totalSupply
        reserve0
        reserve1
      }
    }
  }
`;

export const GET_TOKENS = gql`
  query tokens($tokenAddresses: [String!]) {
    tokens(where: { id_in: $tokenAddresses }, subgraphError: allow) {
      id
      name
      symbol
      decimals
    }
  }
`;

export const GET_TOKENS_DERIVED_ETH = gql`
  query tokens($tokenAddresses: [String!]) {
    tokens(where: { id_in: $tokenAddresses }, subgraphError: allow) {
      id
      derivedETH
    }
    bundles(subgraphError: allow) {
      ethPriceUSD
    }
  }
`;

export const GET_BLOCKS = (timestamps: string[]) => {
  let queryString = 'query blocks {';
  queryString += timestamps.map(timestamp => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
      timestamp + 600
    } }) {
        number
      }`;
  });
  queryString += '}';
  return gql(queryString);
};

export const GET_POOLS_BY_BLOCKS = (poolWithBlock: { poolAddress: string; block: number }[]) => {
  let queryString = 'query pools {';
  queryString += poolWithBlock.map(({ poolAddress, block }) => {
    return (
      `t${block}:pools(where:{id:"${poolAddress}"}` +
      (block > 0 ? `,block:{number:${block}}` : ``) +
      `) {
      feeTier
      volumeUSD
      totalValueLockedUSD
      }`
    );
  });
  queryString += '}';
  return gql(queryString);
};

export const GET_DUST_BY_BLOCKS = (
  poolWithBlock: { poolAddress: string; tokenId: string; block: number }[]
) => {
  let queryString = 'query uniPools {';
  queryString += poolWithBlock.map(({ poolAddress, tokenId, block }) => {
    return (
      `tPool${block}:uniPools(where:{id:"${poolAddress.toLowerCase()}"}` +
      (block > 0 ? `,block:{number:${block}}` : ``) +
      `) {
        dustGlobal0
        dustGlobal1
        ulmLiquidity
      }` +
      `tToken${block}:userPositions(where:{tokenId:"${tokenId}"}` +
      (block > 0 ? `,block:{number:${block}}` : ``) +
      `) {
        localDust0
        localDust1
        liquidity
      }`
    );
  });
  queryString += '}';
  return gql(queryString);
};
