import { Box, Typography } from '@material-ui/core';

import useStyles from './styles';
interface ITokenSwitch {
  value0: string;
  value1: string;
  baseValue: boolean;
  onChange: (value: boolean) => void;
}

const TokenSwitch: React.FC<ITokenSwitch> = ({ value0, value1, onChange, baseValue }) => {
  const classes = useStyles();

  return (
    <Box className={classes.switch}>
      <Box className={classes.switchBody}>
        <Box
          className={`${classes.switchSec} ${baseValue ? `${classes.switchActive}` : ''}`}
          onClick={() => onChange(true)}
        >
          <Typography variant='body2'>{value0}</Typography>
        </Box>
      </Box>
      <Box className={classes.switchBody}>
        <Box
          className={`${classes.switchSec} ${!baseValue ? `${classes.switchActive}` : ''}`}
          onClick={() => onChange(false)}
        >
          <Typography variant='body2'>{value1}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TokenSwitch;
