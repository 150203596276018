import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    textAlign: 'center',
    padding: '18px 0',
    minWidth: '48%',
  },
  text: {
    color: theme.palette.primary.main,
    marginBottom: '3px',
    fontSize: '12px',
    '@media(max-width:550px)': {
      fontSize: '12px',
    },
  },
  amount: {
    color: theme.palette.primary.main,
    fontWeight: 'bolder',
    fontSize: '20px',
    '@media(max-width:550px)': {
      fontSize: '18px',
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
