import { useEffect, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import Jazzicon from '@metamask/jazzicon';

export default function Identicon() {
  const ref = useRef<HTMLDivElement>();

  const { account } = useWeb3React();

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = '';
      ref.current.appendChild(Jazzicon(20, parseInt(account.slice(2, 10), 16)));
    }
  }, [account]);

  return <div style={{ marginTop: '5px' }} ref={ref as any} />;
}
