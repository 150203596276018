import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  right: {
    borderRadius: theme.shape.borderRadius,
  },
  tvlContainer: {
    padding: '20px 15px 0 15px',
  },
  timeBox: {
    height: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 15px',
    '& p': {
      color: theme.palette.common.lightText,
      fontSize: '12px',
    },
  },
  inputStartingPrice: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 130, 255, 0.15)',
    padding: '4px 20px',
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: 5,
    minHeight: 50,
  },
}));

export default useStyles;
