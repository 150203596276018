import { useSelector } from 'react-redux';
import { Price, Token } from '@uniswap/sdk-core';

import { useDispatchWrap } from './utilHooks';
import {
  State,
  getNFTPositions,
  removePosition,
  addTokenDetails,
  selectNFTPosition,
  updateSelectedPosition,
  updatePositions,
  setPageCount,
} from '../redux';
import { priceOrder, priceFromTick } from '../utils/uniswapSDKFunctions';
import { truncSymbol } from '../utils/formating';
import { logError } from '../utils/logs';

export const useNFTPositions = () => {
  const positions = useSelector((state: State) => state.position);
  const getNFTPositionsF = useDispatchWrap(getNFTPositions);
  const removePositionF = useDispatchWrap(removePosition);
  const addTokenDetailsF = useDispatchWrap(addTokenDetails);
  const selectNFTPositionF = useDispatchWrap(selectNFTPosition);
  const updateSelectedPositionF = useDispatchWrap(updateSelectedPosition);
  const updatePositionsF = useDispatchWrap(updatePositions);
  const setPageCountF = useDispatchWrap(setPageCount);

  return {
    ...positions,
    getNFTPositionsF,
    removePositionF,
    addTokenDetailsF,
    selectNFTPositionF,
    updateSelectedPositionF,
    updatePositionsF,
    setPageCountF,
  };
};

export const usePrices = () => {
  const { selectedNFTPosition } = useNFTPositions();

  const inverted = (
    reverse: boolean,
    priceLower: Price<Token, Token>,
    priceUpper: Price<Token, Token>
  ) => {
    const minPrice = reverse ? priceLower.toSignificant(5) : priceUpper.invert().toSignificant(5);
    const maxPrice = reverse ? priceUpper.toSignificant(5) : priceLower.invert().toSignificant(5);
    return { minPrice, maxPrice };
  };

  const getPrices = (reverse: boolean) => {
    try {
      if (selectedNFTPosition) {
        const {
          token0,
          token1,
          baseTickLower,
          baseTickUpper,
          rangeTickLower,
          rangeTickUpper,
          currentTick,
        } = selectedNFTPosition;
        const { priceLower, priceUpper, base } = priceOrder(
          token0,
          token1,
          Number(baseTickLower),
          Number(baseTickUpper)
        );
        const { priceLower: rangePriceLower, priceUpper: rangePriceUpper } = priceOrder(
          token0,
          token1,
          Number(rangeTickLower),
          Number(rangeTickUpper)
        );
        const invert = reverse
          ? base.address.toLowerCase() === token1.address
          : base.address.toLowerCase() === token0.address;

        const currentPrice = invert
          ? priceFromTick(token1, token0, Number(currentTick))
          : priceFromTick(token0, token1, Number(currentTick));

        const { minPrice: baseMinPrice, maxPrice: baseMaxPrice } = inverted(
          reverse,
          priceLower,
          priceUpper
        );
        const { minPrice: rangeMinPrice, maxPrice: rangeMaxPrice } = inverted(
          reverse,
          rangePriceLower,
          rangePriceUpper
        );

        const minPrice =
          parseFloat(baseMinPrice) < parseFloat(rangeMinPrice) ? baseMinPrice : rangeMinPrice;
        const maxPrice =
          parseFloat(baseMaxPrice) > parseFloat(rangeMaxPrice) ? baseMaxPrice : rangeMaxPrice;

        //const secondPosition = minPrice === baseMinPrice ? 'Range' : 'Bid';
        const tokenMsg = invert
          ? `${truncSymbol(token0.symbol, 8)} per ${truncSymbol(token1.symbol, 8)}`
          : `${truncSymbol(token1.symbol, 8)} per ${truncSymbol(token0.symbol, 8)}`;

        return {
          minPrice,
          maxPrice,
          baseMinPrice,
          baseMaxPrice,
          rangeMinPrice,
          rangeMaxPrice,
          currentPrice,
          tokenMsg,
        };
      }
      return {
        minPrice: '0',
        maxPrice: '0',
        baseMinPrice: '0',
        baseMaxPrice: '0',
        rangeMinPrice: '0',
        rangeMaxPrice: '0',
        currentPrice: '0',
        tokenMsg: '',
      };
    } catch (e) {
      logError('getPrices', e);
      return {
        minPrice: '0',
        maxPrice: '0',
        baseMinPrice: '0',
        baseMaxPrice: '0',
        rangeMinPrice: '0',
        rangeMaxPrice: '0',
        currentPrice: '0',
        tokenMsg: '',
      };
    }
  };

  return { getPrices };
};
