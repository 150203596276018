import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: theme.shape.borderRadius,
    padding: "1.5rem",
    //minHeight: "350px",
  },
  mainRoot: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "700px !important",
    },
  },
  parent: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "430px !important",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center !important",
      height: "460px !important",
      // overflowY: "auto",
    },
    "& .react-swipeable-view-container > div": {
      paddingBottom: "2px !important",
    },
  },
  mainContainer: {
    display: "flex",
    padding: "15px 10px",
    background: theme.palette.secondary.main,
    border: "1px solid rgba(255,255,255,0.4)",
    marginTop: "15px",
    borderRadius: "10px",
    width: "100%",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(255,255,255,0.06)",
      transition: "0.5s",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    "& > div": {
      display: "flex",
      [theme.breakpoints.up("xs")]: {
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
  },
  marginTop: {
    marginTop: 15,
  },
  buttonContainer: {
    textTransform: "none",
    // backgroundColor: theme.palette.secondary.main,
    padding: "15px 10px",
    // borderColor: "transparent",
    marginTop: 10,
    width: "99.9%",
    background: theme.palette.secondary.main,
    border: "1px solid rgba(255,255,255,0.4)",
    // padding: "18px 13px",
  },
  price: {
    fontSize: "0.8rem",
    margin: "5px",
    lineHeight: "1",
  },
  amount: {
    //lineHeight: '0',
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  amountClass: {
    margin: "0 3px",
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
    },
  },
  uri: {
    width: 15,
    height: 15,
  },
  buttonTop: {
    textTransform: "none",
    minWidth: "120px",
    borderRadius: theme.shape.borderRadius,
    height: "32px!important",
    padding: "8px 0px",
    color: "white",
    width: "130px",
  },
  topBtnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  greenInfo: {
    "@media(max-width:550px)": {
      padding: "2px 8px",
    },
  },
}));

export default useStyles;
