import { Grid, Typography, GridProps, Box } from '@material-ui/core';
import { useHistory } from 'react-router';

import { BackButton } from '../';

interface IHeader {
  disableGoBack?: boolean;
  elements?: React.ReactNode;
  iconRight?: React.ReactNode;
  goBack?: () => void;
  cAlignItems?: GridProps['alignItems'];
  iconRightHidden?: boolean;
  rebase?: boolean;
}

const Header: React.FC<IHeader> = ({
  elements = <Typography variant='h5'>Header</Typography>,
  disableGoBack = false,
  rebase = false,
  iconRight,
  goBack,
  cAlignItems,
  iconRightHidden,
}) => {
  const history = useHistory();

  return (
    <>
      <Grid container alignItems={cAlignItems ?? 'flex-start'} justify='space-between'>
        <Grid item style={{ visibility: disableGoBack ? 'hidden' : 'visible' }}>
          <BackButton
            onClick={
              goBack
                ? goBack
                : () => {
                    history.goBack();
                  }
            }
          />
        </Grid>
        <Grid item>{elements}</Grid>
        <Grid item style={{ width: '48px', visibility: iconRightHidden ? 'hidden' : undefined }}>
          {iconRight ? iconRight : <></>}
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
