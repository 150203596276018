import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  conatiner: {
    background: 'rgba(0, 0, 0, 0.6)',
    boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.16)',
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
  },
  tokenPair: {
    margin: '15px 0px',
  },
  marginTop: {
    marginTop: 20,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: 'none',
    color: 'white',
    padding: '8px 0px',
  },
  textMarginTop: {
    marginTop: 5,
  },
  bar: {
    '&:hover': {
      color: 'red',
    },
  },
  topLeftBtn: {
    textTransform: 'none',
    padding: '8px 0px',
  },
  marginTopMost: {
    marginTop: 100,
  },
  bottomSelect: {
    borderRadius: 0,
  },
  bottomInput: {
    borderRadius: 0,
  },
  inputMaxContainer: {
    backgroundColor: theme.palette.secondary.main, //"#F8F8F8",
    fontWeight: 500,
    padding: '4px 20px',
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderLeft: `2px solid rgba(255,255,255,0.1)`,
    minHeight: 50,
  },
  inputStartingPrice: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    padding: '4px 20px',
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: 5,
    minHeight: 50,
  },
  bottomHeight: {
    height: theme.mixins.toolbar.minHeight,
  },
  dropButton: {
    textTransform: 'none',
    justifyContent: 'space-between',
    padding: '10px 20px',
    // backgroundColor: theme.palette.secondary.main,
    borderColor: 'transparent',
    minHeight: 50,
  },
  borderPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  secondaryBC: {
    backgroundColor: theme.palette.secondary.main,
  },
  overlapClass01: {
    width: 16,
    height: 16,
  },
  overlapClass02: {
    width: 16,
    height: 16,
  },
  ethIcon: {
    margin: '0px 4px',
    // height: 13,
    width: 20,
    height: 20,
  },

  buttonContent: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  right: {
    borderRadius: theme.shape.borderRadius,
  },
}));

export default useStyles;
