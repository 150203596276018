import { DEV_ENVIRONMENT } from './';

export const CONTRACT_ADDRESSES = DEV_ENVIRONMENT
  ? {
      unipilot: '0x4AC41d4f46C5d00F1Bc3deC8a59cc7Dc4da42b87',
      liquidityManager: '0xd21251556da5a7227281f6e8479510ed106eb16b',
      liquidityMigrator: '0xc8D88A10E63bfD19e6c033B01d529B4A01057C07',
      uniState: '0xFDbC00CE07c6d8199e646D75AB1a97D6bc8648E3',
      oracle: '0x88443bb81713697373356BcD76CF265A1ffc1dEa',
      farmingV1: '0xD0577b90084D602915da0da42E44126A7312a682',
      farmingV2: '0x2bAfa37D1604B177E5FFCc17b1cff97e468b588e',
      farmingV3: '0x89D424AA543ff3B5dafe62642f9D418a5B0E4188',
      balance: '0x84Dc72D9Ce4Bf5cA2b1E6e7a3d57C16c723cf0f3',
      multicall: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
      uniswapV3Factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
      nonfungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
      sushiFactory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
      hypervisorFactory: '0x55123b2396ad16414b630345DD6f7E18784F51A6',
      lixirFactory: '0xC1e1B51C55Be2154fc7c3a30662F60234F3A9335',
    }
  : {
      unipilot: '0xde5bF92E3372AA59C73Ca7dFc6CEc599E1B2b08C',
      liquidityManager: '0xA7979d0592ecfC59b082552828FF36209ec94B11',
      liquidityMigrator: '0x809dAD6cfcfa220028e462a494D4E6378c7C3fBb',
      uniState: '0xc4a8EC5b29a392Dd6dF0d18e07C33301e0275464',
      oracle: '0x458766536f95B9333D48A78A91AB1aaF4226221a',
      farmingV1: '0x6E665791f0219a10A4D52AC0915931Cf045220B8',
      farmingV2: '0x8fdDc464ee63575463AFd214586344a09b309102',
      farmingV3: '0x63534BF9ae7C066Cb9413613212925D70235754F',
      balance: '0x6B9aE204B9779fbd15Fdbd027c9F0d266A32f0b7',
      multicall: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
      uniswapV3Factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
      nonfungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
      sushiFactory: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
      hypervisorFactory: '0xaE03233307865623Aaef76Da9ADe669b86e6F20A',
      lixirFactory: '0x4F0070fBBFA810d7f3128e8f09E48EA7dFAa8bF1',
    };

export const WETH_ADDRESS = DEV_ENVIRONMENT
  ? '0xc778417e063141139fce010982780140aa0cd5ab'
  : '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';

export const PILOT_ADDRESS = '0x37c997b35c619c21323f3518b9357914e8b99525';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
