import { useState } from 'react';

import useStyles from './styles';
import { UN_KNOWN_LOGO } from '../../constants';
import { getUri } from '../../utils/helpers';

interface ITokenLogo {
  uri: string | undefined;
  tokenAddress: string;
  className?: string;
}

const BAD_SRCS: { [tokenAddress: string]: true } = {};

const TokenLogo: React.FC<ITokenLogo> = ({ tokenAddress, uri, className }) => {
  const classes = useStyles();
  const [, refresh] = useState<number>(0);

  const src = uri && !BAD_SRCS[tokenAddress.toLowerCase()] ? getUri(uri) : UN_KNOWN_LOGO;

  return (
    <img
      className={`${classes.logo} ${className ? className : ''}`}
      src={src}
      onError={() => {
        BAD_SRCS[tokenAddress.toLowerCase()] = true;
        refresh(1);
      }}
      alt='icon'
    />
  );
};

export default TokenLogo;
