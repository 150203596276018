import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 25,
    height: 25,
  },
  icon1: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  amount: {
    fontSize: "1.2rem",
    color: "white",
    fontWeight: 500,
  },
  iconContainer01: {
    backgroundColor: "rgba(207, 15, 109,0.2)",
    borderRadius: theme.shape.borderRadius * 2,
    paddingBottom: 5,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer02: {
    backgroundColor: "rgba(255,255,255,0.2)",
    position: "absolute",
    top: 0,
    left: -20,
    // padding: 3,
    borderRadius: theme.shape.borderRadius * 2,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer03: {
    // backgroundColor: "rgba(207, 15, 109,0.2)",
    backgroundColor: "rgba(255, 255, 255,1)",
    borderRadius: theme.shape.borderRadius * 2,
    paddingBottom: 5,
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer04: {
    // backgroundColor: "rgba(255,255,255,0.2)",
    backgroundColor: "rgba(255,255,255,1)",
    position: "absolute",
    top: -8,
    right: -8,
    // padding: 3,
    borderRadius: theme.shape.borderRadius * 2,
    width: 15,
    height: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon02: {
    width: 20,
    height: 20,
  },
  icon03: {
    width: 15,
    height: 15,
  },
  iconContainer05: {
    // backgroundColor: "rgba(207, 15, 109,0.2)",
    backgroundColor: "rgba(255,255,255,0.2)",
    position: "absolute",
    top: 0,
    left: -20,
    // padding: 3,
    borderRadius: theme.shape.borderRadius * 2,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer06: {
    backgroundColor: "rgba(207, 15, 109,0.2)",
    borderRadius: theme.shape.borderRadius * 2,
    paddingBottom: 5,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconHelp: {
    fontSize: "1rem",
    color: "#fff",
    marginLeft: "5px",
    "@media(max-width:550px)": {
      width: "25px",
    },
  },
  tooltip: {
    fontSize: "0.8rem",
  },
}));

export default useStyles;
