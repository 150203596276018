import { Box, Typography, CircularProgress } from '@material-ui/core';

import { RowAlignCenter, CGrid } from '../../components';
import useStyles from './styles';
import TextWithInfo from '../../pages/pairLiquidity/TextWithInfo';

interface ITotal {
  text: string;
  amount: string;
  loading?: boolean;
  icon: JSX.Element;
  subValue1: string;
  subValue2: string;
}

const Total: React.FC<ITotal> = ({ text, amount, icon, subValue1, subValue2, loading = false }) => {
  const classes = useStyles();

  return (
    <RowAlignCenter
      containerClassName={classes.container}
      elements01={
        <Box className={classes.box}>
          <Typography style={{ display: 'flex' }} variant='body2'>
            {text}
            {!loading && (
              <TextWithInfo
                text={''}
                jsx={
                  <Box className={classes.tooltipc}>
                    <Row title='Protocol TVL' val={subValue1} />
                    <Row title='Farming TVL' val={subValue2} />
                  </Box>
                }
              />
            )}
          </Typography>
          {loading ? (
            <CircularProgress style={{ marginTop: '16px' }} size={15} />
          ) : (
            <Typography variant='h4'>{amount}</Typography>
          )}
        </Box>
      }
      elements02={icon}
    />
  );
};

interface IRow {
  title: string;
  val?: null | undefined | string;
}

const Row: React.FC<IRow> = ({ val, title }) => {
  return (
    <CGrid
      alignItems='center'
      item02Props={{
        style: {
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flexs',
        },
      }}
      elements01={<Typography style={{ fontSize: '0.7rem' }}>{title}</Typography>}
      elements02={
        <Typography style={{ marginLeft: 10, fontSize: '0.7rem' }}>{val ? val : 0}</Typography>
      }
    />
  );
};

export default Total;
