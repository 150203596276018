import { TransactionTypes } from '../actionTypes';
import { TransactionAction } from '../actions/transactionActions';
import { ITransaction } from '../../utils/generalTypes';
import { TRANSACTION_STATUS } from '../../utils/enums';

export interface TransactionState {
  transactions: ITransaction[] | [];
  blockNumber: number;
}

let data;
let account: string;
const userTransactions = JSON.parse(localStorage.getItem('transactions') ?? '{}');

const initialState: TransactionState = {
  transactions: [],
  blockNumber: 0,
};

const transactionReducer = (state: TransactionState = initialState, action: TransactionAction) => {
  switch (action.type) {
    case TransactionTypes.ADD_TRANSACTION:
      account = action.payload.account;
      data = userTransactions[account] ?? [];
      userTransactions[account] = [action.payload, ...data];
      localStorage.setItem('transactions', JSON.stringify(userTransactions));
      return { ...state, transactions: userTransactions[account] };

    // case TransactionTypes.ADD_TRANSACTION:
    //   account = action.payload.account;
    //   data = userTransactions[account] ?? [];
    //   if (
    //     action.payload.status === TRANSACTION_STATUS.SUCCESS ||
    //     action.payload.status === TRANSACTION_STATUS.FAILED
    //   ) {
    //     data = data.filter((txn: ITransaction) => txn.hash !== action.payload.hash);
    //   }
    //   userTransactions[account] = [action.payload, ...data];
    //   localStorage.setItem('transactions', JSON.stringify(userTransactions));
    //   return { ...state, transactions: userTransactions[account] };

    case TransactionTypes.CLEAR_TRANSACTION:
      delete userTransactions[action.payload];
      localStorage.setItem('transactions', JSON.stringify(userTransactions));
      return { ...state, transactions: [] };

    case TransactionTypes.GET_TRANSACTIONS:
      return {
        ...state,
        transactions: userTransactions[action.payload] ?? [],
      };

    case TransactionTypes.UPDATE_TRANSACTION:
      account = action.payload.account;
      const index = userTransactions[account].findIndex(
        (txn: ITransaction) => txn.hash === action.payload.hash
      );
      if (index >= 0) {
        userTransactions[account][index] = action.payload;
        localStorage.setItem('transactions', JSON.stringify(userTransactions));
        return {
          ...state,
          transactions: userTransactions[account],
        };
      }
      return state;

    case TransactionTypes.REMOVE_TRANSACTION:
      account = action.payload.account;
      userTransactions[account] = userTransactions[account].filter(
        (txn: ITransaction) => txn.hash !== action.payload.hash
      );
      localStorage.setItem('transactions', JSON.stringify(userTransactions));
      return {
        ...state,
        transactions: userTransactions[account],
      };

    default:
      return state;
  }
};

export default transactionReducer;
