import { RefObject } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { formatDollarAmount } from '../../utils/formating';

dayjs.extend(utc);
interface IChart {
  data: number[][];
  defaultValue: string;
  valueRef: RefObject<HTMLSpanElement>;
  labelRef: RefObject<HTMLSpanElement>;
}

const defaultOptions: Highcharts.Options & any = {
  chart: {
    type: 'areaspline',
    backgroundColor: 'transparent',
    height: 350,
    // height: 280,
  },
  title: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    ordinal: false,
    type: 'datetime',
    tickColor: 'transparent',
    lineColor: 'transparent',
    tickInterval: 5 * 24 * 3600 * 1000,
    labels: {
      formatter: function (dateObj: any) {
        return dayjs(dateObj?.value).format('DD');
      },
    },
    //tickPixelInterval: 10,
  },
  yAxis: {
    title: {
      text: '',
    },
    gridLineWidth: 0,
    visible: false,
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      lineWidth: 2,
      lineColor: '#EFA618',
      color: '#EFA618',
      states: {
        hover: {
          lineWidth: 4,
        },
      },
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 1,
        },
        stops: [
          [0, 'rgba(239, 166, 24,0.6'],
          [1, 'transparent'],
        ],
      },
    },
  },
};

const Chart: React.FC<IChart> = ({ data, defaultValue, valueRef, labelRef }) => {
  const setTooltipValue = (tooltip: any) => {
    const valueInstance = valueRef.current;
    const labelInstance = labelRef.current;
    if (valueInstance && labelInstance) {
      valueInstance.innerHTML = formatDollarAmount(parseFloat(tooltip?.target?.y));
      labelInstance.innerHTML = dayjs(tooltip?.target?.x).format('MMM D, YYYY') + ' (UTC)';
    }
  };

  const setDefaultValue = () => {
    const valueInstance = valueRef.current;
    const labelInstance = labelRef.current;
    if (valueInstance && labelInstance) {
      valueInstance.innerHTML = defaultValue;
      labelInstance.innerHTML = '';
    }
  };

  const options = {
    ...defaultOptions,
    series: [
      {
        data: data,
      },
    ],
    plotOptions: {
      ...defaultOptions.plotOptions,
      series: {
        pointInterval: 5 * 24 * 3600 * 1000,
        point: {
          events: {
            mouseOver: setTooltipValue,
          },
        },
        events: {
          mouseOut: setDefaultValue,
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Chart;
