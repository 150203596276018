import { unipilotContract } from '../getContract';
import { logError, logMessage } from '../../utils/logs';
import { sendTransaction } from '../sendTransaction';
import { CONTRACT_ADDRESSES } from '../../constants';
import { INFTPosition, ILiquidity } from '../../utils/generalTypes';
import { tokenDetail } from '../functions/erc20';
import { LIQUIDITY_TYPES } from '../../utils/enums';
import { rebaseFrequency, liquidityPositions, shouldRebase } from './liquidityManager';
import { getPositionDetails } from './uniState';
import { CALL_TYPE, LOADING } from '../../utils/enums';
import { singleContractMultipleData, singleCall, DecodeType, encodeParameters } from '../calls';
import { fetchNftClaimedReward } from '../../data';
import { formatAmount } from '../../utils/formating';

export const addUnipilotLiquidity = async (
  params: ILiquidity['add'],
  feeTier: string,
  token0Symbol: string,
  token1Symbol: string,
  walletAddress: string,
  liquidityType: LIQUIDITY_TYPES,
  ethValue?: string,
  callback: any = () => {}
) => {
  try {
    logMessage('addUnipilotLiquidity', params);
    const contract = unipilotContract();
    const bytes = encodeParameters(['uint24'], [feeTier]);
    const contractFnc = contract?.methods.deposit(params, bytes);
    const args = {
      walletAddress,
      txnMessage: `${
        liquidityType === LIQUIDITY_TYPES.ADD ? 'Add' : 'Increase'
      } Liquidity ${token0Symbol}/${token1Symbol}`,
      logMessage: 'addUnipilotLiquidity',
      dappLoading:
        liquidityType === LIQUIDITY_TYPES.ADD ? LOADING.ADD_LIQUIDITY : LOADING.INCREASE_LIQUIDITY,
      ethValue,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('addUnipilotLiquidity', e);
  }
};

export const createPoolAndDeposit = async (
  params: ILiquidity['createAndAdd'],
  feeTier: string,
  sqrtPrice: string,
  token0Symbol: string,
  token1Symbol: string,
  walletAddress: string,
  ethValue?: string,
  callback: any = () => {}
) => {
  try {
    logMessage('createPoolAndDeposit', params);
    const contract = unipilotContract();
    const bytesCreate = encodeParameters(['uint24', 'uint160'], [feeTier, sqrtPrice]);
    const bytesDeposite = encodeParameters(['uint24'], [feeTier]);
    const contractFnc = contract?.methods.createPoolAndDeposit(params, [
      bytesCreate,
      bytesDeposite,
    ]);
    const args = {
      walletAddress,
      txnMessage: `Create pool & add liquidity ${token0Symbol}/${token1Symbol}`,
      logMessage: 'createPoolAndDeposit',
      dappLoading: LOADING.ADD_LIQUIDITY,
      ethValue,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('createPoolAndDeposit', e);
  }
};

export const balanceOf = async (walletAddress: string): Promise<string | undefined> => {
  try {
    const contract = unipilotContract();
    const balance = await contract?.methods.balanceOf(walletAddress).call();
    return balance;
  } catch (e) {
    logError('balanceOf', e);
  }
};

export const tokenOfOwnerByIndex = async (
  call: CALL_TYPE,
  [balance, walletAddress]: [number, string]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: '',
    },
  ];
  const contract = unipilotContract();
  const method = contract?.methods.tokenOfOwnerByIndex;
  if (call === CALL_TYPE.MULTI) {
    const indexes = [...Array(balance).keys()].map(index => [walletAddress, index]);
    return await singleContractMultipleData(
      indexes,
      CONTRACT_ADDRESSES.unipilot,
      method,
      types,
      'multi-tokenOfOwnerByIndex '
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall([walletAddress, balance], method, 'single-tokenOfOwnerByIndex');
  }
};

export const ownerOf = async (call: CALL_TYPE, tokenIds: number[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  const contract = unipilotContract();
  const method = contract?.methods.ownerOf;
  if (call === CALL_TYPE.MULTI) {
    return await singleContractMultipleData(
      tokenIds,
      CONTRACT_ADDRESSES.unipilot,
      method,
      types,
      'multi-ownerOf '
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(tokenIds[0], method, 'single-ownerOf');
  }
};

export const getUnipilotPositionDetails = async (
  tokenIds: number[],
  walletAddress: string
): Promise<INFTPosition[] | undefined> => {
  try {
    //get positions from nft id's
    const positionDetails =
      (await getPositionDetails(CALL_TYPE.MULTI, tokenIds, [
        'liquidity',
        'pool',
        'token0',
        'token1',
        'fee',
        'currentTick',
        'amount0',
        'amount1',
        'totalLiquidity',
      ])) ?? [];

    const owners = await ownerOf(CALL_TYPE.MULTI, tokenIds);

    //extract tokenAddresses from positions
    const tokenAddresses = positionDetails.flatMap(({ token0, token1 }: any) => [token0, token1]);

    //extract poolAddresses from positions
    const poolAddresses = positionDetails.map(({ pool }: any) => pool);

    //get tokens details from tokenAddresses
    const tokens = await tokenDetail(tokenAddresses, walletAddress);

    //get base and range ticks
    const liquidityPosition = await liquidityPositions(CALL_TYPE.MULTI, poolAddresses, [
      'baseTickLower',
      'baseTickUpper',
      'rangeTickLower',
      'rangeTickUpper',
      'feesInPilot',
      'managed',
      'oracle0',
      'oracle1',
    ]);

    const readjustParams = liquidityPosition.map(
      ({ baseTickLower, baseTickUpper }: any, index: number) => [
        poolAddresses[index],
        Number(baseTickLower),
        Number(baseTickUpper),
      ]
    );

    //get position rebasing
    const rebasing = await shouldRebase(CALL_TYPE.MULTI, readjustParams, ['readjust']);

    const rewards = await fetchNftClaimedReward(tokenIds.map(tokenId => tokenId.toString()));
    let newPositions: INFTPosition[] = [];

    for (let i = 0; i < tokenIds.length; i++) {
      const {
        liquidity,
        pool: poolAddress,
        token0: token0Address,
        token1: token1Address,
        fee,
        currentTick,
        amount0,
        amount1,
        totalLiquidity,
      } = positionDetails[i];
      const {
        baseTickLower,
        baseTickUpper,
        rangeTickLower,
        rangeTickUpper,
        feesInPilot,
        managed,
        oracle0,
        oracle1,
      } = liquidityPosition[i];
      const token0 = tokens[token0Address.toLowerCase()];
      const token1 = tokens[token1Address.toLowerCase()];
      const tokenId = tokenIds[i];
      const inRange = !rebasing[i].readjust;
      const reward = rewards ? formatAmount(rewards[tokenId] ?? '0', 18) : '0';
      const owner = owners[i];

      let allowRebase = false;
      let rebaseIncentive = managed;
      if (!inRange) {
        allowRebase = await rebaseFrequency(poolAddress);
      }

      //arrange all data into single  position
      newPositions.push({
        owner,
        tokenId,
        poolAddress,
        token0,
        token1,
        fee,
        liquidity,
        totalLiquidity,
        amount0,
        amount1,
        currentTick,
        inRange,
        rebaseIncentive,
        allowRebase,
        baseTickLower,
        baseTickUpper,
        rangeTickLower,
        rangeTickUpper,
        reward,
        feesInPilot,
        oracle0,
        oracle1,
      });
    }
    return newPositions;
  } catch (e) {
    logError('getUnipilotPositionDetails', e);
  }
};

export const findNFTPositions = async (
  walletAddress: string
): Promise<INFTPosition[] | undefined> => {
  try {
    //get user nft balance
    const balance = await balanceOf(walletAddress);

    //get nft ids w.r.t index
    const tokenIds =
      (await tokenOfOwnerByIndex(CALL_TYPE.MULTI, [Number(balance ?? '0'), walletAddress])) ?? [];

    const temp = await getUnipilotPositionDetails(tokenIds, walletAddress);

    return temp;
  } catch (e) {
    logError('findNFTPositions', e);
  }
};

export const removeLiquidity = async (
  params: ILiquidity['remove'],
  tokenId: string,
  walletAddress: string,
  token0Symbol: string,
  token1Symbol: string,
  addToken: boolean,
  callback: any = () => {}
) => {
  try {
    logMessage('Remove Liquidity', params);
    const contract = unipilotContract();
    const bytes = encodeParameters(['address'], [walletAddress]);
    logMessage('Remove Liquidity Bytes', bytes);
    const contractFnc = contract?.methods.withdraw(params, bytes);
    const args = {
      walletAddress,
      txnMessage: `Removed liquidity ${token0Symbol}/${token1Symbol}`,
      logMessage: 'removeLiquidity',
      dappLoading: `${LOADING.REMOVE_LIQUIDITY}-${tokenId}`,
      addToken,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('removeLiquidity', e);
  }
};

export const collect = async (
  params: ILiquidity['collect'],
  tokenId: string,
  walletAddress: string,
  token0Symbol: string,
  token1Symbol: string,
  addToken: boolean,
  callback: any = () => {}
) => {
  try {
    const contract = unipilotContract();
    const bytes = encodeParameters(['address'], [walletAddress]);
    const contractFnc = contract?.methods.collect(params, bytes);
    const args = {
      walletAddress,
      txnMessage: `Collect fee ${token0Symbol}/${token1Symbol}`,
      logMessage: 'collect',
      dappLoading: `${LOADING.COLLECT_FEE}-${tokenId}`,
      addToken,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('collect', e);
  }
};

export const approveUnipilotNft = async (
  tokenId: string,
  to: string,
  walletAddress: string,
  callback: any = () => {}
) => {
  try {
    const contract = unipilotContract();
    const contractFnc = contract?.methods.approve(to, tokenId);
    const args = {
      walletAddress,
      txnMessage: `Approved Unipilot NFT`,
      logMessage: 'approveUnipilotNft',
      dappLoading: `${LOADING.APPROVE}-${tokenId}`,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('approveUnipilotNft', e);
  }
};

export const checkUnipilotNftApproval = async (tokenId: string, approvedTo: string) => {
  try {
    const contract = unipilotContract();
    const approvedAddress = await contract?.methods.getApproved(tokenId).call();
    return approvedAddress?.toLowerCase() === approvedTo.toLowerCase();
  } catch (e) {
    logError('checkUnipilotNftApproval', e);
    return false;
  }
};
