import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  switch: {
    display: 'flex',
    alignItems: 'center',
    //width: '120px',
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  switchBody: {
    //width: '60px',
    overflow: 'hidden',
  },
  switchSec: {
    cursor: 'pointer',
    padding: '5px 8px',
    background: 'transparent',
    textAlign: 'center',
    color: theme.palette.primary.main,
    '& p': {
      fontSize: '10px',
    },
  },
  switchActive: {
    //width: '70px',
    color: '#fff',
    background: theme.palette.primary.main,
  },
}));

export default useStyles;
