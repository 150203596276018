import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { BigNumber } from "bignumber.js";

import {
  getFarmingPools,
  getUserLockedFarmingNfts,
  setLock,
  updateFarmingNft,
  updateFarmingTvl,
  updateSortBy,
  closeFarmingInfoModal,
  State,
} from "../redux";
import { INFTPosition, IFarmingNFT, IFarmingPool } from "../utils/generalTypes";
import { useUnipilotNFT, useApp, useStats } from "./";
import { useDispatchWrap } from "./utilHooks";
import { formatAmount } from "../utils/formating";
import { FARMING_VERSION } from "../utils/enums";

export const useFarming = () => {
  const farming = useSelector((state: State) => state.farming);
  const getFarmingPoolsF = useDispatchWrap(getFarmingPools);
  const getUserLockedFarmingNftsF = useDispatchWrap(getUserLockedFarmingNfts);
  const setLockF = useDispatchWrap(setLock);
  const updateFarmingNftF = useDispatchWrap(updateFarmingNft);
  const updateFarmingTvlF = useDispatchWrap(updateFarmingTvl);
  const updateSortByF = useDispatchWrap(updateSortBy);
  const closeFarmingInfoModalF = useDispatchWrap(closeFarmingInfoModal);

  return {
    ...farming,
    getFarmingPoolsF,
    getUserLockedFarmingNftsF,
    setLockF,
    updateFarmingNftF,
    updateFarmingTvlF,
    updateSortByF,
    closeFarmingInfoModalF,
  };
};

export const useFarmingPositions = (pool: any) => {
  const { getUserPosition } = useUnipilotNFT();
  const { locked, userLockedNfts } = useFarming();
  const { id } = pool;

  const typeCastNfts = useCallback((nfts: INFTPosition[]): IFarmingNFT[] => {
    return nfts.map(
      ({
        tokenId,
        poolAddress,
        liquidity,
        totalLiquidity,
        token0Reserve,
        token1Reserve,
        token0,
        token1,
        amount0,
        amount1,
        reward,
      }) => {
        if (token0Reserve && token1Reserve) {
          return {
            tokenId,
            poolAddress,
            liquidity,
            totalLiquidity,
            token0Reserve,
            token1Reserve,
            locked: false,
            lockedContract: "",
            claimedReward: Number(reward) ?? 0,
            farmingVersion: FARMING_VERSION.V3,
          };
        } else {
          const userShare = new BigNumber(liquidity).dividedBy(totalLiquidity);
          return {
            tokenId,
            poolAddress,
            liquidity,
            totalLiquidity,
            token0Reserve: new BigNumber(formatAmount(amount0, token0.decimals))
              .multipliedBy(userShare)
              .toString(),
            token1Reserve: new BigNumber(formatAmount(amount1, token1.decimals))
              .multipliedBy(userShare)
              .toString(),
            locked: false,
            claimedReward: Number(reward) ?? 0,
            lockedContract: "",
            farmingVersion: FARMING_VERSION.V3,
          };
        }
      }
    );
  }, []);

  const userPosition = useMemo(() => {
    const userUnlockedPositions = getUserPosition(id);
    if (userUnlockedPositions === null) return null;
    else {
      let farmingNfts = typeCastNfts(userUnlockedPositions);
      let lockedNfts: IFarmingNFT[] = [];
      if (userLockedNfts[id.toLowerCase()]) {
        lockedNfts = userLockedNfts[id.toLowerCase()];
        farmingNfts = [...farmingNfts, ...lockedNfts];
      }
      return locked ? lockedNfts : farmingNfts;
    }
  }, [id, getUserPosition, userLockedNfts, locked]);

  return { userPosition };
};

const BLOCKS_PER_MONTH = 172800;
const MONTHS_IN_YEAR = 12;

export const useModifiedFarmingPools = () => {
  const { farmingPools, farmingPoolsTvl } = useFarming();
  const { pilotPrice } = useApp();
  const { poolApy } = useStats();

  const calculateApr = useCallback(
    (pool: IFarmingPool, poolTvl: string) => {
      const { rewardPerBlock, multiplier } = pool;
      if (parseFloat(poolTvl) !== -1 && pilotPrice) {
        const _poolTvl = parseFloat(poolTvl) > 0 ? poolTvl : "1000";
        if (rewardPerBlock && parseFloat(_poolTvl) > 0 && multiplier) {
          const pilotReward = new BigNumber(rewardPerBlock)
            .multipliedBy(multiplier)
            .multipliedBy(pilotPrice)
            .multipliedBy(BLOCKS_PER_MONTH)
            .multipliedBy(MONTHS_IN_YEAR);
          return pilotReward.dividedBy(_poolTvl).multipliedBy(100).toString();
        } else {
          return "0";
        }
      }
    },
    [pilotPrice]
  );

  const modifiedFarmingPools = useMemo<IFarmingPool[] | undefined>(() => {
    if (farmingPools) {
      return farmingPools.map((pool) => {
        const tvl = farmingPoolsTvl[pool.id];
        const poolFeesApr = poolApy ? poolApy[pool.id] : "0";
        const rewardApr = calculateApr(pool, tvl ?? "-1");
        return {
          ...pool,
          tvl: tvl,
          apr: rewardApr,
          completeApr: rewardApr
            ? (parseFloat(rewardApr) + parseFloat(poolFeesApr)).toString()
            : "0",
        };
      });
    }
  }, [farmingPools, calculateApr, farmingPoolsTvl, poolApy]);

  return { modifiedFarmingPools };
};

export const useSortedFarmingPools = () => {
  const { sortBy } = useFarming();
  const { modifiedFarmingPools } = useModifiedFarmingPools();

  const sortedPools = useMemo<IFarmingPool[] | undefined>(() => {
    if (modifiedFarmingPools) {
      const pools = modifiedFarmingPools.sort((accumPool, pool) => {
        if (accumPool && pool) {
          const poolSortValue = (pool[sortBy] ?? "0") as string;
          const accumPoolSortValue = (accumPool[sortBy] ?? "0") as string;
          if (
            parseFloat(poolSortValue) === 0 ||
            parseFloat(accumPoolSortValue) > parseFloat(poolSortValue)
          ) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return -1;
        }
      });
      return pools;
    }
  }, [modifiedFarmingPools, sortBy]);

  return sortedPools;
};
