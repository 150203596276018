import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '5px 10px',
    borderRadius: 20,
    '@media(max-width:550px)': {
      padding: '5px',
    },
  },
  inRange: {
    background: 'rgba(239, 166, 24,0.1)',
    justifyContent: 'center',
    '& p': {
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
  },
  outRange: {
    background: 'rgba(255, 32, 32,0.1)',
    '& p': {
      color: 'rgb(255, 32, 32)',
      fontSize: '14px',
    },
    padding: '4px 8px',
  },
  warnIcon: {
    width: 16,
    height: 16,
    marginTop: 2,
    margin: '0px 5px',
    '@media(max-width:550px)': {
      width: 14,
      height: 14,
      padding: '0',
    },
  },
  text: {
    padding: '0px 5px',
  },
  range: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
  close: {
    background: 'rgb(33, 36, 41, 0.8)',
  },
}));

export default useStyles;
