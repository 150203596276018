import { Typography } from '@material-ui/core';

import useStyles from './styles';

interface IGreenInfo {
  content: string;
  icon?: any;
  className?: string;
}

const GreenInfo: React.FC<IGreenInfo> = ({ content = 'content', icon, className }) => {
  const classes = useStyles();

  return (
    <Typography classes={{ root: `${classes.root} ${className ? className : ''}` }}>
      {icon ?? ''}
      {content}
    </Typography>
  );
};

export default GreenInfo;
