import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  eth: {
    width: 14,
    height: 14,
  },
  pilotToken: {
    width: 30,
    height: 30,
  },
  pilotIcon: {
    width: 30,
    height: 30,
  },
}));

export default useStyles;
