import { Box, Typography } from "@material-ui/core";

import useStyles from "./styles";
import { DoubleToken, GreenInfo } from "..";
import { truncSymbol } from "../../utils/formating";

interface IPoolSymbols {
  token0Address: string;
  token1Address: string;
  token0Symbol: string;
  token1Symbol: string;
  token0Uri: string | undefined;
  token1Uri: string | undefined;
  feeTier?: string;
  customClass?: string;
}

const PoolInfo: React.FC<IPoolSymbols> = ({
  token0Address,
  token1Address,
  token0Symbol,
  token1Symbol,
  token0Uri,
  token1Uri,
  feeTier,
  customClass,
}) => {
  const classes = useStyles();
  return (
    <Box className={[classes.container, customClass].join(" ")}>
      <DoubleToken
        token0Address={token0Address}
        token1Address={token1Address}
        token0Uri={token0Uri}
        token1Uri={token1Uri}
      />
      <Typography variant="h6">{`${truncSymbol(token0Symbol, 8)}/${truncSymbol(
        token1Symbol,
        8
      )}`}</Typography>
      {feeTier && (
        <GreenInfo
          className={classes.feetier}
          content={`${Number(feeTier) / 10000}%`}
        />
      )}
    </Box>
  );
};

export default PoolInfo;
