import { Divider } from '@material-ui/core';

import useStyles from './styles';

function CDivider() {
  const classes = useStyles();

  return <Divider className={classes.divider} />;
}

export default CDivider;
