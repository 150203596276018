import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    background: 'rgba(0, 0, 0, 0.6)',
    boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.16)',
  },
}));

export default useStyles;
