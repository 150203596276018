import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '20px',
    // height: '20px',
    // backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // marginTop: 12,
    // height: 18,
  },
  icon: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
    // paddingTop: 5,
  },
}));

export default useStyles;
