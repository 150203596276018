import { Box } from '@material-ui/core';

import useStyles from './styles';
import { classesJoin } from '../../utils/helpers';

interface IBoxContainer {
  children: any;
  className?: string;
  style?: object;
}

const BoxContainer: React.FC<IBoxContainer> = ({ children, className, style }) => {
  const classes = useStyles();
  return (
    <Box style={style} className={classesJoin([classes.root, className ?? ''])}>
      {children}
    </Box>
  );
};

export default BoxContainer;
