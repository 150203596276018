import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  programmerIcon: {
    width: 20,
    height: 20,
    margin: '0px 10px',
  },
  contentContainer: {
    height: '33px',
    padding: '0 10px',
    //backgroundColor: theme.palette.common.blackish,
    //padding: `${theme.spacing(0.6)}px ${theme.spacing(2)}px`,
    fontSize: '0.9rem',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(255,255,255,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  specialContent: {
    height: '33px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: '0.9rem',
    border: '1px solid rgba(255,255,255,0.1)',
    padding: '0 16px',
    // maxHeight: "20px",
    //alignSelf: 'center',
    borderRadius: 10,
  },
  appBar: {
    marginBottom: '30px',
  },
  goToBottom: {
    top: 'auto',
    bottom: 0,
  },
  toolBarBefore: {
    padding: '0px 0px',
  },
  toolBarAfter: {
    padding: 0,
  },
  logo: {
    width: 140,
    height: 20,
  },
  logoTick_xs: {
    width: 45,
    height: 20,
  },
  logoTick: {
    width: 75,
    height: 30,
  },
  indicator: {
    // maxWidth: "8%",
  },
  tabRoot: {
    // backgroundColor: "red",
    minWidth: 30,
    padding: '5px 0px',
    marginLeft: '50px',
    textTransform: 'none',
  },
  drawerListItem: {
    // backgroundColor: "red",
    '&:hover': {
      backgroundColor: theme.palette.primary.main, //"rgba(239, 166, 24, 0.2)",
    },
  },
  drawerListItemActive: {
    backgroundColor: theme.palette.primary.main,
  },
  drawerRoot: {
    backgroundColor: 'rgba(255,255,255,0.15)',
  },
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: 220,
  },
  drawerListItemHoverNone: {
    '&:hover': {
      backgroundColor: 'transparent', //"rgba(239, 166, 24, 0.2)",
    },
  },
  bottomBorder: {
    // borderBottom: `2px solid ${theme.palette.common.blackish}`,
    // background: "#101D2E",
  },
  inValidChain: {
    borderColor: '#CF352E',
    color: '#CF352E',
  },
  pilotPrice: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
    '& p': {
      fontSize: '16px',
      color: '#fff',
      fontWeight: '500',
      marginLeft: '2px',
    },
    marginRight: '15px',
    // backgroundColor: "red",
    minWidth: '130px',
  },
  pilotLogo: {
    width: '30px',
    height: '30px',
  },
  container: {
    background: 'rgba(0, 0, 0, 0.6)',
    [theme.breakpoints.down('sm')]: {
      background: 'rgba(0, 0, 0, 1)',
    },
    borderRadius: theme.shape.borderRadius,
    marginTop: 20,
  },
  backdropRoot: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255,255,255,0.05) !important',
  },
  item01Center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pilotText: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: '1rem !important',
  },
  walletConnect: {
    backgroundColor: 'rgb(255, 165, 0, 0.3)',
    color: '#ffa500',
    border: 'none',
    '&:hover': {
      background: 'rgb(255, 165, 0, 0.4)',
    },
  },
  Ico: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  disableTab: {
    opacity: 0.5,
    //pointerEvents: 'none',
  },
}));

export default useStyles;
