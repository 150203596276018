import { useEffect } from 'react';
import { Box, Container, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { BigNumber } from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import LaunchIcon from '@material-ui/icons/Launch';

import useStyles from './styles';
import { ButtonIndicator, CGrid, BackButton } from '../../components';
import { useNFTPositions } from '../../hooks';
import { getTokensDollarValue } from '../../redux';
import { colletFee } from '../../contracts/functions/liquidityManager';
import LiquidityDetails from './LiquidityDetails';
import NFTSection from './NFTSection';
import { fectPoolApy, getBlocks, getPosition24hrDust } from '../../data';
import { INFTPosition, IToken, IUpdateNFTPositionData } from '../../utils/generalTypes';
import { priceFromTick } from '../../utils/uniswapSDKFunctions';
import { formatAmount } from '../../utils/formating';
import { ETHERSCAN_ADDRESS_BASE_URL } from '../../constants';

interface IPositionDetail {
  position: INFTPosition;
}

const PositionDetail: React.FC<IPositionDetail> = ({ position }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { account } = useWeb3React();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));
  const _sm02 = useMediaQuery('@media (max-width:800px)');

  const { addTokenDetailsF, nftPositions } = useNFTPositions();

  const { totalLiquidity, liquidity, amount0, amount1, owner } = position;

  const getTokensDV = async (token0: IToken, token1: IToken) => {
    const res = await getTokensDollarValue([token0.address, token1.address]);
    if (res) {
      return {
        token0DollarValue: res?.token0DV,
        token1DollarValue: res?.token1DV,
      };
    } else {
      return { token0DollarValue: null, token1DollarValue: null };
    }
  };

  const performCalculations = async (nftPosition: INFTPosition) => {
    const { token0, token1, tokenId, currentTick, poolAddress } = nftPosition;
    const { token0DollarValue, token1DollarValue } = await getTokensDV(token0, token1);
    const poolApy = await fectPoolApy(poolAddress);
    // const positionDust = await getPosition24hrDust(poolAddress, nftPosition.tokenId);
    const blocks = (await getBlocks()) ?? [];
    const [block24] = blocks;

    // console.log('block24 => ', block24.number);

    const calculateTotalAmountDV = (token0Formated: string, token1Formated: string) => {
      const token0AmountDV = token0DollarValue
        ? new BigNumber(token0Formated).multipliedBy(new BigNumber(token0DollarValue))
        : null;
      const token1AmountDV = token1DollarValue
        ? new BigNumber(token1Formated).multipliedBy(new BigNumber(token1DollarValue))
        : null;
      return { token0AmountDV, token1AmountDV };
    };

    const calculateAmountWRTUserShare = (
      userLiqShare: BigNumber,
      amount0: string,
      amount1: string,
      isFee: boolean = false
    ) => {
      const token0Formated = formatAmount(amount0, token0.decimals);
      const token1Formated = formatAmount(amount1, token1.decimals);

      const token0Value = isFee
        ? token0Formated
        : new BigNumber(token0Formated).multipliedBy(userLiqShare);
      const token1Value = isFee
        ? token1Formated
        : new BigNumber(token1Formated).multipliedBy(userLiqShare);

      const { token0AmountDV, token1AmountDV } = calculateTotalAmountDV(
        token0Value.toString(),
        token1Value.toString()
      );

      const totalSumValue =
        token0AmountDV && token1AmountDV ? token0AmountDV.plus(token1AmountDV) : null;
      return {
        token0Value,
        token1Value,
        totalSumValue,
      };
    };

    const userLiqShare =
      parseFloat(totalLiquidity) > 0
        ? new BigNumber(liquidity).dividedBy(new BigNumber(totalLiquidity))
        : new BigNumber('0');

    const { token0Fee, token1Fee } = await colletFee(tokenId);
    const { token0Fee: token0FeeLastDay, token1Fee: token1FeeLastDay } = await colletFee(
      tokenId,
      block24?.number
    );

    // console.log('token0Fee => ', token0Fee);
    // console.log('token1Fee => ', token1Fee);
    // console.log('token0FeeLastDay => ', token0FeeLastDay);
    // console.log('token1FeeLastDay => ', token1FeeLastDay);

    const {
      token0Value: token0Reserve,
      token1Value: token1Reserve,
      totalSumValue: totalLiquidityDV,
    } = calculateAmountWRTUserShare(userLiqShare, amount0, amount1);

    const {
      token0Value: token0UserFees,
      token1Value: token1UserFees,
      totalSumValue: totalFeesDV,
    } = calculateAmountWRTUserShare(userLiqShare, token0Fee.toString(), token1Fee.toString(), true);

    const { totalSumValue: totalFeesDVLastDay } = calculateAmountWRTUserShare(
      userLiqShare,
      token0FeeLastDay.toString(),
      token1FeeLastDay.toString(),
      true
    );

    // console.log('totalFeesDVLastDay => ', totalFeesDVLastDay?.toString());

    // const { totalSumValue: dust24h } = calculateAmountWRTUserShare(
    //   userLiqShare,
    //   positionDust?.dust0 ?? '0',
    //   positionDust?.dust1 ?? '0',
    //   true
    // );

    //console.log('dust24h => ', dust24h?.toString());

    const fees24h =
      totalFeesDV && totalFeesDVLastDay
        ? totalFeesDV?.isGreaterThanOrEqualTo(totalFeesDVLastDay)
          ? totalFeesDV.minus(totalFeesDVLastDay).toString()
          : totalFeesDV.toString()
        : undefined;

    // console.log(
    //   'fees24h => ',
    //   parseFloat(fees24h ?? '0') - parseFloat(dust24h ? dust24h?.toString() : '0')
    // );

    const currentPrice = priceFromTick(token1, token0, Number(currentTick));

    const updatePositionData: IUpdateNFTPositionData = {
      tokenId,
      token0Reserve: token0Reserve.toString(),
      token1Reserve: token1Reserve.toString(),
      token0UserFees: token0UserFees.toString(),
      token1UserFees: token1UserFees.toString(),
      totalLiquidityDV: totalLiquidityDV?.isGreaterThan(0)
        ? totalLiquidityDV?.toString()
        : totalLiquidityDV?.toString(),
      totalFeesDV: totalFeesDV?.isGreaterThan(0)
        ? totalFeesDV?.toString()
        : totalFeesDV?.toString(),
      currentPrice,
      token0DollarValue: token0DollarValue?.toString(),
      token1DollarValue: token1DollarValue?.toString(),
      poolApy,
      fees24h,
    };

    addTokenDetailsF(updatePositionData);
  };

  useEffect(() => {
    performCalculations(position);
  }, [nftPositions]);

  return (
    <>
      <Container maxWidth='md'>
        <Container disableGutters maxWidth={_sm02 ? 'sm' : 'md'}>
          <CGrid
            item01Props={{
              xs: xs_ ? 12 : undefined,
            }}
            item02Props={{
              xs: xs_ ? 12 : undefined,
            }}
            direction={'row'}
            alignItems={'center'}
            justify='space-between'
            elements01={
              <BackButton
                onClick={() => {
                  history.push('/positions');
                }}
              />
            }
            elements02={
              <Container disableGutters={_sm02} maxWidth='sm'>
                {Number(liquidity) > 0 && account === owner ? (
                  <CGrid
                    alignItems={xs_ ? 'flex-end' : undefined}
                    justify={xs_ ? 'center' : undefined}
                    containerProps={{
                      spacing: 1,
                    }}
                    elements01={
                      <ButtonIndicator
                        style={{}}
                        onClick={() => {
                          history.push(`/remove-liquidity/${id}`);
                        }}
                        disableElevation
                        className={classes.buttonsTop}
                        fullWidth
                        variant='outlined'
                        label='Remove Liquidity'
                        color='primary'
                      />
                    }
                    elements02={
                      <ButtonIndicator
                        style={{ color: 'white' }}
                        onClick={() => {
                          history.push(`/increase-liquidity/${id}`);
                        }}
                        disableElevation
                        className={classes.buttonsTop}
                        variant='contained'
                        label='Increase Liquidity'
                        fullWidth
                        color='primary'
                      />
                    }
                  />
                ) : (
                  <a
                    className={classes.owner}
                    href={ETHERSCAN_ADDRESS_BASE_URL + owner}
                    target='_blank'
                  >
                    Owner
                    <LaunchIcon color='primary' style={{ fontSize: '16px', marginLeft: '5px' }} />
                  </a>
                )}
              </Container>
            }
          />
        </Container>
        <Box mt={'10px'} />
        <Grid container alignItems={'stretch'} direction={_sm02 ? 'column-reverse' : 'row'}>
          <Grid item md lg xl>
            {_sm02 && <Box mt={'24px'} />}
            <LiquidityDetails />
          </Grid>
          <Grid item md lg xl>
            <NFTSection />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PositionDetail;
