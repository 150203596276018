import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider as ReduxProvider } from "react-redux";

import { themeDark, ThemeOptionsX } from "../theme/materialUiTheme";
import Renderer from "./Renderer";
import { getLibrary } from "../utils/walletConnectFunctions";
import { store } from "../redux";
import Updater from "./Updater";
import { ErrorBoundary } from "../components";

import { logEvent } from "@firebase/analytics";
import { analytics, ANALYTICS_TYPES } from "./analytics";

function App() {
  logEvent(analytics, ANALYTICS_TYPES.ARRIVAL);
  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ThemeProvider theme={themeDark as ThemeOptionsX}>
            <CssBaseline />
            <Renderer />
            <Updater />
          </ThemeProvider>
        </Web3ReactProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
}

export default App;
