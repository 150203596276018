import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fileCopyStyle: {
    width: 20,
    height: 20,
    padding: '2px',
  },
  fileCopyContainer: {
    marginLeft: 10,
  },
}));

export default useStyles;
