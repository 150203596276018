import { StatsTypes } from '../actionTypes';
import { Dispatch } from 'redux';
import axios from 'axios';

import { SERVER_ENDPOINTS } from '../../constants';
import { StatsAction } from '../actions/statsActions';
import { fetchProtocolData } from '../../data';
import { IPoolData } from '../../utils/generalTypes';
import { logError } from '../../utils/logs';

const computeProtocolData = (data: IPoolData[]) => {
  let protocolTvl = 0;
  let protocolFees = 0;
  data.forEach(({ ulmTvlUSD, ulmFeesUSD }) => {
    protocolTvl += parseFloat(ulmTvlUSD);
    protocolFees += parseFloat(ulmFeesUSD);
  });
  return { protocolTvl, protocolFees };
};

export const getProtocolData = (): ((dispatch: Dispatch<StatsAction>) => void) => {
  return async (dispatch: Dispatch<StatsAction>) => {
    const res = await fetchProtocolData();
    if (res.data) {
      const { protocolTvl, protocolFees } = computeProtocolData(res.data);
      dispatch({
        type: StatsTypes.PROTOCOL_DATA,
        payload: {
          poolsData: res.data,
          protocolTvl: protocolTvl.toString(),
          protocolFees: protocolFees.toString(),
        },
      });
    } else {
      dispatch({
        type: StatsTypes.PROTOCOL_DATA,
        payload: {
          poolsData: [],
          protocolTvl: '',
          protocolFees: '',
          selectedPool: null,
        },
      });
    }
  };
};

export const selectPool = (pool: IPoolData): StatsAction => {
  return { type: StatsTypes.SELECT_POOL, payload: pool };
};

export const getProtocolFees = (): ((dispatch: Dispatch<StatsAction>) => void) => {
  return async (dispatch: Dispatch<StatsAction>) => {
    try {
      const res = await axios.get(`${SERVER_ENDPOINTS.unipilot}/protocolfees`);
      if (res?.data?.message === 'Success') {
        const { poolFees, totalFees } = res.data.doc;
        dispatch({
          type: StatsTypes.PROTOCOL_FEES,
          payload: { totalProtocolFees: totalFees, poolFees },
        });
      } else {
        dispatch({
          type: StatsTypes.PROTOCOL_FEES,
          payload: { totalProtocolFees: '', poolFees: {} },
        });
      }
    } catch (e) {
      logError('getProtocolFees => ', e);
    }
  };
};

export const getPoolsAPY = (
  poolAddresses: string[]
): ((dispatch: Dispatch<StatsAction>) => void) => {
  return async (dispatch: Dispatch<StatsAction>) => {
    try {
      let query = '';
      poolAddresses.forEach(poolAddress => {
        query += `poolAddress=${poolAddress}&`;
      });
      const res = await axios.get(`${SERVER_ENDPOINTS.unipilot}/poolApr?${query}`);
      if (res?.data?.message === 'Success') {
        dispatch({
          type: StatsTypes.POOL_APY,
          payload: res.data.doc,
        });
      } else {
        dispatch({
          type: StatsTypes.POOL_APY,
          payload: {},
        });
      }
    } catch (e) {
      logError('getPoolsAPY => ', e);
    }
  };
};
