import axios from 'axios';
import { Dispatch } from 'redux';

import { TokenTypes } from '../actionTypes';
import { TokenAction } from '../actions/tokenActions';
import { ITokenList, IToken } from '../../utils/generalTypes';
import { UN_KNOWN_LOGO, ETH } from '../../constants';
import { logError } from '../../utils/logs';
import { getBalances } from '../../contracts/functions/balance';
import { getETHBalance } from '../../contracts/getContract';
import { fetchTokensDerivedETH } from '../../data';

export const addActiveTokenList = (
  tokenList: ITokenList
): ((dispatch: Dispatch<TokenAction>) => void) => {
  return (dispatch: Dispatch<TokenAction>) => {
    dispatch({
      type: TokenTypes.ADD_ACTIVE_TOKEN_LIST,
      payload: tokenList,
    });
  };
};

export const addTokenList = (
  tokenList: ITokenList
): ((dispatch: Dispatch<TokenAction>) => void) => {
  return (dispatch: Dispatch<TokenAction>) => {
    dispatch({
      type: TokenTypes.ADD_TOKEN_LIST,
      payload: tokenList,
    });
  };
};

export const addCustomToken = (token: IToken): ((dispatch: Dispatch<TokenAction>) => void) => {
  return (dispatch: Dispatch<TokenAction>) => {
    dispatch({
      type: TokenTypes.ADD_NEW_TOKEN,
      payload: token,
    });
  };
};

export const addBalance = (
  address: string,
  balance: string,
  isETH: boolean
): ((dispatch: Dispatch<TokenAction>) => void) => {
  return (dispatch: Dispatch<TokenAction>) => {
    dispatch({
      type: TokenTypes.ADD_BALANCE,
      payload: { address, balance, isETH },
    });
  };
};

//get token logo
export const getTokenLogo = async (uri: string): Promise<string> => {
  let _uri = uri;
  try {
    if (uri.startsWith('ipfs')) {
      const _val = uri?.split('//');
      _uri = 'https://ipfs.io/ipfs/' + _val[1];
    }
    const res = await axios.get(_uri);
    if (res) {
      return _uri;
    } else {
      return UN_KNOWN_LOGO;
    }
  } catch (error) {
    logError('Token Logo', error);
    return UN_KNOWN_LOGO;
  }
};

export const getTokenList = async (uri: string): Promise<ITokenList | null> => {
  try {
    let _uri = uri;
    if (uri.startsWith('ipfs')) {
      const _val = uri?.split('//');
      _uri = 'https://ipfs.io/ipfs/' + _val[1];
    }
    const res = await axios.get(_uri);
    const { name, logoURI, tokens } = res?.data;
    if (name && logoURI && tokens) {
      return { name, logoURI, uri };
    } else {
      return null;
    }
  } catch (error) {
    logError('Get Token List', error);
    return null;
  }
};

const tokenExists = (tokens: IToken[], customToken: IToken): boolean => {
  const exists = tokens.filter(
    token => token.address.toLowerCase() === customToken.address.toLowerCase()
  )[0];
  return exists ? true : false;
};

const getTokensBalance = async (account: string, tokens: IToken[]): Promise<IToken[]> => {
  const addresses = tokens.map((token: IToken) => {
    return token.address;
  });

  if (account) {
    const balances = (await getBalances(account, addresses)) ?? [];
    const ethBalance = await getETHBalance(account);
    let _tokens = tokens.map((token: IToken, index: number) => {
      return {
        ...token,
        address: token.address.toLowerCase(),
        balance: token.isETH ? ethBalance : balances[index],
      };
    });
    return _tokens;
  } else {
    let _tokens = tokens.map((token: IToken) => {
      return { ...token, address: token.address.toLowerCase(), balance: '0' };
    });
    return _tokens;
  }
};

export const getTokens = (
  uri: string,
  chainId: number,
  account: string
): ((dispatch: Dispatch<TokenAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<TokenAction>) => {
    try {
      const res = await axios.get(uri);
      if (res?.data?.tokens) {
        let tokens = res.data.tokens;
        tokens = tokens.filter((token: IToken) => token.chainId === chainId);

        let _customTokens = JSON.parse(localStorage.getItem('customTokens') ?? '[]');
        _customTokens = _customTokens.filter(
          (customToken: IToken) =>
            customToken.chainId === chainId && tokenExists(tokens, customToken) === false
        );

        tokens = [ETH, ..._customTokens, ...tokens];
        tokens = await getTokensBalance(account, tokens);

        dispatch({
          type: TokenTypes.ADD_TOKENS,
          payload: { tokens },
        });
      } else {
        let customTokens = JSON.parse(localStorage.getItem('customTokens') ?? '[]');
        customTokens = customTokens.filter(
          (customToken: IToken) => customToken.chainId === chainId
        );
        dispatch({
          type: TokenTypes.ADD_TOKENS,
          payload: { tokens: customTokens },
        });
      }
    } catch (error) {
      logError('Get Tokens', error);
      let customTokens = JSON.parse(localStorage.getItem('customTokens') ?? '[]');
      customTokens = customTokens.filter((customToken: IToken) => customToken.chainId === chainId);
      dispatch({
        type: TokenTypes.ADD_TOKENS,
        payload: { tokens: customTokens },
      });
    }
  };
};

export const getTokensDollarValue = async (
  tokens: string[]
): Promise<{ token0DV: number; token1DV: number } | undefined> => {
  interface IDollar {
    token0: { usd: number };
    token1: { usd: number };
  }

  try {
    // const _tokens = [
    //   '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    //   '0x6b175474e89094c44da98b954eedeac495271d0f',
    // ];

    const token0 = tokens[0].toLowerCase();
    const token1 = tokens[1].toLowerCase();

    const uri = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${tokens}&vs_currencies=USD`;
    const res = await axios.get<IDollar>(uri);
    //console.log('res => ', res);
    if (res.data) {
      return {
        token0DV: res.data[token0 as keyof IDollar].usd,
        token1DV: res.data[token1 as keyof IDollar].usd,
      };
    }
  } catch (e) {
    logError('getTokensDollarValue', e);
    //await fetchTokensDerivedETH(tokens);
    // return {
    //   token0DV: 1,
    //   token1DV: 1,
    // };
  }
};

export const clearTokens = (): ((dispatch: Dispatch<TokenAction>) => void) => {
  return (dispatch: Dispatch<TokenAction>) => {
    dispatch({
      type: TokenTypes.CLEAR_TOKENS,
    });
  };
};

export const updateBalances = (account: string, tokens: IToken[]) => {
  return async (dispatch: Dispatch<TokenAction>) => {
    const tokensWithBalances = await getTokensBalance(account, tokens);
    dispatch({
      type: TokenTypes.ADD_TOKENS,
      payload: { tokens: tokensWithBalances },
    });
  };
};
