import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '0.8rem',
    color: '#67b631',
    backgroundColor: 'rgba(103, 182, 49,0.1)',
    padding: '5px 15px',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    '@media(max-width:550px)': {
      padding: '5px 8px',
    },
  },
}));

export default useStyles;
