import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import {
  Box,
  Container,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
} from '@material-ui/core';

import {
  Header,
  WithPagination,
  Nothing,
  ContainerLoader,
  AnimatedViews,
  MigrateLiquidityTag,
} from '../../components';
import { useMigrateLiquidity, useLiquidity } from '../../hooks';
import { LP_MIGRATION } from '../../constants';

import NFTPosition from './NFTPosition';
import LPToken from './LPToken';
import { IMigrateNFT, IMigrateLPToken } from '../../utils/generalTypes';
import { MIGRATE_PROTOCOL, LIQUIDITY_TYPES } from '../../utils/enums';
import useStyles from './styles';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SimpleTabs: React.FC<{}> = () => {
  const classes = useStyles();
  const { activeTab, setActiveTabF } = useMigrateLiquidity();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabF(newValue);
  };

  return (
    <Box className={classes.root}>
      <Tabs
        className={classes.migrateApp}
        value={activeTab}
        onChange={handleChange}
        aria-label='simple tabs example'
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        scrollButtons='auto'
      >
        <Tab label='Position NFTs' {...a11yProps(0)} />
        <Tab label='LP Tokens' {...a11yProps(1)} />
      </Tabs>
    </Box>
  );
};

const Migrate = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { account } = useWeb3React();

  const {
    setActiveTabF,
    migrateLPTokens,
    migrateNFTs,
    activeTab,
    migrateLPPageCount,
    migrateNFTPageCount,
    setMigrateLpPageCountF,
    setMigrateNftPageCountF,
  } = useMigrateLiquidity();

  const { addDataF, setLiquidityTypeF } = useLiquidity();

  const handleProtocolSelect = (protocol: MIGRATE_PROTOCOL) => {
    setLiquidityTypeF(LIQUIDITY_TYPES.IMPORT);
    addDataF({ protocol });
    history.push('/migrate/import-liquidity');
  };

  return (
    <Container className={classes.mainRoot} maxWidth={xs_ ? 'xs' : false}>
      <Box className={classes.conatiner}>
        <Header
          elements={
            <Typography style={{ marginTop: 8 }} variant='h5'>
              Migrate Liquidity
            </Typography>
          }
          disableGoBack
          iconRightHidden
        />
        <Box className={classes.marginTop} />
        <Grid container className={classes.mainApp}>
          <Grid item>
            <SimpleTabs />
          </Grid>
        </Grid>
        <Grid direction={!xs_ ? 'column' : 'row'} alignItems='stretch' container>
          <Grid className={classes.wrapper} item style={{ width: '100%' }}>
            {/* {activeTab === 1 && (
              <Box className={classes.protocol}>
                {LP_MIGRATION.map(protocol => (
                  <Box onClick={() => handleProtocolSelect(protocol)}>
                    <MigrateLiquidityTag
                      smallTag
                      justify='center'
                      protocol={protocol}
                      trunc={xs_}
                    />
                  </Box>
                ))}
              </Box>
            )} */}
            <Box
              style={{ marginTop: activeTab === 0 ? '30px' : '15px' }}
              className={classes.listContainer}
            >
              <AnimatedViews
                setPage={setActiveTabF}
                page={activeTab}
                items={[
                  migrateNFTs ? (
                    <WithPagination
                      containerClassName={classes.parent}
                      nothingContainerClassName={classes.parent}
                      rowsPerPage={!xs_ ? 4 : 3}
                      page={migrateNFTPageCount}
                      setPage={setMigrateNftPageCountF}
                      items={migrateNFTs ?? []}
                      func={(item: IMigrateNFT) => {
                        return <NFTPosition key={item.id} data={item} />;
                      }}
                    />
                  ) : (
                    <Nothing containerClassName={classes.parent}>
                      {account && <ContainerLoader />}
                    </Nothing>
                  ),
                  migrateLPTokens ? (
                    <WithPagination
                      containerClassName={classes.parent}
                      nothingContainerClassName={classes.parent}
                      rowsPerPage={!xs_ ? 5 : 4}
                      page={migrateLPPageCount}
                      setPage={setMigrateLpPageCountF}
                      items={migrateLPTokens ?? []}
                      func={(item: IMigrateLPToken) => {
                        return <LPToken key={item.id} data={item} />;
                      }}
                    />
                  ) : (
                    <Nothing containerClassName={classes.parent}>
                      {account && <ContainerLoader />}
                    </Nothing>
                  ),
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        {activeTab === 1 && (
          <Box marginTop='15px' textAlign='center'>
            <Typography style={{ fontSize: '14px' }}>
              Can't locate your liquidity?
              <Box
                onClick={() => history.push('/migrate/import-liquidity')}
                style={{ cursor: 'pointer' }}
                component='span'
                color='primary.main'
              >
                {' '}
                Import It
              </Box>
            </Typography>
            {/* <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {LP_MIGRATION.map(protocol => (
                <Box onClick={() => handleProtocolSelect(protocol)}>
                  <MigrateLiquidityTag smallTag justify='center' protocol={protocol} trunc={xs_} />
                </Box>
              ))}
            </Box> */}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Migrate;
