import { useMemo, useRef } from 'react';
import { Typography, Box } from '@material-ui/core';

import useStyles from './styles';
import { BoxContainer, Chart, RowAlignCenter } from '../';
import { PoolTVLChart } from '../../data';
import { formatDollarAmount } from '../../utils/formating';

interface ITVLGraph {
  data: PoolTVLChart[];
}

const TvlGraph: React.FC<ITVLGraph> = ({ data }) => {
  const classes = useStyles();
  const tvlRef = useRef<HTMLSpanElement>(null);
  const timeRef = useRef<HTMLSpanElement>(null);

  const currentTvl = useMemo<string>(() => {
    return formatDollarAmount(data[data.length - 1].ulmTvlUSD);
  }, [data]);

  const formatedData = useMemo<number[][]>(() => {
    return data.map(({ date, ulmTvlUSD }) => [date * 1000, ulmTvlUSD]);
  }, [data]);

  return (
    <BoxContainer style={{ height: '100%' }} className={classes.right}>
      <RowAlignCenter
        containerClassName={classes.tvlContainer}
        elements01={<Typography>TVL</Typography>}
        elements02={
          <Typography ref={tvlRef} style={{ fontSize: '1.3rem', fontWeight: 500 }}>
            {currentTvl}
          </Typography>
        }
      />
      <Box className={classes.timeBox}>
        <Typography ref={timeRef} variant='body2'></Typography>
      </Box>
      <Chart data={formatedData} defaultValue={currentTvl} valueRef={tvlRef} labelRef={timeRef} />
    </BoxContainer>
  );
};

export default TvlGraph;
