import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  conatiner: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
    border: '1px solid rgba(255,255,255,0.1)',
    boxShadow: '0px 4px 8px rgba(50, 50, 50, 0.16)',
    //minHeight: "350px",
  },
  poolDetailsContainer: {
    padding: '20px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 10,
    border: '1px solid rgba(255,255,255,0.1)',
    boxShadow: '0px 4px 8px rgba(50, 50, 50, 0.16)',
  },
  noDataContainer: {
    minHeight: '118px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: 15,
  },
  marginTopRelevant: {
    marginTop: 15,
  },

  marginTopIrrelevant: {
    marginTop: 20,
  },

  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: 'none',
    color: 'white',
    padding: '8px 0px',
    // backgroundColor: theme.palette.common?.purplish,
  },
  paddingTB4LR0: {
    padding: '4px 0px',
  },
  containerUnselected: {
    padding: '10.5px 20px',
    textTransform: 'none',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    minHeight: 50,
  },
  containerSelected: {
    color: 'white',
    //padding: "5px 20px",
    textTransform: 'none',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.secondary.main,
    minHeight: 50,
    // background: "red",
    background: 'linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.05)',
    },
  },
  typoPri: {
    fontSize: '0.8rem',
    fontWeight: 500,
    margin: '0px 5px',
  },
  // buttonContainer: {
  //   textTransform: 'none',
  //   // backgroundColor: theme.palette.secondary.main,
  //   padding: '10px 15px',
  //   borderColor: 'transparent',
  //   marginTop: 10,
  // },
  // uri: {
  //   width: 15,
  //   height: 15,
  // },
  // buttonBottom: {
  //   // backgroundColor: "red",
  //   textTransform: 'none',
  //   color: 'white',
  //   padding: '8px 0px',
  // },
  // greenInfo: {
  //   '@media(max-width:550px)': {
  //     padding: '2px 8px',
  //   },
  // },
}));

export default useStyles;
