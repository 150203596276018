import { Menu, Container, Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { RowAlignCenter, RangeSymbol, CTooltip, Notification, ButtonIndicator } from '../';
import { useLoading } from '../../hooks';
import useStyles from './styles';
import { trunc } from '../../utils/formating';
import { LOADING } from '../../utils/enums';

interface IOptions {
  pool: string;
  currentPrice: string;
  tokenMsg: string;
  rebaseIncentive: boolean;
  allowRebase: boolean;
  tokenId: string;
  anchorEl: HTMLElement | null;
  setAnchorEl: (v: HTMLElement | null) => void;
  handleRebase: () => void;
}

const Options: React.FC<IOptions> = ({
  anchorEl,
  setAnchorEl,
  currentPrice,
  tokenMsg,
  handleRebase,
  rebaseIncentive,
  allowRebase,
  tokenId,
  pool,
}) => {
  const classes = useStyles();
  const { dappLoading } = useLoading();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      classes={{ paper: classes.rootPaper }}
      keepMounted={false}
      id='simple-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        handleClose();
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Container className={classes.root} maxWidth='md'>
        <Notification
          msg={
            rebaseIncentive
              ? `Rebase ${pool} pool to earn pilot tokens`
              : 'This pool is not eligible for incentives'
          }
          err={!rebaseIncentive}
        />

        <Box className={classes.marginTop} />

        <RowAlignCenter
          elements01={<Typography>Range</Typography>}
          elements02={<RangeSymbol inRange={false} />}
        />
        <Box className={classes.marginTop} />

        <RowAlignCenter
          elements01={<Typography>Current price</Typography>}
          elements02={
            currentPrice ? (
              currentPrice.length > 8 ? (
                <CTooltip title={tokenMsg}>
                  <Typography style={{ padding: '0px 5px' }}>{trunc(currentPrice)}</Typography>
                </CTooltip>
              ) : (
                <CTooltip title={tokenMsg}>
                  <Typography style={{ padding: '0px 5px' }}>{currentPrice}</Typography>
                </CTooltip>
              )
            ) : (
              <Skeleton width={80} height={30} />
            )
          }
        />
        <Box className={classes.marginTop} />

        <ButtonIndicator
          help={
            !allowRebase ? 'Pool already rebased twice. You can rebase it again after 10 mins.' : ''
          }
          disableElevation
          onlyDisable={!allowRebase}
          disabled={dappLoading === `${LOADING.REBASE}-${tokenId}`}
          className={classes.buttonBottom}
          variant='contained'
          label='Rebase'
          fullWidth
          color='primary'
          onClick={handleRebase}
        />
      </Container>
    </Menu>
  );
};

export default Options;
