import { TransactionReceipt } from 'web3-core';
import axios from 'axios';

import {
  transactionPending,
  transactionSuccess,
  transactionFailed,
  transactionRejected,
  //  transactionException,
} from '../redux';
import { ETHERSCAN_GAS_API } from '../constants';
import { ITransaction, ISendTransactionArgs } from '../utils/generalTypes';
import { NonPayableTransactionObject, PayableTransactionObject } from './types/types';
import { logError } from '../utils/logs';
import { TRANSACTION_STATUS } from '../utils/enums';
import { getWeb3Provider } from '../contracts/getContract';

type TransactionObject = NonPayableTransactionObject<any> | PayableTransactionObject<any>;

const fetchCurrentGas = async () => {
  try {
    const res = await axios.get(ETHERSCAN_GAS_API);
    let fastGasPrcie = res?.data?.result?.FastGasPrice;
    if (fastGasPrcie) {
      fastGasPrcie = (parseInt(fastGasPrcie) + 10) * 1000000000;
      return fastGasPrcie.toString();
    }
  } catch (e) {
    logError('fetchCurrentGas => ', e);
  }
};

export const sendTransaction = async (
  contractFnc: TransactionObject,
  args: ISendTransactionArgs
) => {
  let transaction: ITransaction = {
    hash: '',
    account: '',
    message: '',
    status: TRANSACTION_STATUS.PENDING,
    checkCount: 0,
  };
  const { walletAddress, txnMessage, logMessage, dappLoading, ethValue, addToken, callback } = args;
  try {
    transactionPending('', {}, dappLoading);
    const fastGasPrice = await fetchCurrentGas();
    const txnObj = fastGasPrice
      ? {
          from: walletAddress,
          value: ethValue ? ethValue : '0',
          maxFeePerGas: fastGasPrice,
        }
      : {
          from: walletAddress,
          value: ethValue ? ethValue : '0',
        };
    contractFnc
      .send(txnObj)
      .on('transactionHash', (txnHash: string) => {
        transaction = {
          hash: txnHash,
          account: walletAddress,
          message: txnMessage,
          status: TRANSACTION_STATUS.PENDING,
          checkCount: 0,
        };
        transactionPending(txnHash, { link: txnHash }, dappLoading, transaction);
      })
      .then((receipt: TransactionReceipt) => {
        transaction['status'] = TRANSACTION_STATUS.SUCCESS;
        transactionSuccess(
          receipt.transactionHash,
          {
            link: receipt.transactionHash,
            addToken: addToken ?? false,
          },
          transaction
        );
        callback();
      })
      .catch((e: any) => {
        if (e.code === 4001) {
          transactionRejected();
        } else if (e?.message?.includes('not mined within 50 blocks')) {
          const provider = getWeb3Provider();
          const handle = setInterval(() => {
            provider.eth.getTransactionReceipt(transaction.hash).then((res: any) => {
              if (res != null && res.blockNumber > 0) {
                clearInterval(handle);
                if (res.status) {
                  transaction['status'] = TRANSACTION_STATUS.SUCCESS;
                  transactionSuccess(
                    transaction.hash,
                    {
                      link: transaction.hash,
                      addToken: addToken ?? false,
                    },
                    transaction
                  );
                  callback();
                } else {
                  transaction['status'] = TRANSACTION_STATUS.FAILED;
                  transactionFailed(transaction.hash, { link: transaction.hash }, transaction);
                }
              }
            });
          });
        } else {
          transaction['status'] = TRANSACTION_STATUS.FAILED;
          transactionFailed(transaction.hash, { link: transaction.hash }, transaction);
        }
        logError(`${logMessage}-e`, e);
      });
  } catch (e) {
    logError(logMessage, e);
    transactionFailed('', {});
  }
};

// transactionPending('', {}, dappLoading);
// const fastGasPrice = await fetchCurrentGas();
// contractFnc
//   .estimateGas({ from: walletAddress })
//   .then(() => {
//     contractFnc
//       .send({
//         from: walletAddress,
//         value: ethValue ? ethValue : '0',
//         maxFeePerGas: fastGasPrice,
//       })
//       .on('transactionHash', (txnHash: string) => {
//         transaction = {
//           hash: txnHash,
//           account: walletAddress,
//           message: txnMessage,
//           status: TRANSACTION_STATUS.PENDING,
//           checkCount: 0,
//         };
//         transactionPending(txnHash, { link: txnHash }, dappLoading, transaction);
//       })
//       .then((receipt: TransactionReceipt) => {
//         transaction['status'] = TRANSACTION_STATUS.SUCCESS;
//         transactionSuccess(
//           receipt.transactionHash,
//           {
//             link: receipt.transactionHash,
//             addToken: addToken ?? false,
//           },
//           transaction
//         );
//         callback();
//       })
//       .catch((e: any) => {
//         if (e.code === 4001) {
//           transactionRejected();
//         } else if (e.includes('not mined within 50 blocks')) {
//           const provider = getWeb3Provider();
//           const handle = setInterval(() => {
//             provider.eth.getTransactionReceipt(transaction.hash).then((res: any) => {
//               if (res != null && res.blockNumber > 0) {
//                 clearInterval(handle);
//                 if (res.status) {
//                   transaction['status'] = TRANSACTION_STATUS.SUCCESS;
//                   transactionSuccess(
//                     transaction.hash,
//                     {
//                       link: transaction.hash,
//                       addToken: addToken ?? false,
//                     },
//                     transaction
//                   );
//                   callback();
//                 } else {
//                   transaction['status'] = TRANSACTION_STATUS.FAILED;
//                   transactionFailed(transaction.hash, { link: transaction.hash }, transaction);
//                 }
//               }
//             });
//           });
//         } else {
//           transaction['status'] = TRANSACTION_STATUS.FAILED;
//           transactionFailed(transaction.hash, { link: transaction.hash }, transaction);
//         }
//         logError(`${logMessage}-e`, e);
//       });
//   })
//   .catch(e => {
//     transactionException({ exception: e });
//   });
