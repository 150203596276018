import { ApolloClient, InMemoryCache } from '@apollo/client';

import { GRAPH_QL_ENDPOINTS } from '../constants';

export const unipilotClient = new ApolloClient({
  uri: GRAPH_QL_ENDPOINTS.unipilot,
  cache: new InMemoryCache(),
});

export const uniswapV2Client = new ApolloClient({
  uri: GRAPH_QL_ENDPOINTS.uniswapV2,
  cache: new InMemoryCache(),
});

export const sushiswapClient = new ApolloClient({
  uri: GRAPH_QL_ENDPOINTS.sushiswap,
  cache: new InMemoryCache(),
});

export const blocklyticsClient = new ApolloClient({
  uri: GRAPH_QL_ENDPOINTS.blocklytics,
  cache: new InMemoryCache(),
});

export const uniswapV3Client = new ApolloClient({
  uri: GRAPH_QL_ENDPOINTS.uniswapV3,
  cache: new InMemoryCache(),
});

export const farmingClient = new ApolloClient({
  uri: GRAPH_QL_ENDPOINTS.farming,
  cache: new InMemoryCache(),
});
