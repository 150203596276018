import { makeStyles } from '@material-ui/core';

const useStylesItemList = makeStyles(theme => ({
  container: {
    border: `2px solid ${theme.palette.secondary.main}`, //"#F8F8F8",
    borderRadius: theme.shape.borderRadius,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  listItemRoot: {
    height: '80px',
  },
}));

export default useStylesItemList;
