import { multicallContract } from '../getContract';
import { logError } from '../../utils/logs';

export const aggregate = async (calls: any, message: string) => {
  try {
    const contract = multicallContract();
    const returnData = await contract?.methods.aggregate(calls).call();
    return returnData;
  } catch (e) {
    logError(`aggregate => ${message}`, e);
  }
};
