import { useEffect, useState } from "react";
import { useFarming } from "./farmingHooks";
import { useNFTPositions } from "./nftPositionHooks";

export const useLegacyShift = () => {
  const [isLegacy, setIsLegacy] = useState(false);
  const [nftExisted, setNFTExisted] = useState(false);
  const { nftPositions } = useNFTPositions();
  const { userLockedNfts } = useFarming();

  useEffect(() => {
    if (
      !sessionStorage.getItem("isLegacyOpen") &&
      nftPositions &&
      userLockedNfts
    ) {
      setNFTExisted(
        nftPositions.length > 0 || Object.keys(userLockedNfts).length > 0
      );

      setIsLegacy(true);
    }
  }, [sessionStorage, nftPositions, userLockedNfts]);

  const onCloseLegacy = () => {
    sessionStorage.setItem("isLegacyOpen", "true");
    setIsLegacy(false);
  };

  return { isLegacy, nftExisted, onCloseLegacy };
};
