import { Box } from '@material-ui/core';

import { CTooltip, PilotIcon } from '../';

const PoolAutoPilot = () => {
  return (
    <CTooltip title='Managed by Unipilot'>
      <Box
        style={{
          height: '30px',
          width: '30px',
          marginLeft: '10px',
          position: 'relative',
          zIndex: 10,
        }}
      >
        <PilotIcon />
      </Box>
    </CTooltip>
  );
};

export default PoolAutoPilot;
