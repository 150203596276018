import { UiTypes } from '../actionTypes';
import { UiAction } from '../actions/uiActions';

export interface UiState {
  snackbar: {
    open: boolean;
    severity: string;
    message: string;
    txHash: string;
  };
  modal: {
    open: boolean;
    variant: string;
    link?: string;
    addToken?: boolean;
    exception?: string;
    message?: string;
  };
  dappLoading: string;
  isApproved: boolean;
  pilotPrice: string;
}

const initialState: UiState = {
  snackbar: { open: false, severity: 'success', message: '', txHash: '' },
  modal: {
    open: false,
    variant: 'loading',
    link: '',
    addToken: false,
    message: '',
  },
  dappLoading: '',
  isApproved: false,
  pilotPrice: '',
};

const uiReducer = (state: UiState = initialState, action: UiAction) => {
  switch (action.type) {
    case UiTypes.SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: { open: true, txHash: '', ...action.payload },
      };
    case UiTypes.HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: { open: false, severity: 'success', message: '', txHash: '' },
      };
    case UiTypes.SHOW_SNACKBAR_INDEEP:
      return { ...state, snackbar: { open: true, ...action.payload } };
    case UiTypes.SHOW_MODAL:
      return { ...state, modal: { open: true, ...action.payload } };
    case UiTypes.HIDE_MODAL:
      return {
        ...state,
        modal: {
          open: false,
          variant: '',
          link: '',
          addToken: false,
          message: '',
        },
      };
    case UiTypes.SET_DAPP_LOADING:
      return { ...state, dappLoading: action.payload };

    case UiTypes.SET_APPROVAL_STATE:
      return { ...state, isApproved: action.payload };
    case UiTypes.UPDATE_PILOT_PRICE:
      return { ...state, pilotPrice: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
