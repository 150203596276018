import { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useHistory, Redirect, useParams } from 'react-router';
import { useWeb3React } from '@web3-react/core';

import {
  ButtonIndicator,
  Header,
  SelectToken,
  InputMax,
  ItemList,
  ContainerSpaceBetween,
  CTooltip,
  RowAlignCenter,
  DisableElements,
  PoolInfo,
} from '../../components';
import { LIQUIDITY_TOKENS, LIQUIDITY_TYPES, LOADING } from '../../utils/enums';
import {
  useLiquidity,
  useNFTPositions,
  useLoading,
  useLiquidityParams,
  useLiquidityAllowance,
  useUpdateBalance,
  useTokenList,
  useTokenAllowance,
} from '../../hooks';
import useStyles from './styles';
import {
  formatAmount,
  trunc,
  toFixed,
  exponentialToDecimal,
  parseAmount,
} from '../../utils/formating';
import { validAmount, calculateDollarValue } from '../../utils/helpers';
import { addUnipilotLiquidity } from '../../contracts/functions/unipilot';
import { IToken } from '../../utils/generalTypes';
import { SmallContentContainer } from '../pairLiquidity/Containers';
import { DEFAULT_TOKEN } from '../../constants';

const IncreaseLiquidity: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { dappLoading } = useLoading();
  const { getAddLiquidityParams } = useLiquidityParams();
  const { checkAllowance } = useLiquidityAllowance();
  const { account } = useWeb3React();
  const {
    token0,
    token1,
    feeTier,
    amount0,
    amount1,
    amount0Reserves,
    amount1Reserves,
    setLiquidityTypeF,
    addDataF,
    clearDataF,
  } = useLiquidity();
  const { tokens } = useTokenList();
  const { selectedNFTPosition, getNFTPositionsF } = useNFTPositions();
  const updateBalance = useUpdateBalance();
  const {
    loading: tokenAllowanceLoading,
    tokenAllowance,
    checkERC20TokenAllowance,
    giveTokenAllowance,
  } = useTokenAllowance();

  const [validBalance, setValidBalance] = useState<boolean>(true);

  useEffect(() => {
    setLiquidityTypeF(LIQUIDITY_TYPES.INCREASE);
    if (selectedNFTPosition) {
      const {
        token0,
        token1,
        fee,
        amount0: amount0Reserves,
        amount1: amount1Reserves,
        currentTick,
      } = selectedNFTPosition;
      addDataF({
        token0,
        token1,
        feeTier: fee,
        amount0Reserves,
        amount1Reserves,
        tick: currentTick,
      });
      checkERC20TokenAllowance(token0, token1);
    }
  }, []);

  useEffect(() => {
    if (selectedNFTPosition) {
      checkAmountValidity();
    }
  }, [amount0, amount1]);

  useEffect(() => {
    let _token0 = token0 ? tokens.filter(token => token.address === token0.address)[0] : null;
    let _token1 = token1 ? tokens.filter(token => token.address === token1.address)[0] : null;
    if (
      _token0 &&
      _token1 &&
      (_token0.balance !== token0?.balance || _token1.balance !== token1?.balance)
    ) {
      addDataF({
        token0: _token0,
        token1: _token1,
      });
      checkAmountValidity(_token0, _token1);
    }
  }, [tokens]);

  if (!selectedNFTPosition || selectedNFTPosition?.tokenId.toString() !== id) {
    return <Redirect to={'/positions'} />;
  }

  const {
    token0Reserve,
    token1Reserve,
    tokenId,
    token0DollarValue: t0DV,
    token1DollarValue: t1DV,
  } = selectedNFTPosition;

  const allowAdd = () => {
    if (token0 && token1) {
      if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_0) || tokenAllowance(LIQUIDITY_TOKENS.TOKEN_1))
        return true;
      else if (!validBalance) return false;
      else if (
        checkAllowance(LIQUIDITY_TOKENS.TOKEN_0) &&
        parseFloat(toFixed(amount0, token0.decimals)) > 0 &&
        amount1 === ''
      ) {
        return true;
      } else if (
        checkAllowance(LIQUIDITY_TOKENS.TOKEN_1) &&
        parseFloat(toFixed(amount1, token1.decimals)) > 0 &&
        amount0 === ''
      ) {
        return true;
      } else if (
        parseFloat(toFixed(amount0, token0.decimals)) > 0 &&
        parseFloat(toFixed(amount1, token1.decimals)) > 0
      ) {
        return true;
      } else if (validBalance && parseFloat(amount0) > 0 && parseFloat(amount1) > 0) return true;
      else return false;
    }
    return false;
  };

  const checkAmountValidity = (
    _token0: IToken | null = token0,
    _token1: IToken | null = token1
  ) => {
    if (_token0 && _token1) {
      let valid = true;
      if (checkAllowance(LIQUIDITY_TOKENS.TOKEN_0) && amount0 && !amount1) {
        valid = !validAmount(_token0, amount0);
      } else if (checkAllowance(LIQUIDITY_TOKENS.TOKEN_1) && amount1 && !amount0) {
        valid = !validAmount(_token1, amount1);
      } else if (amount0 && amount1)
        valid = !validAmount(_token0, amount0) && !validAmount(_token1, amount1);
      setValidBalance(valid);
    } else setValidBalance(true);
  };

  const showText = () => {
    if (dappLoading === LOADING.INCREASE_LIQUIDITY) return dappLoading;
    else if (token0 && token1 && feeTier && !tokenAllowanceLoading) {
      if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_0)) return `Approve ${token0.symbol}`;
      else if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_1)) return `Approve ${token1.symbol}`;
      else if (
        checkAllowance(LIQUIDITY_TOKENS.TOKEN_0) &&
        !(parseFloat(toFixed(amount0, token0.decimals)) > 0)
      )
        return 'Enter an amount';
      else if (
        checkAllowance(LIQUIDITY_TOKENS.TOKEN_1) &&
        !(parseFloat(toFixed(amount1, token1.decimals)) > 0)
      )
        return 'Enter an amount';
      else if (!validBalance) return 'Insufficient balance';
      else return 'Increasing liquidity';
    } else return 'Increasing liquidity';
  };

  const addLiquidity = async () => {
    const params = getAddLiquidityParams(tokenId.toString());
    if (token0 && token1 && params && account) {
      if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_0)) {
        await giveTokenAllowance(token0, LIQUIDITY_TOKENS.TOKEN_0);
      } else if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_1)) {
        await giveTokenAllowance(token1, LIQUIDITY_TOKENS.TOKEN_1);
      } else {
        await addUnipilotLiquidity(
          params,
          feeTier,
          token0?.symbol,
          token1?.symbol,
          account,
          LIQUIDITY_TYPES.INCREASE,
          token0.isETH ? parseAmount(amount0, 18) : token1.isETH ? parseAmount(amount1, 18) : '0',
          () => {
            clearDataF();
            getNFTPositionsF(account);
            updateBalance(token0.address, token0.isETH ?? false);
            updateBalance(token1.address, token0.isETH ?? false);
            history.replace('/positions');
          }
        );
      }
    }
  };

  const balance_tooltip01 = token0?.balance
    ? `${formatAmount(token0?.balance, token0.decimals)} ${token0.symbol}`
    : '';

  const balance01 = token0?.balance
    ? `Balance: ${trunc(formatAmount(token0?.balance, token0.decimals))} ${token0.symbol}`
    : '';

  const balance_tooltip02 = token1?.balance
    ? `${formatAmount(token1?.balance, token1.decimals)} ${token1.symbol}`
    : '';

  const balance02 = token1?.balance
    ? `Balance: ${trunc(formatAmount(token1?.balance, token1.decimals))} ${token1.symbol}`
    : '';

  const token0DollarValue =
    t0DV && amount0 ? calculateDollarValue(amount0, t0DV.toString()).toString() : '';

  const token1DollarValue =
    t1DV && amount1 ? calculateDollarValue(amount1, t1DV.toString()).toString() : '';

  return (
    <Container maxWidth='sm'>
      <Box className={classes.conatiner}>
        <Header
          elements={<Typography variant='h5'>Increase Liquidity</Typography>}
          cAlignItems='center'
        />
        <Box className={classes.marginTop} />

        <PoolInfo
          token0Address={token0?.address ?? ''}
          token1Address={token1?.address ?? ''}
          token0Symbol={token0?.symbol ?? ''}
          token1Symbol={token1?.symbol ?? ''}
          token0Uri={token0?.logoURI}
          token1Uri={token1?.logoURI}
          feeTier={feeTier}
        />

        <DisableElements disabled={!!(amount0Reserves && parseFloat(amount0Reserves) === 0)}>
          <ContainerSpaceBetween
            className01={[classes.marginTop, classes.selectTokenInputMaxContainer].join(' ')}
            className02={classes.bottomSelect}
            className03={classes.bottomInput}
            Component01={
              <SelectToken viewOnly={true} selected={token0} type={LIQUIDITY_TOKENS.TOKEN_0} />
            }
            Component02={
              <InputMax className={classes.inputMaxContainer} type={LIQUIDITY_TOKENS.TOKEN_0} />
            }
          />
          <Box className={classes.textMarginTop} />
          <RowAlignCenter
            elements01={
              <CTooltip title={balance_tooltip01}>
                <Typography variant='caption'>{balance01}</Typography>
              </CTooltip>
            }
            elements02={
              token0DollarValue !== '' ? (
                <CTooltip title={exponentialToDecimal(token0DollarValue.toString())}>
                  <Typography variant='caption'>{`~ $ ${trunc(
                    parseFloat(token0DollarValue)
                  )}`}</Typography>
                </CTooltip>
              ) : null
            }
          />
        </DisableElements>

        <DisableElements disabled={!!(amount1Reserves && parseFloat(amount1Reserves) === 0)}>
          <ContainerSpaceBetween
            className01={[classes.marginTop, classes.selectTokenInputMaxContainer].join(' ')}
            className02={classes.bottomSelect}
            className03={classes.bottomInput}
            Component01={
              <SelectToken viewOnly={true} selected={token1} type={LIQUIDITY_TOKENS.TOKEN_1} />
            }
            Component02={
              <InputMax className={classes.inputMaxContainer} type={LIQUIDITY_TOKENS.TOKEN_1} />
            }
          />
          <Box className={classes.textMarginTop} />
          <RowAlignCenter
            elements01={
              <CTooltip title={balance_tooltip02}>
                <Typography variant='caption'>{balance02}</Typography>
              </CTooltip>
            }
            elements02={
              token1DollarValue !== '' ? (
                <CTooltip title={exponentialToDecimal(token1DollarValue.toString())}>
                  <Typography variant='caption'>{`~ $ ${trunc(
                    parseFloat(token1DollarValue)
                  )}`}</Typography>
                </CTooltip>
              ) : null
            }
          />
        </DisableElements>

        <Box className={classes.marginTop} />
        <Typography variant='h6'>Current Liquidity</Typography>
        <Box mt='10px' />

        <Grid container alignItems='stretch' spacing={2}>
          <Grid item xs={6}>
            <SmallContentContainer token={token0 ?? DEFAULT_TOKEN} amount={token0Reserve} />
          </Grid>
          <Grid item xs={6}>
            <SmallContentContainer token={token1 ?? DEFAULT_TOKEN} amount={token1Reserve} />
          </Grid>
        </Grid>

        <Box className={classes.marginTop} />
        <ItemList />
        <Box className={classes.marginTop} />
        <ButtonIndicator
          disabled={dappLoading === LOADING.INCREASE_LIQUIDITY}
          onlyDisable={!allowAdd()}
          onClick={addLiquidity}
          disableElevation
          className={classes.buttonBottom}
          variant='contained'
          label={showText()}
          fullWidth
          color='primary'
        />
      </Box>
    </Container>
  );
};

export default IncreaseLiquidity;
