import { farmingClient as client } from '../apollo/client';

import {
  NFT_CLAIMED_REWARD,
  FARMING_POOLS_TVL,
  FARMING_PROTOCOL_DATA,
  FARMING_POOLS,
} from '../apollo/queries';
import { logError } from '../utils/logs';
import { ISubgraphToken } from '../utils/generalTypes';

interface NftClaimedReward {
  nfts: {
    tokenId: number;
    reward: string;
  }[];
}

export const fetchNftClaimedReward = async (tokenIds: string[]) => {
  try {
    const { data, error, loading } = await client.query<NftClaimedReward>({
      query: NFT_CLAIMED_REWARD,
      variables: {
        tokenIds,
      },
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      return data.nfts.reduce((accum: { [key: number]: string }, nft) => {
        accum[nft.tokenId] = nft.reward;
        return accum;
      }, {});
    }
    logError('fetchNftClaimedReward-e', error);
  } catch (e) {
    logError('fetchNftClaimedReward', e);
  }
};

interface FarmingPoolsTvl {
  farmPools: {
    id: string;
    tvlTotal: string;
  }[];
}

export const fetchFarmingPoolsTvl = async (poolAddresses: string[]) => {
  try {
    const { data, error, loading } = await client.query<FarmingPoolsTvl>({
      query: FARMING_POOLS_TVL,
      variables: {
        poolAddresses,
      },
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      return data.farmPools;
    }
    logError('fetchFarmingPoolsTvl-e', error);
  } catch (e) {
    logError('fetchFarmingPoolsTvl', e);
  }
};

interface FarmingProtocol {
  protocols: {
    totalReward: string;
    total: string;
  }[];
}

export const fetchFarmingProtocolData = async (id: string) => {
  try {
    const { data, error, loading } = await client.query<FarmingProtocol>({
      query: FARMING_PROTOCOL_DATA,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      return data.protocols[0];
    }
    logError('fetchFarmingProtocolData-e', error);
  } catch (e) {
    logError('fetchFarmingProtocolData', e);
  }
};

interface FarmingPools {
  farmPools: {
    id: string;
    token0: ISubgraphToken;
    token1: ISubgraphToken;
    fee: string;
  }[];
}

export const fetchPools = async () => {
  try {
    const { data, error, loading } = await client.query<FarmingPools>({
      query: FARMING_POOLS,
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      return data.farmPools;
    }
    logError('fetchPools-e', error);
  } catch (e) {
    logError('fetchPools', e);
  }
};
