import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { TickMath } from '@uniswap/v3-sdk';
import { useHistory } from 'react-router-dom';

import { useDispatchWrap } from './utilHooks';
import {
  selectToken,
  addData,
  clearData,
  setLiquidityType,
  updateAddLiquidity,
  State,
} from '../redux';
import { LIQUIDITY_TOKENS, LIQUIDITY_TYPES, CALL_TYPE } from '../utils/enums';
import { ILiquidity, IToken } from '../utils/generalTypes';
import { parseAmount, formatAmount, exponentialToDecimal } from '../utils/formating';
import {
  getTokens,
  priceFromTick,
  tryParseTick,
  findPoolAddress,
  isValidTick,
} from '../utils/uniswapSDKFunctions';
import { poolLiquidity } from '../contracts/functions/pool';
import { uniswapV3PoolAddresses } from '../contracts/functions/uniswapV3Factory';
import { rebase, getTotalAmounts } from '../contracts/functions/liquidityManager';
import { getAllowance, approve, getBalance } from '../contracts/functions/erc20';
import { CONTRACT_ADDRESSES, AMOUNT_INPUT_REGEX, ZERO_ADDRESS, FEE_TIERS } from '../constants';
import { fetchPoolTvl, PoolTVLChart } from '../data';
import { unipilotClient } from '../apollo/client';
import { useNFTPositions, useTokenList } from './';

export const useLiquidity = () => {
  const liquidityData = useSelector((state: State) => state.liquidity);

  const selectTokenF = useDispatchWrap(selectToken);
  const addDataF = useDispatchWrap(addData);
  const clearDataF = useDispatchWrap(clearData);
  const setLiquidityTypeF = useDispatchWrap(setLiquidityType);
  const updateAddLiquidityF = useDispatchWrap(updateAddLiquidity);

  return {
    ...liquidityData,
    selectTokenF,
    addDataF,
    clearDataF,
    setLiquidityTypeF,
    updateAddLiquidityF,
  };
};

export const useLiquidityRatio = () => {
  const { token0, token1, amount0Reserves, amount1Reserves, initialAmount, tick, sqrtPrice } =
    useLiquidity();

  const calculateRatio = (type: LIQUIDITY_TOKENS) => {
    if (token0 && token1) {
      if (initialAmount && parseFloat(initialAmount) > 0 && sqrtPrice) {
        const inital = new BigNumber(initialAmount);
        const ratio =
          type === LIQUIDITY_TOKENS.TOKEN_0 ? inital : new BigNumber(1).dividedBy(inital);
        return ratio;
      } else if (parseFloat(amount0Reserves) > 0 && parseFloat(amount1Reserves) > 0) {
        const _baseToken = token0?.address > token1?.address ? 'token1' : 'token0';
        const { baseToken, quoteToken } = getTokens(token0, token1);
        const token0Reserves = new BigNumber(formatAmount(amount0Reserves, baseToken.decimals));
        const token1Reserves = new BigNumber(formatAmount(amount1Reserves, quoteToken.decimals));
        const ratio =
          type === LIQUIDITY_TOKENS.TOKEN_0
            ? _baseToken === 'token0'
              ? token1Reserves.dividedBy(token0Reserves)
              : token0Reserves.dividedBy(token1Reserves)
            : _baseToken === 'token0'
            ? token0Reserves.dividedBy(token1Reserves)
            : token1Reserves.dividedBy(token0Reserves);
        return ratio;
      } else if (tick && isValidTick(Number(tick), 2)) {
        const ratio =
          type === LIQUIDITY_TOKENS.TOKEN_0
            ? priceFromTick(token0, token1, Number(tick))
            : priceFromTick(token1, token0, Number(tick));
        return new BigNumber(ratio);
      } else return new BigNumber(0);
    } else return new BigNumber(0);
  };

  return calculateRatio;
};

export const useLiquidityParams = () => {
  const {
    token0,
    token1,
    amount0,
    amount1,
    addLiquidityState: { tokenId },
  } = useLiquidity();
  const { account } = useWeb3React();

  const getAddLiquidityParams = (defaultTokenId?: string): ILiquidity['add'] | undefined => {
    if (token0 && token1 && account) {
      const baseToken = token0?.address > token1?.address ? 'token1' : 'token0';
      let _amount0 = new BigNumber(parseAmount(amount0 ? amount0 : '0', token0.decimals));
      let _amount1 = new BigNumber(parseAmount(amount1 ? amount1 : '0', token1.decimals));

      _amount0 =
        _amount0.isEqualTo(_amount1) && _amount0.isGreaterThan(0) ? _amount0.minus(1) : _amount0;

      if (baseToken === 'token0') {
        return [
          account,
          CONTRACT_ADDRESSES.liquidityManager,
          token0.address,
          token1.address,
          exponentialToDecimal(_amount0.toString(), false),
          exponentialToDecimal(_amount1.toString(), false),
          defaultTokenId ? defaultTokenId : tokenId,
        ];
      } else {
        return [
          account,
          CONTRACT_ADDRESSES.liquidityManager,
          token1.address,
          token0.address,
          exponentialToDecimal(_amount1.toString(), false),
          exponentialToDecimal(_amount0.toString(), false),
          defaultTokenId ? defaultTokenId : tokenId,
        ];
      }
    }
  };

  const getCreatePoolWithLiquidityParams = (): ILiquidity['createAndAdd'] | undefined => {
    if (token0 && token1 && account) {
      const baseToken = token0?.address > token1?.address ? 'token1' : 'token0';
      if (baseToken === 'token0') {
        return [
          account,
          CONTRACT_ADDRESSES.liquidityManager,
          token0.address,
          token1.address,
          parseAmount(amount0, token0.decimals),
          parseAmount(amount1, token1.decimals),
          '0',
        ];
      } else {
        return [
          account,
          CONTRACT_ADDRESSES.liquidityManager,
          token1.address,
          token0.address,
          parseAmount(amount1, token1.decimals),
          parseAmount(amount0, token0.decimals),
          '0',
        ];
      }
    }
  };

  return { getAddLiquidityParams, getCreatePoolWithLiquidityParams };
};

export const useLiquidityAllowance = () => {
  const { token0, token1, amount0Reserves, amount1Reserves } = useLiquidity();
  let baseToken = '';

  if (token0 && token1) {
    baseToken =
      token0?.address > token1?.address ? LIQUIDITY_TOKENS.TOKEN_1 : LIQUIDITY_TOKENS.TOKEN_0;
  }

  const checkAllowance = (token: LIQUIDITY_TOKENS) => {
    if (baseToken === token && amount0Reserves && parseFloat(amount0Reserves) === 0) return false;
    else if (baseToken !== token && amount1Reserves && parseFloat(amount1Reserves) === 0)
      return false;
    else return true;
  };

  return { checkAllowance };
};

export const useLiquidityCalculations = () => {
  const { account } = useWeb3React();
  const { token0, token1, feeTier, addLiquidityState, addDataF, updateAddLiquidityF } =
    useLiquidity();
  const { tokenId } = addLiquidityState;

  const handleInitalAmountBlur = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const value = target.value;
      if (value) {
        if (value === '.') {
          addDataF({
            initialAmount: '0.',
            sqrtPrice: '',
            amount0: '',
            amount1: '',
          });
        } else if (token0 && token1 && feeTier && AMOUNT_INPUT_REGEX.test(value)) {
          if (parseFloat(value) > 0) {
            let sqrtPrice = '';
            try {
              const tick = tryParseTick(value, token0, token1, feeTier);
              sqrtPrice = TickMath.getSqrtRatioAtTick(tick).toString();
            } catch (e) {
              sqrtPrice = '';
            }
            addDataF({
              initialAmount: value,
              sqrtPrice: sqrtPrice.toString(),
              amount0: '',
              amount1: '',
            });
          } else {
            addDataF({
              initialAmount: value,
              sqrtPrice: '',
              amount0: '',
              amount1: '',
            });
          }
        }
      } else {
        addDataF({
          initialAmount: '',
          sqrtPrice: '',
          amount0: '',
          amount1: '',
        });
      }
    },
    [token0, token1, feeTier, addDataF]
  );

  const handleRebase = useCallback(async () => {
    if (tokenId && token0 && token1 && account) {
      await rebase(Number(tokenId), token0, token1, feeTier, account, () => {
        updateAddLiquidityF({ outOfRange: false });
      });
    }
  }, [tokenId, token0, token1, account]);

  return { handleInitalAmountBlur, handleRebase };
};

export const usePoolTVL = () => {
  const { poolAddress } = useLiquidity();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PoolTVLChart[] | undefined>(undefined);

  const getPoolTvl = useCallback(async () => {
    if (poolAddress) {
      setLoading(true);
      const res = await fetchPoolTvl(poolAddress, unipilotClient);
      setData(res.data);
      setLoading(false);
    }
  }, [poolAddress, fetchPoolTvl]);

  useEffect(() => {
    getPoolTvl();
  }, [poolAddress]);

  return { loading, data };
};

interface IAllowance {
  allowance0: boolean;
  allowance1: boolean;
}

export const useTokenAllowance = () => {
  const { account } = useWeb3React();
  const { checkAllowance } = useLiquidityAllowance();
  const [allowance, setAllowance] = useState<IAllowance>({
    allowance0: false,
    allowance1: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const tokenAllowance = useCallback(
    (token: LIQUIDITY_TOKENS) => {
      if (token === LIQUIDITY_TOKENS.TOKEN_0) {
        return !allowance.allowance0 && checkAllowance(LIQUIDITY_TOKENS.TOKEN_0);
      } else {
        return !allowance.allowance1 && checkAllowance(LIQUIDITY_TOKENS.TOKEN_1);
      }
    },
    [allowance, checkAllowance]
  );

  const checkERC20TokenAllowance = useCallback(
    async (token0: IToken, token1: IToken) => {
      if (account) {
        setLoading(true);
        const _allowance0 = token0.isETH
          ? 1
          : await getAllowance(token0.address, account, CONTRACT_ADDRESSES.unipilot);
        const _allowance1 = token1.isETH
          ? 1
          : await getAllowance(token1.address, account, CONTRACT_ADDRESSES.unipilot);
        setAllowance({
          allowance0: _allowance0 > 0,
          allowance1: _allowance1 > 0,
        });
        setLoading(false);
      }
    },
    [account, setAllowance, getAllowance, setLoading]
  );

  const giveTokenAllowance = useCallback(
    async (token: IToken, tokenType: LIQUIDITY_TOKENS) => {
      if (account) {
        await approve(token.address, token.symbol, account, CONTRACT_ADDRESSES.unipilot, () => {
          tokenType === LIQUIDITY_TOKENS.TOKEN_0
            ? setAllowance(prevState => ({
                ...prevState,
                allowance0: true,
              }))
            : setAllowance(prevState => ({
                ...prevState,
                allowance1: true,
              }));
        });
      }
    },
    [account, approve, setAllowance]
  );

  return {
    loading,
    tokenAllowance,
    checkERC20TokenAllowance,
    giveTokenAllowance,
  };
};

export const useMaxPoolLiquidity = () => {
  const getPoolWithFees = useCallback(
    async (token0: IToken, token1: IToken) => {
      const poolAddressInputs = FEE_TIERS.map(feeTier => [token0.address, token1.address, feeTier]);
      let poolAddresses = await uniswapV3PoolAddresses(CALL_TYPE.MULTI, poolAddressInputs);
      return poolAddresses.reduce(
        (accum: { [key: string]: string }, poolAddress: string, index: number) => {
          if (poolAddress !== ZERO_ADDRESS) {
            accum[poolAddress] = FEE_TIERS[index];
          }
          return accum;
        },
        {}
      );
    },
    [findPoolAddress]
  );

  const selectMaxLiqFeeTeir = useCallback(
    async (token0: IToken, token1: IToken) => {
      const poolFees = (await getPoolWithFees(token0, token1)) ?? {};
      const poolAddresses = Object.keys(poolFees);
      let maxLiquidity = 0;
      let maxLiquidityPool = '';

      if (poolAddresses?.length > 0) {
        const liquidities = await getTotalAmounts(CALL_TYPE.MULTI, poolAddresses, [
          'totalLiquidity',
        ]);

        if (liquidities?.length > 0) {
          poolAddresses.map((poolAddress: string, index: number) => {
            const liquidity = liquidities[index];
            if (liquidity && Number(liquidity.totalLiquidity) > maxLiquidity) {
              maxLiquidity = Number(liquidity.totalLiquidity);
              maxLiquidityPool = poolAddress;
            }
          });
        }

        if (maxLiquidityPool === '') {
          const uniswapLiquidities = await poolLiquidity(CALL_TYPE.MULTI, poolAddresses);
          if (uniswapLiquidities?.length > 0) {
            poolAddresses.map((poolAddress: string, index: number) => {
              const liquidity = uniswapLiquidities[index];
              if (liquidity && Number(liquidity) > maxLiquidity) {
                maxLiquidity = Number(liquidity);
                maxLiquidityPool = poolAddress;
              }
            });
          }
        }
      }

      return maxLiquidityPool ? poolFees[maxLiquidityPool] : '3000';
    },
    [getPoolWithFees, getTotalAmounts]
  );

  return { selectMaxLiqFeeTeir };
};

// export const useMaxPoolLiquidity = () => {
//   const getPoolAddresses = useCallback(
//     (token0: IToken, token1: IToken) => {
//       let poolAddresses = FEE_TIERS.map(feeTier => findPoolAddress(token0, token1, feeTier));
//       return poolAddresses.filter(poolAddress => poolAddress !== ZERO_ADDRESS);
//     },
//     [findPoolAddress]
//   );

//   const selectMaxLiqFeeTeir = useCallback(
//     async (token0: IToken, token1: IToken) => {
//       const poolAddresses = getPoolAddresses(token0, token1);
//       let maxLiquidity = 0;
//       let maxIndex = 2;

//       if (poolAddresses.length > 0) {
//         const liquidities = await getTotalAmounts(CALL_TYPE.MULTI, poolAddresses, [
//           'totalLiquidity',
//         ]);

//         if (liquidities?.length > 0) {
//           liquidities.forEach((liquidity: { totalLiquidity: string }, index: number) => {
//             if (Number(liquidity.totalLiquidity) > maxLiquidity) {
//               maxLiquidity = Number(liquidity.totalLiquidity);
//               maxIndex = index;
//             }
//           });
//         }
//       }
//       return FEE_TIERS[maxIndex];
//     },
//     [getPoolAddresses, getTotalAmounts]
//   );

//   return { selectMaxLiqFeeTeir };
// };

export const useUnipilotNFT = () => {
  const { nftPositions } = useNFTPositions();
  const { account } = useWeb3React();

  const getUserNft = useCallback(
    (pool: string) => {
      if (nftPositions && account) {
        const userPosition = nftPositions.filter(
          ({ poolAddress, owner }) =>
            poolAddress.toLowerCase() === pool.toLowerCase() &&
            owner.toLowerCase() === account.toLowerCase()
        )[0];
        return userPosition?.tokenId.toString();
      }
    },
    [nftPositions, account]
  );

  const getUserPosition = useCallback(
    (pool: string) => {
      if (nftPositions) {
        const userPosition = nftPositions.filter(
          ({ poolAddress }) => poolAddress.toLowerCase() === pool.toLowerCase()
        );
        return userPosition;
      }
      return null;
    },
    [nftPositions]
  );

  return { getUserNft, getUserPosition };
};

export const useRedirectAddLiquidity = () => {
  const { account } = useWeb3React();
  const history = useHistory();

  const { tokens, addCustomTokenF } = useTokenList();
  const { addDataF, setLiquidityTypeF } = useLiquidity();

  const updateCustomToken = useCallback(
    async (token: IToken) => {
      let _token = token;
      const tokenExist = tokens.find(
        ({ address }) => address.toLowerCase() === token.address.toLowerCase()
      );
      if (!tokenExist) {
        const balance = account
          ? await getBalance(CALL_TYPE.SINGLE, [token.address], account)
          : '0';
        _token = { ..._token, balance };
        addCustomTokenF(_token);
      }
      return _token;
    },
    [tokens, addCustomTokenF]
  );

  const redirectToAddLiquidity = useCallback(
    async (token0: IToken, token1: IToken, feeTier: string, poolAddress: string) => {
      setLiquidityTypeF(LIQUIDITY_TYPES.FARM);
      const _token0 = await updateCustomToken(token0);
      const _token1 = await updateCustomToken(token1);
      addDataF({
        token0: _token0,
        token1: _token1,
        feeTier,
        poolAddress,
        disableAutoFeeTier: true,
      });
      history.push('/');
    },
    [setLiquidityTypeF, addDataF, history]
  );

  return redirectToAddLiquidity;
};
