import { Container, Grid, Typography, Box } from '@material-ui/core';
import { PILOT_LOGO } from '../../constants';

import useStyles from './styles';

interface ICountDown {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const CountDown: React.FC<ICountDown> = ({ days, hours, minutes, seconds }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Container className={classes.boxContainer}>
        <img src={PILOT_LOGO} className={classes.logo} />

        <Typography className={classes.heading} variant='h2'>
          Unipilot Goes Live In
        </Typography>
        {/* <Typography className={classes.subHeading} variant='h5'>
          Will be live in
        </Typography> */}
        <Grid container xs={12} className={classes.timerContainer}>
          <Grid justifyContent='center' item xs={3} className={classes.timerItem}>
            <Typography className={classes.timerText} variant='h3'>
              {days}
            </Typography>
            <Typography className={classes.timerTextSec} variant='body2'>
              DAYS
            </Typography>
          </Grid>
          <Grid justifyContent='center' item xs={3} className={classes.timerItem}>
            <Typography className={classes.timerText} variant='h3'>
              {hours}
            </Typography>
            <Typography className={classes.timerTextSec} variant='body2'>
              HOURS
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.timerItem}>
            <Typography className={classes.timerText} variant='h3'>
              {minutes}
            </Typography>
            <Typography className={classes.timerTextSec} variant='body2'>
              MINS
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.timerItem}>
            <Typography className={classes.timerText} variant='h3'>
              {seconds}
            </Typography>
            <Typography className={classes.timerTextSec} variant='body2'>
              SECS
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CountDown;
