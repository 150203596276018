import { useEffect, useState } from 'react';
import { Box, Grid, Typography, GridProps, Link, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/Error';

import { CTooltip } from '../';
import useStyles from './styles';
import { trunc, exponentialToDecimal } from '../../utils/formating';
import { UN_KNOWN_LOGO } from '../../constants';
import { getTokenLogo } from '../../redux';

interface IContainerSpaceBetween {
  Component01: React.ReactNode;
  Component02: React.ReactNode;
  className01?: string;
  className02?: string;
  className03?: string;
  justify?: GridProps['justify'];
  xs01?: GridProps['xs'];
  xs02?: GridProps['xs'];
  enableSizing?: boolean;
}

export const ContainerSpaceBetween: React.FC<IContainerSpaceBetween> = ({
  Component01,
  Component02,
  className01,
  className02,
  className03,
  justify,
  xs01 = 4,
  xs02 = 8,
  enableSizing = true,
}) => {
  return (
    <Grid
      className={className01}
      container
      alignItems='center'
      justify={justify ? justify : 'space-between'}
    >
      <Grid item xs={enableSizing ? xs01 : undefined} className={className02}>
        {Component01}
      </Grid>
      <Grid item xs={enableSizing ? xs02 : undefined} className={className03}>
        {Component02}
      </Grid>
    </Grid>
  );
};

interface IStringsSpaceBetween {
  string01: string;
  string02: string;
  className01?: string;
  className02?: string;
  className03?: string;
  str02InTrunc?: boolean;
  hideToolTip?: boolean;
}

export const StringsSpaceBetween: React.FC<IStringsSpaceBetween> = ({
  string01,
  string02,
  className01,
  className02,
  className03,
  str02InTrunc = false,
  hideToolTip = false,
}) => {
  return (
    <Grid container alignItems='center' justify='space-between' className={className01}>
      <Grid item>
        <Typography className={className02} variant='caption'>
          {string01}
        </Typography>
      </Grid>
      {str02InTrunc ? (
        <Grid item>
          {parseFloat(string02) > 0 ? (
            !hideToolTip ? (
              <CTooltip title={`${exponentialToDecimal(string02)}`}>
                <Typography className={className03} variant='caption'>
                  {`$ ${trunc(parseFloat(string02))}`}
                </Typography>
              </CTooltip>
            ) : (
              <Typography className={className03} variant='caption'>
                {`$ ${trunc(parseFloat(string02))}`}
              </Typography>
            )
          ) : (
            <Typography className={className03} variant='caption'>
              {`$ ${string02}`}
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid item>
          <Typography className={className03} variant='caption'>
            ${string02}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

interface IOverlapIcons {
  Icon01: React.FC<any>;
  Icon02: React.FC<any>;
  class01?: string;
  class02?: string;
}

export const OverlapIcons: React.FC<IOverlapIcons> = ({ Icon01, Icon02, class01, class02 }) => {
  const classes = useStyles();
  return (
    <Box position='relative'>
      <Box className={classes.iconContainer01}>
        <Icon01 className={class01 ? class01 : classes.icon} />
      </Box>
      <Box className={classes.iconContainer02}>
        <Icon02 className={class02 ? class02 : classes.icon} />
      </Box>
    </Box>
  );
};

interface IRowAlignCenter {
  elements01: React.ReactNode;
  elements02: React.ReactNode;
  justify?: GridProps['justify'];
  containerClassName?: string;
  element01ClassName?: string;
  element02ClassName?: string;
}

export const RowAlignCenter: React.FC<IRowAlignCenter> = ({
  elements01,
  elements02,
  justify,
  containerClassName,
  element01ClassName,
  element02ClassName,
}) => {
  return (
    <Grid
      className={containerClassName}
      container
      alignItems='center'
      justify={justify ? justify : 'space-between'}
    >
      <Grid className={`${element01ClassName}`} item>
        {elements01}
      </Grid>
      <Grid className={`${element02ClassName}`} item>
        {elements02}
      </Grid>
    </Grid>
  );
};

interface IRowThreeContent {
  iconWithStr: React.ReactNode;
  greenInfo?: React.ReactNode;
  amount?: string;
  className03?: string;
  withoutParsedString?: string;
  isPilot?: boolean;
}

export const RowThreeContent: React.FC<IRowThreeContent> = ({
  iconWithStr,
  greenInfo,
  amount,
  className03,
  withoutParsedString = '',
  isPilot = false,
}) => {
  const classes = useStyles();
  //can make Skeleton conditional

  const twapText = (
    <Box>
      <Typography style={{ fontSize: '0.8rem' }}>
        The amount of pilot tokens may slightly vary due to time weighted average price{' '}
        <Link style={{ color: '#fff' }} href='https://empirica.io/blog/twap-strategy/'>
          (TWAP)
        </Link>
      </Typography>
    </Box>
  );

  return (
    <Grid container alignItems='center' justify='space-between' style={{ padding: '15px 0px' }}>
      <Grid item xs={7}>
        <RowAlignCenter elements01={iconWithStr} elements02={greenInfo} />
      </Grid>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {amount ? (
          // <CTooltip title={`${exponentialToDecimal(parseFloat(amount).toPrecision(5))}`}>
          //   <Typography className={className03 ? className03 : classes.amount}>
          //     {`${withoutParsedString}${trunc(parseFloat(parseFloat(amount).toPrecision(5)))}`}
          //   </Typography>
          // </CTooltip>
          <>
            <CTooltip title={`${exponentialToDecimal(amount)}`}>
              <Typography className={className03 ? className03 : classes.amount}>
                {`${withoutParsedString}${trunc(parseFloat(amount))}`}
              </Typography>
            </CTooltip>
            {isPilot && amount && (
              <Tooltip
                leaveDelay={1000}
                title={twapText}
                classes={{ tooltip: classes.tooltip }}
                arrow
              >
                <InfoOutlinedIcon className={classes.iconHelp} />
              </Tooltip>
            )}
          </>
        ) : (
          <Skeleton width={80} height={30} />
        )}
      </Grid>
    </Grid>
  );
};

interface IUpDownOverlapIcons {
  src01: string;
  src02: string;
  class01?: string;
  class02?: string;
}

export const UpDownOverlapIcons: React.FC<IUpDownOverlapIcons> = ({
  src01,
  src02,
  class01,
  class02,
}) => {
  const classes = useStyles();
  return (
    <Box position='relative'>
      <Box className={classes.iconContainer03}>
        <img src={src01} className={class01 ? class01 : classes.icon02} />
      </Box>
      <Box className={classes.iconContainer04}>
        <img src={src02} className={class02 ? class02 : classes.icon03} />
      </Box>
    </Box>
  );
};

interface IOverlapIconsURI {
  src01: string | undefined;
  src02: string | undefined;
  class01?: string;
  class02?: string;
}

export const OverlapIconsURI: React.FC<IOverlapIconsURI> = ({ src01, src02, class01, class02 }) => {
  const classes = useStyles();
  const [logo1, setLogo1] = useState<string>(UN_KNOWN_LOGO);
  const [logo2, setLogo2] = useState<string>(UN_KNOWN_LOGO);

  const getLogos = async () => {
    src01 && setLogo1(await getTokenLogo(src01));
    src02 && setLogo2(await getTokenLogo(src02));
  };

  useEffect(() => {
    getLogos();
  }, [src01, src02]);

  return (
    <Box style={{ marginLeft: '15px' }} position='relative'>
      {/* <Box className={classes.iconContainer05}> */}
      <img src={logo1} className={class01 ? class01 : classes.icon1} />
      {/* </Box> */}
      {/* <Box className={classes.iconContainer06}> */}
      <img src={logo2} className={class02 ? class02 : classes.icon1} />
      {/* </Box> */}
    </Box>
  );
};

interface ICGrid {
  elements01: React.ReactNode;
  elements02: React.ReactNode;
  alignItems?: GridProps['alignItems'];
  justify?: GridProps['justifyContent'];
  direction?: GridProps['direction'];
  containerProps?: GridProps;
  item01Props?: GridProps;
  item02Props?: GridProps;
  containerClassName?: string;
  element01ClassName?: string;
  element02ClassName?: string;
}

export const CGrid: React.FC<ICGrid> = ({
  elements01,
  elements02,
  justify,
  alignItems,
  direction,
  containerClassName,
  element01ClassName,
  element02ClassName,
  containerProps,
  item01Props,
  item02Props,
}) => {
  return (
    <Grid
      {...containerProps}
      className={containerClassName}
      container
      alignItems={alignItems ? alignItems : 'center'}
      justify={justify ? justify : 'space-between'}
      direction={direction ? direction : 'row'}
    >
      <Grid {...item01Props} className={`${element01ClassName}`} item>
        {elements01}
      </Grid>
      <Grid {...item02Props} className={`${element02ClassName}`} item>
        {elements02}
      </Grid>
    </Grid>
  );
};
