import { liquidityMigratorContract } from '../getContract';
import { getBalance, getTotalSupply } from './erc20';
import { getPairReserves } from './uniswapV2Pair';
import { getTotalAmounts } from './hypervisor';
import { calculateTotals } from './lixiVault';
import { userAmounts } from './popsicleV3Optimizer';
import { sendTransaction } from '../sendTransaction';
import { logMessage, logError } from '../../utils/logs';
import { ILiquidity, ILPToken, IMigrateLPToken } from '../../utils/generalTypes';
import { LOADING, MIGRATE_PROTOCOL, CALL_TYPE } from '../../utils/enums';
import { getPairTokenAmounts } from '../../utils/uniswapSDKFunctions';

export const migrateV3Liquidity = async (
  params: ILiquidity['migrateV3'],
  token0Symbol: string,
  token1Symbol: string,
  walletAddress: string,
  callback: any = () => {}
) => {
  try {
    logMessage('migrateV3LiquidityParams', params);
    const contract = liquidityMigratorContract();
    const contractFnc = contract?.methods.migrateV3Liquidity(params);
    const args = {
      walletAddress,
      txnMessage: `Migrate liquidity ${token0Symbol}/${token1Symbol}`,
      logMessage: 'migrateV3Liquidity',
      dappLoading: LOADING.MIGRATE,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('migrateV3Liquidity', e);
  }
};

export const migrateV2Liquidity = async (
  params: ILiquidity['migrateV2'],
  token0Symbol: string,
  token1Symbol: string,
  walletAddress: string,
  protocol: MIGRATE_PROTOCOL,
  callback: any = () => {}
) => {
  try {
    logMessage('migrateV2LiquidityParams', params);
    const contract = liquidityMigratorContract();
    const contractFnc =
      protocol === MIGRATE_PROTOCOL.Visor
        ? contract?.methods.migrateVisorLiquidity(params)
        : protocol === MIGRATE_PROTOCOL.Lixir
        ? contract?.methods.migrateLixirLiquidity(params)
        : protocol === MIGRATE_PROTOCOL.Popsicle
        ? contract?.methods.migratePopsicleLiquidity(params)
        : contract?.methods.migrateV2Liquidity(params);
    const args = {
      walletAddress,
      txnMessage: `Migrate liquidity ${token0Symbol}/${token1Symbol}`,
      logMessage: 'migrateV2Liquidity',
      dappLoading: LOADING.MIGRATE,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('migrateV2Liquidity', e);
  }
};

const getReserves = async (addresses: string[], protocol: MIGRATE_PROTOCOL) => {
  let pairReserves: { reserve0: string; reserve1: string }[];
  if (protocol === MIGRATE_PROTOCOL.UniswapV2 || protocol === MIGRATE_PROTOCOL.SushiSwap) {
    pairReserves = (
      (await getPairReserves(CALL_TYPE.MULTI, addresses, ['_reserve0', '_reserve1'])) ?? []
    ).map(({ _reserve0, _reserve1 }: any) => ({ reserve0: _reserve0, reserve1: _reserve1 }));
  } else if (protocol === MIGRATE_PROTOCOL.Visor) {
    pairReserves = (
      (await getTotalAmounts(CALL_TYPE.MULTI, addresses, ['total0', 'total1'])) ?? []
    ).map(({ total0, total1 }: any) => ({ reserve0: total0, reserve1: total1 }));
  } else if (protocol === MIGRATE_PROTOCOL.Lixir) {
    pairReserves = (
      (await calculateTotals(CALL_TYPE.MULTI, addresses, ['total0', 'total1'])) ?? []
    ).map(({ total0, total1 }: any) => ({ reserve0: total0, reserve1: total1 }));
  } else if (protocol === MIGRATE_PROTOCOL.Popsicle) {
    pairReserves = (
      (await userAmounts(CALL_TYPE.MULTI, addresses, ['amount0', 'amount1'])) ?? []
    ).map(({ amount0, amount1 }: any) => ({ reserve0: amount0, reserve1: amount1 }));
  } else {
    pairReserves = [];
  }
  return pairReserves;
};

export const getLpTokenDetail = async (
  lpTokens: ILPToken[],
  walletAddress: string,
  protocol: MIGRATE_PROTOCOL
): Promise<IMigrateLPToken[] | undefined> => {
  try {
    const addresses = lpTokens.map(({ id }) => id);
    const balances = await getBalance(CALL_TYPE.MULTI, addresses, walletAddress);
    const totalSupplies = await getTotalSupply(CALL_TYPE.MULTI, addresses);
    const reserves = await getReserves(addresses, protocol);

    return lpTokens.map((lpToken, index) => {
      const { reserve0, reserve1 } = reserves[index];
      const { token0Reserveds, token1Reserveds } = getPairTokenAmounts(
        lpToken.token0,
        lpToken.token1,
        reserve0,
        reserve1,
        totalSupplies[index],
        balances[index]
      );
      return {
        ...lpToken,
        liquidity: balances[index],
        totalSupply: totalSupplies[index],
        token0Reserve: token0Reserveds.toSignificant(5),
        token1Reserve: token1Reserveds.toSignificant(5),
      };
    });
  } catch (e) {
    logError('getLpTokenDetail => ', e);
  }
};
