import Web3 from "web3";
import { utils } from "ethers";
import dayjs from "dayjs";
import numbro from "numbro";

import { logError } from "../utils/logs";

export const conciseAddress = (address: string): string => {
  if (Web3.utils.isAddress(address)) {
    const addr = Web3.utils.toChecksumAddress(address);
    return `${addr.slice(0, 6)}...${addr.slice(addr.length - 3, addr.length)}`;
  }
  return "-";
};

export const ogTrunc = (val: number): string | number => {
  if (!Number(val)) {
    return 0;
  }
  const _val = (val * 100 - Math.trunc(val) * 100) / 100;
  if (!_val) {
    return Math.trunc(val);
  }
  let decimal = 0;
  if (_val < 0.000001) {
    decimal = 7;
    return parseFloat(val.toString()).toFixed(7);
  } else if (_val < 0.00001) {
    decimal = 6;
  } else if (_val < 0.0001) {
    decimal = 5;
  } else if (_val < 0.001) {
    decimal = 4;
  } else if (_val < 0.01) {
    decimal = 3;
  } else if (_val < 1) {
    decimal = 2;
  } else {
    return Math.trunc(val).toString();
  }
  const truncVal =
    _val.toString().match(new RegExp("^-?\\d+(?:.\\d{0," + decimal + "})?")) ??
    [][0];
  return (Math.trunc(val) + parseFloat(truncVal.toString())).toFixed(decimal);
};

export const trunc = (val: number | string, len = 8): string => {
  const numVal = typeof val === "string" ? parseFloat(val) : val;
  let _val = ogTrunc(numVal).toString();
  if (_val.includes("e") || _val.includes("E")) {
    _val = exponentialToDecimal(val.toString(), false);
  }
  const _val2 = _val.split(".");
  if (_val2[0].length > len) {
    const _val = _val2[0].slice(0, len);
    const joined = `${_val}...`;
    return joined;
  }
  if (_val.includes(".")) {
    const splitedVal = _val.split(".");
    if (val < 0.0001) {
      return "<0.0001";
    }
    return [
      splitedVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      splitedVal[1],
    ].join(".");
  } else {
    return Number(_val).toLocaleString();
  }
};

export const toFixed = (amount: string, decimals: number): string => {
  const regex = new RegExp("^-?\\d+(?:.\\d{0," + decimals + "})?");
  const nonExpAmount = exponentialToDecimal(amount, false);
  const _amount =
    nonExpAmount.toString().split(".").length > 1
      ? nonExpAmount.toString().match(regex) ?? [][0]
      : nonExpAmount.toString();
  return _amount ? `${_amount.toString()}` : "";
};

export const parseAmount = (num: string, decimals: number): string => {
  try {
    return utils.parseUnits(toFixed(num, decimals), decimals).toString();
  } catch (e) {
    logError("parseAmount", e);
    return "";
  }
};

export const formatAmount = (num: string, decimals: number): string => {
  try {
    const nonExponentNum = exponentialToDecimal(num, false);
    return utils.formatUnits(nonExponentNum, decimals);
  } catch (e) {
    logError("formatAmount", e);
    return "";
  }
};

export const sortObjectArray = <T, U>(arr: T[], key: U): T[] => {
  const newArr = arr;
  newArr.sort((a: any, b: any) =>
    a[key].toLowerCase().localeCompare(b[key].toLowerCase())
  );
  return newArr;
};

export const exponentialToDecimal = (
  exponential: string,
  alloCommas: boolean = true
) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text: string) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        if (alloCommas) {
          text = `${text.slice(0, textLength - j)},${text.slice(
            textLength - j,
            textLength
          )}`;
        } else {
          text = `${text.slice(0, textLength - j)}${text.slice(
            textLength - j,
            textLength
          )}`;
        }
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

export const truncSymbol = (symbol: string, length: number = 5) => {
  return symbol.length > length ? `${symbol.substring(0, length)}...` : symbol;
};

export const unixToDate = (unix: number, format = "YYYY-MM-DD"): string => {
  return dayjs.unix(unix).utc().format(format);
};

export const formatDollarAmount = (
  num: number | undefined,
  digits = 2,
  round = true
) => {
  if (num === 0) return "$0.00";
  if (!num) return "-";
  if (num < 0.001 && digits <= 3) {
    return "<$0.001";
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: "M",
      billion: "B",
    },
  });
};

export const formatTokenAmount = (num: number | undefined, digits = 2) => {
  if (num === 0) return "0";
  if (!num) return "-";
  if (num < 0.001) {
    return "<0.001";
  }
  return numbro(num).format({
    average: true,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: "M",
      billion: "B",
    },
  });
};
