import { useState, useMemo } from 'react';
import {
  Container,
  Typography,
  DialogContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import useStyles from './styles';
import {
  IMDialog,
  CGrid,
  CloseButton,
  Notification,
  ButtonIndicator,
  Check,
  AnimatedViews,
} from '../';

interface IFMigration {
  open: boolean;
  close: () => void;
  tokenId: number;
  lockNft: (callBack?: () => void) => void;
  unlockNft: (callBack?: () => void) => void;
  approve: (callBack?: () => void) => void;
  closeCallback: (callBack?: () => void) => void;
}

function getSteps() {
  return ['Unstake', 'Approve', 'Stake', 'Finish'];
}

export const FMigration: React.FC<IFMigration> = ({
  open,
  close,
  tokenId,
  lockNft,
  unlockNft,
  approve,
  closeCallback,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const _xs = useMediaQuery(theme.breakpoints.down('xs'));

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const lastStep = useMemo<boolean>(() => {
    return activeStep + 1 == steps.length;
  }, [activeStep]);

  const callBack = () => {
    activeStep < steps.length - 1 && setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleClose = () => {
    if (lastStep) {
      closeCallback();
      close();
    } else {
      close();
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      unlockNft(callBack);
    } else if (activeStep === 1) {
      approve(callBack);
    } else if (activeStep === 2) {
      lockNft(callBack);
    } else {
      handleClose();
    }
  };

  return (
    <IMDialog
      PaperProps={{ className: classes.dialogPaperTransMigration }}
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent
        style={{
          padding: 10,
        }}
      >
        <Container disableGutters maxWidth='md'>
          <Box
            style={{
              padding: '5px 10px',
            }}
          >
            <CGrid
              alignItems='center'
              justify='space-between'
              elements01={<Typography variant='h5'>Migration</Typography>}
              elements02={<CloseButton onClick={handleClose} />}
            />
            <Box mt={1} />
          </Box>
          {_xs ? (
            <AnimatedViews
              items={steps.map((text, idx) => (
                <StepsIndicator steps={idx + 1} text={text} />
              ))}
              page={activeStep}
              setPage={() => {}}
            />
          ) : (
            <Stepper
              style={{ backgroundColor: 'transparent' }}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box className={classes.boxContainerMig}>
              {!lastStep && (
                <>
                  <Notification
                    msg={
                      'The migration of your NFT involves three steps: withdrawing the NFT from previous contract, approving and then staking to the new contract.'
                    }
                  />
                  <Box mt={2} />
                </>
              )}

              {!lastStep ? (
                <CGrid
                  alignItems='center'
                  justify='space-between'
                  elements01={
                    <Typography
                      variant='h5'
                      color='primary'
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Token ID
                    </Typography>
                  }
                  elements02={
                    <Typography className={classes.nftId} variant='h5'>
                      {tokenId}
                    </Typography>
                  }
                />
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Check className={classes.check} />
                </Box>
              )}

              {lastStep && (
                <>
                  <Box mt={2} />
                  <Typography style={{ textAlign: 'center' }}>Congratulations!</Typography>
                  <Typography style={{ textAlign: 'center' }}>
                    Your NFT successfully migrated
                  </Typography>
                </>
              )}

              <Box mt={lastStep ? 6 : 3} />

              <ButtonIndicator
                onClick={handleNext}
                disableElevation
                className={classes.buttonBottom}
                variant='contained'
                label={steps[activeStep]}
                fullWidth
                color='primary'
              />
            </Box>
          </Box>
        </Container>
      </DialogContent>
    </IMDialog>
  );
};

export default FMigration;

const StepsIndicator = ({ steps = 1, text = 'Approve' }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          style={{
            backgroundColor: theme.palette.primary.main,
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 25,
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {steps}
        </Typography>
        <Typography style={{ fontSize: '1.2rem', fontWeight: 400, marginTop: 5 }}>
          {text}
        </Typography>
      </Box>
    </>
  );
};
