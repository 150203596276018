import { Token } from '@uniswap/sdk-core';

import { WETH_ADDRESS, PILOT_LOGO, PILOT_ADDRESS } from './';
import { IToken } from '../utils/generalTypes';

export const ETH: IToken = {
  address: WETH_ADDRESS,
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
  logoURI: 'https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip',
  isETH: true,
};

export const WETH: IToken = {
  address: WETH_ADDRESS,
  name: 'Wrapped Ether',
  symbol: 'WETH',
  decimals: 18,
  logoURI:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc778417E063141139Fce010982780140Aa0cD5Ab/logo.png',
};

export const PILOT: IToken = {
  address: PILOT_ADDRESS,
  //address: '0x18F10404cC1414e6ACBfEA16CCC97D9ed303e98C',
  name: 'Unipilot',
  symbol: 'PILOT',
  decimals: 18,
  logoURI: PILOT_LOGO,
};

export const DEFAULT_TOKEN: IToken = {
  address: '',
  name: 'Unknown',
  symbol: 'Unknown',
  decimals: 0,
};

export const DAI = new Token(
  1,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
);

export const USDC = new Token(1, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C');

export const USDT = new Token(
  1,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
);

export const WBTC = new Token(
  1,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC'
);
