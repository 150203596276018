import { Button, ButtonProps, SvgIconProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IButtonDropIcon {
  style?: React.CSSProperties;
  visibleExpandIcon?: boolean;
  viewOnly?: boolean;
  iconColor?: SvgIconProps['color'];
  endIcon?: JSX.Element;
}

const ButtonDropIcon: React.FC<IButtonDropIcon & ButtonProps> = ({
  onClick = () => {},
  children,
  style = {},
  visibleExpandIcon = true,
  viewOnly = false,
  iconColor,
  endIcon = <ExpandMoreIcon color={iconColor} />,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      style={style}
      disableRipple={viewOnly}
      onClick={viewOnly && !visibleExpandIcon ? () => {} : onClick}
      endIcon={visibleExpandIcon ? endIcon : undefined}
    >
      {children}
    </Button>
  );
};

export default ButtonDropIcon;
