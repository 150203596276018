import { FarmingTypes } from '../actionTypes';
import { FarmingActions } from '../actions/farmingActions';
import { IFarmingPool, IFarmingNFT } from '../../utils/generalTypes';

export interface FarmingState {
  farmingPools: IFarmingPool[] | undefined;
  userLockedNfts: { [key: string]: IFarmingNFT[] };
  locked: boolean;
  farmingPoolsTvl: { [key: string]: string };
  tvl: string | undefined;
  reward: string | undefined;
  sortBy: keyof IFarmingPool;
  whiteListedPools: string[];
  farmingInfoModal: boolean;
}

const initialState: FarmingState = {
  farmingPools: undefined,
  userLockedNfts: {},
  locked: false,
  farmingPoolsTvl: {},
  tvl: undefined,
  reward: undefined,
  sortBy: 'tvl',
  whiteListedPools: [],
  farmingInfoModal: false,
};

const farmingReducer = (state: FarmingState = initialState, action: FarmingActions) => {
  switch (action.type) {
    case FarmingTypes.GET_FARMING_POOLS:
      return {
        ...state,
        farmingPools: action.payload.farmingPools,
        whiteListedPools: action.payload.whiteListedPools,
      };

    case FarmingTypes.GET_USER_LOCKED_NFTS:
      return { ...state, userLockedNfts: action.payload };

    case FarmingTypes.CLEAR_USER_LOCKED_NFTS:
      return { ...state, userLockedNfts: {} };

    case FarmingTypes.SET_LOCK:
      return { ...state, locked: action.payload };

    case FarmingTypes.UPDATE_LOCKED_NFT:
      const poolAddress = action.payload.poolAddress;
      const tempPool = state.userLockedNfts[poolAddress];
      const index = tempPool.findIndex(({ tokenId }) => tokenId === action.payload.tokenId);
      tempPool[index] = { ...tempPool[index], claimedReward: action.payload.amount };
      return {
        ...state,
        userLockedNfts: { ...state.userLockedNfts, [poolAddress]: [...tempPool] },
      };

    case FarmingTypes.SET_FARMING_POOLS_TVL:
      const { farmingPoolsTvl, reward, tvl } = action.payload;
      return { ...state, farmingPoolsTvl, tvl, reward };

    case FarmingTypes.UPDATE_SORT_BY:
      return { ...state, sortBy: action.payload };

    case FarmingTypes.FARMING_MODAL:
      return { ...state, farmingInfoModal: action.payload };

    default:
      return state;
  }
};

export default farmingReducer;
