import { useEffect, useState, useMemo } from 'react';
import { Grid, List, ListItem, Typography, CircularProgress } from '@material-ui/core';

import { CDivider, CTooltip } from '../';
import useStyles from './styles';
import { useLiquidity, useLiquidityRatio, useLiquidityAllowance } from '../../hooks';
import { LIQUIDITY_TOKENS } from '../../utils/enums';
import { trunc, exponentialToDecimal, truncSymbol } from '../../utils/formating';
import { getTokensDollarValue } from '../../redux';

interface IItemList {
  loading?: boolean;
}

interface IDollarValue {
  token0DollarValue: number | null;
  token1DollarValue: number | null;
}

interface ICurrentPrice {
  currentPricetoken0Pertoken1: number | null;
  currentPricetoken1Pertoken0: number | null;
}

const PILOT_ETH_3000 = '0xfc9f572124d8f469960b94537b493f2676776c03';

const ItemList: React.FC<IItemList> = ({ loading = false }) => {
  const classes = useStyles();

  const {
    token0,
    token1,
    feeTier,
    tick,
    initialAmount,
    sqrtPrice,
    amount0Reserves,
    amount1Reserves,
    poolAddress,
  } = useLiquidity();

  const calculateRatio = useLiquidityRatio();
  const { checkAllowance } = useLiquidityAllowance();

  const [dollarValue, setDollarValue] = useState<IDollarValue>({
    token0DollarValue: 0,
    token1DollarValue: 0,
  });
  const [currentPrice, setCurrentPrice] = useState<ICurrentPrice>({
    currentPricetoken0Pertoken1: 0,
    currentPricetoken1Pertoken0: 0,
  });

  const { token0DollarValue, token1DollarValue } = dollarValue;
  const { currentPricetoken0Pertoken1, currentPricetoken1Pertoken0 } = currentPrice;

  const hidePilotRatio = useMemo<boolean>(() => {
    return poolAddress.toLowerCase() === PILOT_ETH_3000;
  }, [poolAddress]);

  const showData = (): boolean => {
    return token0 &&
      token1 &&
      feeTier &&
      ((amount0Reserves && amount1Reserves) || (initialAmount && sqrtPrice) || tick)
      ? true
      : false;
  };

  const fetchPrice = async (): Promise<void> => {
    if (token0 && token1) {
      setDollarValue({ token0DollarValue: null, token1DollarValue: null });
      const res = await getTokensDollarValue([token0.address, token1.address]);
      res
        ? setDollarValue({
            token0DollarValue: res.token0DV,
            token1DollarValue: res.token1DV,
          })
        : setDollarValue({ token0DollarValue: 0, token1DollarValue: 0 });
    } else {
      setDollarValue({ token0DollarValue: 0, token1DollarValue: 0 });
    }
  };

  const setCurrentPrices = () => {
    if (showData()) {
      let currentPricetoken0Pertoken1 = checkAllowance(LIQUIDITY_TOKENS.TOKEN_0)
        ? parseFloat(calculateRatio(LIQUIDITY_TOKENS.TOKEN_0).toPrecision(8))
        : 0;

      let currentPricetoken1Pertoken0 = checkAllowance(LIQUIDITY_TOKENS.TOKEN_1)
        ? parseFloat(calculateRatio(LIQUIDITY_TOKENS.TOKEN_1).toPrecision(8))
        : 0;

      setCurrentPrice({
        currentPricetoken0Pertoken1,
        currentPricetoken1Pertoken0,
      });
    } else {
      setCurrentPrice({
        currentPricetoken0Pertoken1: 0,
        currentPricetoken1Pertoken0: 0,
      });
    }
  };

  useEffect(() => {
    fetchPrice();
  }, [token0, token1]);

  useEffect(() => {
    setCurrentPrices();
  }, [token0, token1, amount0Reserves, amount1Reserves, initialAmount, sqrtPrice, tick]);

  const vals = [
    {
      name: 'Rate',
      vals: {
        one: currentPricetoken0Pertoken1 && currentPricetoken0Pertoken1 > 0 && (
          <CTooltip title={exponentialToDecimal(currentPricetoken0Pertoken1.toString())}>
            <Typography variant='body2'>{`1 ${truncSymbol(token0?.symbol ?? '', 10)} =  ${trunc(
              currentPricetoken0Pertoken1
            )} ${truncSymbol(token1?.symbol ?? '', 10)}`}</Typography>
          </CTooltip>
        ),
        two: currentPricetoken1Pertoken0 && currentPricetoken1Pertoken0 > 0 && (
          <CTooltip title={exponentialToDecimal(currentPricetoken1Pertoken0.toString())}>
            <Typography variant='body2'>{`1 ${truncSymbol(token1?.symbol ?? '', 10)} =  ${trunc(
              currentPricetoken1Pertoken0
            )} ${truncSymbol(token0?.symbol ?? '', 10)}`}</Typography>
          </CTooltip>
        ),
      },
    },
    {
      name: 'USD Price',
      vals: {
        one: token0DollarValue && token0DollarValue > 0 && (
          <CTooltip title={`${exponentialToDecimal(token0DollarValue.toString())}`}>
            <Typography variant='body2'>{`1 ${truncSymbol(token0?.symbol ?? '', 10)} = $ ${trunc(
              token0DollarValue
            )}`}</Typography>
          </CTooltip>
        ),
        two: token1DollarValue && token1DollarValue > 0 && (
          <CTooltip title={`${exponentialToDecimal(token1DollarValue.toString())}`}>
            <Typography variant='body2'>{`1 ${truncSymbol(token1?.symbol ?? '', 10)} = $ ${trunc(
              token1DollarValue
            )}`}</Typography>
          </CTooltip>
        ),
      },
    },
  ];

  return (
    <List className={classes.container}>
      <Typography variant='h6' style={{ padding: '0px 15px', paddingTop: '15px' }}>
        Pool Details
      </Typography>
      {/* <ItemListBox
        divider
        val={vals[0]}
        loading={loading}
        showValue={
          currentPricetoken0Pertoken1 !== 0 && currentPricetoken1Pertoken0 !== 0 && !hidePilotRatio
        }
      /> */}
      <ItemListBox
        val={vals[1]}
        loading={token0DollarValue === null && token1DollarValue === null}
        showValue={token0DollarValue !== 0 && token1DollarValue !== 0}
      />
    </List>
  );
};

export default ItemList;

interface ItemListBox {
  divider?: boolean;
  val: any;
  showValue: boolean;
  loading?: boolean;
}

const ItemListBox: React.FC<ItemListBox> = ({ divider = false, val, showValue, loading }) => {
  const classes = useStyles();
  return (
    <>
      <ListItem
        classes={{
          root: classes.listItemRoot,
        }}
      >
        <Grid container alignItems='flex-start' justify='space-between'>
          <Grid item>
            <Typography variant='body2'>{val.name}</Typography>
          </Grid>
          <Grid item>
            {loading ? (
              <CircularProgress size={20} style={{ color: '#fff' }} />
            ) : showValue ? (
              <Grid container direction='column' alignItems='flex-end' spacing={1}>
                <Grid item>{val.vals.one}</Grid>
                <Grid item>{val.vals.two}</Grid>
              </Grid>
            ) : (
              <Grid item>
                <Typography variant='body2'>---</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ListItem>
      {divider && <CDivider />}
    </>
  );
};
