import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Grid, Slider, Tooltip, Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: Props) {
  const { children, open, value } = props;
  const classes = useStyles();

  return (
    <Tooltip
      open={open}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      enterTouchDelay={0}
      placement='top'
      title={value}
      //   style={{ marginTop: 100 }}
    >
      {children}
    </Tooltip>
  );
}

interface ICSlider {
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
}

const CSlider: React.FC<ICSlider> = ({ amount, setAmount }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item>{/* <Typography className={classes.sliderPerc}>0%</Typography> */}</Grid>
        {/* <Grid item>
          <Typography className={classes.sliderPerc}>100%</Typography>
        </Grid> */}
      </Grid>
      <Slider
        // style={{ padding: "0px 3px" }}
        valueLabelDisplay='on'
        classes={{
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
          valueLabel: classes.valueLabel,
        }}
        min={0}
        step={1}
        max={100}
        valueLabelFormat={x => `${x}%`}
        value={amount}
        // ValueLabelComponent={ValueLabelComponent}
        aria-label='custom thumb label'
        defaultValue={50}
        onChange={(e, value) => {
          // @ts-ignore
          setAmount(value);
        }}
        // onEnded={(value) => {
        //   console.log(value);
        // }}
      />
      <Box mt={'15px'} />
    </>
  );
};

export default CSlider;
