import { TablePagination, Box } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import useStyles from './styles';
import { Nothing } from '../';

interface IWithPagination {
  rowsPerPage: number;
  page: number;
  items: any[];
  setPage: any;
  func: any;
  title?: string;
  containerClassName?: string;
  nothingContainerClassName?: string;
}

const WithPagination: React.FC<IWithPagination> = ({
  rowsPerPage,
  page = 0,
  items = [],
  setPage = () => {},
  func = () => {},
  title = 'No position available',
  containerClassName = '',
  nothingContainerClassName = '',
}) => {
  const classes = useStyles();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeIndex = (index: number) => {
    setPage(index);
  };

  return (
    <>
      <Box
        className={
          items.length > 0 ? [classes.conatiner, containerClassName].join(' ') : classes.conatiner
        }
      >
        <SwipeableViews
          springConfig={{
            duration: '0.7s',
            easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
            delay: '0s',
          }}
          index={page}
          onChangeIndex={handleChangeIndex}
        >
          {items.length > 0 ? (
            [...Array(Math.ceil(items.length / rowsPerPage)).keys()].map((n, i) => {
              return (
                <TabPanel
                  items={items.slice(n * rowsPerPage, n * rowsPerPage + rowsPerPage)}
                  func={func}
                  index={n}
                  value={page}
                  key={n}
                />
              );
            })
          ) : (
            <Nothing containerClassName={nothingContainerClassName} title={title} />
          )}
        </SwipeableViews>
      </Box>

      {rowsPerPage < items.length && (
        <TablePagination
          component='div'
          count={items.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage=''
          rowsPerPageOptions={[]}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      )}
    </>
  );
};

export default WithPagination;

interface TabPanelProps {
  index: number;
  value: number;
  items: any[];
  func: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { value, index, items, func, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {items.length > 0 &&
            items.map((val: any) => {
              let component = func(val);
              return <>{component}</>;
            })}
        </>
      )}
    </div>
  );
};
