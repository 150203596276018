import { Tooltip } from '@material-ui/core';

import useStyles from './styles';

interface ICTooltip {
  title: string;
  children: JSX.Element;
}

const CTooltip: React.FC<ICTooltip> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltip }} arrow>
      {children}
    </Tooltip>
  );
};

export default CTooltip;
