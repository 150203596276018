import { MigrateLiquidityTypes } from '../actionTypes';
import { MigrateLiquidityActions } from '../actions/migrateLiquidityActions';
import { IMigrateLPToken, IMigrateNFT, ILPToken } from '../../utils/generalTypes';
import { MIGRATE_TYPE } from '../../utils/enums';

export interface MigrateLiquidityState {
  migrateLPTokens: IMigrateLPToken[] | null;
  lpTokens: ILPToken[];
  migrateNFTs: IMigrateNFT[] | null;
  selectedMigrate: IMigrateLPToken | IMigrateNFT | undefined;
  migrateLPPageCount: number;
  migrateNFTPageCount: number;
  activeTab: number;
}

const initialState: MigrateLiquidityState = {
  migrateLPTokens: null,
  lpTokens: [],
  migrateNFTs: null,
  selectedMigrate: undefined,
  migrateLPPageCount: 0,
  migrateNFTPageCount: 0,
  activeTab: 0,
};

let data;
let account: string;
const userLpTokens = JSON.parse(localStorage.getItem('lpTokens') ?? '{}');

const settingReducer = (
  state: MigrateLiquidityState = initialState,
  action: MigrateLiquidityActions
) => {
  switch (action.type) {
    case MigrateLiquidityTypes.GET_MIGRATE_NFT:
      return {
        ...state,
        migrateNFTs: action.payload,
        migrateNFTPageCount: 0,
      };

    case MigrateLiquidityTypes.GET_MIGRATE_LP:
      account = action.payload.account;
      userLpTokens[account] = action.payload.lpTokens;
      account !== '' && localStorage.setItem('lpTokens', JSON.stringify(userLpTokens));
      return {
        ...state,
        migrateLPTokens: action.payload.lpTokenDetails,
        lpTokens: action.payload.lpTokens,
        migrateLPPageCount: 0,
      };

    case MigrateLiquidityTypes.ADD_LP_TOKEN:
      account = action.payload.account;
      data = userLpTokens[account] ?? [];
      userLpTokens[account] = [action.payload.lpToken, ...data];
      localStorage.setItem('lpTokens', JSON.stringify(userLpTokens));
      return {
        ...state,
        migrateLPTokens: [action.payload.lpTokenDetail, ...(state.migrateLPTokens ?? [])],
        lpTokens: (userLpTokens[account] ?? []) as ILPToken[],
      };

    case MigrateLiquidityTypes.REMOVE_LP_TOKEN:
      account = action.payload.account;
      data = (userLpTokens[account] ?? []) as ILPToken[];
      userLpTokens[account] = data.filter(({ id }) => id !== action.payload.id);
      localStorage.setItem('lpTokens', JSON.stringify(userLpTokens));
      return {
        ...state,
        lpTokens: userLpTokens[account],
        migrateLPTokens: (state.migrateLPTokens ?? []).filter(({ id }) => id !== action.payload.id),
      };

    case MigrateLiquidityTypes.UPDATE_MIGRATE_NFT:
      const oldSelectedMigarte = state.selectedMigrate;
      if (oldSelectedMigarte && oldSelectedMigarte.type === MIGRATE_TYPE.NFT) {
        const newSelectedMigrate = action.payload.find(({ id }) => id === oldSelectedMigarte.id);
        return { ...state, migrateNFTs: action.payload, selectedMigrate: newSelectedMigrate };
      } else {
        return { ...state, migrateNFTs: action.payload };
      }

    case MigrateLiquidityTypes.UPDATE_MIGRATE_LP:
      account = action.payload.account;
      userLpTokens[account] = action.payload.lpTokens;
      localStorage.setItem('lpTokens', JSON.stringify(userLpTokens));
      const _oldSelectedMigarte = state.selectedMigrate;
      if (_oldSelectedMigarte && _oldSelectedMigarte.type === MIGRATE_TYPE.LP_TOKEN) {
        const newSelectedMigrate = action.payload.lpTokenDetails.find(
          ({ id }) => id === _oldSelectedMigarte.id
        );
        return {
          ...state,
          migrateLPTokens: action.payload.lpTokenDetails,
          lpTokens: action.payload.lpTokens,
          selectedMigrate: newSelectedMigrate,
        };
      } else {
        return {
          ...state,
          migrateLPTokens: action.payload.lpTokenDetails,
          lpTokens: action.payload.lpTokens,
        };
      }

    case MigrateLiquidityTypes.SELECT_MIGRATE:
      return { ...state, selectedMigrate: action.payload };

    case MigrateLiquidityTypes.CLEAR_NFT_MIGRATE:
      return { ...state, migrateNFTs: null, selectedMigrate: undefined };

    case MigrateLiquidityTypes.CLEAR_LP_MIGRATE:
      return { ...state, migrateLPTokens: null, selectedMigrate: undefined };

    case MigrateLiquidityTypes.SET_NFT_PAGE_COUNT:
      return { ...state, migrateNFTPageCount: action.payload };

    case MigrateLiquidityTypes.SET_LP_PAGE_COUNT:
      return { ...state, migrateLPPageCount: action.payload };

    case MigrateLiquidityTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };

    default:
      return state;
  }
};

export default settingReducer;
