import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';

import { useDispatchWrap } from './utilHooks';
import {
  addActiveTokenList,
  addTokenList,
  getTokenList,
  getTokens,
  updateBalances,
  addBalance,
  addCustomToken,
  clearTokens,
  State,
} from '../redux';
import { CALL_TYPE } from '../utils/enums';
import { getBalance } from '../contracts/functions/erc20';
import { getETHBalance } from '../contracts/getContract';

export const useTokenList = () => {
  const token = useSelector((state: State) => state.token);
  const addActiveTokenListF = useDispatchWrap(addActiveTokenList);
  const addTokenListF = useDispatchWrap(addTokenList);
  const getTokensF = useDispatchWrap(getTokens);
  const addBalanceF = useDispatchWrap(addBalance);
  const addCustomTokenF = useDispatchWrap(addCustomToken);
  const clearTokensF = useDispatchWrap(clearTokens);
  const updateBalancesF = useDispatchWrap(updateBalances);

  return {
    ...token,
    addActiveTokenListF,
    addTokenListF,
    getTokenList,
    getTokensF,
    addBalanceF,
    addCustomTokenF,
    clearTokensF,
    updateBalancesF,
  };
};

export const useUpdateBalance = () => {
  const { addBalanceF } = useTokenList();
  const { account } = useWeb3React();

  const updateBalance = async (tokenAddress: string, isETH: boolean) => {
    let balance = '0';
    if (account) {
      balance = isETH
        ? await getETHBalance(account)
        : await getBalance(CALL_TYPE.SINGLE, [tokenAddress], account);
    }
    addBalanceF(tokenAddress, balance, isETH);
  };

  return updateBalance;
};

export const useUpdateTokensBalance = () => {
  const { tokens, updateBalancesF } = useTokenList();
  const { account } = useWeb3React();

  const updateTokensBalance = () => {
    if (account && tokens) {
      updateBalancesF(account, tokens);
    }
  };

  return updateTokensBalance;
};
