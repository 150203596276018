import { Grid, Typography, Box } from '@material-ui/core';
import { findAllByDisplayValue } from '@testing-library/react';

import { Warning } from '../';
import useStyles from './styles';

interface IRangeSymbol {
  inRange?: boolean;
  textClass?: string;
  anotherClass?: string;
  closed?: boolean;
}

const RangeSymbol: React.FC<IRangeSymbol> = ({
  textClass = '',
  inRange = false,
  anotherClass = '',
  closed = false,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={`${classes.container} ${
        closed
          ? classes.close
          : inRange
          ? [classes.inRange, anotherClass].join(' ')
          : classes.outRange
      }`}
      alignItems='center'
    >
      {!closed && (
        <Grid item>
          {inRange ? (
            <Box className={classes.range}></Box>
          ) : (
            <Warning className={classes.warnIcon} />
          )}
        </Grid>
      )}
      <Grid item>
        <Typography variant='body2' className={`${classes.text}  ${textClass}`}>{`${
          closed ? 'Closed' : inRange ? 'In range' : 'Needs rebase'
        }`}</Typography>
      </Grid>
    </Grid>
  );
};

export default RangeSymbol;
