export const REACT_APP_INFURA_KEY = process.env.REACT_APP_INFURA_KEY ?? '';
export const REACT_APP_PRODUCTION_PORTIS_ID = process.env.REACT_APP_PRODUCTION_PORTIS_ID ?? '';
export const REACT_APP_DEVELOPMENT_PORTIS_ID = process.env.REACT_APP_DEVELOPMENT_PORTIS_ID ?? '';
export const REACT_APP_FORTMATIC_PRODUCTION_KEY =
  process.env.REACT_APP_FORTMATIC_PRODUCTION_KEY ?? '';
export const REACT_APP_FORTMATIC_DEVELOPMENT_KEY =
  process.env.REACT_APP_FORTMATIC_DEVELOPMENT_KEY ?? '';
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? '';
export const REAT_APP_ETHERSCAN_API_KEY = process.env.REAT_APP_ETHERSCAN_API_KEY ?? '';
export const REACT_APP_ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY ?? '';
