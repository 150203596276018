import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 15,
    padding: "1.5rem",
    "@media(max-width:400px)": {
      padding: "20px 10px",
    },
  },
  tokenPair: {
    margin: "15px 0px",
  },
  marginTop: {
    marginTop: 20,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
  },
  textMarginTop: {
    marginTop: 5,
  },

  strFont: {
    fontSize: "1.2rem",
  },
  ethIcon: {
    margin: "0px 4px",
    // height: 13,
    width: 20,
    height: 20,
  },

  buttonContent: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  underline: {},
  inputRoot: {
    backgroundColor: theme.palette.secondary.main,
    "&:not(.Mui-disabled):hover::before": {
      borderColor: theme.palette.primary.main,
    },
    "&:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.12)",
    },
    padding: "10px 15px",
    borderRadius: theme.shape.borderRadius,
    // "&:after": {
    //   borderBottomColor: "green",
    // },
    // "&:hover:not($disabled):before": {
    //   borderBottomColor: "blue",
    // },
    // "&:hover:not($disabled):after": {
    //   borderBottomColor: "yellow",
    // },
    // "&hover:$underline:before": {
    //   borderBottomColor: theme.palette.secondary.main,
    // },
    // "& .MuiInputBase-input": {
    //   color: "#fff", // Text color
    // },
    // "& .MuiInput-underline:before": {
    //   borderBottomColor: "red", // Semi-transparent underline
    // },
    // "& .MuiInput-underline:hover:before": {
    //   borderBottomColor: "red", // Solid underline on hover
    // },
    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "red", // Solid underline on focus
    // },
  },
  overlapClass01: {
    width: 16,
    height: 16,
  },
  overlapClass02: {
    width: 16,
    height: 16,
  },
}));

export default useStyles;
