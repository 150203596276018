import { MaxUint256 } from '@ethersproject/constants';

import { erc20TokenContract, getETHBalance } from '../getContract';
import { logError } from '../../utils/logs';
import { WETH_ADDRESS, ETH, DEFAULT_TOKEN } from '../../constants';
import { sendTransaction } from '../sendTransaction';
import { IToken } from '../../utils/generalTypes';
import { getLogoUri } from '../../utils/helpers';
import { CALL_TYPE, LOADING } from '../../utils/enums';
import {
  multipleContractMultipleData,
  multipleContractSingleData,
  singleCall,
  DecodeType,
} from '../calls';
import { fetchTokensFromUniswapSubgraph } from '../../data/token';

export const getDecimals = async (call: CALL_TYPE, tokenAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'uint8',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      erc20TokenContract,
      tokenAddresses,
      'decimals',
      types,
      'multi-decimals'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = erc20TokenContract(tokenAddresses[0]);
    return await singleCall([], contract?.methods.decimals, 'single-decimals');
  }
};

export const getName = async (call: CALL_TYPE, tokenAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'string',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      erc20TokenContract,
      tokenAddresses,
      'name',
      types,
      'multi-name'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = erc20TokenContract(tokenAddresses[0]);
    return await singleCall([], contract?.methods.name, 'single-name');
  }
};

export const getSymbol = async (call: CALL_TYPE, tokenAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'string',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      erc20TokenContract,
      tokenAddresses,
      'symbol',
      types,
      'multi-symbol'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = erc20TokenContract(tokenAddresses[0]);
    return await singleCall([], contract?.methods.symbol, 'single-symbol');
  }
};

export const getBalance = async (
  call: CALL_TYPE,
  tokenAddresses: string[],
  walletAddress: string
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'balance',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractSingleData(
      walletAddress,
      erc20TokenContract,
      tokenAddresses,
      'balanceOf',
      types,
      'multi-balanceOf'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = erc20TokenContract(tokenAddresses[0]);
    return await singleCall([walletAddress], contract?.methods.balanceOf, 'single-balanceOf');
  }
};

export const getTotalSupply = async (call: CALL_TYPE, tokenAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      erc20TokenContract,
      tokenAddresses,
      'totalSupply',
      types,
      'multi-totalSupply'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = erc20TokenContract(tokenAddresses[0]);
    return await singleCall([], contract?.methods.totalSupply, 'single-totalSupply');
  }
};

export const getAllowance = async (
  tokenAddress: string,
  walletAddress: string,
  contractAddress: string
): Promise<number> => {
  try {
    const contract = erc20TokenContract(tokenAddress);
    const allowance = await contract?.methods.allowance(walletAddress, contractAddress).call();
    return Number(allowance) ?? 0;
  } catch (e) {
    logError('allowance', e);
    return 0;
  }
};

export const approve = async (
  tokenAddress: string,
  tokenSymbol: string,
  walletAddress: string,
  contractAddress: string,
  callback: any = () => {}
): Promise<void> => {
  const contract = erc20TokenContract(tokenAddress);
  const contractFnc = contract?.methods.approve(contractAddress, MaxUint256._hex);
  const args = {
    walletAddress,
    txnMessage: `Approve ${tokenSymbol}`,
    logMessage: 'approve',
    dappLoading: LOADING.APPROVE,
    callback,
  };
  if (contractFnc) {
    await sendTransaction(contractFnc, args);
  }
};

export const tokenDetail = async (tokenAddresses: string[], walletAddress: string) => {
  let uniqueAddresses: string[] = [];
  tokenAddresses.forEach(tokenAddress => {
    const address = tokenAddress.toLowerCase();
    if (!uniqueAddresses.includes(address)) {
      uniqueAddresses.push(address);
    }
  });

  const fallBackTokens = await fetchTokensFromUniswapSubgraph(
    uniqueAddresses.map(address => address.toLowerCase())
  );

  const ethBalance = await getETHBalance(walletAddress);
  const names = await getName(CALL_TYPE.MULTI, uniqueAddresses);
  const symbols = await getSymbol(CALL_TYPE.MULTI, uniqueAddresses);
  const decimals = await getDecimals(CALL_TYPE.MULTI, uniqueAddresses);
  const balances = await getBalance(CALL_TYPE.MULTI, uniqueAddresses, walletAddress);

  let tokens: { [key: string]: IToken } = {};
  uniqueAddresses.forEach((address, index) => {
    const fallBackToken = fallBackTokens ? fallBackTokens[address] : DEFAULT_TOKEN;
    const token =
      address === WETH_ADDRESS
        ? { ...ETH, balance: ethBalance }
        : {
            address,
            name: names ? names[index] : fallBackToken.name,
            symbol: symbols ? symbols[index] : fallBackToken.symbol,
            decimals: decimals[index],
            balance: balances[index],
            logoURI: getLogoUri(address),
          };
    tokens = { ...tokens, [address]: token };
  });

  return tokens;
};
