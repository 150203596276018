import { Dispatch } from 'redux';

import { PositionTypes } from '../actionTypes';
import { PositionAction } from '../actions/positionActions';
import { findNFTPositions, getUnipilotPositionDetails } from '../../contracts/functions/unipilot';
import {
  INFTPosition,
  IUpdateSelectedNFTPosition,
  IUpdateNFTPositionData,
} from '../../utils/generalTypes';

export const getNFTPositions = (walletAddress: string) => {
  return async (dispatch: Dispatch<PositionAction>) => {
    dispatch({
      type: PositionTypes.CLEAR_POSITIONS,
    });
    const positions = (await findNFTPositions(walletAddress)) ?? [];
    dispatch({
      type: PositionTypes.GET_NFT_POSITIONS,
      payload: positions,
    });
  };
};

export const removePosition = (tokenId: number) => {
  return (dispatch: Dispatch<PositionAction>) => {
    dispatch({
      type: PositionTypes.REMOVE_POSITION,
      payload: tokenId,
    });
  };
};

export const selectNFTPosition = (nftPosition: INFTPosition) => {
  return (dispatch: Dispatch<PositionAction>) => {
    dispatch({
      type: PositionTypes.SELECT_POSITION,
      payload: nftPosition,
    });
  };
};

export const addTokenDetails = (data: IUpdateNFTPositionData) => {
  return (dispatch: Dispatch<PositionAction>) => {
    const {
      tokenId,
      token0Reserve,
      token1Reserve,
      token0UserFees,
      token1UserFees,
      totalLiquidityDV,
      totalFeesDV,
      currentPrice,
      token0DollarValue,
      token1DollarValue,
      poolApy,
      fees24h,
    } = data;

    dispatch({
      type: PositionTypes.ADD_TOKEN_DETAIL,
      payload: {
        tokenId,
        token0Reserve,
        token1Reserve,
        token0UserFees,
        token1UserFees,
        totalLiquidityDV,
        totalFeesDV,
        currentPrice,
        token0DollarValue,
        token1DollarValue,
        poolApy,
        fees24h,
      },
    });
  };
};

export const updateSelectedPosition = (params: IUpdateSelectedNFTPosition) => {
  return (dispatch: Dispatch<PositionAction>) => {
    const { rmUserFee0, rmUserFee1, rmUserFeeDV, reverse } = params;
    dispatch({
      type: PositionTypes.UPDATE_SELECTED_POSITION,
      payload: {
        rmUserFee0,
        rmUserFee1,
        rmUserFeeDV,
        reverse,
      },
    });
  };
};

export const updatePositions = (tokenIds: number[], walletAddress: string) => {
  return async (dispatch: Dispatch<PositionAction>) => {
    const updatePositions = await getUnipilotPositionDetails(tokenIds, walletAddress);
    if (updatePositions) {
      dispatch({
        type: PositionTypes.UPDATE_POSITIONS,
        payload: updatePositions,
      });
    }
  };
};

export const setPageCount = (count: number) => {
  return (dispatch: Dispatch<PositionAction>) => {
    dispatch({
      type: PositionTypes.SET_PAGE_COUNT,
      payload: count,
    });
  };
};
