import { Dialog as MuiDialog, DialogProps } from '@material-ui/core';

import useStyles from './styles';

interface IDialog {
  open: boolean;
}

const IMDialog: React.FC<IDialog & DialogProps> = ({ open, onClose, children, ...props }) => {
  const classes = useStyles();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      {...props}
      maxWidth='xl'
    >
      {children}
    </MuiDialog>
  );
};

export default IMDialog;
