import { Grid, Typography } from '@material-ui/core';

import { TokenLogo, CGrid } from '../../components';
import useStyles from './styles';
import BlackBox from './BlackBox';
import AmountFunc from './Amount';
import { IToken } from '../../utils/generalTypes';
import { truncSymbol } from '../../utils/formating';

interface ILongContentContainer {
  title: string;
  amount: string | undefined;
  dollorSymbol: boolean;
  start?: boolean;
  jsxElements: React.ReactNode;
}

export const LongContentContainer: React.FC<ILongContentContainer> = ({
  title,
  amount,
  jsxElements,
  start = true,
  dollorSymbol,
}) => {
  const classes = useStyles();
  return (
    <BlackBox>
      <CGrid
        alignItems={start ? 'flex-start' : 'center'}
        justify='space-between'
        containerProps={{
          spacing: 1,
        }}
        elements01={
          <CGrid
            alignItems='stretch'
            direction='column'
            elements01={
              <Typography style={{ fontSize: '1rem', fontWeight: 500 }}>{title}</Typography>
            }
            elements02={
              <AmountFunc
                className={[classes.amountFuncTypo, classes.primaryColor].join(' ')}
                dollorSymbol={dollorSymbol}
                amount={amount}
              />
            }
          />
        }
        elements02={jsxElements}
      />
    </BlackBox>
  );
};

interface ISmallContentContainer {
  token: IToken;
  amount: string | undefined;
}

export const SmallContentContainer: React.FC<ISmallContentContainer> = ({ token, amount }) => {
  const classes = useStyles();
  return (
    <BlackBox>
      <Grid container alignItems='center' direction='column'>
        <Grid item>
          <TokenLogo
            tokenAddress={token?.address}
            uri={token?.logoURI}
            className={classes.tokenLogo}
          />
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: '1.2rem' }}>{truncSymbol(token?.symbol, 8)}</Typography>
        </Grid>
        <Grid item>
          <AmountFunc className={[classes.amountFuncTypoSm].join(' ')} amount={amount} />
        </Grid>
      </Grid>
    </BlackBox>
  );
};
