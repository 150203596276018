import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(255,255,255,0.4)',
    padding: theme.spacing(0.5, 1),
    borderRadius: '10px',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
}));

export default useStyles;
