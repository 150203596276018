import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selected: {
    height: '100%',
    borderRadius: 0,
    padding: '15px 5px',
    background: 'linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)',
  },
  notSelected: {
    color: 'white',
    borderColor: 'rgba(255,255,255,0.1)',
    height: '100%',
    padding: '15px 5px',
    borderRadius: 0,
    border: `1px solid rgba(255,255,255,1)`,
    background: 'linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)',
    boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.16)',
  },
  subTitle: {
    // fontSize: '0.7rem',
    textTransform: 'none',

    width: '75px',
    fontSize: '12px',
    margin: '0 auto',
    '@media(max-width:420px)': {
      fontSize: '10px',
      width: 'auto',
    },
  },
  mainTitle: {
    fontSize: '14px',
    '@media(max-width:420px)': {
      fontSize: '11px',
    },
  },
  radiusStart: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  radiusEnd: {
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  radiusZero: {
    borderRadius: 0,
    padding: '15px 8px',
  },
  multiButtonUnselected: {
    borderWidth: 1,
    color: 'rgba(255,255,255,0.2)',
    borderColor: 'rgba(255,255,255,0.2)',
    borderRadius: theme.shape.borderRadius / 4,
  },
  multiButtonSelected: {
    backgroundColor: 'rgba(239, 166, 24,0.09)',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius / 4,
  },
  disableWrapper: {
    height: '100%',
  },
}));

export default useStyles;
