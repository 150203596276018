import { DEV_ENVIRONMENT } from "./";

export const GRAPH_QL_ENDPOINTS = DEV_ENVIRONMENT
  ? {
      unipilot: "https://api.thegraph.com/subgraphs/name/rafaqat11/learning",
      uniswapV2:
        "https://api.thegraph.com/subgraphs/name/bilalmir135/uniswap-v2",
      sushiswap:
        "https://api.thegraph.com/subgraphs/name/bilalmir135/sushi-swap-exchange",
      blocklytics:
        "https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks",
      uniswapV3: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
      farming:
        "https://api.thegraph.com/subgraphs/name/rafaqat11/mudassirdebug2",
    }
  : {
      unipilot:
        "https://api.thegraph.com/subgraphs/name/unipilotvoirstudio/unipilotstats",
      uniswapV2: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2",
      sushiswap: "https://api.thegraph.com/subgraphs/name/sushiswap/exchange",
      blocklytics:
        "https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks",
      uniswapV3: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
      farming:
        "https://api.thegraph.com/subgraphs/name/unipilotvoirstudio/unipilot-farming",
    };

export const SERVER_ENDPOINTS = DEV_ENVIRONMENT
  ? { unipilot: "https://unipilot-stats-api.herokuapp.com/api/unipilot" }
  : { unipilot: "https://unipilot--api.herokuapp.com/api/unipilot" };
