import { useState, useMemo, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';

import useStyles from './styles';
import PoolAccordian from './Farm';
import { Nothing, ContainerLoader, LiquidityChart, Dropdown, PilotToken } from '../../components';
import Total from '../stats/Total';
import { useFarming, useSortedFarmingPools } from '../../hooks';
import { IFarmingPool } from '../../utils/generalTypes';
import { formatDollarAmount, formatTokenAmount } from '../../utils/formating';

const sortOptions: { label: string; value: keyof IFarmingPool }[] = [
  { label: 'TVL', value: 'tvl' },
  { label: 'APR', value: 'completeApr' },
  { label: 'Multiplier', value: 'multiplier' },
];

const Farming = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  const { locked, userLockedNfts, tvl, reward, sortBy, setLockF, updateSortByF } = useFarming();
  const farmingPools = useSortedFarmingPools();

  const [searched, setSearched] = useState<string>('');
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeDropdDown = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateSortByF(event.target.value as keyof IFarmingPool);
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const filterLockedPools = useCallback(
    (farmingPools: IFarmingPool[]) => {
      return farmingPools.filter(({ id }) => userLockedNfts.hasOwnProperty(id.toLowerCase()));
    },
    [farmingPools, userLockedNfts]
  );

  const filteredPools = useMemo(() => {
    if (farmingPools) {
      let _farmingPools = locked ? filterLockedPools(farmingPools) : farmingPools;
      return _farmingPools.filter(
        ({ token0, token1 }) =>
          token0.symbol.toLowerCase().includes(searched.toLowerCase()) ||
          token1.symbol.toLowerCase().includes(searched.toLowerCase())
      );
    }
  }, [farmingPools, searched, userLockedNfts, locked, sortBy]);

  return (
    <>
      <Container className={classes.root} maxWidth={xs_ ? 'xs' : 'md'}>
        <Grid container spacing={2}>
          <Grid style={{ width: '100%' }} item md={6}>
            <Total
              loading={tvl === undefined}
              text='TVL'
              amount={tvl ? `${formatDollarAmount(parseFloat(tvl))}` : '---'}
              icon={<LiquidityChart className={classes.icon} />}
            />
          </Grid>
          <Grid style={{ width: '100%' }} item md={6}>
            <Total
              loading={reward === undefined}
              text='Total Pilot Claimed'
              amount={reward ? `${formatTokenAmount(parseFloat(reward))}` : '---'}
              icon={<PilotToken className={classes.pilotIcon} />}
            />
          </Grid>
        </Grid>

        <Box className={classes.marginTop} />

        <Box className={classes.conatiner}>
          <Box className={classes.farmHead}>
            <Box className={classes.head}>
              <FormControlLabel
                control={
                  <Switch
                    checked={locked}
                    onChange={() => {
                      setLockF(!locked);
                    }}
                    name='checkedB'
                    color='primary'
                  />
                }
                classes={{ label: locked ? classes.locked : undefined }}
                label='Staked Only'
              />
              {xs_ && (
                <Box>
                  <Typography className={classes.super}>Sort By</Typography>
                  <Dropdown
                    title='Sort by'
                    value={sortBy}
                    handleChange={handleChangeDropdDown}
                    options={sortOptions}
                  />
                </Box>
              )}
            </Box>
            <Box className={classes.dropSearch}>
              {!xs_ && (
                <Box>
                  <Typography className={classes.super}>Sort By</Typography>
                  <Dropdown
                    title='Sort by'
                    value={sortBy}
                    handleChange={handleChangeDropdDown}
                    options={sortOptions}
                  />
                </Box>
              )}
              <Box>
                <Typography className={classes.super}>Search</Typography>
                <SearchBar
                  value={searched}
                  onChange={searchVal => setSearched(searchVal)}
                  onCancelSearch={() => setSearched('')}
                  className={classes.searchBox}
                  placeholder='Search Farms'
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.farmBody}>
            <Box className={classes.farmBodyContent}>
              {filteredPools && filteredPools.length > 0 ? (
                filteredPools.map(pool => (
                  <PoolAccordian
                    key={pool.id}
                    pool={pool}
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                ))
              ) : filteredPools === undefined ? (
                <Nothing containerClassName={classes.parentEmpty}>
                  <ContainerLoader />
                </Nothing>
              ) : (
                <Nothing title='Nothing to show' containerClassName={classes.parentEmpty}></Nothing>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Farming;
