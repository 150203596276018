import { lixirVaultContract } from '../getContract';
import { CALL_TYPE, MIGRATE_PROTOCOL, MIGRATE_TYPE } from '../../utils/enums';
import { LixirVault } from '../returnTypes';
import { DecodeType, multipleContractMultipleData, singleCall } from '../calls';
import { tokenDetail } from './erc20';
import { getPairTokenAmounts } from '../../utils/uniswapSDKFunctions';
import { formatAmount } from '../../utils/formating';
import { IMigrateLPToken } from '../../utils/generalTypes';
import { logError } from '../../utils/logs';

export const calculateTotals = async <T extends keyof LixirVault>(
  call: CALL_TYPE,
  vaultAddresses: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'total0',
    },
    {
      type: 'uint256',
      name: 'total1',
    },
    {
      type: 'uint128',
      name: 'mL',
    },
    {
      type: 'uint128',
      name: 'rL',
    },
  ];
  if (call === CALL_TYPE.MULTI && outputs) {
    return await multipleContractMultipleData(
      [],
      lixirVaultContract,
      vaultAddresses,
      'calculateTotals',
      types,
      'multi-calculateTotals',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = lixirVaultContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.calculateTotals, 'single-calculateTotals');
  }
};

export const token0 = async (call: CALL_TYPE, vaultAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      lixirVaultContract,
      vaultAddresses,
      'token0',
      types,
      'multi-lixir-token0'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = lixirVaultContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.token0, 'single-lixir-token0');
  }
};

export const token1 = async (call: CALL_TYPE, vaultAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      lixirVaultContract,
      vaultAddresses,
      'token1',
      types,
      'multi-lixir-token1'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = lixirVaultContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.token1, 'single-lixir-token1');
  }
};

export const getLixirVaults = async (
  data: { id: string; balance: string; totalSupply: string }[],
  account: string
): Promise<IMigrateLPToken[] | undefined> => {
  try {
    const addresses = data.map(({ id }) => id);
    const token0s = await token0(CALL_TYPE.MULTI, addresses);
    const token1s = await token1(CALL_TYPE.MULTI, addresses);
    const totalAmounts = await calculateTotals(CALL_TYPE.MULTI, addresses, ['total0', 'total1']);
    const tokens = [...token0s, ...token1s];
    const tokenDetails = await tokenDetail(tokens, account);
    return addresses.map((address, index) => {
      const token0 = tokenDetails[token0s[index].toLowerCase()];
      const token1 = tokenDetails[token1s[index].toLowerCase()];
      const { balance, totalSupply } = data[index];
      const { total0, total1 } = totalAmounts[index];
      const { token0Reserveds, token1Reserveds } = getPairTokenAmounts(
        token0,
        token1,
        total0,
        total1,
        totalSupply,
        balance
      );
      return {
        id: address.toLowerCase(),
        token0,
        token1,
        pairAddress: address,
        liquidity: balance,
        totalSupply,
        protocol: MIGRATE_PROTOCOL.Lixir,
        type: MIGRATE_TYPE.LP_TOKEN,
        token0Reserve: token0Reserveds.toSignificant(5),
        token1Reserve: token1Reserveds.toSignificant(5),
      };
    });
  } catch (e) {
    logError('getLixirVaults', e);
  }
};
