import Routes from './Routes';
import { TransactionDialog, Snackbar } from '../components';

function Layout() {
  return (
    <>
      <Snackbar />
      <TransactionDialog />
      <Routes />
    </>
  );
}

export default Layout;
