import { REACT_APP_ENVIRONMENT, REACT_APP_INFURA_KEY } from './';
import {
  REACT_APP_DEVELOPMENT_PORTIS_ID,
  REACT_APP_FORTMATIC_DEVELOPMENT_KEY,
  REACT_APP_FORTMATIC_PRODUCTION_KEY,
  REACT_APP_PRODUCTION_PORTIS_ID,
  REAT_APP_ETHERSCAN_API_KEY,
  REACT_APP_ALCHEMY_API_KEY,
} from './envs';

export const DEV_ENVIRONMENT = REACT_APP_ENVIRONMENT === 'DEVELOPMENT';
//export const DEV_ENVIRONMENT = true;
export const CHAIN_ID = DEV_ENVIRONMENT ? 4 : 1;
export const SUPPORTED_NETWORK = DEV_ENVIRONMENT ? 'rinkeby' : 'mainnet';
export const NETWORK_URL1 = `https://mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`;
export const NETWORK_URL4 = `https://rinkeby.infura.io/v3/${REACT_APP_INFURA_KEY}`;
export const NETWORK_URL5 = `https://goerli.infura.io/v3/${REACT_APP_INFURA_KEY}`;
export const NETWORK_URL = DEV_ENVIRONMENT ? NETWORK_URL4 : NETWORK_URL1;
export const ETHERSCAN_TX_BASE_URL = DEV_ENVIRONMENT
  ? 'https://rinkeby.etherscan.io//tx/'
  : 'https://etherscan.io/tx/';
export const ETHERSCAN_ADDRESS_BASE_URL = DEV_ENVIRONMENT
  ? 'https://rinkeby.etherscan.io//address/'
  : 'https://etherscan.io/address/';
export const UN_KNOWN_LOGO =
  'https://gateway.pinata.cloud/ipfs/QmTRdZMSSb1EwZE7MY4z91Ms7QgLgsV4WBNY1FD1cYaFPx';
export const PILOT_LOGO =
  'https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz';
export const DEFAULT_TOKEN_LIST = 'Unipilot Default List';
export const DAPP_CURRENT_VERSION = 'Unipilot v2.0.0';
export const ETH_MIN_LIMIT = 0.03;
export const FORTMATIC_KEY = DEV_ENVIRONMENT
  ? REACT_APP_FORTMATIC_DEVELOPMENT_KEY
  : REACT_APP_FORTMATIC_PRODUCTION_KEY;
export const PORTIS_KEY = DEV_ENVIRONMENT
  ? REACT_APP_DEVELOPMENT_PORTIS_ID
  : REACT_APP_PRODUCTION_PORTIS_ID;
export const AMOUNT_INPUT_REGEX = /^((\d+[.]?\d*)|(\.\d+))$/;
export const FEE_TIERS = ['100', '500', '3000', '10000'];
export const REPORT_LINK = 'https://discord.com/channels/851882230766895184/873464755677958164';
export const ETHERSCAN_GAS_API = `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${REAT_APP_ETHERSCAN_API_KEY}`;
export const ALCHEMY_URL = `https://eth-mainnet.alchemyapi.io/v2/${REACT_APP_ALCHEMY_API_KEY}`;
