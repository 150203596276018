import { Box } from '@material-ui/core';

interface IDisableElements {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const DisableElements: React.FC<IDisableElements> = ({ children, disabled, className }) => {
  return (
    <Box
      className={className}
      style={
        disabled
          ? {
              opacity: 0.5,
              pointerEvents: 'none',
            }
          : {}
      }
    >
      {children}
    </Box>
  );
};

export default DisableElements;
