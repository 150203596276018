import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 20,
  },
  feeTier: {
    display: 'flex',
    alignItems: 'center',
  },
  feeTierLoader: {
    color: '#fff',
    marginLeft: '10px',
  },
}));

export default useStyles;
