import { useSelector } from 'react-redux';
import { useDispatchWrap } from './utilHooks';
import { State, clearTransaction, getTransactions, loadTransactions } from '../redux';

export const useTransaction = () => {
  const transactions = useSelector((state: State) => state.transaction);

  const clearTransactionF = useDispatchWrap(clearTransaction);
  const getTransactionsF = useDispatchWrap(getTransactions);
  const loadTransactionsF = useDispatchWrap(loadTransactions);

  return { ...transactions, clearTransactionF, getTransactionsF, loadTransactionsF };
};
