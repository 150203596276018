import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  conatiner: {
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
    '@media(max-width:550px)': {
      padding: '1.5rem 1.2rem',
    },
    height: '100%',
  },
  tokenPair: {
    margin: '15px 0px',
  },
  marginTop: {
    marginTop: 20,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: 'none',
    color: 'white',
    padding: '8px 0px',
    // backgroundColor: theme.palette.common?.purplish,
  },
  textMarginTop: {
    marginTop: 5,
  },
  bar: {
    '&:hover': {
      color: 'red',
    },
  },
  topLeftBtn: {
    textTransform: 'none',
    padding: '8px 0px',
  },
  marginTopMost: {
    marginTop: 100,
  },
  bottomSelect: {
    borderRadius: 0,
  },
  bottomInput: {
    borderRadius: 0,
  },
  inputMaxContainer: {
    // backgroundColor: theme.palette.secondary.main, //"#F8F8F8",
    fontWeight: 500,
    padding: '4px 20px',
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    // borderLeft: `2px solid rgba(255,255,255,0.1)`,
    minHeight: 50,
  },
  selectTokenInputMaxContainer: {
    background: 'linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)',
    border: '1px solid rgba(255, 255, 255, 0.05)',
    borderRadius: theme.shape.borderRadius,
  },
  inputStartingPrice: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 130, 255, 0.15)',
    padding: '4px 20px',
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: 5,
    minHeight: 50,
  },
  bottomHeight: {
    height: theme.mixins.toolbar.minHeight,
  },
  dropButton: {
    textTransform: 'none',
    justifyContent: 'space-between',
    padding: '10px 20px',
    // backgroundColor: theme.palette.secondary.main,
    borderColor: 'transparent',
    minHeight: 50,
  },
  borderPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  secondaryBC: {
    backgroundColor: theme.palette.secondary.main,
  },

  right: {
    borderRadius: theme.shape.borderRadius,
  },
  balanceHeight: {
    height: 24,
  },
  rebaseBtn: {
    color: theme.palette.primary.main,
  },
  tvlContainer: {
    padding: '20px 15px 0 15px',
  },
  timeBox: {
    height: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 15px',
    '& p': {
      color: theme.palette.common.lightText,
      fontSize: '12px',
    },
  },
}));

export default useStyles;
