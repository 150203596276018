import { IconButton, IconButtonProps } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
//import GraphicEqIcon from '@material-ui/icons/GraphicEq';

import useStyles from "./styles";

interface IButton {
  classNameContainer?: string;
  classNameIcon?: string;
}

export const CloseButton: React.FC<IconButtonProps> = ({
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
  );
};

export const BackButton: React.FC<IconButtonProps> = ({
  onClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <KeyboardBackspaceIcon />
    </IconButton>
  );
};

export const SettingsButton: React.FC<IconButtonProps> = ({
  onClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <SettingsIcon />
    </IconButton>
  );
};

// export const RebaseButton: React.FC<IconButtonProps & IButton> = ({
//   classNameIcon,
//   onClick = () => {},
// }) => {
//   const classes = useStyles();
//   return (
//     <IconButton onClick={onClick} className={classes.closeButton}>
//       <GraphicEqIcon className={classNameIcon} />
//     </IconButton>
//   );
// };

export const FileCopyButton: React.FC<IconButtonProps & IButton> = ({
  onClick = () => {},
  classNameContainer,
  classNameIcon,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      onClick={onClick}
      className={[classes.closeButton, classNameContainer ?? ""].join(" ")}
    >
      <FileCopyIcon className={classNameIcon} />
    </IconButton>
  );
};

export const TickButton: React.FC<IconButtonProps & IButton> = ({
  onClick = () => {},
  classNameContainer,
  classNameIcon,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      onClick={onClick}
      className={[classes.closeButton, classNameContainer ?? ""].join(" ")}
    >
      <CheckCircleIcon className={classNameIcon} />
    </IconButton>
  );
};

export const CompareIcon: React.FC<IconButtonProps> = ({
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <CompareArrowsIcon />
    </IconButton>
  );
};
