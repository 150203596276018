import { oracleContract } from '../getContract';
import { logError } from '../../utils/logs';

export const getPilotAmountForTokens = async (
  token0: string,
  token1: string,
  amount0: string,
  amount1: string,
  oracle0: string,
  oracle1: string
) => {
  try {
    const contract = oracleContract();
    const pilotTokens = await contract?.methods
      .getPilotAmountForTokens(token0, token1, amount0, amount1, oracle0, oracle1)
      .call();
    return pilotTokens;
  } catch (e) {
    logError('getPilotAmountForTokens', e);
  }
};

export const getPilotAmountWethPair = async (
  tokenAlt: string,
  amountAlt: string,
  amountWETH: string,
  altOracle: string
) => {
  try {
    const contract = oracleContract();
    const pilotTokens = await contract?.methods
      .getPilotAmountWethPair(tokenAlt, amountAlt, amountWETH, altOracle)
      .call();
    return pilotTokens;
  } catch (e) {
    logError('getPilotAmountWethPair', e);
  }
};
