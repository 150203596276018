import { LiquidityTypes } from '../actionTypes';
import { LiquidityAction } from '../actions/liquidityActions';
import { IToken } from '../../utils/generalTypes';
import { LIQUIDITY_TYPES, LIQUIDITY_TOKENS, MIGRATE_PROTOCOL } from '../../utils/enums';

export interface LiquidityState {
  type: LIQUIDITY_TYPES;
  token0: IToken | null;
  token1: IToken | null;
  poolAddress: string;
  createPool: boolean;
  feeTier: string;
  initialAmount?: string;
  strategy: string;
  token0Allowance: boolean;
  token1Allowance: boolean;
  sqrtPrice: string;
  tick: string;
  tickLower: string;
  tickUpper: string;
  liquidity: string;
  amount0: string;
  amount1: string;
  validAmount0: boolean;
  validAmount1: boolean;
  amount0Reserves: string;
  amount1Reserves: string;
  protocol?: MIGRATE_PROTOCOL;
  disableAutoFeeTier: boolean;
  addLiquidityState: {
    pairExists: boolean;
    loading: boolean;
    outOfRange: boolean;
    rebaseIncentive: boolean;
    rebaseFrequencyAllow: boolean;
    tokenId: string;
  };
}

const initialState: LiquidityState = {
  type: LIQUIDITY_TYPES.ADD,
  token0: null,
  token1: null,
  poolAddress: '',
  createPool: false,
  feeTier: '',
  strategy: '',
  initialAmount: '',
  token0Allowance: false,
  token1Allowance: false,
  sqrtPrice: '',
  tick: '',
  tickLower: '',
  tickUpper: '',
  liquidity: '',
  amount0: '',
  amount1: '',
  validAmount0: true,
  validAmount1: true,
  amount0Reserves: '',
  amount1Reserves: '',
  disableAutoFeeTier: false,
  addLiquidityState: {
    pairExists: false,
    loading: false,
    outOfRange: false,
    rebaseIncentive: false,
    rebaseFrequencyAllow: false,
    tokenId: '0',
  },
};

const addLiquidityReducer = (state: LiquidityState = initialState, action: LiquidityAction) => {
  switch (action.type) {
    case LiquidityTypes.SELECT_TOKEN:
      if (action.payload.type === LIQUIDITY_TOKENS.TOKEN_0) {
        const tempToken1 = state.token1;
        if (state.type === LIQUIDITY_TYPES.ADD) {
          return {
            ...initialState,
            token0: action.payload.token,
            token1: tempToken1,
          };
        } else {
          return {
            ...state,
            token0: action.payload.token,
            token1: tempToken1,
          };
        }
      } else {
        const tempToken0 = state.token0;
        if (state.type === LIQUIDITY_TYPES.ADD) {
          return {
            ...initialState,
            token1: action.payload.token,
            token0: tempToken0,
          };
        } else {
          return {
            ...state,
            token1: action.payload.token,
            token0: tempToken0,
          };
        }
      }
    case LiquidityTypes.ADD_DATA:
      return { ...state, ...action.payload };
    case LiquidityTypes.CLEAR_DATA:
      return { ...initialState, type: state.type };
    case LiquidityTypes.SET_LIQUIDITY_TYPE:
      if (state.type === LIQUIDITY_TYPES.FARM && action.payload === LIQUIDITY_TYPES.ADD)
        return { ...state, type: action.payload };
      else return { ...initialState, type: action.payload };
    case LiquidityTypes.UPDATE_ADD_LIQUIDITY:
      return { ...state, addLiquidityState: { ...state.addLiquidityState, ...action.payload } };
    default:
      return state;
  }
};

export default addLiquidityReducer;
