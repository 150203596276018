import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { POOL_TVL, POOL_VOLUME } from '../apollo/queries';
import { logError } from '../utils/logs';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
const ONE_DAY_UNIX = 24 * 60 * 60;

interface PoolTvlData {
  uniPoolDayDatas: {
    date: number;
    ulmTvlUSD: string;
    uniswapPoolDayData: {
      volumeUSD: string;
    };
  }[];
}

export type PoolTVLChart = {
  date: number;
  ulmTvlUSD: number;
  volumeUSD: number;
};

export const fetchPoolTvl = async (
  address: string,
  client: ApolloClient<NormalizedCacheObject>
) => {
  let data: {
    date: number;
    ulmTvlUSD: string;
    uniswapPoolDayData: {
      volumeUSD: string;
    };
  }[] = [];

  const startTimestamp = 1633633200;
  const endTimestamp = dayjs.utc().unix();

  let error = false;
  let skip = 0;
  let allFound = false;

  try {
    while (!allFound) {
      const {
        data: chartResData,
        error,
        loading,
      } = await client.query<PoolTvlData>({
        query: POOL_TVL,
        variables: {
          address: address.toLowerCase(),
          startTime: startTimestamp,
          skip,
        },
        fetchPolicy: 'cache-first',
      });
      if (!loading) {
        skip += 1000;
        if (chartResData.uniPoolDayDatas.length < 1000 || error) {
          allFound = true;
        }
        if (chartResData) {
          data = data.concat(chartResData.uniPoolDayDatas);
        }
      }
    }
  } catch (e) {
    error = true;
    logError('fetchPoolTvl', e);
  }

  if (data && data.length > 0) {
    const formattedExisting = data.reduce(
      (accum: { [date: number]: PoolTVLChart }, dayData, index) => {
        if (index === 0 && parseFloat(dayData.ulmTvlUSD) > 0) {
          const startDate = parseInt(((dayData.date - ONE_DAY_UNIX) / ONE_DAY_UNIX).toFixed(0));
          accum[startDate] = {
            date: dayData.date - ONE_DAY_UNIX,
            ulmTvlUSD: 0,
            volumeUSD: 0,
          };
        }
        const roundedDate = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0));
        accum[roundedDate] = {
          date: dayData.date,
          ulmTvlUSD: parseFloat(dayData.ulmTvlUSD),
          volumeUSD: parseFloat(dayData.uniswapPoolDayData.volumeUSD),
        };

        return accum;
      },
      {}
    );

    const firstEntry = formattedExisting[parseInt(Object.keys(formattedExisting)[0])];

    // fill in empty days ( there will be no day datas if no trades made that day )
    let timestamp = firstEntry?.date ?? startTimestamp;
    let latestTvl = firstEntry?.ulmTvlUSD ?? 0;
    while (timestamp < endTimestamp - ONE_DAY_UNIX) {
      const nextDay = timestamp + ONE_DAY_UNIX;
      const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0));
      if (!Object.keys(formattedExisting).includes(currentDayIndex.toString())) {
        formattedExisting[currentDayIndex] = {
          date: nextDay,
          ulmTvlUSD: latestTvl,
          volumeUSD: 0,
        };
      } else {
        latestTvl = formattedExisting[currentDayIndex].ulmTvlUSD;
      }
      timestamp = nextDay;
    }

    let ulm = 0;

    const dateMap = Object.keys(formattedExisting).map(key => {
      ulm += formattedExisting[parseInt(key)].ulmTvlUSD;
      return formattedExisting[parseInt(key)];
    });

    return {
      data: dateMap[dateMap.length - 1].ulmTvlUSD > 0 ? dateMap : undefined,
      error: false,
    };
  } else {
    return {
      data: undefined,
      error,
    };
  }
};

interface PoolVolumeData {
  poolDayDatas: {
    date: number;
    volumeUSD: string;
  }[];
}

export type PoolVolumeChart = {
  date: number;
  volumeUSD: number;
};

export const fetchPoolVoulme = async (
  address: string,
  client: ApolloClient<NormalizedCacheObject>
) => {
  let data: {
    date: number;
    volumeUSD: string;
  }[] = [];

  const startTimestamp = 1633633200;
  const endTimestamp = dayjs.utc().unix();

  let error = false;
  let skip = 0;
  let allFound = false;

  try {
    while (!allFound) {
      const {
        data: chartResData,
        error,
        loading,
      } = await client.query<PoolVolumeData>({
        query: POOL_VOLUME,
        variables: {
          address: address.toLowerCase(),
          startTime: startTimestamp,
          skip,
        },
        fetchPolicy: 'cache-first',
      });

      if (!loading) {
        skip += 1000;
        if (chartResData.poolDayDatas.length < 1000 || error) {
          allFound = true;
        }
        if (chartResData) {
          data = data.concat(chartResData.poolDayDatas);
        }
      }
    }
  } catch (e) {
    error = true;
    logError('fetchPoolTvl', e);
  }

  if (data && data.length > 0) {
    const formattedExisting = data.reduce(
      (accum: { [date: number]: PoolVolumeChart }, dayData, index) => {
        if (index === 0 && parseFloat(dayData.volumeUSD) > 0) {
          const startDate = parseInt(((dayData.date - ONE_DAY_UNIX) / ONE_DAY_UNIX).toFixed(0));
          accum[startDate] = {
            date: dayData.date - ONE_DAY_UNIX,
            volumeUSD: 0,
          };
        }
        const roundedDate = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0));
        accum[roundedDate] = {
          date: dayData.date,
          volumeUSD: parseFloat(dayData.volumeUSD),
        };

        return accum;
      },
      {}
    );

    const firstEntry = formattedExisting[parseInt(Object.keys(formattedExisting)[0])];

    // fill in empty days ( there will be no day datas if no trades made that day )
    let timestamp = firstEntry?.date ?? startTimestamp;
    let latestTvl = firstEntry?.volumeUSD ?? 0;
    while (timestamp < endTimestamp - ONE_DAY_UNIX) {
      const nextDay = timestamp + ONE_DAY_UNIX;
      const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0));
      if (!Object.keys(formattedExisting).includes(currentDayIndex.toString())) {
        formattedExisting[currentDayIndex] = {
          date: nextDay,
          volumeUSD: 0,
        };
      } else {
        latestTvl = formattedExisting[currentDayIndex].volumeUSD;
      }
      timestamp = nextDay;
    }

    let ulm = 0;

    const dateMap = Object.keys(formattedExisting).map(key => {
      ulm += formattedExisting[parseInt(key)].volumeUSD;
      return formattedExisting[parseInt(key)];
    });

    return {
      data: dateMap[dateMap.length - 1].volumeUSD > 0 ? dateMap : undefined,
      error: false,
    };
  } else {
    return {
      data: undefined,
      error,
    };
  }
};
