import { poolContract } from '../getContract';
import { CALL_TYPE } from '../../utils/enums';
import { Slot0 } from '../returnTypes';
import { DecodeType, multipleContractMultipleData, singleCall } from '../calls';

export const slot0 = async <T extends keyof Slot0>(
  call: CALL_TYPE,
  poolAddresses: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint160',
      name: 'sqrtPriceX96',
    },
    {
      type: 'int24',
      name: 'tick',
    },
  ];
  if (call === CALL_TYPE.MULTI && outputs) {
    return await multipleContractMultipleData(
      [],
      poolContract,
      poolAddresses,
      'slot0',
      types,
      'multi-slot0',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = poolContract(poolAddresses[0]);
    return await singleCall([], contract?.methods.slot0, 'single-slot0');
  }
};

export const poolLiquidity = async (call: CALL_TYPE, poolAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'uint128',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      poolContract,
      poolAddresses,
      'liquidity',
      types,
      'multi-poolLiquidity'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = poolContract(poolAddresses[0]);
    return await singleCall([], contract?.methods.liquidity, 'single-poolLiquidity');
  }
};
