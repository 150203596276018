import SwipeableViews from 'react-swipeable-views';

interface IAnimatedViews {
  page: number;
  items: any[];
  nothingContainerClassName?: string;
  setPage: any;
}

const AnimatedViews: React.FC<IAnimatedViews> = ({
  page = 0,
  setPage = () => {},
  items = [],
  nothingContainerClassName = '',
}) => {
  const handleChangeIndex = (index: number) => {
    setPage(index);
  };

  return (
    <SwipeableViews
      springConfig={{
        duration: '0.7s',
        easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
        delay: '0s',
      }}
      index={page}
      onChangeIndex={handleChangeIndex}
      disabled
    >
      {items.length > 0 &&
        items.map((item: any, i: number) => {
          return <TabPanel item={item} index={i} value={page} key={i} />;
        })}
    </SwipeableViews>
  );
};

export default AnimatedViews;

interface TabPanelProps {
  index: any;
  value: any;
  item: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { value, index, item, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{item}</>}
    </div>
  );
};
