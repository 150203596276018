import { useState, useEffect } from 'react';
import { Container, Grid, Button, Box, Typography } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router-dom';

import {
  IMDialog,
  Header,
  ConfirmSelected,
  ConfirmUnselected,
  Notification,
  RowThreeContent,
  PilotToken,
  ButtonIndicator,
  StringsSpaceBetween,
  CloseButton,
  TokenLogo,
  TokenSwitch,
  RowAlignCenter,
} from '../';
import useStyles from './styles';
import { useNFTPositions, useLoading, useIsMetamask } from '../../hooks';
import { collect } from '../../contracts/functions/unipilot';
import { WETH_ADDRESS, WETH, ETH, CONTRACT_ADDRESSES } from '../../constants';
import { LOADING } from '../../utils/enums';
import { ILiquidity } from '../../utils/generalTypes';
import { truncSymbol } from '../../utils/formating';

interface IWDDialog {
  open: boolean;
  close: () => void;
  modalType: string;
  pilotToken?: string;
  action?: (value: boolean) => void;
  defaultInWETH?: boolean;
}

const msg01 = 'Collect 95% of your earned fees in the Pilot tokens (5% will go to the index fund)';
const msg02 = 'Collect 85% of your earned fees (15% will go to the index fund)';

const WDDialog: React.FC<IWDDialog> = ({
  open,
  close,
  modalType,
  pilotToken,
  action,
  defaultInWETH = false,
}) => {
  const classes = useStyles();
  const { account } = useWeb3React();
  const history = useHistory();

  const { selectedNFTPosition, getNFTPositionsF } = useNFTPositions();
  const { dappLoading } = useLoading();
  const isMetamask = useIsMetamask();

  const [selected, setSelected] = useState<boolean>(
    pilotToken && parseFloat(pilotToken) > 0 ? true : false
  );
  const [inWeth, setInWeth] = useState<boolean>(false);

  useEffect(() => {
    pilotToken && parseFloat(pilotToken) > 0 && setSelected(true);
  }, [pilotToken]);

  if (!selectedNFTPosition) {
    return null;
  }

  const { token0, token1, token0UserFees, token1UserFees, totalFeesDV, tokenId } =
    selectedNFTPosition;

  let _token0 = token0?.address === WETH_ADDRESS ? (inWeth || defaultInWETH ? WETH : ETH) : token0;
  let _token1 = token1?.address === WETH_ADDRESS ? (inWeth || defaultInWETH ? WETH : ETH) : token1;

  const handleClick = async (): Promise<void> => {
    if (account) {
      if (action && modalType === 'Remove') {
        await action(selected);
      } else {
        const params: ILiquidity['collect'] = [
          selected,
          inWeth,
          CONTRACT_ADDRESSES.liquidityManager,
          tokenId.toString(),
        ];
        const addToken = isMetamask && selected;
        await collect(
          params,
          tokenId.toString(),
          account,
          _token0.symbol,
          _token1.symbol,
          addToken,
          () => {
            getNFTPositionsF(account);
            //history.push('/positions');
          }
        );
      }
    }
  };

  return (
    <IMDialog open={open} onClose={close}>
      <Container style={{ padding: '15px 15px' }} maxWidth='xs'>
        <Header
          disableGoBack
          cAlignItems='center'
          elements={<Typography variant='body1'>{modalType}</Typography>}
          iconRight={<CloseButton onClick={close} />}
        />
        <Box style={{ padding: 15 }}>
          <Grid container alignItems='stretch' justify='space-between' spacing={1}>
            {pilotToken && parseFloat(pilotToken) > 0 && (
              <Grid item xs={6}>
                <BigButton
                  onClick={() => {
                    setSelected(true);
                  }}
                  selected={selected}
                />
              </Grid>
            )}
            <Grid item xs={pilotToken && parseFloat(pilotToken) > 0 ? 6 : 12}>
              <BigButton
                onClick={() => {
                  setSelected(false);
                }}
                selected={!selected}
                name={`${truncSymbol(_token0.symbol, 8)}/${truncSymbol(_token1.symbol, 8)}`}
              />
            </Grid>
          </Grid>
          {/* <Box className={classes.marginTop} />
          <Notification msg={selected ? msg01 : msg02} /> */}
          <Box className={classes.marginTop} />
          <StringsSpaceBetween
            string01={'Unclaimed fees'}
            str02InTrunc
            string02={totalFeesDV ?? '_'}
            className02={classes.strFont}
            className03={classes.strFont}
          />
          <Box className={classes.marginTop} />

          {selected ? (
            <RowThreeContent
              iconWithStr={
                <Grid container alignItems='center' spacing={1}>
                  <Grid item>
                    <PilotToken />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.buttonContent}>Pilot Token</Typography>
                  </Grid>
                </Grid>
              }
              greenInfo={''}
              amount={pilotToken ?? '-'}
              isPilot
            />
          ) : (
            <>
              <RowThreeContent
                iconWithStr={
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <TokenLogo tokenAddress={_token0.address} uri={_token0.logoURI} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.buttonContent}>
                        {truncSymbol(_token0.symbol, 8)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                greenInfo={''}
                amount={token0UserFees ?? '_'}
              />

              <RowThreeContent
                iconWithStr={
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <TokenLogo tokenAddress={_token1.address} uri={_token1.logoURI} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.buttonContent}>
                        {truncSymbol(_token1.symbol, 8)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                greenInfo={''}
                amount={token1UserFees ?? '_'}
              />

              {modalType !== 'Remove' &&
                ((token0.address === WETH_ADDRESS && parseFloat(token0UserFees ?? '0') > 0) ||
                  (token1.address === WETH_ADDRESS && parseFloat(token1UserFees ?? '0') > 0)) && (
                  <RowAlignCenter
                    elements01={<Typography variant='body2'>Collect as WETH</Typography>}
                    elements02={
                      <TokenSwitch
                        value0='On'
                        value1='Off'
                        baseValue={inWeth}
                        onChange={(value: boolean) => setInWeth(value)}
                      />
                    }
                  />
                )}
            </>
          )}

          <Box className={classes.marginTop} />

          <ButtonIndicator
            disableElevation
            disabled={
              dappLoading === `${LOADING.COLLECT_FEE}-${tokenId}` ||
              dappLoading === `${LOADING.REMOVE_LIQUIDITY}-${tokenId}`
            }
            className={classes.buttonBottom}
            variant='contained'
            onClick={handleClick}
            label={modalType}
            fullWidth
            color='primary'
          />
        </Box>
      </Container>
    </IMDialog>
  );
};

export default WDDialog;

const BigButton = ({ selected, onClick = () => {}, name = 'Pilot Tokens' }: any) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={selected ? classes.selectedBigBtn : classes.unselectedBigBtn}
      color={'primary'}
      variant='outlined'
      fullWidth
    >
      <Grid
        container
        direction='column'
        alignItems='center'
        // spacing={1}
      >
        <Grid item>
          {selected ? (
            <ConfirmSelected className={classes.confirmIcon} />
          ) : (
            <ConfirmUnselected className={classes.confirmIcon} />
          )}
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          <Typography color={'inherit'} style={{ fontSize: '0.8rem', textTransform: 'none' }}>
            Collect as
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color={'inherit'}
            style={{
              fontSize: '1rem',
              textTransform: 'none',
              letterSpacing: 1,
              lineHeight: 1.2,
            }}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};
