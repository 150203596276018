import {
  Container,
  Box,
  Typography,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';

import { IMDialog, CloseButton, CGrid, ButtonIndicator } from '../';
import useStyles from './styles';

interface IDialog {
  open: boolean;
  nftExisted: boolean;
  close: () => void;
}
function getSteps() {
  return [
    'Go to Unipilot v2 DApp.',
    'Select migration tab.',
    'Select the NFT you wish to migrate.',
  ];
}

const LegacyShift: React.FC<IDialog> = ({ open, close, nftExisted }) => {
  const classes = useStyles();
  const steps = getSteps();

  const handleClick = () => {
    window.open('https://app.unipilot.io', '_blank');
    close();
  };
  return (
    <IMDialog
      open={open}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      maxWidth='xl'
    >
      <DialogContent
        style={{
          padding: 10,
        }}
      >
        <Container disableGutters maxWidth='md'>
          <Box
            style={{
              padding: '5px 10px',
            }}
          >
            <CGrid
              alignItems='center'
              justify='space-between'
              elements01={<Typography variant='h5'>Unipilot v2 is live!</Typography>}
              elements02={<CloseButton onClick={close} />}
            />
            <Box mt={1} />
          </Box>
          <Typography component='div' style={{ padding: '0 10px' }}>
            <span style={{ display: 'block', marginBottom: '10px' }}>Dear Pilot,</span>
            {nftExisted ? (
              <>
                <Box>
                  This is the legacy DApp, Migrate your Liquidity to Unipilot v2 in these simple
                  steps now.
                </Box>
                <Stepper
                  orientation='vertical'
                  nonLinear={true}
                  style={{ backgroundColor: 'transparent' }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} active={true}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </>
            ) : (
              <>
                <Box style={{ marginBottom: 30 }}>
                  This is the legacy DApp. Click the link below to visit Unipilot v2, our new and
                  improved protocol.
                </Box>
              </>
            )}
          </Typography>

          <Box>
            <ButtonIndicator
              onClick={handleClick}
              disableElevation
              className={classes.buttonBottom}
              variant='contained'
              label='Unipilot V2 DApp'
              fullWidth
              color='primary'
            />
          </Box>
        </Container>
      </DialogContent>
    </IMDialog>
  );
};

export default LegacyShift;
