import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { injectedConnectorList } from '../utils/web3Connectors';
import { getWeb3Provider } from '../contracts/getContract';
import { PILOT } from '../constants';
import { useModal } from './uiHooks';

export const useIsMetamask = () => {
  const { connector } = useWeb3React();
  return (connector && connector === injectedConnectorList[0].connector) ?? false;
};

export const useAddTokenToMetamask = () => {
  const isMetamask = useIsMetamask();
  const { hideModalF } = useModal();
  const provider = getWeb3Provider();

  const addToken = useCallback(() => {
    if (provider && isMetamask) {
      provider.currentProvider?.send(
        {
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: PILOT.address,
              symbol: PILOT.symbol,
              decimals: PILOT.decimals,
              image: PILOT.logoURI,
            },
          },
        },
        () => {
          hideModalF();
        }
      );
    } else {
    }
  }, [provider, isMetamask]);

  return addToken;
};
