export enum LIQUIDITY_TOKENS {
  TOKEN_0 = 'token0',
  TOKEN_1 = 'token1',
}

export enum LIQUIDITY_TYPES {
  ADD = 'ADD',
  INCREASE = 'INCREASE',
  MIGRATE = 'MIGRATE',
  IMPORT = 'IMPORT',
  FARM = 'FARM',
}

export enum TRANSACTION_STATUS {
  PENDING = 'pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
}

export enum LOADING {
  ADD_LIQUIDITY = 'Adding liquidity',
  INCREASE_LIQUIDITY = 'Increasing liquidity',
  REMOVE_LIQUIDITY = 'Removing liquidity',
  APPROVE = 'Approving',
  REBASE = 'Rebasing',
  COLLECT_FEE = 'Collecting',
  MIGRATE = 'Migrating',
  LOCK = 'Staking',
  UNLOCK = 'Unstaking',
  CLAIM_REWARD = 'Claming',
}

export enum CALL_TYPE {
  SINGLE = 'Single',
  MULTI = 'Multi',
}

export enum MIGRATE_PROTOCOL {
  UniswapV2 = 'Uniswap-V2',
  UniswapV3 = 'Uniswap-V3',
  SushiSwap = 'SushiSwap',
  Visor = 'Visor',
  Lixir = 'Lixir',
  Popsicle = 'Popsicle',
}

export enum MIGRATE_TYPE {
  NFT = 'NFT',
  LP_TOKEN = 'LP_TOKEN',
}

export enum FARMING_VERSION {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export enum STATS_DROPDOWN {
  TVL = 'ulmTvlUSD',
  VOLUME = 'volumeUSD',
  FEES = 'ulmFeesUSD',
  APY = 'apy',
}
