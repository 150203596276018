import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      fontSize: '16px',
    },
  },
  feetier: {
    marginLeft: '10px',
  },
}));

export default useStyles;
