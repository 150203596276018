import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {
    "& .MuiInput-underline:before, .MuiInput-underline:after": {
      display: "none",
    },
  },
  defaultClass: {
    "& .MuiInput-root": {
      fontSize: "26px",
      fontWeight: 600,
    },
  },
}));

export default useStyles;
