import { useState } from 'react';
import { Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { ButtonDropIcon, Dialog, TokenLogo } from '../';
import useStylesSelectToken from './styles';
import { useLiquidity } from '../../hooks';
import { IToken } from '../../utils/generalTypes';
import { LIQUIDITY_TOKENS } from '../../utils/enums';
import { truncSymbol } from '../../utils/formating';
//import { purplishButton } from '../../theme/materialUiTheme';

interface ISelectToken {
  selected: IToken | null;
  type: LIQUIDITY_TOKENS;
  viewOnly?: boolean;
  rounded?: boolean;
  visibleCheckIcon?: boolean;
  check?: boolean;
  onClickCheck?: () => void;
}

const SelectToken: React.FC<ISelectToken> = ({
  selected,
  type,
  viewOnly = false,
  rounded = false,
  visibleCheckIcon = false,
  check = false,
  onClickCheck = () => {},
}) => {
  const classes = useStylesSelectToken();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  const { selectTokenF } = useLiquidity();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: IToken, _type: LIQUIDITY_TOKENS = type) => {
    selectTokenF(value, _type);
    setOpen(false);
  };

  const endIcon = visibleCheckIcon ? (
    <CheckCircleOutlineIcon color={check ? 'primary' : 'secondary'} />
  ) : undefined;

  const color = !selected ? (viewOnly ? 'secondary' : 'primary') : 'secondary';

  const isSelected = !selected ? classes.containerUnselected : classes.containerSelected;

  const isRounded = rounded ? classes.roundedViewOnly : classes.viewOnly;

  const isViewOnly = viewOnly ? isRounded : '';

  const isRoundedSelected = check ? classes.roundSelected : '';

  return (
    <>
      <Dialog open={open} close={handleClose} setItem={handleSelect} />
      <ButtonDropIcon
        endIcon={endIcon}
        iconColor={'inherit'}
        fullWidth
        disableElevation
        color={color}
        variant='outlined'
        className={[isSelected, isViewOnly, isRoundedSelected].join(' ')}
        onClick={viewOnly && visibleCheckIcon ? onClickCheck : handleOpen}
        viewOnly={viewOnly}
        visibleExpandIcon={visibleCheckIcon || !viewOnly}
      >
        {selected ? (
          <Box display='flex' padding='4px 0px' alignItems='center'>
            <TokenLogo tokenAddress={selected.address} uri={selected.logoURI ?? ''} />
            <Typography className={classes.typoPri}>
              {truncSymbol(selected.symbol, xs_ ? 4 : 8)}
            </Typography>
          </Box>
        ) : (
          <Typography className={classes.typoPri}>Select</Typography>
        )}
      </ButtonDropIcon>
      {/* </ThemeProvider> */}
    </>
  );
};

export default SelectToken;
