import { Box } from '@material-ui/core';

import useStyles from './styles';

const BlackBox: React.FC<any> = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.blackBoxContainer}>{children}</Box>;
};

export default BlackBox;
