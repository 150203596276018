import { useMemo } from 'react';
import { Box, Grid, Input, Typography } from '@material-ui/core';

import { BoxContainer, Notification } from '../../components';
import useStyles from './styles';
import { useLiquidity, useLiquidityCalculations } from '../../hooks';
import { MIGRATE_PROTOCOL } from '../../utils/enums';
import { truncSymbol } from '../../utils/formating';
interface IPricing {
  msg?: string;
  migrateProtocol?: MIGRATE_PROTOCOL;
}

const Pricing: React.FC<IPricing> = ({ msg, migrateProtocol }) => {
  const classes = useStyles();

  const { token0, token1, initialAmount, sqrtPrice } = useLiquidity();
  const { handleInitalAmountBlur } = useLiquidityCalculations();

  const message = useMemo<string>(() => {
    return msg
      ? msg
      : 'This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool. Then, enter your liquidity price range and deposit amount. Gas fees will be higher than usual due to the initialization transaction.';
  }, [msg]);

  return (
    <>
      <BoxContainer style={{ height: '100%' }} className={classes.right}>
        <Box style={{ padding: '20px' }}>
          <Typography variant='h6'>{`Set Starting Price`}</Typography>

          <Box mt={'10px'} />

          <Notification msg={message} />
          <Box mt={'15px'} />

          {msg && migrateProtocol && (
            <Typography variant='h6'>
              {migrateProtocol === MIGRATE_PROTOCOL.UniswapV2
                ? 'Uniswap V2 Price'
                : 'Sushiswap Price'}
            </Typography>
          )}

          <Input
            placeholder='0.0'
            disableUnderline
            fullWidth
            className={classes.inputStartingPrice}
            onChange={handleInitalAmountBlur}
            value={initialAmount}
            endAdornment={''}
          />
          <Box style={{ marginTop: 10 }} />

          <Grid
            style={{
              padding: '15px 20px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.16)',
              borderRadius: 10,
            }}
            container
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>
                {`${truncSymbol(token0?.symbol ?? '', 10)} price`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{`${
                initialAmount && sqrtPrice ? `${initialAmount} ${token1?.symbol}` : '-'
              }`}</Typography>
            </Grid>
          </Grid>
        </Box>
      </BoxContainer>
    </>
  );
};

export default Pricing;
