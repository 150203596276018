import { BigNumber } from 'bignumber.js';

import { blocklyticsClient, uniswapV3Client } from '../apollo/client';
import { GET_BLOCKS, GET_POOLS_BY_BLOCKS } from '../apollo/queries';
import { splitQuery, deltaTimestamps } from '../utils/splitQuery';
import { logError } from '../utils/logs';

export const getBlocks = async () => {
  const timestamps = deltaTimestamps();
  const results = (await splitQuery(GET_BLOCKS, blocklyticsClient, [], timestamps)) as any;
  let formattedData: any = [];

  if (results) {
    Object.keys(results).forEach(key => {
      if (results[key].length > 0) {
        formattedData.push({
          timestamp: key.split('t')[1],
          number: results[key][0]['number'],
        });
      }
    });
  }
  return formattedData;
};

const DAYS_IN_YEAR = 365;

export const getPoolsByBlock = async (poolAddress: string) => {
  try {
    const blocks = (await getBlocks()) ?? [];
    const [block24, block48] = blocks;
    const inputs = [
      { poolAddress, block: block24.number },
      { poolAddress, block: block48.number },
      { poolAddress, block: 0 },
    ];

    const results = (await splitQuery(GET_POOLS_BY_BLOCKS, uniswapV3Client, [], inputs)) as any;
    if (results) {
      const formated = Object.keys(results).reduce(
        (
          accum: {
            [key: number]: { volumeUSD: string; totalValueLockedUSD: string; feeTier: string };
          },
          key: any
        ) => {
          const block = key.split('t')[1];
          accum[block] = results[key][0];
          return accum;
        },
        {}
      );

      const feeTier = new BigNumber(formated[0].feeTier);
      const volume24 = new BigNumber(formated[0].volumeUSD).minus(
        formated[block24.number].volumeUSD
      );
      const currentTvl = new BigNumber(formated[0].totalValueLockedUSD);
      const fees24Hr = feeTier.dividedBy(1000000).multipliedBy(volume24);

      const feesAPY = fees24Hr
        .dividedBy(currentTvl)
        .multipliedBy(DAYS_IN_YEAR)
        .multipliedBy(100)
        .toString();
      return feesAPY;
    }
  } catch (e) {
    logError('getPoolsByBlock', e);
  }
};
