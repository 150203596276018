import { useMemo } from 'react';
import { Button, CircularProgress } from '@material-ui/core';

import { RowAlignCenter, SmallChart, PoolInfo } from '../../components';
import useStyles from './styles';
import { IPoolData } from '../../utils/generalTypes';
import { unWrappedStatsETH } from '../../utils/helpers';
import { useStats, usePoolGraph } from '../../hooks';

interface IPools {
  poolData: IPoolData;
}

const Pools: React.FC<IPools> = ({ poolData }) => {
  const classes = useStyles();
  const { selectedPool, selectPoolF } = useStats();
  const { data, loading } = usePoolGraph(poolData.id);

  const { token0, token1, feeTier } = poolData.uniswapPool;

  const formatedData = useMemo<number[][]>(() => {
    if (data) {
      return data.map(({ date, ulmTvlUSD }) => [date * 1000, ulmTvlUSD]);
    } else return [];
  }, [data]);

  return (
    <Button
      className={`${classes.poolBtn} ${
        selectedPool?.id === poolData.id ? classes.selectedPool : ''
      }`}
      onClick={() => selectPoolF(poolData)}
      disableRipple={true}
    >
      <RowAlignCenter
        element02ClassName={classes.graphContainer}
        elements01={
          <PoolInfo
            token0Address={token0.address}
            token1Address={token1.address}
            token0Symbol={token0.symbol}
            token1Symbol={token1.symbol}
            token0Uri={token0.logoURI}
            token1Uri={token1.logoURI}
            feeTier={feeTier}
          />
        }
        elements02={!loading ? <SmallChart data={formatedData} /> : <CircularProgress size={15} />}
      />
    </Button>
  );
};

export default Pools;
