import { uniswapV3FactoryContract } from '../getContract';
import { CONTRACT_ADDRESSES } from '../../constants';
import { logError } from '../../utils/logs';
import { CALL_TYPE } from '../../utils/enums';
import { DecodeType, singleContractMultipleData, singleCall } from '../calls';

export const getUniswapV3PoolAddress = async (
  token0Address: string,
  token1Address: string,
  feeTier: string
): Promise<string | undefined> => {
  try {
    const contract = uniswapV3FactoryContract();
    const poolAddress = await contract?.methods
      .getPool(token0Address, token1Address, feeTier)
      .call();
    return poolAddress;
  } catch (e) {
    logError('getUniswapV3PoolAddress', e);
  }
};

export const uniswapV3PoolAddresses = async (call: CALL_TYPE, inputs: string[][]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  const contract = uniswapV3FactoryContract();
  const method = contract?.methods.getPool;
  if (call === CALL_TYPE.MULTI) {
    return await singleContractMultipleData(
      inputs,
      CONTRACT_ADDRESSES.uniswapV3Factory,
      method,
      types,
      'multi-uniswapV3PoolAddresses'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(inputs[0], method, 'single-uniswapV3PoolAddresses');
  }
};
