import { TransactionTypes } from '../actionTypes';
import { Dispatch } from 'redux';

import { TransactionAction } from '../actions/transactionActions';
import { ITransaction } from '../../utils/generalTypes';
import { store } from '../store';
import { TRANSACTION_STATUS } from '../../utils/enums';
import { getWeb3Provider } from '../../contracts/getContract';

// export const addTransaction = (transaction: ITransaction): void => {
//   store.dispatch({
//     type: TransactionTypes.ADD_TRANSACTION,
//     payload: transaction,
//   });
// };

export const addTransaction = (txn: ITransaction): void => {
  store.dispatch({
    type: TransactionTypes.ADD_TRANSACTION,
    payload: txn,
  });
};

export const clearTransaction = (
  account: string
): ((dispatch: Dispatch<TransactionAction>) => void) => {
  return (dispatch: Dispatch<TransactionAction>) => {
    dispatch({
      type: TransactionTypes.CLEAR_TRANSACTION,
      payload: account,
    });
  };
};

export const getTransactions = (
  account: string
): ((dispatch: Dispatch<TransactionAction>) => void) => {
  return (dispatch: Dispatch<TransactionAction>) => {
    dispatch({
      type: TransactionTypes.GET_TRANSACTIONS,
      payload: account,
    });
  };
};

const checkTransaction = async (txn: ITransaction) => {
  const web3 = getWeb3Provider();
  const res = await web3.eth.getTransactionReceipt(txn.hash);
  if (res) {
    return { ...txn, status: res.status ? TRANSACTION_STATUS.SUCCESS : TRANSACTION_STATUS.FAILED };
  } else {
    if (txn.checkCount < 3) {
      return { ...txn, checkCount: txn.checkCount + 1 };
    } else return null;
  }
};

export const loadTransactions = (account: string, pendingTxns?: ITransaction[]) => {
  return async (dispatch: Dispatch<TransactionAction>) => {
    let pendingTransactions;
    if (pendingTxns) {
      pendingTransactions = pendingTxns;
    } else {
      const transactions = JSON.parse(localStorage.getItem('transactions') ?? '{}');
      const userTransactions = transactions[account] ?? [];
      pendingTransactions = userTransactions.filter(
        (txn: ITransaction) => txn.status === TRANSACTION_STATUS.PENDING
      );
    }

    if (pendingTransactions.length > 0) {
      pendingTransactions.forEach(async (pTxn: ITransaction) => {
        const res = await checkTransaction(pTxn);
        res
          ? dispatch({ type: TransactionTypes.UPDATE_TRANSACTION, payload: res })
          : dispatch({
              type: TransactionTypes.REMOVE_TRANSACTION,
              payload: pTxn,
            });
      });
    }
  };
};
