import { StatsTypes } from '../actionTypes';
import { StatsAction } from '../actions/statsActions';
import { IPoolData } from '../../utils/generalTypes';

export interface StatsState {
  poolsData: IPoolData[] | undefined;
  protocolTvl: string | undefined;
  protocolFees: string | undefined;
  selectedPool: IPoolData | undefined | null;
  totalProtocolFees: string | undefined;
  poolFees: { [key: string]: { claimed: string; unclaimed: string; total: string } };
  poolApy: { [key: string]: string } | undefined;
}

const initialState: StatsState = {
  poolsData: undefined,
  protocolTvl: undefined,
  protocolFees: undefined,
  selectedPool: undefined,
  totalProtocolFees: undefined,
  poolFees: {},
  poolApy: undefined,
};

const statsReducer = (state: StatsState = initialState, action: StatsAction) => {
  switch (action.type) {
    case StatsTypes.PROTOCOL_DATA:
      return { ...state, ...action.payload };
    case StatsTypes.SELECT_POOL:
      return { ...state, selectedPool: action.payload };
    case StatsTypes.PROTOCOL_FEES:
      const { totalProtocolFees, poolFees } = action.payload;
      return { ...state, totalProtocolFees, poolFees };
    case StatsTypes.POOL_APY:
      return { ...state, poolApy: action.payload };
    default:
      return state;
  }
};

export default statsReducer;
