import { SvgIconTypeMap, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

import { CGrid, PricesPopover } from "../../components";
import useStyles from "./styles";

interface ITextWithInfo {
  text: string;
  jsx: JSX.Element;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> | undefined;
  className?: string;
}

const TextWithInfo: React.FC<ITextWithInfo> = ({
  text,
  jsx,
  Icon,
  className,
}) => {
  const classes = useStyles();
  return (
    <CGrid
      containerClassName={className}
      containerProps={{
        spacing: 1,
      }}
      alignItems="center"
      justify="flex-start"
      elements01={
        <Typography className={classes.font0P9Rem}>{text}</Typography>
      }
      elements02={<PricesPopover Icon={Icon} jsx={jsx} />}
    />
  );
};

export default TextWithInfo;
