import { Grid, Typography } from "@material-ui/core";

import { Loud, Warning } from "../";
import useStyles from "./styles";

interface INotification {
  msg?: string;
  err?: boolean;
  hide?: boolean;
}

const Notification: React.FC<INotification> = ({
  msg = "Looks like you are the first person to provide liquidity in this pool.",
  err = false,
  hide = false,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={`${classes.container} ${err ? classes.errContainer : ""}`}
      alignItems="center"
      wrap="nowrap"
    >
      {!hide && (
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {err ? (
            <Warning className={classes.iconLoud} />
          ) : (
            <Loud className={classes.iconLoud} />
          )}
        </Grid>
      )}
      <Grid item>
        <Typography className={classes.text}>{msg}</Typography>
      </Grid>
    </Grid>
  );
};

export default Notification;
