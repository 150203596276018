import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      padding: '30px 20px',
      overflowX: 'hidden',
    },
    heading: {
      marginBottom: '10px',
      fontWeight: 'bold',
      color: '#fff',
    },
    errorBox: {
      color: '#fff',
      background: 'rgba(0, 0, 0, 0.6)',
      boxShadow: '0px 4px 8px rgba(27, 27, 27, 0.16)',
      padding: '20px',
      borderRadius: '10px',
      overflowX: 'scroll',
      '& code': {
        fontSize: '10px',
      },
      '&::-webkit-scrollbar': {
        width: '0px',
        height: '0px',
      },
    },
  });

export default styles;
