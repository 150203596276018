import { Dispatch } from 'redux';

import { LiquidityTypes } from '../actionTypes';
import { LiquidityAction } from '../actions/liquidityActions';
import { IToken, ILiquidityData, IUpdateLiquidity } from '../../utils/generalTypes';
import { LIQUIDITY_TYPES } from '../../utils/enums';

export const selectToken = (
  token: IToken,
  type: string
): ((dispatch: Dispatch<LiquidityAction>) => void) => {
  return (dispatch: Dispatch<LiquidityAction>) => {
    dispatch({
      type: LiquidityTypes.SELECT_TOKEN,
      payload: { token, type },
    });
  };
};

export const addData = (data: ILiquidityData): ((dispatch: Dispatch<LiquidityAction>) => void) => {
  return (dispatch: Dispatch<LiquidityAction>) => {
    dispatch({
      type: LiquidityTypes.ADD_DATA,
      payload: data,
    });
  };
};

export const clearData = (): ((dispatch: Dispatch<LiquidityAction>) => void) => {
  return (dispatch: Dispatch<LiquidityAction>) => {
    dispatch({
      type: LiquidityTypes.CLEAR_DATA,
    });
  };
};

export const setLiquidityType = (
  type: LIQUIDITY_TYPES
): ((dispatch: Dispatch<LiquidityAction>) => void) => {
  return (dispatch: Dispatch<LiquidityAction>) => {
    dispatch({
      type: LiquidityTypes.SET_LIQUIDITY_TYPE,
      payload: type,
    });
  };
};

export const updateAddLiquidity = (
  data: IUpdateLiquidity
): ((dispatch: Dispatch<LiquidityAction>) => void) => {
  return (dispatch: Dispatch<LiquidityAction>) => {
    dispatch({
      type: LiquidityTypes.UPDATE_ADD_LIQUIDITY,
      payload: data,
    });
  };
};
