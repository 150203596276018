import { Box, Input, Typography } from '@material-ui/core';

import useStyles from './styles';
import { DisableElements, Notification } from '../';
import { useLiquidity, useLiquidityCalculations } from '../../hooks';
import { truncSymbol } from '../../utils/formating';

const InitialAmount = () => {
  const classes = useStyles();
  const {
    token0,
    token1,
    feeTier,
    initialAmount,
    addLiquidityState: { loading, pairExists },
  } = useLiquidity();
  const { handleInitalAmountBlur } = useLiquidityCalculations();

  return (
    <DisableElements disabled={!(token0 && token1 && feeTier)}>
      <Box marginTop='20px' />
      {feeTier && !loading && !pairExists && (
        <>
          <Typography variant='h6'>{`Set Starting Price (${truncSymbol(
            token0?.symbol ?? '',
            10
          )})`}</Typography>
          <Input
            placeholder='0.0'
            disableUnderline
            fullWidth
            className={classes.inputStartingPrice}
            onChange={handleInitalAmountBlur}
            value={initialAmount}
            endAdornment={truncSymbol(token1?.symbol ?? '')}
          />
          <Box marginTop='20px' />
          <Notification />
        </>
      )}
    </DisableElements>
  );
};

export default InitialAmount;
