import { useState } from "react";
import {
  Popper,
  Box,
  Fade,
  Paper,
  Typography,
  SvgIconTypeMap,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/Error";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import useStyles from "./styles";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

interface IPricesPopover {
  className?: string;
  jsx: JSX.Element;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const PricesPopover: React.FC<IPricesPopover> = ({
  className,
  jsx,
  Icon = InfoOutlinedIcon,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleOver = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLeave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        component="div"
        className={`${classes.container} ${className ? className : ""}`}
        //aria-describedby={id}
      >
        <Box
          onMouseOver={handleOver}
          onMouseLeave={handleLeave}
          style={{
            position: "absolute",
            width: 18,
            height: 18,
            // backgroundColor: "red",
          }}
        />
        <Icon className={classes.icon} />
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right"
        transition
        style={{ marginLeft: 20, zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {jsx}
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default PricesPopover;
