import Web3 from 'web3';
import BigNumber, { BigNumber as BN } from 'bignumber.js';
//import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { ETH, WETH_ADDRESS, PILOT, PILOT_LOGO } from '../constants';
import { IToken, ISubgraphToken } from './generalTypes';
import { formatAmount } from './formating';

export const getNetworkName = (chainId: number | undefined): string => {
  if (chainId === 1) return 'Mainnet';
  else if (chainId === 3) return 'Ropsten';
  else if (chainId === 4) return 'Rinkeby';
  else if (chainId === 5) return 'Goerli';
  else if (chainId === 42) return 'Kovan';
  else return 'Wrong Newtwork';
};

export const validAmount = (token: IToken, amount: string): boolean => {
  if (token?.balance) {
    return parseFloat(formatAmount(token?.balance, token.decimals)) < parseFloat(amount)
      ? true
      : false;
  } else return false;
};

export const getTokens = (token0: string, token1: string) => {
  const _token0 = Web3.utils.toChecksumAddress(token0);
  const _token1 = Web3.utils.toChecksumAddress(token1);
  if (_token0 > _token1) {
    return { token0: token1, token1: token0 };
  } else {
    return { token0, token1 };
  }
};

export const getLogoUri = (tokenAddress: string): string => {
  if (tokenAddress.toLowerCase() === PILOT.address.toLowerCase()) return PILOT_LOGO;
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${Web3.utils.toChecksumAddress(
    tokenAddress
  )}/logo.png`;
};

export const calculateDollarValue = (tokenValue: string, tokenDollarValue: string) => {
  const dollarValue = new BN(tokenValue).multipliedBy(new BN(tokenDollarValue));
  return dollarValue;
};

export const checkRange = (tickLower: number, tickUpper: number, tick: number) => {
  return tickLower <= tick && tickUpper >= tick;
};

export const isInRange = (
  tickLower: number,
  tickUpper: number,
  tick: number,
  rangeTickLower: number,
  rangeTickUpper: number
) => {
  return checkRange(tickLower, tickUpper, tick) || checkRange(rangeTickLower, rangeTickUpper, tick);
};

export const classesJoin = (classes: string[]) => {
  return classes.join(' ');
};

export const unWrappedStatsETH = (token: { id: string; symbol: string }) => {
  return token.id.toLowerCase() === WETH_ADDRESS
    ? { id: ETH.address, symbol: ETH.symbol, logoURI: ETH.logoURI }
    : { ...token, logoURI: getLogoUri(token.id) };
};

export const typeCastSubgraphToken = (token: ISubgraphToken): IToken => {
  const { id, symbol, name, decimals } = token;
  return id === WETH_ADDRESS
    ? ETH
    : { address: id, symbol, name, decimals: Number(decimals), logoURI: getLogoUri(id) };
};

export const calculateShares = (
  amount0: string,
  amount1: string,
  userValue: string,
  totalValue: string
) => {
  const userShare = new BigNumber(userValue).dividedBy(totalValue);
  return {
    shareValue0: new BigNumber(amount0).multipliedBy(userShare),
    shareValue1: new BigNumber(amount1).multipliedBy(userShare),
  };
};

export const getUri = (uri: string) => {
  let _uri = uri;
  if (uri.startsWith('ipfs')) {
    const _val = uri?.split('//');
    _uri = 'https://ipfs.io/ipfs/' + _val[1];
  }
  return _uri;
};
