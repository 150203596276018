import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { useNFTPositions } from '../../hooks';
import { getUnipilotPositionDetails } from '../../contracts/functions/unipilot';
import PositionDetail from './PositionDetail';
import { ZERO_ADDRESS } from '../../constants';

const PairLiquidity: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();

  const { nftPositions, selectedNFTPosition, selectNFTPositionF } = useNFTPositions();

  const getRoutePosition = async (id: number) => {
    const position = await getUnipilotPositionDetails([id], ZERO_ADDRESS);
    if (position) {
      selectNFTPositionF(position[0]);
    } else {
      selectNFTPositionF(undefined);
    }
  };

  useEffect(() => {
    if (!(selectedNFTPosition && selectedNFTPosition.tokenId?.toString() === id)) {
      getRoutePosition(Number(id));
    }
  }, [nftPositions]);

  if (!selectedNFTPosition && selectedNFTPosition !== null) {
    return <Redirect to='/positions' />;
  }

  return (
    <>
      {selectedNFTPosition && selectedNFTPosition?.hasOwnProperty('tokenId') ? (
        <PositionDetail position={selectedNFTPosition} />
      ) : (
        <CircularProgress size={20} />
      )}
    </>
  );
};

export default PairLiquidity;
