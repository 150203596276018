import { uniStateContract } from '../getContract';
import { CONTRACT_ADDRESSES } from '../../constants';
import { CALL_TYPE } from '../../utils/enums';
import { singleContractMultipleData, singleCall, DecodeType } from '../calls';
import { PositionDetails } from '../returnTypes';

export const getPositionDetails = async <T extends keyof PositionDetails>(
  call: CALL_TYPE,
  tokenIds: number[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: 'pool',
    },
    {
      type: 'address',
      name: 'token0',
    },
    {
      type: 'address',
      name: 'token1',
    },
    {
      type: 'int24',
      name: 'currentTick',
    },
    {
      type: 'uint24',
      name: 'fee',
    },
    {
      type: 'uint256',
      name: 'liquidity',
    },
    {
      type: 'uint256',
      name: 'amount0',
    },
    {
      type: 'uint256',
      name: 'amount1',
    },
    {
      type: 'uint256',
      name: 'totalLiquidity',
    },
  ];
  const contract = uniStateContract();
  const method = contract?.methods.getPositionDetails;
  const inuputs = tokenIds.map((tokenId: number) => [tokenId, CONTRACT_ADDRESSES.liquidityManager]);

  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      inuputs,
      CONTRACT_ADDRESSES.uniState,
      method,
      types,
      'multi-positionDetails',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(inuputs[0], method, 'single-positionDetails');
  }
};
