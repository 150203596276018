import { useMemo, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { useLiquidity } from '../hooks';
import { useDispatchWrap } from './utilHooks';
import {
  getMigrateLP,
  getMigrateNFT,
  selectMigrate,
  setMigrateNftPageCount,
  setMigrateLpPageCount,
  setActiveTab,
  addLpToken,
  removeLpToken,
  updateMigrateNFT,
  updateMigrateLp,
  State,
} from '../redux';
import { getSushiPairAddress } from '../contracts/functions/sushiFactory';
import { getHypervisor } from '../contracts/functions/hypervisorFactory';
import { getVaults } from '../contracts/functions/lixirFactory';
import { getLpTokenDetail } from '../contracts/functions/liquidityMigrator';
import { MigrateTokens, IMigrateLPToken, ILPToken, IToken } from '../utils/generalTypes';
import { findV2PoolAddress, getTokens, findPoolAddress } from '../utils/uniswapSDKFunctions';
import { CALL_TYPE, MIGRATE_PROTOCOL, MIGRATE_TYPE } from '../utils/enums';
import { ZERO_ADDRESS, POPSICLE_VAULTS } from '../constants';

export const useMigrateLiquidity = () => {
  const migrateLiquidity = useSelector((state: State) => state.migrateLiquidity);
  const getMigrateLPF = useDispatchWrap(getMigrateLP);
  const getMigrateNFTF = useDispatchWrap(getMigrateNFT);
  const selectMigrateF = useDispatchWrap(selectMigrate);
  const setMigrateNftPageCountF = useDispatchWrap(setMigrateNftPageCount);
  const setMigrateLpPageCountF = useDispatchWrap(setMigrateLpPageCount);
  const setActiveTabF = useDispatchWrap(setActiveTab);
  const addLpTokenF = useDispatchWrap(addLpToken);
  const removeLpTokenF = useDispatchWrap(removeLpToken);
  const updateMigrateNFTF = useDispatchWrap(updateMigrateNFT);
  const updateMigrateLpF = useDispatchWrap(updateMigrateLp);

  const migrateLiqTokens = useMemo<MigrateTokens[] | undefined>(() => {
    const { migrateLPTokens, migrateNFTs } = migrateLiquidity;
    if (migrateLPTokens && migrateNFTs) {
      return [...migrateLPTokens, ...migrateNFTs];
    }
  }, [migrateLiquidity]);

  return {
    ...migrateLiquidity,
    migrateLiqTokens,
    getMigrateLPF,
    getMigrateNFTF,
    selectMigrateF,
    setMigrateNftPageCountF,
    setMigrateLpPageCountF,
    setActiveTabF,
    addLpTokenF,
    removeLpTokenF,
    updateMigrateNFTF,
    updateMigrateLpF,
  };
};

export const useLPToken = () => {
  const { token0, token1, protocol, feeTier, clearDataF } = useLiquidity();
  const { addLpTokenF } = useMigrateLiquidity();
  const { account } = useWeb3React();
  const history = useHistory();

  const [lpToken, setLpToken] = useState<ILPToken | undefined>();
  const [lpTokenDetail, setLpTokenDetail] = useState<IMigrateLPToken | undefined | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const computePoolAddress = useCallback(
    async (token0: IToken, token1: IToken, protocol: MIGRATE_PROTOCOL, feeTier?: string) => {
      let poolAddress = ZERO_ADDRESS;
      if (protocol === MIGRATE_PROTOCOL.UniswapV2) {
        poolAddress = findV2PoolAddress(token0, token1);
      } else if (protocol === MIGRATE_PROTOCOL.SushiSwap) {
        poolAddress = (await getSushiPairAddress(token0.address, token1.address)) ?? ZERO_ADDRESS;
      } else if (protocol === MIGRATE_PROTOCOL.Visor && feeTier) {
        poolAddress =
          (await getHypervisor(token0.address, token1.address, feeTier)) ?? ZERO_ADDRESS;
      } else if (protocol === MIGRATE_PROTOCOL.Lixir) {
        poolAddress =
          (await getVaults(CALL_TYPE.SINGLE, [[token0.address, token1.address, '0']])) ??
          ZERO_ADDRESS;
      } else if (protocol === MIGRATE_PROTOCOL.Popsicle && feeTier) {
        const uniswapV3PoolAddress = findPoolAddress(token0, token1, feeTier);
        poolAddress =
          POPSICLE_VAULTS.find(
            ({ uniswapV3Pool }) =>
              uniswapV3PoolAddress.toLowerCase() === uniswapV3Pool.toLowerCase()
          )?.vault ?? ZERO_ADDRESS;
      }
      return poolAddress.toLowerCase();
    },
    []
  );

  const findUserPosition = useCallback(async () => {
    if (
      account &&
      token0 &&
      token1 &&
      protocol &&
      (protocol === MIGRATE_PROTOCOL.Visor || protocol === MIGRATE_PROTOCOL.Popsicle
        ? feeTier
        : true)
    ) {
      setLoading(true);
      setLpTokenDetail(undefined);
      setLpToken(undefined);
      const pairAddress = await computePoolAddress(token0, token1, protocol, feeTier);
      const { baseToken, quoteToken } = getTokens(token0, token1);
      const _lpToken = {
        id: pairAddress,
        token0: baseToken,
        token1: quoteToken,
        pairAddress,
        protocol,
        type: MIGRATE_TYPE.LP_TOKEN,
        fee: feeTier,
      };
      setLpToken(_lpToken);
      const lpTokenDetail = await getLpTokenDetail([_lpToken], account, protocol);
      setLpTokenDetail(
        lpTokenDetail && parseFloat(lpTokenDetail[0].liquidity) > 0 ? lpTokenDetail[0] : null
      );
      setLoading(false);
    } else {
      setLpTokenDetail(undefined);
      setLpToken(undefined);
    }
  }, [account, token0, token1, protocol, feeTier]);

  const importLpToken = useCallback(() => {
    if (lpTokenDetail && account) {
      addLpTokenF(lpToken, lpTokenDetail, account);
      setLpTokenDetail(undefined);
      setLpToken(undefined);
      clearDataF();
      history.push('/migrate');
    }
  }, [lpToken, lpTokenDetail, account]);

  useEffect(() => {
    findUserPosition();
  }, [token0, token1, protocol, feeTier]);

  return { lpTokenDetail, loading, importLpToken };
};
