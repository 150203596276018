import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { exponentialToDecimal, trunc } from '../../utils/formating';
import { CTooltip } from '../../components';

interface IAmount {
  amount: string | undefined;
  className?: string;
  dollorSymbol?: boolean;
  len?: number;
  endSymbol?: string;
}

const Amount: React.FC<IAmount> = ({
  amount,
  className,
  dollorSymbol = false,
  len = 15,
  endSymbol = '',
}) => {
  return (
    <>
      {amount && amount.length < len ? (
        <Typography className={className}>{`${dollorSymbol ? '$ ' : ''}${amount}${
          endSymbol ? endSymbol : ''
        }`}</Typography>
      ) : amount ? (
        <CTooltip title={`${exponentialToDecimal(amount)}`}>
          <Typography className={className}>
            {`${dollorSymbol ? '$ ' : ''}${trunc(amount)} ${endSymbol ? endSymbol : ''}`}
          </Typography>
        </CTooltip>
      ) : (
        <Skeleton width={80} height={30} />
      )}
    </>
  );
};

export default Amount;
