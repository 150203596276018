import { Button, CircularProgress, ButtonProps } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import useStyles from './styles';
import { CTooltip } from '../';

interface IButtonIndicator {
  loaderSize?: number;
  label?: string;
  containerStyle?: React.CSSProperties;
  onlyDisable?: boolean;
  progressStyle?: React.CSSProperties;
  type?: 'over' | 'left';
  help?: string;
}

const ButtonIndicator: React.FC<IButtonIndicator & ButtonProps> = ({
  onClick = () => {},
  loaderSize = 16,
  label = 'label',
  disabled: loading,
  children,
  containerStyle,
  onlyDisable = false,
  progressStyle,
  type = 'left',
  help,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div style={containerStyle} className={classes.wrapper}>
      <Button
        style={{ height: '45px' }}
        {...rest}
        onClick={onClick}
        disabled={loading || onlyDisable}
      >
        {loading && type === 'left' && (
          <CircularProgress
            style={progressStyle}
            size={loaderSize}
            className={classes.buttonProgress2nd}
          />
        )}{' '}
        {children ? children : label}
      </Button>
      {help && (
        <CTooltip title={help}>
          <HelpOutlineIcon className={classes.help} />
        </CTooltip>
      )}
      {loading && type === 'over' && (
        <CircularProgress
          style={progressStyle}
          size={loaderSize}
          className={classes.buttonProgress}
        />
      )}
    </div>
  );
};

export default ButtonIndicator;
