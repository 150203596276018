import axios from 'axios';

import { UiTypes } from '../actionTypes';
import { Dispatch } from 'redux';
import { UiAction } from '../actions/uiActions';
import { store, addTransaction } from '../';
import { IModal, ISnackbar, ITransaction } from '../../utils/generalTypes';
import { PILOT_ADDRESS } from '../../constants';

export const hideModal = (): ((dispatch: Dispatch<UiAction>) => void) => {
  return (dispatch: Dispatch<UiAction>) => {
    dispatch({
      type: UiTypes.HIDE_MODAL,
    });
  };
};

export const showSnackbar = (
  severity: string,
  message: string
): ((dispatch: Dispatch<UiAction>) => void) => {
  return (dispatch: Dispatch<UiAction>) => {
    dispatch({
      type: UiTypes.SHOW_SNACKBAR,
      payload: { severity, message },
    });
  };
};

export const showModal = (variant:string, modal: IModal) =>{
  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: {variant, ...modal },
  });
}

export const showSB = (severity: string, message: string) => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR,
    payload: { severity, message },
  });
};

export const hideSnackbar = (): ((dispatch: Dispatch<UiAction>) => void) => {
  return (dispatch: Dispatch<UiAction>) => {
    dispatch({ type: UiTypes.HIDE_SNACKBAR });
  };
};

export const updatePilotPrice = (): ((dispatch: Dispatch<UiAction>) => void) => {
  return async (dispatch: Dispatch<UiAction>) => {
    try {
      const uri = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${PILOT_ADDRESS}&vs_currencies=USD`;
      const res = await axios.get(uri);
      if (res.data) {
        dispatch({ type: UiTypes.UPDATE_PILOT_PRICE, payload: res.data[PILOT_ADDRESS].usd });
      }
    } catch (e) {}
  };
};

export const showSnackbarInDeep = (data: ISnackbar): void => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: data,
  });
};

export const setApprovalState = (data: boolean): void => {
  store.dispatch({
    type: UiTypes.SET_APPROVAL_STATE,
    payload: data,
  });
};

export const transactionPending = (
  txHash: string,
  modal: IModal,
  loading: string,
  transaction?: ITransaction
): void => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: 'Transaction pending', severity: 'info', txHash },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: 'loading', ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: loading,
  });

  //transaction && addTransaction(transaction);
};

export const transactionSuccess = (
  txHash: string,
  modal: IModal,
  transaction: ITransaction
): void => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: 'Transaction successful', severity: 'success', txHash },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: 'success', ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: '',
  });

  addTransaction(transaction);
};

export const transactionRejected = (): void => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: 'Transaction rejected', severity: 'error', txHash: '' },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: 'blocked' },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: '',
  });
};

export const transactionFailed = (
  txHash: string,
  modal: IModal,
  transaction?: ITransaction
): void => {
  store.dispatch({
    type: UiTypes.SHOW_SNACKBAR_INDEEP,
    payload: { message: 'Transaction failed', severity: 'error', txHash },
  });

  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: 'error', ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: '',
  });

  transaction && addTransaction(transaction);
};

export const transactionException = (modal: IModal) => {
  store.dispatch({
    type: UiTypes.SHOW_MODAL,
    payload: { variant: 'exception', ...modal },
  });

  store.dispatch({
    type: UiTypes.SET_DAPP_LOADING,
    payload: '',
  });
};
