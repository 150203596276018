import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 15,
    padding: "1.5rem",
  },
  tokenPair: {
    margin: "15px 0px",
  },
  marginTop: {
    marginTop: 20,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
  },
  textMarginTop: {
    marginTop: 5,
  },
  overlapClass01: {
    width: 16,
    height: 16,
  },
  overlapClass02: {
    width: 16,
    height: 16,
  },
  ethIcon: {
    margin: "0px 4px",
    // height: 13,
    width: 20,
    height: 20,
  },

  buttonContent: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  bottomSelect: {
    borderRadius: 0,
  },
  bottomInput: {
    borderRadius: 0,
  },
  inputMaxContainer: {
    fontWeight: 500,
    padding: "4px 20px",
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    // borderLeft: `2px solid rgba(255,255,255,0.1)`,
    minHeight: 50,
  },
  selectTokenInputMaxContainer: {
    background: "linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)",
    border: "1px solid rgba(255, 255, 255, 0.05)",
    borderRadius: theme.shape.borderRadius,
  },
  CSBContainer: {
    padding: "15px 0px",
  },
}));

export default useStyles;
