import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useDispatchWrap = (func: any) => {
  const dispatch = useDispatch();

  return (...params: any) => dispatch(func(...params));
};

export const useSorting = <T>(
  data: T[] | undefined,
  sortBy: keyof T,
  customCondition?: (accum: T, item: T) => boolean,
  order: 'asc' | 'dsc' = 'asc'
) => {
  const defaultCondition = useCallback(
    (accum: T, item: T) => {
      const itemSortValue = (item[sortBy] ?? '0') as string;
      const accumSortValue = (accum[sortBy] ?? '0') as string;
      return (
        parseFloat(itemSortValue) === 0 || parseFloat(accumSortValue) > parseFloat(accumSortValue)
      );
    },
    [sortBy]
  );

  const sortCondition = useMemo(() => (customCondition ? customCondition : defaultCondition), []);

  const sortAsc = useCallback(
    (data: T[]) => {
      return data.sort((accum, item) => {
        if (accum && item) {
          if (sortCondition(accum, item)) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return -1;
        }
      });
    },
    [sortCondition]
  );

  const sortDsc = useCallback(
    (data: T[]) => {
      return data.sort((accum, item) => {
        if (accum && item) {
          if (sortCondition(accum, item)) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return 1;
        }
      });
    },
    [sortCondition]
  );

  return useMemo<T[] | undefined>(() => {
    if (data) {
      return order === 'asc' ? sortAsc(data) : sortDsc(data);
    }
  }, [data, sortAsc, sortDsc]);
};
