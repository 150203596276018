export enum UiTypes {
  SHOW_SNACKBAR = 'SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR',
  SHOW_SNACKBAR_INDEEP = 'SHOW_SNACKBAR_INDEEP',
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
  SET_DAPP_LOADING = 'SET_DAPP_LOADING',
  SET_APPROVAL_STATE = 'SET_APPROVAL_STATE',
  UPDATE_PILOT_PRICE = 'UPDATE_PILOT_PRICE',
}

export enum TokenTypes {
  ADD_ACTIVE_TOKEN_LIST = 'ADD_ACTIVE_TOKEN_LIST',
  ADD_TOKEN_LIST = 'ADD_TOKEN_LIST',
  ADD_TOKENS = 'ADD_TOKENS',
  ADD_BALANCE = 'ADD_BALANCE',
  ADD_NEW_TOKEN = 'ADD_NEW_TOKEN',
  CLEAR_TOKENS = 'CLEAR_TOKENS',
}

export enum PositionTypes {
  GET_NFT_POSITIONS = 'GET_NFT_POSITIONS',
  REMOVE_POSITION = 'REMOVE_POSITION',
  ADD_TOKEN_DETAIL = 'ADD_TOKEN_DETAIL',
  SELECT_POSITION = 'SELECT_POSITION',
  CLEAR_POSITIONS = 'CLEAR_POSITIONS',
  UPDATE_SELECTED_POSITION = 'UPDATE_SELECTED_POSITION',
  UPDATE_POSITIONS = 'UPDATE_POSITIONS',
  SET_PAGE_COUNT = 'SET_PAGE_COUNT',
}

export enum SettingTypes {
  SET_SLIPPAGE = 'SET_SLIPPAGE',
  SET_DEADLINE = 'SET_DEADLINE',
}

export enum TransactionTypes {
  ADD_TRANSACTION = 'ADD_TRANSACTION',
  CLEAR_TRANSACTION = 'CLEAR_TRANSACTION',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS',
  REMOVE_TRANSACTION = 'REMOVE_TRANSACTION',
  UPDATE_TRANSACTION = 'UPDATE_TRANSACTION',
}

export enum LiquidityTypes {
  SELECT_TOKEN = 'SELECT_TOKEN',
  ADD_DATA = 'ADD_DATA',
  CLEAR_DATA = 'CLEAR_DATA',
  SET_LIQUIDITY_TYPE = 'SET_LIQUIDITY_TYPE',
  UPDATE_ADD_LIQUIDITY = 'UPDATE_ADD_LIQUIDITY',
}

export enum MigrateLiquidityTypes {
  GET_MIGRATE_NFT = 'GET_MIGRATE_NFT',
  GET_MIGRATE_LP = 'GET_MIGRATE_LP',
  SELECT_MIGRATE = 'SELECT_MIGRATE',
  CLEAR_NFT_MIGRATE = 'CLEAR_NFT_MIGRATE',
  CLEAR_LP_MIGRATE = 'CLEAR_LP_MIGRATE',
  SET_NFT_PAGE_COUNT = 'SET_NFT_PAGE_COUNT',
  SET_LP_PAGE_COUNT = 'SET_LP_PAGE_COUNT',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  ADD_LP_TOKEN = 'ADD_LP_TOKEN',
  REMOVE_LP_TOKEN = 'REMOVE_LP_TOKEN',
  UPDATE_MIGRATE_NFT = 'UPDATE_MIGRATE_NFT',
  UPDATE_MIGRATE_LP = 'UPDATE_MIGRATE_LP',
}

export enum StatsTypes {
  PROTOCOL_DATA = 'PROTOCOL_DATA',
  SELECT_POOL = 'SELECT_POOL',
  PROTOCOL_FEES = 'PROTOCOL_FEES',
  POOL_APY = 'POOL_APY',
}

export enum FarmingTypes {
  GET_FARMING_POOLS = 'GET_FARMING_POOLS',
  GET_USER_LOCKED_NFTS = 'GET_USER_LOCKED_NFTS',
  CLEAR_USER_LOCKED_NFTS = 'CLEAR_USER_LOCKED_NFTS',
  SET_USER_NFT = 'SET_USER_NFT',
  SET_LOCK = 'SET_LOCK',
  UPDATE_LOCKED_NFT = 'UPDATE_LOCKED_NFT',
  SET_FARMING_POOLS_TVL = 'SET_FARMING_POOLS_TVL',
  UPDATE_SORT_BY = 'UPDATE_SORT_BY',
  FARMING_MODAL = 'FARMING_MODAL',
}
