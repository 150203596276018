import { uniswapV2PairContract } from '../getContract';
import { CALL_TYPE } from '../../utils/enums';
import { multipleContractMultipleData, singleCall, DecodeType } from '../calls';
import { PairReserves } from '../returnTypes';

export const getPairReserves = async <T extends keyof PairReserves>(
  call: CALL_TYPE,
  pairAddress: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint112',
      name: '_reserve0',
    },
    {
      type: 'uint112',
      name: '_reserve1',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      uniswapV2PairContract,
      pairAddress,
      'getReserves',
      types,
      'multi-getReserves',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = uniswapV2PairContract(pairAddress[0]);
    return await singleCall([], contract?.methods.decimals, 'single-getReserves');
  }
};
