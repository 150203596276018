import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import mainBG from "../assets/others/main-bg.png";

// "#F8F8F8" light gray

const orangish = "#EFA618";
const purplish = "#3650CD";
const disable = "#171D27";
const purplishLight = "#3650ff";

export interface CommonColors {
  black: string;
  white: string;
  hoverBC01?: string;
  hoverTC01?: string;
  darkBlackish?: string;
  lightText?: string;
  mainBg?: string;
  purplish?: string;
}

interface PaletteOptionsX extends PaletteOptions {
  common?: Partial<CommonColors>;
}

export interface ThemeOptionsX extends ThemeOptions {
  palette?: PaletteOptionsX;
}

// xs: 500,
//       sm: 650,
//       md: 1000,
//       lg: 1280,
//       xl: 400,
//       xxs: 350,

export const themeDark = createTheme({
  mixins: {
    toolbar: {
      minHeight: 50,
      ["@media (max-width:999.95px)"]: {
        minHeight: 50,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 500,
      sm: 550,
      sm02: 800,

      md: 1100,
      lg: 1280,
      xl: 400,
      xxs: 350,
    },
  },
  palette: {
    type: "dark",
    common: {
      black: "#080F19",
      hoverBC01: "#0F1825",
      hoverTC01: "#FFFFFF",
      blackish: "#080F19",
      lightText: "#999797",
      mainBg: "rgba(0, 0, 0, 0.6)",
      purplish,
      purplishLight,
    },
    primary: {
      main: orangish,
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.6)", //'#132337', //"#0F1825",
    },

    background: {
      paper: "rgba(0, 0, 0, 0.6)", //"#080F19",
      default: "#020E25", //"rgba(0, 0, 0, 0.6)", // "#132337", //"#0F1825", //"#05070A",
      main: "rgba(0, 0, 0, 0.6)",
    },
  },

  typography: {
    fontFamily: "Rubik",
    caption: {
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "none",
    },
    body2: {
      fontSize: "0.9rem",
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `url(${mainBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          // transform: "rotate(180deg)",
        },
      },
    },
    MuiStepIcon: {
      text: {
        fontWeight: "bold",
        fill: "#fff",
      },
    },
    //   MuiButton: {
    //     contained: {
    //       backgroundColor: purplish,
    //       color: "white",
    //       "&:hover": {
    //         backgroundColor: "#3650ff",
    //       },
    //     },
    //     outlined: {
    //       // borderColor: `${purplish} !important`,
    //       // color: `${purplish} !important`,
    //       // "&:hover": {
    //       //   opacity: 0.5,
    //       // },
    //     },
    //     // root: {
    //     //   backgroundColor: "#20295F",
    //     //   "&:hover": {
    //     //     boxShadow: "1px 1px 20px 5px rgba(255,255,255,0.4) inset",
    //     //     borderColor: "transparent",
    //     //   },
    //     // },
    // },
  },
} as ThemeOptionsX);

export const purplishButton = createTheme({
  palette: {
    action: {
      disabledBackground: disable,
      disabled: "rgba(255,255,255,0.3)",
    },
    primary: {
      main: purplish,
    },
    secondary: {
      main: orangish,
    },
  },
  typography: {
    fontFamily: "Rubik",
  },
  shape: {
    borderRadius: 10,
  },

  overrides: {
    MuiButton: {
      // disabled: {
      //   backgroundColor: `#223199 !important`,
      //   color: "white !important",
      // },
      // contained: {
      //   backgroundColor: purplish,
      //   color: "white",
      //   "&:hover": {
      //     backgroundColor: "#3650ff",
      //   },
      // },
      outlined: {
        // borderColor: `${purplish} !important`,
        color: `${purplish}`,
      },
      root: {
        // minHeight: 40,
        lineHeight: 2,
      },
    },
  },
});
// #223199
