import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  fabProgress: {
    color: 'green',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2nd: {
    marginRight: 10,
  },
  help: {
    position: 'absolute',
    top: 8,
    right: 10,
    opacity: '0.5',
  },
}));

export default useStyles;
