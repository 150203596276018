import { useState, useEffect } from "react";
import { Container, Grid, useTheme, useMediaQuery } from "@material-ui/core";

import {
  ItemList,
  BoxContainer,
  Pricing,
  Empty,
  TvlGraph,
  FMigration,
} from "../../components";
import useStyles from "./styles";
import { LIQUIDITY_TYPES } from "../../utils/enums";
import AddLiquidity from "./AddLiquidity";
import { useLiquidity, usePoolTVL } from "../../hooks";

const Index: React.FC<{}> = () => {
  const classes = useStyles();
  const { type, feeTier, addLiquidityState, setLiquidityTypeF } =
    useLiquidity();
  const { data, loading: tvlLoading } = usePoolTVL();
  const theme = useTheme();
  const _md = useMediaQuery(theme.breakpoints.down("md"));

  const [allowCheck, setAllowCheck] = useState<boolean>(false);

  const { pairExists, loading } = addLiquidityState;

  useEffect(() => {
    setLiquidityTypeF(LIQUIDITY_TYPES.ADD);
    setAllowCheck(true);
  }, []);

  useEffect(() => {
    if (type !== LIQUIDITY_TYPES.ADD && allowCheck) {
      setLiquidityTypeF(LIQUIDITY_TYPES.ADD);
    }
  }, [type]);

  return (
    <>
      {/* <FMigration /> */}
      <Container maxWidth="md">
        <Grid
          container
          alignItems="stretch"
          justifyContent={_md ? "center" : undefined}
        >
          <Grid item md={6} lg={6}>
            <AddLiquidity allowCheck={allowCheck} />
          </Grid>
          {!_md && (
            <Grid item md={6} lg={6}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                style={{ height: "100%" }}
              >
                <Grid item style={{ flexGrow: 4 / 4 }}>
                  <Container
                    disableGutters
                    maxWidth="sm"
                    style={{ height: "100%", paddingBottom: 24 }}
                  >
                    {feeTier ? (
                      tvlLoading || loading ? (
                        <Empty msg="" loading={true} />
                      ) : pairExists ? (
                        data ? (
                          <TvlGraph data={data} />
                        ) : (
                          <Empty msg="No liquidity data" />
                        )
                      ) : (
                        <Pricing />
                      )
                    ) : (
                      <Empty msg="Position information will appear here." />
                    )}
                  </Container>
                </Grid>
                <Grid item>
                  <Container
                    disableGutters
                    maxWidth="sm"
                    style={{ height: "100%" }}
                  >
                    <BoxContainer className={classes.right}>
                      {allowCheck && <ItemList loading={loading} />}
                    </BoxContainer>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Index;
