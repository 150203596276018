import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import useStyles from './styles';
import { WDialog, ButtonIndicator, ADialog } from '../';

const ConnectWalletBtn = () => {
  const { account } = useWeb3React();
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ADialog
        addr={account ? account : ''}
        Component={WDialog}
        open={open}
        close={() => setOpen(false)}
      />

      <ButtonIndicator
        onClick={() => setOpen(true)}
        className={classes.button}
        variant='contained'
        label={'Connect Wallet'}
        fullWidth
        color='primary'
      />
    </>
  );
};

export default ConnectWalletBtn;
