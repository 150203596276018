import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  conatiner: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.5)' : theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: '1.3rem',
    [theme.breakpoints.up('sm')]: {
      minHeight: '650px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1rem !important',
    },
    '& .MuiIconButton-root.MuiAccordionSummary-expandIcon': {
      color: theme.palette.primary.main,
    },
    '@media(max-width:770px)': {
      marginTop: '15px',
    },
  },
  farmHead: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  head: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      margin: '10px 0',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
        fontSize: '0.8rem',
      },
    },
    '& .MuiFormControlLabel-root': {
      margin: '0',
    },
  },
  marginTop: {
    marginTop: '10px',
  },
  dropSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      margin: '0 5px',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important',
        margin: '0',
      },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  searchBox: {
    background: '#000',
    color: '#fff',
    '& .MuiIconButton-root:hover, & .MuiIconButton-root:focus': {
      background: 'none',
    },
  },
  farmBody: {
    [theme.breakpoints.up('xs')]: {
      marginTop: '30px !important',
    },
    marginTop: '10px',
  },
  farmBodyHead: {
    display: 'flex',
    padding: '10px',
    marginTop: '20px',
    '& > div': {
      flex: '25% 0 0',
      paddingLeft: '15px',
    },
    '& p': {
      fontWeight: '500',
    },
  },
  bodyContent: {
    background: '#0D0F18',
    alignItems: 'center',
    margin: '10px 5px !important',
    borderRadius: '10px !important',
    border: '1px solid rgba(255,255,255,0.15)',
    [theme.breakpoints.up('sm')]: {
      minHeight: '75px',
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      margin: '0 !important',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'start',
        padding: '10px',
      },
    },
    '& .MuiAccordionSummary-content > div': {
      [theme.breakpoints.up('xs')]: {
        flex: '18% 0 0',
        padding: '15px 10px 10px 10px',
        minHeight: '50px',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderRadius: '10px 10px 0 0',
    },
    '& .MuiAccordionSummary-root': {
      [theme.breakpoints.down('xs')]: {
        padding: '7px',
      },
    },
    '& .MuiCollapse-root': {
      background: '#03050D',
      borderRadius: '0 0 10px 10px',
    },
    '& .MuiAccordionDetails-root': {
      [theme.breakpoints.up('xs')]: {
        padding: '8px 35px',
      },
    },
  },
  farmBodyContent: {
    '& .MuiAccordion-root:before': {
      display: 'none',
    },
  },
  parent: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '520px !important',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center !important',
      minHeight: '430px !important',
    },
    '& .react-swipeable-view-container > div': {
      overflow: 'hidden !important',
      width: '99.9% !important',
    },
  },
  parentEmpty: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '545px !important',
    },
  },
  heading: {
    color: 'rgba(255,255,255,0.4)',
    fontSize: '0.9em',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    // display: "flex",
    // [theme.breakpoints.down("xs")]: {
    // },
  },
  icon: {
    fontSize: '1rem',
    color: '#fff',
    marginLeft: '5px',
    height: '72px',
    '@media(max-width:550px)': {
      width: '40px',
    },
  },
  pilotIcon: {
    width: '70px',
    height: '72px',
    '@media(max-width:550px)': {
      width: '50px',
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  bodyDetails: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  bodyDetailsMainCol: {
    '& h5': {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('xs')]: {
      flex: '48% 0 0',
      padding: '10px',
    },
  },
  rewardCol: {
    margin: '10px 0',
    padding: '0 10px',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      minHeight: '68px',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid rgba(255,255,255,0.3)',
      background: '#0D0F18',
      borderRadius: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: '0px',
      '& > div:first-of-type': {
        border: '1px solid rgba(255,255,255,0.3)',
        background: '#0D0F18',
        marginBottom: '5px',
        borderRadius: '15px',
        minHeight: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  bottomCol: {
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '25px',
      padding: '0',
    },
  },
  estReward: {
    fontSize: '0.8rem',
    fontWeight: 600,
  },
  rewardCol1: {},
  rewardNum: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.6rem',
    margin: '0 5px',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rewardDV: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: '12px',
    marginLeft: '6px',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  rewardBtn: {
    background: theme.palette.primary.main,
    padding: '20px',
    textTransform: 'none',
    color: 'white',
    height: '30px !important',
    [theme.breakpoints.down('xs')]: {
      marginTop: '8px',
    },
  },
  nftCol: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  nftCol1: {
    border: '1px solid rgba(255,255,255,0.3)',
    background: '#0D0F18',
    borderRadius: '15px',
    minWidth: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: '75px',
      height: '40px',
    },
  },
  nftBtn: {
    background: theme.palette.primary.main,
    padding: '10px',
    width: '95%',
    fontWeight: 600,
    textTransform: 'none',
    margin: '0 10px',
  },
  liqHeading: {
    fontSize: '0.8rem',
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '5px',
  },
  liqValue: {
    fontSize: '1.5rem',
    fontWeight: 600,
    textAlign: 'center',
    //marginTop: '5px',
  },
  mobileOnly: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  mobileCol: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  none: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  switch: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  buttonBottom: {
    textTransform: 'none',
    color: 'white',
    padding: '10px',
    width: '95%',
    fontWeight: 600,
    margin: '0 10px',
  },
  unstakeBtn: {
    background: 'rgba(255,255,255,0.1)',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
    width: '100%',
  },
  super: {
    fontSize: '0.7rem',
    textTransform: 'uppercase',
    marginLeft: '3px',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  locked: {
    color: theme.palette.primary.main,
  },
  tokenDropdown: {
    '& .MuiInput-formControl': {
      // margin: "0 !important",
      // marginLeft: "10px",
      width: '115px',
      [theme.breakpoints.down('xs')]: {
        width: '60px',
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '1.5rem',
      fontWeight: '600',
      paddingRight: '0',
      // marginLeft: '-9px',
      position: 'relative',
      top: '5px !important',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        top: '2px !important',
      },
    },
    '& .MuiSelect-icon': {
      right: '15px',
      top: '20%',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        right: '-4px',
      },
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
  },
  rewardLoader: {
    width: '40px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lock: {
    fontSize: '14px',
    margin: '2px 5px 0 5px',
    color: theme.palette.primary.main,
  },
  tokenIdCol: {
    display: 'flex',
    justifyContent: 'space-between',
    // [theme.breakpoints.down("xs")]: {
    //   flexDirection: "column",
    // },
  },
  liqCol: {
    marginTop: '10px ',
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  liqColValue: {
    border: '1px solid rgba(255,255,255,0.3)',
    background: '#0D0F18',
    borderRadius: '15px',
    // width: '50%',
    padding: '10px',
    minHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    '& h5': {
      fontSize: '1.5rem',
      color: '$fff',
      fontWeight: 600,
    },
  },
  nftBtnCol: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: '0',
    },
    '& > div': {
      flex: '50%',
      [theme.breakpoints.down('xs')]: {
        marginTop: '10px',
      },
    },
    '& button': {
      margin: '0 !important',
      width: '98% !important',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important',
      },
    },
    '& button:last-of-type': {
      marginLeft: '5px !important',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0px !important',
      },
    },
  },
  addLiqMsg: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  modalBtn: {
    '& button': {
      background: theme.palette.primary.main,
    },
  },
  tooltipc: {
    backgroundColor: 'rgb(44, 47, 51)',
    padding: '10px',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '200px !important',
  },
  fontSize: {
    fontSize: '0.85rem',
    fontWeight: 500,
  },
  aprRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileTooltip: {
    width: 'auto',
  },
  positionLink: {
    marginTop: '5px',
    '& span': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
      fontSize: '14px',
    },
  },
  accumulated: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  Ico: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '5px',
    cursor: 'pointer',
  },
  nftInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
