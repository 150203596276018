import { IconButton } from '@material-ui/core';

import { RangeSymbol } from '../';

interface IReabseButton {
  inRange: boolean | undefined;
  onClick: any;
  closed?: boolean;
}

const ReabseButton: React.FC<IReabseButton> = ({ inRange, onClick, closed }) => {
  return (
    <IconButton
      style={{
        padding: 0,
        borderRadius: 20,
      }}
      onClick={inRange ? () => {} : onClick}
    >
      <RangeSymbol closed={closed} inRange={inRange} />
    </IconButton>
  );
};

export default ReabseButton;
