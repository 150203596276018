import { hypervisorContract } from '../getContract';
import { CALL_TYPE, MIGRATE_PROTOCOL, MIGRATE_TYPE } from '../../utils/enums';
import { HypervisorPoolReserves } from '../returnTypes';
import { DecodeType, multipleContractMultipleData, singleCall } from '../calls';
import { logError } from '../../utils/logs';
import { tokenDetail } from './erc20';
import { getPairTokenAmounts } from '../../utils/uniswapSDKFunctions';
import { formatAmount } from '../../utils/formating';
import { IMigrateLPToken } from '../../utils/generalTypes';

export const getTotalAmounts = async <T extends keyof HypervisorPoolReserves>(
  call: CALL_TYPE,
  hypervisorPoolAddresses: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'total0',
    },
    {
      type: 'uint256',
      name: 'total1',
    },
  ];
  if (call === CALL_TYPE.MULTI && outputs) {
    return await multipleContractMultipleData(
      [],
      hypervisorContract,
      hypervisorPoolAddresses,
      'getTotalAmounts',
      types,
      'multi-getTotalAmounts',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = hypervisorContract(hypervisorPoolAddresses[0]);
    return await singleCall([], contract?.methods.getTotalAmounts, 'single-getTotalAmounts');
  }
};

export const token0 = async (call: CALL_TYPE, hypervisorAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      hypervisorContract,
      hypervisorAddresses,
      'token0',
      types,
      'multi-hypervisor-token0'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = hypervisorContract(hypervisorAddresses[0]);
    return await singleCall([], contract?.methods.token0, 'single-hypervisor-token0');
  }
};

export const token1 = async (call: CALL_TYPE, hypervisorAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      hypervisorContract,
      hypervisorAddresses,
      'token1',
      types,
      'multi-hypervisor-token1'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = hypervisorContract(hypervisorAddresses[0]);
    return await singleCall([], contract?.methods.token1, 'single-hypervisor-token0');
  }
};

export const fee = async (call: CALL_TYPE, hypervisorAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'uint24',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      hypervisorContract,
      hypervisorAddresses,
      'fee',
      types,
      'multi-hypervisor-fee'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = hypervisorContract(hypervisorAddresses[0]);
    return await singleCall([], contract?.methods.fee, 'single-hypervisor-fee');
  }
};

export const getHypervisors = async (
  data: { id: string; balance: string; totalSupply: string }[],
  account: string
): Promise<IMigrateLPToken[] | undefined> => {
  try {
    const addresses = data.map(({ id }) => id);
    const token0s = await token0(CALL_TYPE.MULTI, addresses);
    const token1s = await token1(CALL_TYPE.MULTI, addresses);
    const fees = await fee(CALL_TYPE.MULTI, addresses);
    const totalAmounts = await getTotalAmounts(CALL_TYPE.MULTI, addresses, ['total0', 'total1']);
    const tokens = [...token0s, ...token1s];
    const tokenDetails = await tokenDetail(tokens, account);
    return addresses.map((address, index) => {
      const token0 = tokenDetails[token0s[index].toLowerCase()];
      const token1 = tokenDetails[token1s[index].toLowerCase()];
      const { balance, totalSupply } = data[index];
      const { total0, total1 } = totalAmounts[index];
      const { token0Reserveds, token1Reserveds } = getPairTokenAmounts(
        token0,
        token1,
        total0,
        total1,
        totalSupply,
        balance
      );
      return {
        id: address.toLowerCase(),
        token0,
        token1,
        pairAddress: address,
        liquidity: balance,
        totalSupply,
        fee: fees[index],
        protocol: MIGRATE_PROTOCOL.Visor,
        type: MIGRATE_TYPE.LP_TOKEN,
        token0Reserve: token0Reserveds.toSignificant(5),
        token1Reserve: token1Reserveds.toSignificant(5),
      };
    });
  } catch (e) {
    logError('getHypervisor', e);
  }
};
