import { FarmingTypes } from '../actionTypes';
import { Dispatch } from 'redux';

import { FarmingActions } from '../actions/farmingActions';
import {
  getPoolInfo,
  getUserLockedNfts,
  getPilotPerBlock,
} from '../../contracts/functions/farming';
import { CALL_TYPE } from '../../utils/enums';
import { formatAmount } from '../../utils/formating';
import { fetchFarmingPoolsTvl, fetchFarmingProtocolData, fetchPools } from '../../data';
import { IFarmingPool } from '../../utils/generalTypes';
import { typeCastSubgraphToken } from '../../utils/helpers';

export const getFarmingPools = (): ((dispatch: Dispatch<FarmingActions>) => void) => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    dispatch({
      type: FarmingTypes.GET_FARMING_POOLS,
      payload: { farmingPools: undefined, whiteListedPools: [] },
    });

    const farmingPools = (await fetchPools()) ?? [];
    const poolAddresses = farmingPools.map(({ id }) => id);
    const pilotPerBlock = await getPilotPerBlock();
    const poolInfo = await getPoolInfo(CALL_TYPE.MULTI, poolAddresses, [
      'globalReward',
      'totalLockedLiquidity',
      'lastRewardBlock',
      'rewardMultiplier',
      'rewardPerBlock',
    ]);

    if (poolInfo) {
      const pools = farmingPools.map((pool, index) => {
        const { rewardMultiplier, totalLockedLiquidity } = poolInfo[index];
        return {
          ...pool,
          totalSupply: totalLockedLiquidity,
          id: poolAddresses[index],
          token0: typeCastSubgraphToken(pool.token0),
          token1: typeCastSubgraphToken(pool.token1),
          rewardPerBlock: formatAmount(pilotPerBlock ?? '0', 18),
          multiplier: formatAmount(rewardMultiplier, 18),

          feeTier: pool.fee,
        };
      });
      dispatch({
        type: FarmingTypes.GET_FARMING_POOLS,
        payload: { farmingPools: pools, whiteListedPools: poolAddresses },
      });
    } else {
      dispatch({
        type: FarmingTypes.GET_FARMING_POOLS,
        payload: { farmingPools: [], whiteListedPools: [] },
      });
    }
  };
};

export const getUserLockedFarmingNfts = (
  farmingPools: IFarmingPool[] | undefined,
  account: string
) => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    dispatch({ type: FarmingTypes.CLEAR_USER_LOCKED_NFTS });
    const nfts = await getUserLockedNfts(farmingPools ?? [], account, () => {
      dispatch({ type: FarmingTypes.FARMING_MODAL, payload: true });
    });
    dispatch({ type: FarmingTypes.GET_USER_LOCKED_NFTS, payload: nfts });
  };
};

export const setLock = (value: boolean) => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    dispatch({ type: FarmingTypes.SET_LOCK, payload: value });
  };
};

export const updateFarmingNft = (poolAddress: string, tokenId: number, amount: number) => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    dispatch({
      type: FarmingTypes.UPDATE_LOCKED_NFT,
      payload: { poolAddress: poolAddress.toLowerCase(), tokenId, amount },
    });
  };
};

export const updateFarmingTvl = (poolAddresses: string[]) => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    const farmingTvl = await fetchFarmingPoolsTvl(poolAddresses);
    const farmingProtocol = await fetchFarmingProtocolData('1');

    if (farmingTvl && farmingProtocol) {
      const pools = farmingTvl.reduce((accum: { [key: string]: string }, farm) => {
        accum[farm.id] = farm.tvlTotal;
        return accum;
      }, {});
      dispatch({
        type: FarmingTypes.SET_FARMING_POOLS_TVL,
        payload: {
          farmingPoolsTvl: pools,
          tvl: farmingProtocol.total,
          reward: formatAmount(farmingProtocol.totalReward, 18),
        },
      });
    }
  };
};

export const updateSortBy = (value: keyof IFarmingPool) => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    dispatch({
      type: FarmingTypes.UPDATE_SORT_BY,
      payload: value,
    });
  };
};

export const closeFarmingInfoModal = () => {
  return async (dispatch: Dispatch<FarmingActions>) => {
    dispatch({
      type: FarmingTypes.FARMING_MODAL,
      payload: false,
    });
  };
};
