import React, { ErrorInfo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import styles from './styles';

type ErrorBoundaryState = {
  error: Error | null;
};

interface Props extends WithStyles<typeof styles> {}
class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // console.log('Error => ', error);
    // console.log('Error Info => ', errorInfo);
  }

  render() {
    const { error } = this.state;
    const { classes } = this.props;

    if (error !== null) {
      return (
        <Box className={classes.container}>
          <Typography className={classes.heading} variant='h5'>
            Something went wrong
          </Typography>
          <Box className={classes.errorBox}>
            <code>{error.stack}</code>
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
