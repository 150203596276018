import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import {
  AddLiquidity,
  IncreaseLiquidity,
  PairLiquidity,
  RemoveLiquidity,
  LiquidityPosition,
  Stats,
  Migrate,
  MigrateLiquidity,
  Import,
  Farming,
} from '../pages';
import { MainHeader, FarmingMigrationInfo, LegacyShift } from '../components';
import { useFarming, useLegacyShift } from '../hooks';

function Routes() {
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down('sm'));

  // const { farmingInfoModal, closeFarmingInfoModalF } = useFarming();
  const { isLegacy, nftExisted, onCloseLegacy } = useLegacyShift();

  return (
    <>
      <BrowserRouter>
        {/* Will add after v2 launch */}
        <LegacyShift open={isLegacy} close={onCloseLegacy} nftExisted={nftExisted} />
        {/* <FarmingMigrationInfo open={farmingInfoModal} close={closeFarmingInfoModalF} /> */}
        <Box
          style={{
            height: sm_ ? undefined : '10vh',
          }}
        >
          <MainHeader bottom={sm_} />
        </Box>
        <Box
          style={{
            minHeight: sm_ ? '100vh' : '90vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px 0px',
            marginBottom: sm_ ? 40 : undefined,
          }}
        >
          <Switch>
            <Route exact path='/' component={AddLiquidity} />
            <Route exact path='/positions' component={LiquidityPosition} />
            <Route exact path='/positions/:id' component={PairLiquidity} />
            <Route exact path='/remove-liquidity/:id' component={RemoveLiquidity} />
            <Route exact path='/increase-liquidity/:id' component={IncreaseLiquidity} />
            <Route exact path='/stats' component={Stats} />
            {/* <Route exact path="/migrate" component={Migrate} /> */}
            <Route exact path='/farming' component={Farming} />
            {/* <Route
              exact
              path="/migrate-liquidity/:id"
              component={MigrateLiquidity}
            />
            <Route exact path="/migrate/import-liquidity" component={Import} /> */}
            <Redirect to='/' />
          </Switch>
        </Box>
      </BrowserRouter>
    </>
  );
}

export default Routes;
