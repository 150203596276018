import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import {
  useTokenList,
  useLiquidity,
  useTransaction,
  useNFTPositions,
  useUpdateTokensBalance,
  useSnackbar,
  useApp,
  useStats,
  useMigrateLiquidity,
  useFarming,
} from '../hooks';
import { CHAIN_ID } from '../constants';

const Updater = () => {
  const { chainId, account } = useWeb3React();
  const { tokens, activeTokenList, getTokensF } = useTokenList();
  const { showSnackbarF } = useSnackbar();
  const { clearDataF } = useLiquidity();
  const { getTransactionsF } = useTransaction();
  const { getNFTPositionsF, updatePositionsF, nftPositions } = useNFTPositions();
  const { updatePilotPriceF, updateAppVersion } = useApp();
  const { poolsData, getProtocolDataF, getProtocolFeesF, getPoolsAPYF } = useStats();
  const updateTokensBalance = useUpdateTokensBalance();
  const { lpTokens, getMigrateLPF, getMigrateNFTF, updateMigrateNFTF, updateMigrateLpF } =
    useMigrateLiquidity();
  const {
    whiteListedPools,
    farmingPools,
    getFarmingPoolsF,
    getUserLockedFarmingNftsF,
    updateFarmingTvlF,
  } = useFarming();

  const [initialRender, setInitialRender] = useState<boolean>(false);

  const update = (account: string) => {
    getTransactionsF(account);
    getNFTPositionsF(account);
    getMigrateNFTF(account);
    getMigrateLPF(account);
  };

  useEffect(() => {
    getTokensF(activeTokenList.uri, CHAIN_ID, account);
  }, [chainId, activeTokenList, account]);

  useEffect(() => {
    clearDataF();
    if (account) {
      if (chainId === CHAIN_ID) {
        update(account);
      } else {
        update('');
        showSnackbarF('error', 'Unsupported network');
      }
    } else {
      initialRender && update('');
    }
  }, [chainId, account]);

  useEffect(() => {
    let tokenIds: number[] | null;
    if (nftPositions) {
      tokenIds = nftPositions.map(({ tokenId }) => tokenId);
    }

    const interval = setInterval(() => {
      updatePilotPriceF();
      updateTokensBalance();
      if (account) {
        tokenIds && updatePositionsF(tokenIds, account);
        updateMigrateNFTF(account);
        lpTokens.length > 0 && updateMigrateLpF(lpTokens, account);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [account, tokens, nftPositions, lpTokens, farmingPools]);

  useEffect(() => {
    if (chainId === CHAIN_ID) {
      getFarmingPoolsF();
    } else {
      getFarmingPoolsF();
    }
  }, [chainId]);

  useEffect(() => {
    updatePilotPriceF();
    updateAppVersion();
    getProtocolDataF();
    setInitialRender(true);
    getProtocolFeesF();
  }, []);

  useEffect(() => {
    if (account) {
      getUserLockedFarmingNftsF(farmingPools, account);
    }
  }, [farmingPools, account]);

  useEffect(() => {
    updateFarmingTvlF(whiteListedPools);
  }, [whiteListedPools]);

  useEffect(() => {
    if (poolsData) {
      const poolAddresses = poolsData.map(({ id }) => id.toLowerCase());
      getPoolsAPYF(poolAddresses);
    }
  }, [poolsData]);

  return null;
};

export default Updater;
