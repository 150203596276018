import { useState, useEffect } from 'react';
import { Box, Container, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';
import {
  ButtonIndicator,
  SelectToken,
  InputMax,
  ContainerSpaceBetween,
  DisableElements,
  CTooltip,
  RowAlignCenter,
  BoxContainer,
  ItemList,
  RebaseButton,
  RebasePopover,
  FeeTierSelection,
  InitialAmount,
  ConnectWalletButton,
  PoolAutoPilot,
} from '../../components';
import { addUnipilotLiquidity, createPoolAndDeposit } from '../../contracts/functions/unipilot';
import {
  getTotalAmounts,
  rebaseFrequency,
  rebase,
  liquidityPositions,
  shouldRebase,
} from '../../contracts/functions/liquidityManager';
import { getUniswapV3PoolAddress } from '../../contracts/functions/uniswapV3Factory';
import { slot0 } from '../../contracts/functions/pool';
import { getTokensDollarValue } from '../../redux';
import {
  useLiquidity,
  useLoading,
  useLiquidityParams,
  useNFTPositions,
  useLiquidityAllowance,
  useUpdateBalance,
  useTokenList,
  useTokenAllowance,
  useMaxPoolLiquidity,
  useUnipilotNFT,
} from '../../hooks';
import {
  formatAmount,
  trunc,
  exponentialToDecimal,
  toFixed,
  parseAmount,
  truncSymbol,
} from '../../utils/formating';
import { priceFromTick, getTokens, isValidTick } from '../../utils/uniswapSDKFunctions';
import { ZERO_ADDRESS, CHAIN_ID, DEFAULT_TOKEN } from '../../constants';
import { validAmount, calculateDollarValue } from '../../utils/helpers';
import { LIQUIDITY_TYPES, LIQUIDITY_TOKENS, CALL_TYPE, LOADING } from '../../utils/enums';
import { IToken } from '../../utils/generalTypes';

interface IAddLiquidity {
  allowCheck: boolean;
}

const AddLiquidity: React.FC<IAddLiquidity> = ({ allowCheck }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));
  const _md = useMediaQuery(theme.breakpoints.down('md'));
  const { account, chainId } = useWeb3React();

  const {
    type,
    token0,
    token1,
    feeTier,
    tick,
    initialAmount,
    amount0,
    amount1,
    amount0Reserves,
    amount1Reserves,
    sqrtPrice,
    addLiquidityState,
    disableAutoFeeTier,
    addDataF,
    updateAddLiquidityF,
    clearDataF,
  } = useLiquidity();

  const { dappLoading } = useLoading();
  const { getAddLiquidityParams, getCreatePoolWithLiquidityParams } = useLiquidityParams();
  const { checkAllowance } = useLiquidityAllowance();
  const { getNFTPositionsF } = useNFTPositions();
  const updateBalance = useUpdateBalance();
  const { tokens } = useTokenList();
  const { tokenAllowance, checkERC20TokenAllowance, giveTokenAllowance } = useTokenAllowance();
  const { selectMaxLiqFeeTeir } = useMaxPoolLiquidity();
  const { getUserNft } = useUnipilotNFT();

  const { loading, outOfRange, rebaseIncentive, rebaseFrequencyAllow, tokenId } = addLiquidityState;

  const [validBalance, setValidBalance] = useState<boolean>(true);
  const [feeTierloading, setFeeTierLoading] = useState<boolean>(false);
  const [currentPrice, setCurrentPrice] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dollarValues, setDollarVales] = useState({
    token0Dollar: 0,
    token1Dollar: 0,
  });

  const clearStates = () => {
    updateAddLiquidityF({ pairExists: false });
    setDollarVales({ token0Dollar: 0, token1Dollar: 0 });
  };

  const checkTokenAllowance = async () => {
    clearStates();
    updateAddLiquidityF({ outOfRange: false });
    if (token0 && token1) {
      await checkERC20TokenAllowance(token0, token1);
      const res = await getTokensDollarValue([token0.address, token1.address]);
      res &&
        setDollarVales({
          token0Dollar: res.token0DV,
          token1Dollar: res.token1DV,
        });
    }
  };

  const allowAdd = () => {
    if (!account) return true;
    if (token0 && token1 && chainId === CHAIN_ID) {
      if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_0) || tokenAllowance(LIQUIDITY_TOKENS.TOKEN_1))
        return true;
      else if (!validBalance) return false;
      else if (
        checkAllowance(LIQUIDITY_TOKENS.TOKEN_0) &&
        parseFloat(toFixed(amount0, token0.decimals)) > 0 &&
        amount1 === ''
      ) {
        return true;
      } else if (
        checkAllowance(LIQUIDITY_TOKENS.TOKEN_1) &&
        parseFloat(toFixed(amount1, token1.decimals)) > 0 &&
        amount0 === ''
      ) {
        return true;
      } else if (
        parseFloat(toFixed(amount0, token0.decimals)) > 0 &&
        parseFloat(toFixed(amount1, token1.decimals)) > 0
      ) {
        return true;
      } else if (validBalance && parseFloat(amount0) > 0 && parseFloat(amount1) > 0) return true;
      else return false;
    }
    return false;
  };

  const liquidityCallback = () => {
    clearDataF();
    getNFTPositionsF(account);
    clearStates();
    if (token0 && token1) {
      updateBalance(token0.address, token0.isETH ?? false);
      updateBalance(token1.address, token1.isETH ?? false);
    }

    history.push('/positions');
  };

  const addLiquidity = async () => {
    if (token0 && token1 && account) {
      if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_0)) {
        await giveTokenAllowance(token0, LIQUIDITY_TOKENS.TOKEN_0);
      } else if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_1)) {
        await giveTokenAllowance(token1, LIQUIDITY_TOKENS.TOKEN_1);
      } else if (!initialAmount && token0 && token1) {
        const params = getAddLiquidityParams();
        params &&
          (await addUnipilotLiquidity(
            params,
            feeTier,
            token0.symbol,
            token1.symbol,
            account ?? '',
            LIQUIDITY_TYPES.ADD,
            token0.isETH ? parseAmount(amount0, 18) : token1.isETH ? parseAmount(amount1, 18) : '0',
            () => {
              liquidityCallback();
            }
          ));
      } else if (initialAmount && token0 && token1) {
        const params = getCreatePoolWithLiquidityParams();
        params &&
          (await createPoolAndDeposit(
            params,
            feeTier,
            sqrtPrice,
            token0.symbol,
            token1.symbol,
            account,
            token0.isETH ? parseAmount(amount0, 18) : token1.isETH ? parseAmount(amount1, 18) : '0',
            () => {
              liquidityCallback();
            }
          ));
      }
    }
  };

  const showText = () => {
    if (account) {
      if (chainId !== CHAIN_ID)
        return `Change network to ${CHAIN_ID === 1 ? 'mainnet' : 'rinkeby'}`;
      if (dappLoading === LOADING.ADD_LIQUIDITY || dappLoading === LOADING.APPROVE)
        return dappLoading;
      else if (token0 && token1 && feeTier && !loading) {
        if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_0))
          return `Approve ${truncSymbol(token0.symbol, 10)}`;
        else if (tokenAllowance(LIQUIDITY_TOKENS.TOKEN_1))
          return `Approve ${truncSymbol(token1.symbol, 10)}`;
        else if (
          checkAllowance(LIQUIDITY_TOKENS.TOKEN_0) &&
          !(parseFloat(toFixed(amount0, token0.decimals)) > 0)
        )
          return 'Enter an amount';
        else if (
          checkAllowance(LIQUIDITY_TOKENS.TOKEN_1) &&
          !(parseFloat(toFixed(amount1, token1.decimals)) > 0)
        )
          return 'Enter an amount';
        else if (!validBalance) return 'Insufficient balance';
        else return 'Add liquidity';
      } else return 'Add liquidity';
    }
    return 'Connect Wallet';
  };

  const checkPairExistance = async () => {
    if (token0 && token1) {
      updateAddLiquidityF({
        loading: true,
        outOfRange: false,
        rebaseIncentive: false,
        rebaseFrequencyAllow: false,
      });
      const poolAddress = await getUniswapV3PoolAddress(token0.address, token1.address, feeTier);
      if (poolAddress && poolAddress !== ZERO_ADDRESS) {
        updateAddLiquidityF({ pairExists: true });
        const poolData = await getTotalAmounts(CALL_TYPE.SINGLE, [poolAddress]);
        const nftId = getUserNft(poolAddress);
        const poolDetails = await slot0(CALL_TYPE.SINGLE, [poolAddress]);

        updateAddLiquidityF({ tokenId: nftId ?? '0' });
        if (poolDetails) {
          const { tick: currentTick } = poolDetails;
          const { baseToken, quoteToken } = getTokens(token0, token1);
          currentTick && setCurrentPrice(priceFromTick(baseToken, quoteToken, Number(currentTick)));
          if (poolData && (parseFloat(poolData.amount0) > 0 || parseFloat(poolData.amount1) > 0)) {
            const { managed, baseTickLower, baseTickUpper } = await liquidityPositions(
              CALL_TYPE.SINGLE,
              [poolAddress],
              ['managed', 'baseTickLower', 'baseTickUpper']
            );
            const outOfRange = await shouldRebase(CALL_TYPE.SINGLE, [
              [poolAddress, Number(baseTickLower), Number(baseTickUpper)],
            ]);
            const rebaseIncentive = managed;

            updateAddLiquidityF({ rebaseIncentive, outOfRange });
            if (outOfRange) {
              const rebaseFrequencyAllow = await rebaseFrequency(poolAddress);
              updateAddLiquidityF({ rebaseFrequencyAllow });
            }

            addDataF({
              poolAddress,
              tick: '',
              amount0Reserves: poolData.amount0,
              amount1Reserves: poolData.amount1,
            });
          } else {
            addDataF({
              poolAddress,
              tick: currentTick,
              amount0Reserves: '',
              amount1Reserves: '',
            });
          }
        }
      } else {
        updateAddLiquidityF({ pairExists: false, tokenId: '0' });
        addDataF({
          poolAddress: '',
          tick: '',
          amount0Reserves: '',
          amount1Reserves: '',
          amount0: '',
          amount1: '',
          createPool: true,
        });
      }
      updateAddLiquidityF({ loading: false });
    }
  };

  const checkAmountValidity = (
    _token0: IToken | null = token0,
    _token1: IToken | null = token1
  ) => {
    if (_token0 && _token1) {
      let valid = true;
      if (checkAllowance(LIQUIDITY_TOKENS.TOKEN_0) && amount0 && !amount1) {
        valid = !validAmount(_token0, amount0);
      } else if (checkAllowance(LIQUIDITY_TOKENS.TOKEN_1) && amount1 && !amount0) {
        valid = !validAmount(_token1, amount1);
      } else if (amount0 && amount1) {
        valid = !validAmount(_token0, amount0) && !validAmount(_token1, amount1);
      }
      setValidBalance(valid);
    } else setValidBalance(true);
  };

  const maxLiquidityPool = async () => {
    if (token0 && token1 && !disableAutoFeeTier && feeTier === '') {
      setFeeTierLoading(true);
      addDataF({ feeTier: await selectMaxLiqFeeTeir(token0, token1) });
      setFeeTierLoading(false);
    }
  };

  const handleRebase = async () => {
    if (tokenId && token0 && token1 && account) {
      await rebase(Number(tokenId), token0, token1, feeTier, account, () => {
        updateAddLiquidityF({ outOfRange: false });
        setAnchorEl(null);
        checkPairExistance();
      });
    }
  };

  const handleSettingButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const balanceUpdate = () => {
    let _token0 = token0
      ? tokens.filter(token => token.address === token0.address && token.isETH === token0.isETH)[0]
      : null;
    let _token1 = token1
      ? tokens.filter(token => token.address === token1.address && token.isETH === token1.isETH)[0]
      : null;
    if (
      _token0 &&
      _token1 &&
      (_token0.balance !== token0?.balance || _token1.balance !== token1?.balance)
    ) {
      addDataF({
        token0: _token0,
        token1: _token1,
      });
      checkAmountValidity(_token0, _token1);
    }
  };

  useEffect(() => {
    if (allowCheck) {
      checkTokenAllowance();
      type === LIQUIDITY_TYPES.ADD && maxLiquidityPool();
      balanceUpdate();
    }
  }, [token0?.address, token1?.address, allowCheck]);

  useEffect(() => {
    if (token0 && token1 && feeTier && allowCheck) {
      checkPairExistance();
    }
  }, [token0, token1, feeTier]);

  useEffect(() => {
    allowCheck && checkAmountValidity();
  }, [amount0, amount1]);

  useEffect(() => {
    balanceUpdate();
  }, [tokens]);

  const balance_tooltip01 = token0?.balance
    ? `${formatAmount(token0?.balance, token0.decimals)} ${token0.symbol}`
    : '';

  const balance01 = token0?.balance
    ? `Balance: ${trunc(formatAmount(token0?.balance, token0.decimals))} ${truncSymbol(
        token0.symbol,
        10
      )}`
    : '';

  const balance_tooltip02 = token1?.balance
    ? `${formatAmount(token1?.balance, token1.decimals)} ${token1.symbol}`
    : '';

  const balance02 = token1?.balance
    ? `Balance: ${trunc(formatAmount(token1?.balance, token1.decimals))} ${truncSymbol(
        token1.symbol,
        10
      )}`
    : '';

  const token0DollarValue =
    dollarValues.token0Dollar && amount0
      ? calculateDollarValue(amount0, dollarValues.token0Dollar.toString()).toString()
      : '';

  const token1DollarValue =
    dollarValues.token1Dollar && amount1
      ? calculateDollarValue(amount1, dollarValues.token1Dollar.toString()).toString()
      : '';

  const { baseToken, quoteToken } = getTokens(token0 ?? DEFAULT_TOKEN, token1 ?? DEFAULT_TOKEN);

  return (
    <>
      <RebasePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        tokenId={tokenId.toString()}
        rebaseIncentive={rebaseIncentive}
        allowRebase={rebaseFrequencyAllow}
        //allowRebase={true}
        currentPrice={currentPrice}
        tokenMsg={`${baseToken?.symbol} per ${quoteToken?.symbol}`}
        pool={`${token0?.symbol}/${token1?.symbol}`}
        handleRebase={handleRebase}
      />
      <Container style={{ height: '100%' }} disableGutters={xs_} maxWidth='sm'>
        <BoxContainer className={classes.conatiner}>
          <RowAlignCenter
            elements01={<Typography variant='h6'>Select Pair</Typography>}
            elements02={
              // <RebaseButton inRange={false} onClick={handleSettingButton} />
              outOfRange ? (
                <RebaseButton inRange={false} onClick={handleSettingButton} />
              ) : rebaseIncentive ? (
                <PoolAutoPilot />
              ) : (
                <></>
              )
            }
          />

          <ContainerSpaceBetween
            xs01={6}
            xs02={6}
            className01={classes.marginTop}
            className02={classes.paddingRight5}
            className03={classes.paddingLeft5}
            Component01={<SelectToken selected={token0} type={LIQUIDITY_TOKENS.TOKEN_0} />}
            Component02={<SelectToken selected={token1} type={LIQUIDITY_TOKENS.TOKEN_1} />}
          />

          <Box style={{ marginTop: 30 }} />
          <DisableElements disabled={!(token0 && token1)}>
            <FeeTierSelection loading={feeTierloading} />
          </DisableElements>
          <Box className={classes.marginTop} />

          {_md && <InitialAmount />}

          <Box style={{ marginTop: 30 }} />

          <DisableElements disabled={!isValidTick(Number(tick), 2)}>
            <DisableElements
              disabled={
                !loading && initialAmount && parseFloat(initialAmount) > 0 && sqrtPrice
                  ? false
                  : !loading && tick
                  ? false
                  : !(feeTier && amount0Reserves && amount1Reserves)
              }
            >
              <DisableElements disabled={!checkAllowance(LIQUIDITY_TOKENS.TOKEN_0)}>
                <Box>
                  <ContainerSpaceBetween
                    className01={[classes.marginTop, classes.selectTokenInputMaxContainer].join(
                      ' '
                    )}
                    className02={classes.bottomSelect}
                    className03={classes.bottomInput}
                    Component01={
                      <SelectToken
                        viewOnly={true}
                        selected={token0 ?? null}
                        type={LIQUIDITY_TOKENS.TOKEN_0}
                      />
                    }
                    Component02={
                      <InputMax
                        className={classes.inputMaxContainer}
                        type={LIQUIDITY_TOKENS.TOKEN_0}
                      />
                    }
                  />
                  <Box className={classes.textMarginTop} />
                  <RowAlignCenter
                    containerClassName={classes.balanceHeight}
                    elements01={
                      <CTooltip title={balance_tooltip01}>
                        <Typography variant='caption'>{balance01}</Typography>
                      </CTooltip>
                    }
                    elements02={
                      token0DollarValue !== '' ? (
                        <CTooltip title={exponentialToDecimal(token0DollarValue.toString())}>
                          <Typography variant='caption'>{`~ $ ${trunc(
                            parseFloat(token0DollarValue)
                          )}`}</Typography>
                        </CTooltip>
                      ) : null
                    }
                  />
                </Box>
              </DisableElements>

              <Box mt={'10px'} />

              <DisableElements disabled={!checkAllowance(LIQUIDITY_TOKENS.TOKEN_1)}>
                <Box>
                  <ContainerSpaceBetween
                    className01={[classes.selectTokenInputMaxContainer].join(' ')}
                    className02={classes.bottomSelect}
                    className03={classes.bottomInput}
                    Component01={
                      <SelectToken
                        viewOnly={true}
                        selected={token1 ?? null}
                        type={LIQUIDITY_TOKENS.TOKEN_1}
                      />
                    }
                    Component02={
                      <InputMax
                        className={classes.inputMaxContainer}
                        type={LIQUIDITY_TOKENS.TOKEN_1}
                      />
                    }
                  />
                  <Box className={classes.textMarginTop} />
                  <RowAlignCenter
                    containerClassName={classes.balanceHeight}
                    elements01={
                      <CTooltip title={balance_tooltip02}>
                        <Typography variant='caption'>{balance02}</Typography>
                      </CTooltip>
                    }
                    elements02={
                      token1DollarValue !== '' ? (
                        <CTooltip title={exponentialToDecimal(token1DollarValue.toString())}>
                          <Typography variant='caption'>{`~ $ ${trunc(
                            parseFloat(token1DollarValue)
                          )}`}</Typography>
                        </CTooltip>
                      ) : null
                    }
                  />
                </Box>
              </DisableElements>

              <Box mt={'10px'} />
            </DisableElements>

            {_md && (
              <DisableElements disabled={!(token0 && token1 && feeTier && !loading)}>
                {allowCheck && <ItemList loading={loading} />}
                <Box className={classes.marginTop} />
              </DisableElements>
            )}

            <DisableElements
              disabled={!account ? false : !(token0 && token1 && feeTier && !loading)}
            >
              {account ? (
                <ButtonIndicator
                  disabled={
                    dappLoading === LOADING.ADD_LIQUIDITY || dappLoading === LOADING.APPROVE
                  }
                  onlyDisable={!allowAdd()}
                  onClick={addLiquidity}
                  disableElevation
                  className={classes.buttonBottom}
                  variant='contained'
                  label={showText()}
                  fullWidth
                  color='primary'
                />
              ) : (
                <ConnectWalletButton />
              )}
            </DisableElements>
          </DisableElements>
        </BoxContainer>
      </Container>
    </>
  );
};

export default AddLiquidity;
