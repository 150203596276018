import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  mainRoot: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '700px !important',
    },
  },
  listContainer: {
    marginTop: '0px',
  },
  wrapper: {
    position: 'relative',
  },
  parent: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '430px !important',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center !important',
      height: '430px !important',
      // overflowY: "auto",
    },
  },
  mainContainer: {
    display: 'flex',
    padding: '15px 10px',
    background: theme.palette.secondary.main,
    border: '1px solid rgba(255,255,255,0.4)',
    marginTop: '15px',
    borderRadius: '10px',
    width: '99.9% !important',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255,255,255,0.06)',
      transition: '0.5s',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& > div': {
      display: 'flex',
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
  },
  lpContainer: {
    width: '99.9% !important',
    padding: '20px',
    background: theme.palette.secondary.main,
    border: '1px solid rgba(255,255,255,0.4)',
    marginTop: '15px',
    borderRadius: '10px',
    minHeight: '72px',
    '&:hover': {
      background: 'rgba(255,255,255,0.06)',
      transition: '0.5s',
    },
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  head: {
    fontWeight: 700,
    fontSize: '0.9rem',
  },
  price: {
    fontSize: '0.8rem',
    margin: '5px',
    lineHeight: '1',
  },
  amount: {
    //lineHeight: '0',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  amountClass: {
    margin: '0 3px',
    fontSize: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
  },
  pool: {
    fontSize: '0.8rem',
  },
  range: {
    fontSize: '0.7em',
    color: '#67b631',
    fontWeight: 600,
  },
  mainApp: {
    '& .MuiGrid-item': {
      width: '100% !important',
    },
  },
  migrateApp: {
    // background: "transparent !important",
    color: 'rgba(255, 255, 255, 0.7) !important',
    '& button': {
      // width: "50%",
      // flex: "50%",
      background: 'transparent',
      borderBottom: '2px solid rgba(255, 255, 255, 0.4)',
      textTransform: 'none',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: theme.palette.primary.main,
      borderBottom: 'none !important',
    },
  },
  conatiner: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.5)' : theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      height: '700px',
    },
  },
  marginTop: {
    marginTop: 15,
  },
  buttonContainer: {
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.main,
    padding: '10px 15px',
    borderColor: 'transparent',
    marginTop: 10,
  },
  uri: {
    width: 15,
    height: 15,
  },
  buttonBottom: {
    textTransform: 'none',
    color: 'white',
    padding: '8px 0px',
  },
  dot: {
    width: 12,
    height: 12,
    backgroundColor: '#67b631',
    borderRadius: theme.shape.borderRadius,
    marginRight: 5,
    display: 'inline-block',
  },
  dotDanger: {
    backgroundColor: '#f44336 !important',
  },
  inRange: {
    color: '#67b631',
    textAlign: 'right',
  },
  outRange: {
    textAlign: 'right',
  },
  mobile: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  onXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  rangeClass: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rangeParent: {
    padding: '5px !important',
    width: '90px !important',
  },
  protocol: {
    // top: '0',
    // position: 'absolute',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
