import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logo: {
    // width: '25px',
    // height: '25px',
    // borderRadius: '12px',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    margin: '0px 5px',
  },
  doubleToken: {
    '& img': {
      margin: '0',
    },
  },
}));

export default useStyles;
