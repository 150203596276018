import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CGrid } from "../../components";

interface IRow {
  title: string;
  val?: null | undefined | string;
}

const GridRow: React.FC<IRow> = ({
  val = <Skeleton style={{ marginBottom: 5 }} width={30} height={30} />,
  title,
}) => {
  return (
    <CGrid
      alignItems="center"
      item02Props={{
        style: {
          alignItems: "center",
          justifyContent: "center",
          display: "flexs",
        },
      }}
      elements01={
        <Typography style={{ fontSize: "0.7rem" }}>{title}</Typography>
      }
      elements02={
        <Typography style={{ marginLeft: 10, fontSize: "0.7rem" }}>
          {val ? val : 0}
        </Typography>
      }
    />
  );
};

export default GridRow;
