import { TokenTypes } from '../actionTypes';
import { TokenAction } from '../actions/tokenActions';
import TokenList from '../../assets/tokenList.json';
import { ITokenList, IToken } from '../../utils/generalTypes';
import { CHAIN_ID, DEFAULT_TOKEN_LIST } from '../../constants';
import { sortObjectArray } from '../../utils/formating';

export interface TokenState {
  tokens: IToken[];
  tokenList: ITokenList[];
  activeTokenList: ITokenList;
}

let data;
const activeTokenList = JSON.parse(localStorage.getItem('activeToken') ?? '{}');
const customeTokenList = JSON.parse(localStorage.getItem('customTokenList') ?? '[]');
let customTokens = JSON.parse(localStorage.getItem('customTokens') ?? '[]');
customTokens = customTokens.filter((token: IToken) => token.chainId === CHAIN_ID);

const initialState: TokenState = {
  tokens: [],
  tokenList: sortObjectArray([...TokenList, ...customeTokenList], 'name') as ITokenList[],
  activeTokenList: activeTokenList.hasOwnProperty('name')
    ? activeTokenList
    : TokenList.find(list => list.name === DEFAULT_TOKEN_LIST),
};

const tokenReducer = (state: TokenState = initialState, action: TokenAction) => {
  switch (action.type) {
    case TokenTypes.ADD_ACTIVE_TOKEN_LIST:
      localStorage.setItem('activeToken', JSON.stringify(action.payload));
      return { ...state, activeTokenList: action.payload };

    case TokenTypes.ADD_TOKEN_LIST:
      const listExixts = state.tokenList.find(tokenList => tokenList.name === action.payload.name);
      if (listExixts) return state;
      else {
        data = JSON.parse(localStorage.getItem('customTokenList') ?? '[]');
        data = [...data, action.payload];
        localStorage.setItem('customTokenList', JSON.stringify(data));
        return { ...state, tokenList: [...state.tokenList, action.payload] };
      }

    case TokenTypes.ADD_TOKENS:
      return { ...state, tokens: sortObjectArray(action.payload.tokens, 'symbol') as IToken[] };

    case TokenTypes.ADD_BALANCE:
      const tempTokens = state.tokens;
      const index = state.tokens.findIndex(
        token => token.address === action.payload.address && token.isETH === action.payload.isETH
      );
      tempTokens[index] = { ...tempTokens[index], balance: action.payload.balance };
      return { ...state, tokens: [...tempTokens] };

    case TokenTypes.ADD_NEW_TOKEN:
      customTokens = [...customTokens, action.payload];
      data = [action.payload, ...state.tokens];
      localStorage.setItem('customTokens', JSON.stringify(customTokens));
      return { ...state, tokens: data };

    case TokenTypes.CLEAR_TOKENS:
      return { ...state, tokens: [] };

    default:
      return state;
  }
};

export default tokenReducer;
