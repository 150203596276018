import { useWeb3React } from '@web3-react/core';

import { injected, walletconnect, walletlink, fortmatic, portis } from '../../utils/web3Connectors';
import useStyles from './styles';
import WalletConnect from '../../assets/wallets/walletconnect.png';
import Coinbase from '../../assets/wallets/coinbase.png';
import Fortmatic from '../../assets/wallets/fortmatic.png';
import Portis from '../../assets/wallets/portis.png';
import InjectedIcon from '../IndentIcon';
import { UN_KNOWN_LOGO } from '../../constants';

const AccountIcon = () => {
  const { account, connector } = useWeb3React();
  const classes = useStyles();

  if (account) {
    if (connector === injected) {
      return <InjectedIcon />;
    } else if (connector === walletconnect) {
      return <img className={classes.accWalletIcon} src={WalletConnect} alt='account-icon' />;
    } else if (connector === walletlink) {
      return <img className={classes.accWalletIcon} src={Coinbase} alt='account-icon' />;
    } else if (connector === fortmatic) {
      return <img className={classes.accWalletIcon} src={Fortmatic} alt='account-icon' />;
    } else if (connector === portis) {
      return <img className={classes.accWalletIcon} src={Portis} alt='account-icon' />;
    } else {
      return <img className={classes.accWalletIcon} src={UN_KNOWN_LOGO} alt='account-icon' />;
    }
  }
  return null;
};

export default AccountIcon;
