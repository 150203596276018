import { useState, useEffect } from 'react';
import { Box, Container, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { Redirect, useParams, useHistory } from 'react-router';
import { BigNumber } from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

import {
  ButtonIndicator,
  Header,
  CDivider,
  RowAlignCenter,
  RowThreeContent,
  MultiButtons,
  TokenLogo,
  WDDialog,
  TokenSwitch,
  CSlider,
  PoolInfo,
} from '../../components';
import { useNFTPositions, useLoading, useUpdateBalance, useIsMetamask } from '../../hooks';
import useStyles from './styles';
import { removeLiquidity } from '../../contracts/functions/unipilot';
import { getPilotAmountForTokens, getPilotAmountWethPair } from '../../contracts/functions/oracle';
import { WETH_ADDRESS, WETH, ETH, CONTRACT_ADDRESSES, ZERO_ADDRESS } from '../../constants';
import {
  formatAmount,
  parseAmount,
  exponentialToDecimal,
  truncSymbol,
} from '../../utils/formating';
import { LOADING } from '../../utils/enums';
import { ILiquidity } from '../../utils/generalTypes';

const RemoveLiquidity: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { account } = useWeb3React();

  const { selectedNFTPosition, getNFTPositionsF, updateSelectedPositionF } = useNFTPositions();
  const { dappLoading } = useLoading();
  const updateBalance = useUpdateBalance();
  const isMetamask = useIsMetamask();

  const [open, setOpen] = useState<boolean>(false);
  const [inWeth, setInWeth] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [pilotToken, setPilotToken] = useState<string>('');
  const [tokenReserves, setTokenReserves] = useState<any>({
    token0Res: '',
    token1Res: '',
  });

  useEffect(() => {
    if (selectedNFTPosition) {
      calculateReserves();
    }
  }, [percentage, selectedNFTPosition]);

  if (!selectedNFTPosition || selectedNFTPosition?.tokenId.toString() !== id) {
    return <Redirect to={'/positions'} />;
  }

  const {
    token0,
    token1,
    fee,
    token0Reserve,
    token1Reserve,
    liquidity,
    tokenId,
    token0UserFees,
    token1UserFees,
    token0DollarValue,
    token1DollarValue,
    feesInPilot,
    oracle0,
    oracle1,
  } = selectedNFTPosition;

  let _token0 = token0.address === WETH_ADDRESS ? (inWeth ? WETH : ETH) : token0;
  let _token1 = token1.address === WETH_ADDRESS ? (inWeth ? WETH : ETH) : token1;

  const rmLiquidity = new BigNumber(formatAmount(liquidity, 18));

  const calculateReserves = () => {
    if (token0Reserve && token1Reserve) {
      let liqPer = new BigNumber(percentage).dividedBy(100);
      const token0Res = new BigNumber(token0Reserve).multipliedBy(liqPer).toString();
      const token1Res = new BigNumber(token1Reserve).multipliedBy(liqPer).toString();
      setTokenReserves({ token0Res, token1Res });
    }
  };

  const calculateTotalAmountDV = (token0Formated: string, token1Formated: string) => {
    const token0AmountDV = token0DollarValue
      ? new BigNumber(token0Formated).multipliedBy(new BigNumber(token0DollarValue))
      : null;
    const token1AmountDV = token1DollarValue
      ? new BigNumber(token1Formated).multipliedBy(new BigNumber(token1DollarValue))
      : null;
    return token0AmountDV && token1AmountDV
      ? exponentialToDecimal(token0AmountDV.plus(token1AmountDV).toString())
      : null;
  };

  const userPilotTokens = async () => {
    const _token0Fee = token0UserFees ? token0UserFees.toString() : '0';
    const _token1Fee = token1UserFees ? token1UserFees.toString() : '0';
    const altOracle = oracle0 === ZERO_ADDRESS ? oracle1 : oracle0;

    let pilotTokens;
    if (token0.address === WETH_ADDRESS) {
      pilotTokens = await getPilotAmountWethPair(
        token1.address,
        parseAmount(_token1Fee, token1.decimals),
        parseAmount(_token0Fee, token0.decimals),
        altOracle
      );
    } else if (token1.address === WETH_ADDRESS) {
      pilotTokens = await getPilotAmountWethPair(
        token0.address,
        parseAmount(_token0Fee, token0.decimals),
        parseAmount(_token1Fee, token1.decimals),
        altOracle
      );
    } else {
      pilotTokens = await getPilotAmountForTokens(
        token0.address,
        token1.address,
        parseAmount(_token0Fee, token0.decimals),
        parseAmount(_token1Fee, token1.decimals),
        oracle0,
        oracle1
      );
    }

    const rmUserFeeDV = calculateTotalAmountDV(_token0Fee, _token1Fee);

    updateSelectedPositionF({
      rmUserFee0: _token0Fee,
      rmUserFee1: _token1Fee,
      rmUserFeeDV,
    });
    if (pilotTokens) {
      return formatAmount(pilotTokens.toString(), 18);
    }
  };

  const removeLiq = async (withPilotToken: boolean = false) => {
    if (account) {
      const removeLiq = exponentialToDecimal(
        rmLiquidity.multipliedBy(percentage / 100).toString(),
        false
      );
      const params: ILiquidity['remove'] = [
        withPilotToken,
        inWeth,
        CONTRACT_ADDRESSES.liquidityManager,
        parseAmount(removeLiq, 18),
        tokenId.toString(),
      ];
      const addToken = withPilotToken && isMetamask;
      await removeLiquidity(
        params,
        tokenId.toString(),
        account,
        _token0.symbol,
        _token1.symbol,
        addToken,
        () => {
          getNFTPositionsF(account);
          updateBalance(token0.address, token0.isETH ?? false);
          updateBalance(token1.address, token1.isETH ?? false);
          history.replace('/positions');
        }
      );
    }
  };

  const handleRemove = async () => {
    const totalFee = new BigNumber(token0UserFees ?? '0').plus(token1UserFees ?? '0');
    if (totalFee.isGreaterThan(0)) {
      const pilotTokens = feesInPilot && (await userPilotTokens());
      pilotTokens && setPilotToken(pilotTokens);
      setOpen(true);
    } else {
      await removeLiq();
    }
  };

  const handleSelect = (value: number) => {
    setPercentage(value);
  };

  return (
    <>
      <WDDialog
        open={open}
        close={() => setOpen(false)}
        modalType={'Remove'}
        action={removeLiq}
        pilotToken={pilotToken}
        defaultInWETH={inWeth}
      />
      <Container maxWidth='xs'>
        <Box className={classes.conatiner}>
          <Header
            elements={
              <Typography style={{ marginTop: 8 }} variant='h5'>
                Remove Liquidity
              </Typography>
            }
          />
          <Box className={classes.marginTop} />
          <Box
            style={{
              padding: '0 15px',
            }}
          >
            <PoolInfo
              token0Address={token0.address}
              token1Address={token1.address}
              token0Symbol={token0.symbol}
              token1Symbol={token1.symbol}
              token0Uri={token0.logoURI}
              token1Uri={token1.logoURI}
              feeTier={fee}
            />

            <Box className={classes.marginTop} />

            <RowAlignCenter
              elements01={<Typography variant='h6'>Amount</Typography>}
              elements02={<Typography variant='h4'>{`${percentage}%`}</Typography>}
            />

            <Box className={classes.marginTop} />

            <Divider />
            <Box className={classes.marginTop} />

            <Grid container direction='column' spacing={1}>
              <Grid item>
                <CSlider amount={percentage} setAmount={setPercentage} />
              </Grid>
            </Grid>
            <Box className={classes.marginTop} />

            <MultiButtons selected={Number(percentage)} onSelect={handleSelect} />

            <Box className={classes.marginTop} />
            <RowThreeContent
              iconWithStr={
                <Grid container alignItems='center' spacing={1}>
                  <Grid item>
                    <TokenLogo tokenAddress={_token0.address} uri={_token0.logoURI} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.buttonContent}>
                      {truncSymbol(_token0.symbol, 8)}
                    </Typography>
                  </Grid>
                </Grid>
              }
              amount={tokenReserves.token0Res ?? '_'}
            />

            <CDivider />
            <RowThreeContent
              iconWithStr={
                <Grid container alignItems='center' spacing={1}>
                  <Grid item>
                    <TokenLogo tokenAddress={_token1.address} uri={_token1.logoURI} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.buttonContent}>
                      {truncSymbol(_token1.symbol, 8)}
                    </Typography>
                  </Grid>
                </Grid>
              }
              amount={tokenReserves.token1Res ?? '_'}
            />

            {(token0.address === WETH_ADDRESS || token1.address === WETH_ADDRESS) && (
              <RowAlignCenter
                elements01={<Typography variant='body2'>Collect as WETH</Typography>}
                elements02={
                  <TokenSwitch
                    value0='On'
                    value1='Off'
                    baseValue={inWeth}
                    onChange={(value: boolean) => setInWeth(value)}
                  />
                }
              />
            )}

            <Box className={classes.marginTop} />

            <ButtonIndicator
              disabled={dappLoading === `${LOADING.REMOVE_LIQUIDITY}-${tokenId}`}
              onlyDisable={percentage === 0}
              onClick={handleRemove}
              disableElevation
              className={classes.buttonBottom}
              variant='contained'
              label={percentage === 0 ? 'Enter a percent' : 'Remove'}
              fullWidth
              color='primary'
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RemoveLiquidity;
