import { PROTOCOL_TVL_FEE } from '../apollo/queries';
import { unipilotClient as client } from '../apollo/client';
import { ISubgraphToken } from '../utils/generalTypes';
import { typeCastSubgraphToken } from '../utils/helpers';
import { logError } from '../utils/logs';

interface ProtocolPool {
  id: string;
  ulmTvlUSD: string;
  ulmFeesUSD: string;
  apy: string;
  rebaseTimestamp: string;
  uniswapPool: {
    token0: ISubgraphToken;
    token1: ISubgraphToken;
    feeTier: string;
    volumeUSD: string;
  };
}
interface ProtocolData {
  uniPools: ProtocolPool[];
}

export const fetchProtocolData = async () => {
  let data: ProtocolPool[] = [];

  let error = false;
  let skip = 0;
  let allFound = false;

  try {
    while (!allFound) {
      const {
        data: resData,
        error,
        loading,
      } = await client.query<ProtocolData>({
        query: PROTOCOL_TVL_FEE,
        variables: {
          skip,
        },
        fetchPolicy: 'cache-first',
      });

      if (!loading) {
        skip += 1000;
        if (resData.uniPools.length < 1000 || error) {
          allFound = true;
        }
        if (resData) {
          data = data.concat(resData.uniPools);
        }
      }
    }
  } catch (e) {
    error = true;
    logError('fetchProtocolData', e);
  }

  if (data && data.length > 0) {
    const formatedData = data.map(item => {
      return {
        ...item,
        uniswapPool: {
          ...item.uniswapPool,
          token0: typeCastSubgraphToken(item.uniswapPool.token0),
          token1: typeCastSubgraphToken(item.uniswapPool.token1),
        },
      };
    });
    return { data: formatedData, error: false };
  } else return { data: undefined, error };
};
