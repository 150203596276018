import { useMemo, useRef, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { isMobile } from "react-device-detect";
import HelpOutline from "@material-ui/icons/HelpOutline";

import {
  DollarDrop,
  LiquidityChart,
  RowAlignCenter,
  BarChart,
  ButtonIndicator,
  PoolInfo,
  WithPagination,
  Dropdown,
  CTooltip,
} from "../../components";
import Total from "./Total";
import TotalWithInfo from "./TotalWIthInfo";
import Pool from "./Pools";
import useStyles from "./styles";
import {
  useStats,
  useSelectedPoolData,
  useTopPools,
  useRedirectAddLiquidity,
  useFarming,
} from "../../hooks";
import { formatDollarAmount, trunc } from "../../utils/formating";
import { IPoolData, IUniswapPoolData } from "../../utils/generalTypes";
import { STATS_DROPDOWN } from "../../utils/enums";

dayjs.extend(utc);

const sortOptions: {
  label: string;
  value: keyof IPoolData | keyof IUniswapPoolData;
}[] = [
  { label: "TVL", value: "ulmTvlUSD" },
  { label: "Volume", value: "volumeUSD" },
  { label: "Fees", value: "ulmFeesUSD" },
  { label: "APR", value: "apy" },
];

const Stats = () => {
  const classes = useStyles();

  const {
    poolsData,
    protocolTvl,
    totalProtocolFees,
    selectedPool,
    poolFees,
    poolApy,
  } = useStats();
  const { topPools, sortBy, updateSortByF } = useTopPools();
  const { data, volumeData } = useSelectedPoolData();
  const redirectToAddLiquidity = useRedirectAddLiquidity();
  const { tvl: farmingTvl } = useFarming();

  const [page, setPage] = useState<number>(0);
  const volumeRef = useRef<HTMLSpanElement>(null);
  const timeRef = useRef<HTMLSpanElement>(null);

  // const formatedData = useMemo<number[][]>(() => {
  //   if (data) {
  //     return data.map(({ date, volumeUSD }) => [date * 1000, volumeUSD]);
  //   } else return [];
  // }, [data]);

  const handleChangeDropdDown = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    updateSortByF(event.target.value as STATS_DROPDOWN);
    //setPage(0);
  };

  const formatedData = useMemo<number[][]>(() => {
    if (volumeData) {
      return volumeData.map(({ date, volumeUSD }) => [date * 1000, volumeUSD]);
    } else return [];
  }, [volumeData]);

  // const currentVolume = useMemo<string>(() => {
  //   if (data) {
  //     return formatDollarAmount(data[data.length - 1].volumeUSD);
  //   } else return '0';
  // }, [data]);

  const currentVolume = useMemo<string>(() => {
    if (volumeData) {
      return formatDollarAmount(volumeData[volumeData.length - 1].volumeUSD);
    } else return "0";
  }, [volumeData]);

  const selectedPoolFees = useMemo(() => {
    if (selectedPool && poolFees) {
      const fees = poolFees[selectedPool.id];
      return fees ? fees.total : "";
    }
  }, [selectedPool, poolFees]);

  const selectedPoolApr = useMemo(() => {
    if (selectedPool && poolApy) {
      const apr = poolApy[selectedPool.id];
      return apr ? apr : "";
    }
  }, [selectedPool, poolApy]);

  return (
    <Container className={classes.mainContainer} maxWidth="md">
      <Grid container spacing={2}>
        <Grid style={{ width: "100%" }} item md={6}>
          <TotalWithInfo
            loading={protocolTvl === undefined || farmingTvl === undefined}
            text="TVL"
            amount={
              protocolTvl && farmingTvl
                ? formatDollarAmount(
                    parseFloat(protocolTvl) + parseFloat(farmingTvl)
                  )
                : "---"
            }
            subValue1={
              protocolTvl
                ? formatDollarAmount(parseFloat(protocolTvl), 2)
                : "---"
            }
            subValue2={
              farmingTvl ? formatDollarAmount(parseFloat(farmingTvl), 2) : "---"
            }
            icon={<LiquidityChart className={classes.icon} />}
          />
        </Grid>
        <Grid style={{ width: "100%" }} item md={6}>
          <Total
            loading={totalProtocolFees === undefined}
            text="Total Fees Accumulated"
            //amount={'---'}
            amount={
              totalProtocolFees
                ? formatDollarAmount(parseFloat(totalProtocolFees))
                : "---"
            }
            icon={<DollarDrop className={classes.icon} />}
          />
        </Grid>
      </Grid>

      <Box className={classes.marginTop} />

      <Grid alignItems="stretch" container spacing={2}>
        <Grid style={{ width: "100%" }} item md={6}>
          <Box
            style={{ height: "100%" }}
            className={`${classes.container} ${classes.topPools}`}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography style={{ fontSize: "1.1rem" }} variant="body2">
                  Top Pools
                </Typography>
              </Grid>
              <Grid item>
                <Dropdown
                  title="Sort by"
                  value={sortBy}
                  handleChange={handleChangeDropdDown}
                  options={sortOptions}
                />
              </Grid>
            </Grid>

            <Box className={classes.marginTop} />
            {topPools ? (
              <WithPagination
                containerClassName={classes.poolsContainer}
                // nothingContainerClassName={classes.parent}
                rowsPerPage={isMobile ? 10 : 8}
                page={page}
                setPage={setPage}
                items={topPools ?? []}
                func={(pool: IPoolData) => {
                  return <Pool key={pool.id} poolData={pool} />;
                }}
              />
            ) : (
              <Box
                style={{
                  height: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={15} />
              </Box>
            )}
            {/* {poolsData ? (
              topPools.map(pool => <Pool key={pool.id} poolData={pool} />)
            ) : (
              <Box
                style={{
                  height: '90%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={15} />
              </Box>
            )} */}
          </Box>
        </Grid>
        <Grid style={{ width: "100%" }} item md={6}>
          <Box className={`${classes.container} ${classes.graph}`}>
            {selectedPool ? (
              <>
                <RowAlignCenter
                  containerClassName={classes.graphDetailsTop}
                  elements01={
                    <PoolInfo
                      token0Address={selectedPool.uniswapPool.token0.address}
                      token1Address={selectedPool.uniswapPool.token1.address}
                      token0Symbol={selectedPool.uniswapPool.token0.symbol}
                      token1Symbol={selectedPool.uniswapPool.token1.symbol}
                      token0Uri={selectedPool.uniswapPool.token0.logoURI}
                      token1Uri={selectedPool.uniswapPool.token1.logoURI}
                      feeTier={selectedPool.uniswapPool.feeTier}
                    />
                  }
                  elements02={
                    selectedPool ? (
                      <ButtonIndicator
                        onClick={() =>
                          redirectToAddLiquidity(
                            selectedPool?.uniswapPool.token0,
                            selectedPool?.uniswapPool.token1,
                            selectedPool?.uniswapPool.feeTier,
                            selectedPool?.id
                          )
                        }
                        style={{ color: "white" }}
                        disableElevation
                        className={classes.buttonsTop}
                        variant="contained"
                        label="Add liquidity"
                        fullWidth
                        color="primary"
                      />
                    ) : null
                  }
                />

                {/* <Box>Coming soon</Box> */}
                <RowAlignCenter
                  containerClassName={classes.graphDetails}
                  elements01={
                    <Typography className={classes.heading} variant="body2">
                      Uniswap V3 Volume
                    </Typography>
                  }
                  elements02={
                    <Typography
                      ref={volumeRef}
                      className={classes.amount}
                      variant="h5"
                    >
                      {currentVolume}
                    </Typography>
                  }
                />
                <Box className={classes.volumeTime}>
                  <Typography ref={timeRef} variant="body2"></Typography>
                </Box>
                <BarChart
                  data={formatedData}
                  defaultValue={currentVolume}
                  valueRef={volumeRef}
                  labelRef={timeRef}
                />
              </>
            ) : (
              <Box className={classes.statsGraph}>
                {selectedPool === undefined && <CircularProgress size={15} />}
              </Box>
            )}
          </Box>

          <Box className={classes.marginTop} />

          <Box
            className={`${classes.container} ${classes.marginTop}`}
            style={{ marginTop: "20px" }}
          >
            <Box className={classes.infoContainer}>
              <BoxInfo
                text="APR"
                // value={selectedPoolApr ? `${trunc(parseFloat(selectedPoolApr))}%` : '--'}
                value={"---"}
                loading={selectedPoolApr === undefined}
                style={{ marginBottom: "15px" }}
                //showInfo
              />

              <BoxInfo
                text="Last Rebased"
                value={
                  selectedPool && Number(selectedPool.rebaseTimestamp) > 0
                    ? dayjs(Number(selectedPool.rebaseTimestamp) * 1000).format(
                        "MMM D, YYYY"
                      )
                    : "--"
                }
                loading={selectedPool === undefined}
                style={{ marginBottom: "15px" }}
              />
              <BoxInfo
                text="Fees Generated"
                //value={'---'}
                value={
                  selectedPoolFees
                    ? formatDollarAmount(parseFloat(selectedPoolFees))
                    : "--"
                }
                loading={selectedPoolFees === undefined}
              />
              <BoxInfo
                text="TVL"
                value={
                  selectedPool
                    ? formatDollarAmount(parseFloat(selectedPool.ulmTvlUSD))
                    : "--"
                }
                loading={selectedPool === undefined}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

interface IBoxInfo {
  text: string;
  value: string;
  loading?: boolean;
  showInfo?: boolean;
  style?: CSSProperties;
}

const BoxInfo: React.FC<IBoxInfo> = ({
  text,
  value,
  style,
  loading = false,
  showInfo = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.info} style={style}>
      <Typography variant="body2">{text}</Typography>
      {loading ? (
        <CircularProgress style={{ marginTop: "3px" }} size={15} />
      ) : (
        <Box style={{ display: "flex" }}>
          <Typography variant="h5">{value}</Typography>
          {showInfo && (
            <Box style={{ marginLeft: "5px" }}>
              <CTooltip title="The values might take upto 24hrs to render due to subgraph syncing">
                <HelpOutline />
              </CTooltip>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Stats;
