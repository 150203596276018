import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { isMobile } from 'react-device-detect';

import {
  CHAIN_ID,
  NETWORK_URL,
  NETWORK_URL1,
  NETWORK_URL4,
  FORTMATIC_KEY,
  PORTIS_KEY,
} from '../constants';

const POLLING_INTERVAL = 12000;

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: NETWORK_URL1, 4: NETWORK_URL4 },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Unipilot',
});

export const fortmatic = new FortmaticConnector({
  apiKey: FORTMATIC_KEY,
  //chainId: 1,
  chainId: CHAIN_ID,
});

export const portis = new PortisConnector({
  dAppId: PORTIS_KEY,
  networks: [CHAIN_ID],
});

export const injectedConnectorList = [
  {
    name: 'Metamask',
    connector: injected,
    connectorType: InjectedConnector,
  },
  {
    name: 'Injected',
    connector: injected,
    connectorType: InjectedConnector,
  },
];

export const desktopWalletList = [
  {
    name: 'Metamask',
    connector: injected,
    connectorType: InjectedConnector,
  },
  {
    name: 'WalletConnect',
    connector: walletconnect,
    connectorType: WalletConnectConnector,
  },
  {
    name: 'Coinbase',
    connector: walletlink,
    connectorType: WalletLinkConnector,
  },
  {
    name: 'Fortmatic',
    connector: fortmatic,
    connectorType: FortmaticConnector,
  },
  {
    name: 'Portis',
    connector: portis,
    connectorType: PortisConnector,
  },
];

export const mobileWalletList = [
  {
    name: 'WalletConnect',
    connector: walletconnect,
    connectorType: WalletConnectConnector,
  },

  {
    name: 'Fortmatic',
    connector: fortmatic,
    connectorType: FortmaticConnector,
  },
  {
    name: 'Portis',
    connector: portis,
    connectorType: PortisConnector,
  },
];

export const walletList = isMobile
  ? window?.ethereum
    ? [window?.ethereum?.isMetaMask ? injectedConnectorList[0] : injectedConnectorList[1]]
    : mobileWalletList
  : desktopWalletList;
