import { liquidityManagerContract } from '../getContract';
import { logError } from '../../utils/logs';
import { CONTRACT_ADDRESSES } from '../../constants';
import { IToken } from '../../utils/generalTypes';
import { sendTransaction } from '../sendTransaction';
import {
  singleContractMultipleData,
  _singleContractMultipleData,
  singleCall,
  DecodeType,
} from '../calls';
import { showModal } from '../../redux';
import { CALL_TYPE, LOADING } from '../../utils/enums';
import { TotalAmount, LiquidityPositions, Rebase } from '../returnTypes';

export const shouldRebase = async <T extends keyof Rebase>(
  call: CALL_TYPE,
  inputs: [string, number, number][],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'bool',
      name: 'readjust',
    },
  ];
  const contract = liquidityManagerContract();
  const method = contract?.methods.shouldReadjust;
  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      inputs,
      CONTRACT_ADDRESSES.liquidityManager,
      method,
      types,
      'multi-shouldRebase',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(inputs[0], method, 'single-shouldRebase');
  }
};

export const rebase = async (
  tokenId: number,
  token0: IToken,
  token1: IToken,
  fee: string,
  walletAddress: string,
  callback: any = () => {}
) => {
  try {
    // console.log('tokenId => ', tokenId);
    // console.log('token0 => ', token0);
    // console.log('token1 => ', token1);
    // console.log('fee => ', fee);
    const contract = liquidityManagerContract();
    const contractFnc = contract?.methods.readjustLiquidity(token0.address, token1.address, fee);
    const args = {
      walletAddress,
      txnMessage: `Rebase ${token0.symbol}/${token1.symbol}`,
      logMessage: 'rebase',
      dappLoading: `${LOADING.REBASE}-${tokenId}`,
      callback,
    };
    contractFnc
      ?.estimateGas({ from: walletAddress })
      .then(async () => {
        if (contractFnc) {
          await sendTransaction(contractFnc, args);
        }
      })
      .catch(error => {
        logError('rebase', error);
        showModal('exception', { message: 'Pool is too volatile to rebase' });
      });
  } catch (e) {
    logError('rebase', e);
  }
};

export const rebaseFrequency = async (poolAddress: string) => {
  try {
    const contract = liquidityManagerContract();
    const rebase = await contract?.methods.readjustFrequencyStatus(poolAddress).call();
    if (!rebase) {
      return !rebase;
    } else {
      return false;
    }
  } catch (e) {
    logError('rebaseFrequency', e);
    return false;
  }
};

export const getTotalAmounts = async <T extends keyof TotalAmount>(
  call: CALL_TYPE,
  poolAddresses: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'amount0',
    },
    {
      type: 'uint256',
      name: 'amount1',
    },
    {
      type: 'uint256',
      name: 'totalLiquidity',
    },
  ];

  const contract = liquidityManagerContract();
  const method = contract?.methods.updatePositionTotalAmounts;
  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      poolAddresses,
      CONTRACT_ADDRESSES.liquidityManager,
      method,
      types,
      'multi-totalAmount',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(poolAddresses[0], method, 'single-totalAmount');
  }
};

export const liquidityPositions = async <T extends keyof LiquidityPositions>(
  call: CALL_TYPE,
  poolAddresses: string[],
  outputs?: T[]
) => {
  const types: any = [
    {
      components: [
        {
          type: 'int24',
          name: 'baseTickLower',
        },
        {
          type: 'int24',
          name: 'baseTickUpper',
        },
        {
          type: 'uint128',
          name: 'baseLiquidity',
        },
        {
          type: 'int24',
          name: 'rangeTickLower',
        },
        {
          type: 'int24',
          name: 'rangeTickUpper',
        },
        {
          type: 'uint128',
          name: 'rangeLiquidity',
        },
        {
          type: 'uint256',
          name: 'fees0',
        },
        {
          type: 'uint256',
          name: 'fees1',
        },
        {
          type: 'uint256',
          name: 'feeGrowthGlobal0',
        },
        {
          type: 'uint256',
          name: 'feeGrowthGlobal1',
        },
        {
          type: 'uint256',
          name: 'totalLiquidity',
        },
        {
          type: 'bool',
          name: 'feesInPilot',
        },
        {
          type: 'address',
          name: 'oracle0',
        },
        {
          type: 'address',
          name: 'oracle1',
        },
        {
          type: 'uint256',
          name: 'timestamp',
        },
        {
          type: 'uint8',
          name: 'counter',
        },
        {
          type: 'bool',
          name: 'status',
        },
        {
          type: 'bool',
          name: 'managed',
        },
      ],
    },
  ];
  const contract = liquidityManagerContract();
  const method = contract?.methods.poolPositions;
  if (call === CALL_TYPE.MULTI && outputs) {
    return await _singleContractMultipleData(
      poolAddresses,
      CONTRACT_ADDRESSES.liquidityManager,
      method,
      types,
      'multi-liquidityPositions',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(poolAddresses[0], method, 'single-liquidityPositions');
  }
};

export const colletFee = async (tokenId: number, blockNumber?: number) => {
  try {
    const activeArchiveNode = blockNumber ? true : false;
    const contract = liquidityManagerContract(activeArchiveNode);
    const fees = blockNumber
      ? await contract?.methods.getUserFees(tokenId).call({}, blockNumber)
      : await contract?.methods.getUserFees(tokenId).call();

    if (fees) {
      return { token0Fee: Number(fees.fees0), token1Fee: Number(fees.fees1) };
    } else {
      return { token0Fee: 0, token1Fee: 0 };
    }
  } catch (e) {
    logError('colletFee', e);
    return { token0Fee: 0, token1Fee: 0 };
  }
};
