import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';

import { ButtonIndicator, DisableElements } from '../';
import useStyles from './styles';
import { useLiquidity } from '../../hooks';

const GroupButton: React.FC<{}> = () => {
  const classes = useStyles();

  const {
    feeTier,
    addDataF,
    addLiquidityState: { loading },
  } = useLiquidity();

  const vals = [
    {
      fee: '0.01% fee',
      value: '100',
      caption: 'Best for very stable pairs',
      class: classes.radiusStart,
    },
    {
      fee: '0.05% fee',
      value: '500',
      caption: 'Best for stable pairs',
      class: classes.radiusZero,
      //class: classes.radiusStart,
    },
    {
      fee: '0.3% fee',
      value: '3000',
      caption: 'Best for most pairs',
      class: classes.radiusZero,
    },
    {
      fee: '1% fee',
      value: '10000',
      caption: 'Best for exotic pairs',
      class: classes.radiusEnd,
    },
  ];

  const handleFeeTierSelection = (fee: string) => {
    if (fee !== feeTier) {
      addDataF({
        feeTier: fee,
        sqrtPrice: '',
        tick: '',
        amount0Reserves: '',
        amount1Reserves: '',
        initialAmount: '',
        createPool: false,
      });
    }
  };

  return (
    <>
      <ButtonGroup
        fullWidth
        variant='contained'
        color='primary'
        aria-label='contained  button group'
        disableElevation
      >
        <Grid container alignItems='stretch'>
          {vals.map(val => (
            <Grid key={val.value} item xs={3}>
              <DisableElements
                className={classes.disableWrapper}
                disabled={loading && feeTier !== val.value}
              >
                <Button
                  color='primary'
                  variant='outlined'
                  disableRipple
                  fullWidth
                  onClick={() => handleFeeTierSelection(val.value)}
                  classes={{ root: val.class }}
                  className={feeTier === val.value ? classes.selected : classes.notSelected}
                >
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className={classes.mainTitle} variant='subtitle1'>
                        {val.fee}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subTitle}>{val.caption}</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </DisableElements>
            </Grid>
          ))}
        </Grid>
      </ButtonGroup>
    </>
  );
};

export default GroupButton;

const texts = [25, 50, 75, 100];
interface IMultiButtonsProps {
  selected: number;
  onSelect: (value: number) => void;
}

export const MultiButtons: React.FC<IMultiButtonsProps> = ({ selected, onSelect }) => {
  const classes = useStyles();

  const buttons = texts.map((text, index) => (
    <Grid key={index} item xs={3}>
      <ButtonIndicator
        disableElevation
        className={selected === text ? classes.multiButtonSelected : classes.multiButtonUnselected}
        variant='outlined'
        label={`${text}%`}
        fullWidth
        color='primary'
        onClick={() => {
          onSelect(text);
        }}
      />
    </Grid>
  ));

  return (
    <Grid container spacing={2} alignItems='center'>
      {buttons}
    </Grid>
  );
};
