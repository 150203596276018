import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    // maxWidth: 200,
    // backgroundColor: "rgba(239, 166, 24,0.2)", //"#F8F8F8",
    backgroundColor: "rgba(0, 0, 0, 0.6)", //"#F8F8F8",
    boxShadow: "0px 4px 8px rgba(27, 27, 27, 0.16)",
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    borderRadius: theme.shape.borderRadius,
    // border: `1px solid ${theme.palette.primary.main}`,
  },
  text: {
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
  },
  iconLoud: {
    //width: 19.44,
    width: 20,
    height: 20,
    marginRight: `${theme.spacing(2)}px`,
  },
  errContainer: {
    // background: "rgba(255, 32, 32,0.1)",
    backgroundColor: "rgba(0, 0, 0, 0.6)", //"#F8F8F8",
    boxShadow: "0px 4px 8px rgba(27, 27, 27, 0.16)",
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    borderRadius: theme.shape.borderRadius,
    "& p": {
      color: "rgb(255, 32, 32)",
      fontSize: "14px",
    },
    // border: "none",
  },
}));

export default useStyles;
