import { Dispatch } from 'redux';

import { MigrateLiquidityTypes } from '../actionTypes';
import { MigrateLiquidityActions } from '../actions/migrateLiquidityActions';
import { IMigrateLPToken, IMigrateNFT, ILPToken } from '../../utils/generalTypes';
import { getBalance, getTotalSupply } from '../../contracts/functions/erc20';
import { findNFTPositionsUniV3 } from '../../contracts/functions/nonFungiblePositionManager';
import { getLpTokenDetail } from '../../contracts/functions/liquidityMigrator';
import { MIGRATE_PROTOCOL, MIGRATE_TYPE, CALL_TYPE } from '../../utils/enums';
import { typeCastSubgraphToken } from '../../utils/helpers';
import { parseAmount, formatAmount } from '../../utils/formating';
import { WHITELISTED_MIGRATION_POOLS } from '../../constants';
import { uniswapV2Client, sushiswapClient } from '../../apollo/client';
import { getPairTokenAmounts } from '../../utils/uniswapSDKFunctions';
import { fetchLpTokens } from '../../data/lpTokenDetails';
import { getHypervisors } from '../../contracts/functions/hypervisor';
import { getLixirVaults } from '../../contracts/functions/lixiVault';
import { getPopsicleVaults } from '../../contracts/functions/popsicleV3Optimizer';
import { getPairReserves } from '../../contracts/functions/uniswapV2Pair';
import { logError } from '../../utils/logs';

export const getMigrateNFT = (walletAddress: string) => {
  return async (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.CLEAR_NFT_MIGRATE,
    });

    const uniswapV3LpTokens = (await findNFTPositionsUniV3(walletAddress)) ?? [];
    dispatch({
      type: MigrateLiquidityTypes.GET_MIGRATE_NFT,
      payload: uniswapV3LpTokens,
    });
  };
};

export const updateMigrateNFT = (walletAddress: string) => {
  return async (dispatch: Dispatch<MigrateLiquidityActions>) => {
    const uniswapV3LpTokens = await findNFTPositionsUniV3(walletAddress);
    if (uniswapV3LpTokens) {
      dispatch({
        type: MigrateLiquidityTypes.UPDATE_MIGRATE_NFT,
        payload: uniswapV3LpTokens,
      });
    }
  };
};

export const addLpToken = (
  lpToken: ILPToken,
  lpTokenDetail: IMigrateLPToken,
  walletAddress: string
) => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    const userLpTokens = (JSON.parse(localStorage.getItem('lpTokens') ?? '{}')[walletAddress] ??
      []) as ILPToken[];
    const lpExists = userLpTokens.find(({ id }) => id === lpToken.id);
    if (!lpExists) {
      dispatch({
        type: MigrateLiquidityTypes.ADD_LP_TOKEN,
        payload: { lpToken, lpTokenDetail, account: walletAddress },
      });
    }
  };
};

const getLpTokenDetails = async (userLpTokens: ILPToken[], account: string) => {
  const lpTokens = userLpTokens.reduce((accum: { [key: string]: ILPToken[] }, lpToken) => {
    if (lpToken.protocol === MIGRATE_PROTOCOL.Visor) {
      accum['visor'] = [...(accum['visor'] ?? []), lpToken];
    } else if (lpToken.protocol === MIGRATE_PROTOCOL.Lixir) {
      accum['lixir'] = [...(accum['lixir'] ?? []), lpToken];
    } else if (lpToken.protocol === MIGRATE_PROTOCOL.Popsicle) {
      accum['popsicle'] = [...(accum['popsicle'] ?? []), lpToken];
    } else {
      accum['uniSushi'] = [...(accum['uniSushi'] ?? []), lpToken];
    }
    return accum;
  }, {});

  const visorLpTokenDetails =
    (await getLpTokenDetail(lpTokens['visor'] ?? [], account, MIGRATE_PROTOCOL.Visor)) ?? [];
  const uniSushiLpTokenDetails =
    (await getLpTokenDetail(lpTokens['uniSushi'] ?? [], account, MIGRATE_PROTOCOL.UniswapV2)) ?? [];
  const lixirLpTokenDetails =
    (await getLpTokenDetail(lpTokens['lixir'] ?? [], account, MIGRATE_PROTOCOL.Lixir)) ?? [];
  const popsicleLpTokenDetails =
    (await getLpTokenDetail(lpTokens['popsicle'] ?? [], account, MIGRATE_PROTOCOL.Popsicle)) ?? [];
  const lpTokenDetails = [
    ...uniSushiLpTokenDetails,
    ...visorLpTokenDetails,
    ...lixirLpTokenDetails,
    ...popsicleLpTokenDetails,
  ];
  return lpTokenDetails;
};

const getProtocol = (lpAddress: string) => {
  if (WHITELISTED_MIGRATION_POOLS.Visor.includes(lpAddress)) return MIGRATE_PROTOCOL.Visor;
  else if (WHITELISTED_MIGRATION_POOLS.Lixir.includes(lpAddress)) return MIGRATE_PROTOCOL.Lixir;
  else if (WHITELISTED_MIGRATION_POOLS.Popsicle.includes(lpAddress))
    return MIGRATE_PROTOCOL.Popsicle;
  else return '';
};

const getGraphLpTokenDetails = async (
  protocol: MIGRATE_PROTOCOL,
  storedLps: string[],
  account: string
): Promise<IMigrateLPToken[] | undefined> => {
  try {
    const client = protocol === MIGRATE_PROTOCOL.UniswapV2 ? uniswapV2Client : sushiswapClient;
    const res = (await fetchLpTokens(client, account)) ?? [];
    const lpAddresses = res.map(({ pair: { id } }) => id);
    const balances = await getBalance(CALL_TYPE.MULTI, lpAddresses, account);

    const reserves = await getPairReserves(CALL_TYPE.MULTI, lpAddresses, [
      '_reserve0',
      '_reserve1',
    ]);

    const lpDetails: IMigrateLPToken[] = [];

    res.forEach(({ pair }, index) => {
      const { id, token0: t0, token1: t1, totalSupply: ts } = pair;
      const balance = balances[index];
      const { _reserve0, _reserve1 } = reserves[index];
      const totalSupply = parseAmount(ts, 18);

      const token0 = typeCastSubgraphToken(t0);
      const token1 = typeCastSubgraphToken(t1);

      let token0Reserveds: string = '0';
      let token1Reserveds: string = '0';

      if (parseFloat(totalSupply) > 0) {
        const { token0Reserveds: t0R, token1Reserveds: t1R } = getPairTokenAmounts(
          token0,
          token1,
          _reserve0,
          _reserve1,
          totalSupply,
          balance
        );
        token0Reserveds = t0R.toSignificant(5);
        token1Reserveds = t1R.toSignificant(5);
      }

      if (Number(balance) > 0 && storedLps.includes(id.toLowerCase()) === false) {
        const lpDetail: IMigrateLPToken = {
          id,
          token0,
          token1,
          pairAddress: id,
          liquidity: balance,
          totalSupply,
          token0Reserve: token0Reserveds,
          token1Reserve: token1Reserveds,
          protocol,
          type: MIGRATE_TYPE.LP_TOKEN,
        };
        lpDetails.push(lpDetail);
      }
    });
    return lpDetails;
  } catch (e) {
    logError('getGraphLpTokenDetails', e);
  }
};

const initialPools = async (storedLps: string[], account: string) => {
  try {
    let lpAddresses = Object.values(WHITELISTED_MIGRATION_POOLS).reduce(
      (accum: string[], pools: string[]) => {
        accum = [...accum, ...pools];
        return accum;
      }
    );

    lpAddresses = lpAddresses.filter(
      lpAddress => storedLps.includes(lpAddress.toLowerCase()) === false
    );

    const balances = await getBalance(CALL_TYPE.MULTI, lpAddresses, account);
    const totalSupplies = await getTotalSupply(CALL_TYPE.MULTI, lpAddresses);

    const data: any = {
      [MIGRATE_PROTOCOL.Lixir]: [],
      [MIGRATE_PROTOCOL.Visor]: [],
      [MIGRATE_PROTOCOL.Popsicle]: [],
    };

    balances.forEach((balance: string, index: number) => {
      const lpAddress = lpAddresses[index];
      const protocol = getProtocol(lpAddress);
      const totalSupply = totalSupplies[index];
      if (parseInt(balance) > 0 && lpAddress && protocol) {
        data[protocol] = [...data[protocol], { id: lpAddress, balance, totalSupply }];
      }
    });

    const lixirLps =
      data[MIGRATE_PROTOCOL.Lixir].length > 0
        ? (await getLixirVaults(data[MIGRATE_PROTOCOL.Lixir], account)) ?? []
        : [];

    const visorLps =
      data[MIGRATE_PROTOCOL.Visor].length > 0
        ? (await getHypervisors(data[MIGRATE_PROTOCOL.Visor], account)) ?? []
        : [];

    const popsicleLps =
      data[MIGRATE_PROTOCOL.Popsicle].length > 0
        ? (await getPopsicleVaults(data[MIGRATE_PROTOCOL.Popsicle], account)) ?? []
        : [];

    const uniswapV2Lps =
      (await getGraphLpTokenDetails(MIGRATE_PROTOCOL.UniswapV2, storedLps, account)) ?? [];

    const sushiswapLps =
      (await getGraphLpTokenDetails(MIGRATE_PROTOCOL.SushiSwap, storedLps, account)) ?? [];

    return [...lixirLps, ...visorLps, ...popsicleLps, ...uniswapV2Lps, ...sushiswapLps];
  } catch (e) {
    logError('initialPools', e);
  }
};

export const getMigrateLP = (account: string) => {
  return async (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.CLEAR_LP_MIGRATE,
    });

    const userLpTokens = (JSON.parse(localStorage.getItem('lpTokens') ?? '{}')[account] ??
      []) as ILPToken[];

    const lpAddresses = userLpTokens.map(({ id }) => id);
    const newLpDetails: IMigrateLPToken[] = (await initialPools(lpAddresses, account)) ?? [];
    const newUserLpTokens: ILPToken[] = newLpDetails.map(
      ({ id, token0, token1, pairAddress, protocol, type, fee }) => {
        return { id, token0, token1, pairAddress, protocol, fee, type };
      }
    );

    const lpTokenDetails = await getLpTokenDetails(userLpTokens, account);
    let _userLpTokens: ILPToken[] = [];
    let _lpTokenDetails: IMigrateLPToken[] = [];

    userLpTokens.length > 0 &&
      userLpTokens.forEach((userLpToken, index) => {
        if (parseFloat(lpTokenDetails[index]?.liquidity) > 0) {
          _userLpTokens.push(userLpToken);
          _lpTokenDetails.push(lpTokenDetails[index]);
        }
      });

    const lpDetails = [..._lpTokenDetails, ...newLpDetails];
    const lpTokens = [..._userLpTokens, ...newUserLpTokens];

    if (lpDetails.length > 0) {
      dispatch({
        type: MigrateLiquidityTypes.GET_MIGRATE_LP,
        payload: {
          lpTokenDetails: lpDetails,
          lpTokens: lpTokens,
          account,
        },
      });
    } else {
      dispatch({
        type: MigrateLiquidityTypes.GET_MIGRATE_LP,
        payload: { lpTokenDetails: [], lpTokens: [], account },
      });
    }
  };
};

export const updateMigrateLp = (userLpTokens: ILPToken[], account: string) => {
  return async (dispatch: Dispatch<MigrateLiquidityActions>) => {
    const lpTokenDetails = await getLpTokenDetails(userLpTokens, account);
    let _userLpTokens: ILPToken[] = [];
    let _lpTokenDetails: IMigrateLPToken[] = [];

    userLpTokens.length > 0 &&
      userLpTokens.forEach((userLpToken, index) => {
        if (parseFloat(lpTokenDetails[index]?.liquidity) > 0) {
          _userLpTokens.push(userLpToken);
          _lpTokenDetails.push(lpTokenDetails[index]);
        }
      });

    dispatch({
      type: MigrateLiquidityTypes.UPDATE_MIGRATE_LP,
      payload: { lpTokenDetails: _lpTokenDetails, lpTokens: _userLpTokens, account },
    });
  };
};

export const selectMigrate = (migrate: IMigrateLPToken | IMigrateNFT) => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.SELECT_MIGRATE,
      payload: migrate,
    });
  };
};

export const clearNftMigrate = () => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.CLEAR_NFT_MIGRATE,
    });
  };
};

export const setMigrateNftPageCount = (count: number) => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.SET_NFT_PAGE_COUNT,
      payload: count,
    });
  };
};

export const setMigrateLpPageCount = (count: number) => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.SET_LP_PAGE_COUNT,
      payload: count,
    });
  };
};

export const setActiveTab = (tab: number) => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.SET_ACTIVE_TAB,
      payload: tab,
    });
  };
};

export const removeLpToken = (id: string, account: string) => {
  return (dispatch: Dispatch<MigrateLiquidityActions>) => {
    dispatch({
      type: MigrateLiquidityTypes.REMOVE_LP_TOKEN,
      payload: { id, account },
    });
  };
};
