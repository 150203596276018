import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    margin: '0',
    height: '25px',
    width: '25px',
    marginRight: '5px',
  },
  smallImg: {
    margin: '0',
    height: '15px',
    width: '15px',
    marginRight: '3px',
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallTag: {
    margin: '0 5px',
    marginTop: '10px',
    border: '1px solid rgba(255,255,255,0.2)',
    width: 'auto',
    alignItems: 'center',
    borderRadius: '10px',
    padding: '6px 8px',
    cursor: 'pointer',
    opacity: '0.9',

    '& p': {
      marginTop: '2px',
      fontSize: '11px',
    },
    '&:hover': {
      opacity: '1',
    },
  },
}));

export default useStyles;
