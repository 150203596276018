import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDispatchWrap } from './utilHooks';
import { getProtocolData, selectPool, getProtocolFees, getPoolsAPY, State } from '../redux';
import { unipilotClient, uniswapV3Client } from '../apollo/client';
import { PoolTVLChart, fetchPoolTvl, fetchPoolVoulme, PoolVolumeChart } from '../data';
import { IPoolData, IUniswapPoolData } from '../utils/generalTypes';
import { STATS_DROPDOWN } from '../utils/enums';

export const useStats = () => {
  const liquidityData = useSelector((state: State) => state.stats);
  const getProtocolDataF = useDispatchWrap(getProtocolData);
  const selectPoolF = useDispatchWrap(selectPool);
  const getProtocolFeesF = useDispatchWrap(getProtocolFees);
  const getPoolsAPYF = useDispatchWrap(getPoolsAPY);

  return {
    ...liquidityData,
    getProtocolDataF,
    selectPoolF,
    getProtocolFeesF,
    getPoolsAPYF,
  };
};

export const useSelectedPoolData = () => {
  const { selectedPool } = useStats();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PoolTVLChart[] | undefined>(undefined);
  const [volumeData, setVolumeData] = useState<PoolVolumeChart[] | undefined>(undefined);

  const getPoolData = useCallback(async () => {
    if (selectedPool) {
      setLoading(true);
      const res = await fetchPoolTvl(selectedPool.id, unipilotClient);
      const poolVolume = await fetchPoolVoulme(selectedPool.id, uniswapV3Client);
      setData(res.data);
      setVolumeData(poolVolume.data);
      setLoading(false);
    }
  }, [selectedPool, fetchPoolTvl]);

  useEffect(() => {
    getPoolData();
  }, [selectedPool]);

  return { loading, data, volumeData };
};

export const useTopPools = () => {
  type HotFix = STATS_DROPDOWN.APY | STATS_DROPDOWN.FEES | STATS_DROPDOWN.TVL;

  const [sortBy, setSortBy] = useState<STATS_DROPDOWN>(STATS_DROPDOWN.APY);

  const { poolApy, poolFees } = useSelector((state: State) => state.stats);

  const updateSortByF = (value: STATS_DROPDOWN) => {
    setSortBy(value);
  };

  const { poolsData, selectPoolF } = useStats();

  const modifiedPoolsData = useMemo<IPoolData[] | undefined>(() => {
    if (poolApy && poolFees && poolsData) {
      return poolsData?.map(poolData => {
        const id = poolData.id;

        poolData.apy = poolApy[id] ? poolApy[id] : '0';
        poolData.ulmFeesUSD = poolFees[id] ? poolFees[id].total : '0';

        return poolData;
      });
    }

    return undefined;
  }, [poolApy, poolFees, poolsData]);

  const topPools = useMemo<IPoolData[] | undefined>(() => {
    if (modifiedPoolsData) {
      if (modifiedPoolsData.length > 0) {
        const pools = modifiedPoolsData.sort((accumPool, pool) => {
          const forPoolData =
            parseFloat(pool[sortBy as HotFix]) === 0 ||
            parseFloat(accumPool[sortBy as HotFix]) > parseFloat(pool[sortBy as HotFix]);

          const forUniswapPoolData =
            parseFloat(pool.uniswapPool[sortBy as STATS_DROPDOWN.VOLUME]) === 0 ||
            parseFloat(accumPool.uniswapPool[sortBy as STATS_DROPDOWN.VOLUME]) >
              parseFloat(pool.uniswapPool[sortBy as STATS_DROPDOWN.VOLUME]);

          if (accumPool && pool) {
            if (sortBy === STATS_DROPDOWN.VOLUME ? forUniswapPoolData : forPoolData) {
              return -1;
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        });
        selectPoolF(pools[0]);
        return pools;
      }
      return [];
    }
    return undefined;
  }, [modifiedPoolsData, sortBy]);

  return { topPools, sortBy, updateSortByF };
};

export const usePoolGraph = (address: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PoolTVLChart[] | undefined>(undefined);

  const getPoolTvl = useCallback(async () => {
    setLoading(true);
    const res = await fetchPoolTvl(address, unipilotClient);
    setData(res.data);
    setLoading(false);
  }, [address, fetchPoolTvl]);

  useEffect(() => {
    getPoolTvl();
  }, [address]);

  return { loading, data };
};
