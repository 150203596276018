import { useMediaQuery, useTheme, Box } from '@material-ui/core';

import { CTooltip, RangeSymbol } from '../../components';

interface IRangeInfo {
  inRange?: boolean;
  closed?: boolean;
}

const RangeInfo: React.FC<IRangeInfo> = ({ inRange = true, closed = false }) => {
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      {xs_ ? (
        !inRange ? (
          <CTooltip title='Needs rebase'>
            <Box
              style={{
                width: 10,
                height: 10,
                backgroundColor: '#f44336',
                borderRadius: 10,
              }}
            />
          </CTooltip>
        ) : (
          <CTooltip title='In range'>
            <Box
              style={{
                width: 10,
                height: 10,
                backgroundColor: '#EFA618',
                borderRadius: 10,
              }}
            />
          </CTooltip>
        )
      ) : (
        <RangeSymbol closed={closed} inRange={inRange} />
      )}
    </>
  );
};

export default RangeInfo;
