import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";

export enum ANALYTICS_TYPES {
  ARRIVAL = "USER_ARRIVED",
}

const firebaseConfig = {
  apiKey: "AIzaSyBideiZ4w1TmYfP7kU7UwVZhb3FwDC1iFM",
  authDomain: "unipilot-5176a.firebaseapp.com",
  projectId: "unipilot-5176a",
  storageBucket: "unipilot-5176a.appspot.com",
  messagingSenderId: "359707001720",
  appId: "1:359707001720:web:074d80cefcd3850d64be8d",
  measurementId: "G-5K0X70R20G",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
