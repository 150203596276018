import { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import {
  GreenInfo,
  RowAlignCenter,
  RebasePopover,
  CGrid,
  TokenSwitch,
  PoolInfo,
  RebaseButton,
  CTooltip,
} from '../../components';
import { useNFTPositions, usePrices } from '../../hooks';
import AmountFunc from './Amount';
import TextWithInfo from './TextWithInfo';
import useStyles from './styles';
import PilotCoin from '../../assets/others/PilotCoin.gif';
import { trunc, truncSymbol } from '../../utils/formating';
import { rebase } from '../../contracts/functions/liquidityManager';

const NFTSection: React.FC<{}> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));
  const _sm02 = useMediaQuery('@media (max-width:800px)');
  const history = useHistory();
  const { account } = useWeb3React();

  const { getPrices } = usePrices();
  const { selectedNFTPosition, updateSelectedPositionF, getNFTPositionsF } = useNFTPositions();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const DAYS_ROW = [
    { day: 'Mon.', fee: '0.4' },
    { day: 'Tues.', fee: '0.5' },
    { day: 'Wed.', fee: '0.8' },
    { day: 'Thur.', fee: '0.4' },
    { day: 'Fri.', fee: '0.3' },
    { day: 'Sat.', fee: '0.2' },
    { day: 'Sun.', fee: '0.1' },
  ];

  if (!selectedNFTPosition) {
    return null;
  }

  const {
    fee,
    inRange,
    token0,
    token1,
    tokenId,
    reverse,
    rebaseIncentive,
    allowRebase,
    poolApy,
    token0Reserve,
    liquidity,
    fees24h,
  } = selectedNFTPosition;
  const {
    minPrice,
    maxPrice,
    baseMinPrice,
    baseMaxPrice,
    rangeMinPrice,
    rangeMaxPrice,
    currentPrice,
    tokenMsg,
  } = getPrices(reverse ?? false);

  const handleSettingButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const priceTrunc = (price: string) => {
    return price.length > 15 ? trunc(parseFloat(price)) : price;
  };

  const handleRebase = async () => {
    if (account) {
      await rebase(tokenId, token0, token1, fee, account, () => {
        getNFTPositionsF(account);
        history.replace('/positions');
      });
    }
  };

  return (
    <Container disableGutters={_sm02} maxWidth='sm' style={{ height: '100%' }}>
      <RebasePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        tokenId={tokenId?.toString()}
        rebaseIncentive={rebaseIncentive}
        allowRebase={allowRebase}
        currentPrice={currentPrice}
        tokenMsg={tokenMsg}
        pool={`${token0?.symbol}/${token1?.symbol}`}
        handleRebase={handleRebase}
      />

      <Box className={classes.rightContentContainer}>
        <Box className={classes.paper}>
          <RowAlignCenter
            elements01={
              <PoolInfo
                token0Address={token0.address}
                token1Address={token1.address}
                token0Symbol={token0.symbol}
                token1Symbol={token1.symbol}
                token0Uri={token0.logoURI}
                token1Uri={token1.logoURI}
                feeTier={!xs_ ? fee : undefined}
              />
            }
            elements02={
              <RebaseButton
                closed={Number(liquidity) === 0}
                inRange={inRange}
                onClick={handleSettingButton}
              />
            }
          />

          {xs_ && (
            <GreenInfo
              className={classes.greenInfo}
              content={`Fee tier ${Number(fee) / 10000} %`}
            />
          )}

          <Box className={classes.pilotCoin}>
            <img src={PilotCoin} />
          </Box>
        </Box>

        <Box style={{ flexGrow: 1 }} className={classes.paper02}>
          <RowAlignCenter
            elements01={
              <Typography style={{ fontWeight: 500 }} variant='body2'>
                {tokenMsg}
              </Typography>
            }
            elements02={
              <TokenSwitch
                value0={truncSymbol(token0.symbol, 8)}
                value1={truncSymbol(token1.symbol, 8)}
                baseValue={reverse ?? false}
                onChange={(value: boolean) => updateSelectedPositionF({ reverse: value })}
              />
            }
          />
          <Box mt={'5px'} />
          <CGrid
            containerClassName={classes.CGridContainer}
            elements01={<Typography className={classes.font0P9Rem}>{`Token ID`}</Typography>}
            elements02={
              <AmountFunc amount={tokenId?.toString()} className={classes.font1P2RemPri600} />
            }
            alignItems={'center'}
            justify={'space-between'}
          />

          <CGrid
            elements01={
              <TextWithInfo
                text='Min Price'
                jsx={
                  <Box className={classes.tooltipc}>
                    <Typography className={classes.fontSize}>{`Base min: ${priceTrunc(
                      baseMinPrice
                    )}`}</Typography>
                    <Typography className={classes.fontSize}>{`Range min: ${priceTrunc(
                      rangeMinPrice
                    )}`}</Typography>
                  </Box>
                }
              />
            }
            elements02={<AmountFunc amount={minPrice} className={classes.font1P2RemPri600} />}
            alignItems={'center'}
            justify={'space-between'}
          />

          <CGrid
            containerClassName={classes.CGridContainer}
            elements01={
              <TextWithInfo
                text='Max Price'
                jsx={
                  <Box className={classes.tooltipc}>
                    <Typography className={classes.fontSize}>{`Base max: ${priceTrunc(
                      baseMaxPrice
                    )}`}</Typography>
                    <Typography className={classes.fontSize}>{`Range max: ${priceTrunc(
                      rangeMaxPrice
                    )}`}</Typography>
                  </Box>
                }
              />
            }
            elements02={<AmountFunc amount={maxPrice} className={classes.font1P2RemPri600} />}
            alignItems={'center'}
            justify={'space-between'}
          />

          <CGrid
            containerClassName={classes.CGridContainer}
            elements01={<Typography className={classes.font0P9Rem}>{`Current Price`}</Typography>}
            elements02={<AmountFunc amount={currentPrice} className={classes.font1P2RemPri600} />}
            alignItems={'center'}
            justify={'space-between'}
          />

          <CGrid
            containerClassName={classes.CGridContainer}
            elements01={<Typography className={classes.font0P9Rem}>{`APR`}</Typography>}
            elements02={
              <AmountFunc
                amount={token0Reserve === undefined ? poolApy : poolApy ?? '0'}
                endSymbol='%'
                className={classes.font1P2RemPri600}
              />
            }
            alignItems={'center'}
            justify={'space-between'}
          />

          {/* <CGrid
            containerClassName={classes.CGridContainer}
            elements01={
              <TextWithInfo
                text="Fees 24h"
                jsx={
                  <Box
                    className={[classes.tooltipc, classes.tableTooltip].join(
                      " "
                    )}
                  >
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Days</TableCell>
                            <TableCell align="right">Fees</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {DAYS_ROW.map((row) => (
                            <TableRow key={row.day}>
                              <TableCell component="th" scope="row">
                                {row.day}
                              </TableCell>
                              <TableCell align="right">{row.fee}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                }
              />
            }
            elements02={
              <AmountFunc
                dollorSymbol
                amount={fees24h}
                className={classes.font1P2RemPri600}
              />
            }
            alignItems={'center'}
            justify={'space-between'}
          /> */}
        </Box>
      </Box>
    </Container>
  );
};

export default NFTSection;
