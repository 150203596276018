import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  mainContainer: {
    justifyContent: 'center',
  },
  container: {
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
    backgroundColor: theme.palette.common.mainBg,
    width: '100%',
    '@media(max-width:550px)': {
      padding: '1.5rem 1rem',
    },
  },
  graph: {
    padding: '1.8rem 0 1.8rem 0',
  },
  graphDetails: {
    // padding: '0 1.5rem',
    padding: '0 1.5rem 0 0rem',
    '@media(max-width:550px)': {
      padding: '0 1rem 0 0',
    },
  },
  graphDetailsTop: {
    // padding: '0 1.5rem',
    padding: '0 0rem 0 1rem',
    marginBottom: '5px',
    '@media(max-width:550px)': {
      padding: '0 1rem',
    },
  },
  icon: {
    width: '50px',
    marginLeft: '10px',
    '@media(max-width:550px)': {
      width: '40px',
    },
  },
  box: {
    '& h4': {
      fontWeight: '600',
      fontSize: '30px',
      marginTop: '5px',
      '@media(max-width:550px)': {
        fontSize: '20px',
      },
    },
  },

  tokenSec: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: '10px',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    width: '48%',
    padding: '10px 0',
    borderRadius: '10px',
    minHeight: '68px',
    '& p': {
      fontSize: '12px',
      marginBottom: '5px',
      '@media(max-width:500px)': {
        fontSize: '10px',
      },
    },
    '& h5': {
      fontSize: '18px',
      '@media(max-width:500px)': {
        fontSize: '14px',
      },
    },
  },
  statsGraph: {
    minHeight: '280px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graphContainer: {
    width: '100px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width:500px)': {
      width: 'auto',
      height: '50px',
    },
  },

  pair: {
    marginRight: '20px',
    '@media(max-width:500px)': {
      //marginLeft: '10px',
      margin: '0 5px',
    },
  },
  amount: {
    fontWeight: 600,
    '@media(max-width:550px)': {
      fontSize: '20px',
    },
  },
  heading: {
    padding: '0 1.5rem ',
    marginBottom: '10px',
    '@media(max-width:550px)': {
      padding: '0 1rem ',
    },
  },
  selectedPool: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  poolBtn: {
    width: '100%',
    padding: '0 10px',
    margin: '2px 0',
  },
  volumeTime: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '25px',
    '& p': {
      color: theme.palette.common.lightText,
      fontSize: '12px',
      padding: '0 1.5rem ',
      '@media(max-width:550px)': {
        padding: '0 1rem ',
      },
    },
  },
  poolFee: {
    '@media(max-width:550px)': {
      padding: '2px 8px',
    },
  },
  topPools: {
    minHeight: '500px',
  },
  buttonsTop: {
    textTransform: 'none',
    padding: '2px 5px',
    width: '110px',
    borderRadius: theme.shape.borderRadius,
    fontSize: '14px',
    margin: '0 15px 10px 0',
    '@media(max-width:550px)': {
      // margin: '0 10px 10',
      margin: '0',
      width: '80px',
      fontSize: '10px',
    },
  },
  poolsContainer: {
    minHeight: '520px',
  },
  tooltipc: {
    backgroundColor: 'rgb(44, 47, 51)',
    padding: '10px',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '200px !important',
  },
}));

export default useStyles;
