import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import HelpOutline from '@material-ui/icons/HelpOutline';

import useStyles from './styles';
import { GridRow } from '../../components';
import TextWithInfo from '../../pages/pairLiquidity/TextWithInfo';

interface CommonTypes {
  isMobile?: boolean;
  isMargin?: boolean;
  showIcon?: boolean;
  toolTipValue?: string;
  endSymbol?: string;
  startSymbol?: string;
  headValue: string;
  value: string | undefined;
  subValue?: string;
  subValue2?: string;
}

const Common: React.FC<CommonTypes> = ({
  headValue,
  value,
  toolTipValue,
  startSymbol = '',
  endSymbol = '',
  isMobile = false,
  isMargin = false,
  showIcon = false,
  subValue,
  subValue2,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down('sm'));
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      className={isMobile ? classes.mobileCol : classes.none}
      style={{ marginLeft: isMargin && !sm_ ? '10px' : 0 }}
    >
      <Typography className={classes.heading}>
        {headValue}
        {xs_ && value && showIcon && (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <TextWithInfo
              className={classes.mobileTooltip}
              text={''}
              jsx={
                <Box className={classes.tooltipc}>
                  {headValue === 'APR' ? (
                    <>
                      <GridRow
                        title='Rewards APR'
                        val={subValue2 === undefined ? subValue2 : `${subValue2}%`}
                      />
                      <GridRow
                        title='Fees APY'
                        val={subValue === undefined ? subValue : `${subValue}%`}
                      />
                    </>
                  ) : (
                    <Box>
                      <Typography style={{ fontSize: '0.7rem' }}>{toolTipValue}</Typography>
                    </Box>
                  )}
                </Box>
              }
              Icon={HelpOutline}
            />
          </Box>
        )}
      </Typography>
      {value ? (
        <Typography className={classes.center}>
          {`${startSymbol}${value}${endSymbol}`}
          {!xs_ && value && showIcon && (
            <TextWithInfo
              text={''}
              jsx={
                <Box className={classes.tooltipc}>
                  {headValue === 'APR' ? (
                    <>
                      <GridRow
                        title='Rewards APR'
                        val={subValue2 === undefined ? subValue2 : `${subValue2}%`}
                      />
                      <GridRow
                        title='Fees APR'
                        val={subValue === undefined ? subValue : `${subValue}%`}
                      />
                    </>
                  ) : (
                    <Box>
                      <Typography style={{ fontSize: '0.7rem' }}>{toolTipValue}</Typography>
                    </Box>
                  )}
                </Box>
              }
              Icon={HelpOutline}
            />
          )}
        </Typography>
      ) : (
        <Skeleton height={24} width={60} />
      )}
    </Box>
  );
};

export default Common;
