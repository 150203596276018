import { DEV_ENVIRONMENT } from './';
import { MIGRATE_PROTOCOL } from '../utils/enums';

export const WHITELISTED_MIGRATION_POOLS = DEV_ENVIRONMENT
  ? {
      [MIGRATE_PROTOCOL.Visor]: [
        '0x86c8f401d4986aa3c4d891b8b16949320df6a40e',
        '0x6d36ab4e6f02331c60d28cf633db2b5b59acca20',
        '0x860f743395f7b988442b3bcd32248d726d71cd16',
        '0x5902a5019547133d186e32578f0ce83cf842626d',
        '0x80e0b252418637c175522158421f51f4428b7c63',
      ],
      [MIGRATE_PROTOCOL.Lixir]: [
        '0xBFa7697Db8D5C0cDf464814C6798D3C1aa997c9E',
        '0x2a7c48b62208410ba68144e34449dbe533c45bf7',
        '0x6086781ba226e6ebd53f453a9b10938dc74b84c8',
      ],
      [MIGRATE_PROTOCOL.Popsicle]: [
        '0x17b57339b45D5cAcCe71e8CF11137c585B9F0074',
        '0x19f79ed76273BDED42f8949BF4ebb5c31d59F87e',
        '0x0f90db6A32ED4aD232D5881407912CCfd66b654b',
        '0x1f2aC33f19E880dCb28F8FfB21882BB4b0EB8bd9',
      ],
    }
  : {
      [MIGRATE_PROTOCOL.Visor]: [
        '0x0407c810546f1dc007f01a80e65983072d5c6dfa',
        '0x09b8d86c6275e707155cdb5963cf611a432ccb21',
        '0x18d3284d9eff64fc97b64ab2b871738e684aa151',
        '0x1b56860eaf38f27b99d2b0d8ffac86b0f1173f1a',
        '0x23c85dca3d19b31f14aeea19beac32c2cb2ffc72',
        '0x33682bfc1d94480a0e3de0a565180b182b71d485',
        '0x407e99b20d61f245426031df872966953909e9d3',
        '0x4564a37c88e3b13d3a0c08832dcf88278997e6fe',
        '0x4f7997158d66ca31d9734674fdcd12cc74e503a7',
        '0x5230371a6d5311b1d7dd30c0f5474c2ef0a24661',
        '0x53a4512bbe5083695d8e890789fe1cf6f5686d52',
        '0x65bc5c6a2630a87c2b494f36148e338dd76c054f',
        '0x6c8116abe5c5f2c39553c6f4217840e71462539c',
        '0x6e67bb258b6485b688cbb526c868d4428b634cf1',
        '0x716bd8a7f8a44b010969a1825ae5658e7a18630d',
        '0x7f92463e24b2ea1f7267aceed3ad68f7a956d2d8',
        '0x8cd73cb1e1fa35628e36b8c543c5f825cd4e77f1',
        '0x92ccaa1b3dccccae7d68fff50e6e47a747233e62',
        '0x97491b65c9c8e8754b5c55ed208ff490b2ee6190',
        '0x9a98bffabc0abf291d6811c034e239e916bbcec0',
        '0xb60f4ac1514be672b2ec35a023f4c89373d3a4ef',
        '0xb666bfdb553a1aff4042c1e4f39e43852ba9731d',
        '0xbb9b86a75ca3115caab045e2af17b0bba483acbc',
        '0xbff4a47a0f77637f735e3a0ce10ff2bf9be12e89',
        '0xc14e7ec60699a39cfd59bae06168afc2c76f32ac',
        '0xc92ff322c8a18e38b46393dbcc8a7c5691586497',
        '0xd8dbdb77305898365d7ba6dd438f2663f7d4e409',
        '0xd930ab15c8078ebae4ac8da1098a81583603f7ce',
        '0xdbaa93e030bf2983add67c851892a9e2ee51e66a',
      ],
      [MIGRATE_PROTOCOL.Lixir]: [
        '0x9E815d1c9a4BE458e7F9A0aDD703e7545EDa7C28',
        '0x453A9f40a24DbE3CdB4edC988aF9bfE0F5602b15',
        '0xda559e686B7BB1eaeC95B3548555866e4174A3ac',
      ],
      [MIGRATE_PROTOCOL.Popsicle]: [
        '0xa7053782dC3523D2C82B439Acf3f9344Fb47b97f',
        '0xd2C5A739ebfE3E00CFa88A51749d367d7c496CCf',
        '0x949FDF28F437258E7564a35596b1A99b24F81e4e',
        '0xa0273C10b8A4BF0bDC57cb0bC974E3A9d89527b8',
        '0x495410B129A27bC771ce8fb316d804a5686B8Ea7',
        '0x5C08A6762CAF9ec8a42F249eBC23aAE66097218D',
        '0xaE7b92C8B14E7bdB523408aE0A6fFbf3f589adD9',
        '0x9683D433621A83aA7dd290106e1da85251317F55',
        '0x212Aa024E25A9C9bAF5b5397B558B7ccea81740B',
        '0xBE5d1d15617879B22C7b6a8e1e16aDD6d0bE3c61',
        '0xFF338D347E59d6B61E5C69382915D863bb22Ef2f',
        '0xa1BE64Bb138f2B6BCC2fBeCb14c3901b63943d0E',
        '0x8d8B490fCe6Ca1A31752E7cFAFa954Bf30eB7EE2',
      ],
    };

export const LP_MIGRATION = [
  MIGRATE_PROTOCOL.UniswapV2,
  MIGRATE_PROTOCOL.SushiSwap,
  MIGRATE_PROTOCOL.Visor,
  MIGRATE_PROTOCOL.Lixir,
  MIGRATE_PROTOCOL.Popsicle,
];

export const POPSICLE_VAULTS = DEV_ENVIRONMENT
  ? [
      {
        vault: '0x17b57339b45D5cAcCe71e8CF11137c585B9F0074',
        uniswapV3Pool: '0xc6c6b2fdeb3bfc78339e36efc645c3475c6a2241',
      },
      {
        vault: '0x19f79ed76273BDED42f8949BF4ebb5c31d59F87e',
        uniswapV3Pool: '0x9b998f32b638eeed55ed77b78873c621b6c4813f',
      },
      {
        vault: '0x0f90db6A32ED4aD232D5881407912CCfd66b654b',
        uniswapV3Pool: '0x8e27ed377df48337f81e053e6f593f60c545cb94',
      },
      {
        vault: '0x1f2aC33f19E880dCb28F8FfB21882BB4b0EB8bd9',
        uniswapV3Pool: '0xaa33f9a45bda875572d755737962a5a37ab4d050',
      },
    ]
  : [
      {
        vault: '0xa7053782dC3523D2C82B439Acf3f9344Fb47b97f',
        uniswapV3Pool: '0x3019D4e366576A88D28B623afAF3eCb9ec9d9580',
      },
      {
        vault: '0xd2C5A739ebfE3E00CFa88A51749d367d7c496CCf',
        uniswapV3Pool: '0xD8de6af55F618a7Bc69835D55DDC6582220c36c0',
      },
      {
        vault: '0x949FDF28F437258E7564a35596b1A99b24F81e4e',
        uniswapV3Pool: '0x3B685307C8611AFb2A9E83EBc8743dc20480716E',
      },
      {
        vault: '0xa0273C10b8A4BF0bDC57cb0bC974E3A9d89527b8',
        uniswapV3Pool: '0x2F62f2B4c5fcd7570a709DeC05D68EA19c82A9ec',
      },
      {
        vault: '0x495410B129A27bC771ce8fb316d804a5686B8Ea7',
        uniswapV3Pool: '0x5764a6f2212d502bc5970f9f129ffcd61e5d7563',
      },
      {
        vault: '0x5C08A6762CAF9ec8a42F249eBC23aAE66097218D',
        uniswapV3Pool: '0xFEbf38B1D34818D4827034f97b7D6D77c79D4997',
      },
      {
        vault: '0xaE7b92C8B14E7bdB523408aE0A6fFbf3f589adD9',
        uniswapV3Pool: '0x8ad599c3A0ff1De082011EFDDc58f1908eb6e6D8',
      },
      {
        vault: '0x9683D433621A83aA7dd290106e1da85251317F55',
        uniswapV3Pool: '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640',
      },
      {
        vault: '0x212Aa024E25A9C9bAF5b5397B558B7ccea81740B',
        uniswapV3Pool: '0xcbcdf9626bc03e24f779434178a73a0b4bad62ed',
      },
      {
        vault: '0xBE5d1d15617879B22C7b6a8e1e16aDD6d0bE3c61',
        uniswapV3Pool: '0x4585fe77225b41b697c938b018e2ac67ac5a20c0',
      },
      {
        vault: '0xFF338D347E59d6B61E5C69382915D863bb22Ef2f',
        uniswapV3Pool: '0x36bcF57291a291a6E0E0bFF7B12B69B556BCd9ed',
      },
      {
        vault: '0xa1BE64Bb138f2B6BCC2fBeCb14c3901b63943d0E',
        uniswapV3Pool: '0x4e68Ccd3E89f51C3074ca5072bbAC773960dFa36',
      },
      {
        vault: '0x8d8B490fCe6Ca1A31752E7cFAFa954Bf30eB7EE2',
        uniswapV3Pool: '0x11b815efb8f581194ae79006d24e0d814b7697f6',
      },
    ];

//"https://gateway.pinata.cloud/ipfs/QmTJnqvXUQnUSixGgoVgzJwSMXMQjR66jTzrFynA4Zwgth"

// "https://gateway.pinata.cloud/ipfs/QmVcZtWBMJDmYGTsXKJe1i2izrUdpahWqWpDGeBVoHTuHQ"
