import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import useStyles from './styles';
import {
  PoolInfo,
  ButtonIndicator,
  Dropdown,
  CTooltip,
  ConnectWalletButton,
  FMigration,
} from '../../components';
import {
  useFarmingPositions,
  useLoading,
  useFarming,
  useNFTPositions,
  useApp,
  useRedirectAddLiquidity,
  useStats,
} from '../../hooks';
import { CONTRACT_ADDRESSES } from '../../constants';
import Common from './common';
import { checkUnipilotNftApproval, approveUnipilotNft } from '../../contracts/functions/unipilot';
import { colletFee } from '../../contracts/functions/liquidityManager';
import {
  lockNFT,
  unlockNFT,
  getCurrentReward,
  withdrawReward,
} from '../../contracts/functions/farming';
import { getTokensDollarValue } from '../../redux';
import { IFarmingPool, IFarmingNFT } from '../../utils/generalTypes';
import { LOADING, CALL_TYPE, FARMING_VERSION } from '../../utils/enums';
import { trunc, formatAmount, formatDollarAmount } from '../../utils/formating';

interface FarmDataInt {
  pool: IFarmingPool;
  handleChange: (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  expanded: string | boolean;
}

const TOOLTIP_INFO = {
  reward: 'Number of pilot token distributed per block among all the users.',
  apr: 'Yearly percentage ROI generated through PILOT liquidity farming rewards.',
  multiplier:
    'The Multiplier represents the proportion of PILOT rewards each pool receives, as a proportion of the PILOT produced each block.',
};

const BLACKLIST_MSG = 'Not available for further farming';
const UNIPILOT_V2_MSG = 'Use Unipilot V2 for further farming';

const BLACKLISTED_POOLS = [
  '0x7379e81228514a1d2a6cf7559203998e20598346',
  '0xe8c6c9227491c0a8156a0106a0204d881bb7e531',
  '0xc63b0708e2f7e69cb8a1df0e1389a98c35a76d52',
  '0x9febc984504356225405e26833608b17719c82ae',
  '0x6c6bc977e13df9b0de53b251522280bb72383700',
  '0x7858e59e0c01ea06df3af3d20ac7b0003275d4bf',
  '0xd35efae4097d005720608eaf37e42a5936c94b44',
];

const FarmData: React.FC<FarmDataInt> = ({ pool, handleChange, expanded }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));
  const { account } = useWeb3React();
  const history = useHistory();

  const { userPosition } = useFarmingPositions(pool);
  const { dappLoading } = useLoading();
  const {
    whiteListedPools,
    farmingPools,
    locked,
    farmingPoolsTvl,
    getUserLockedFarmingNftsF,
    updateFarmingNftF,
    updateFarmingTvlF,
  } = useFarming();
  const { removePositionF, getNFTPositionsF } = useNFTPositions();
  const { pilotPrice } = useApp();
  const { poolApy } = useStats();
  const redirectToAddLiquidity = useRedirectAddLiquidity();

  const [approved, setApproved] = useState<boolean | null>(null);
  const [nftLock, setNftLock] = useState<boolean>(false);
  const [selectedFarmingNft, setSelectedFarmingNft] = useState<IFarmingNFT | null>(null);
  const [feesDollarValue, setFeesDollarValue] = useState<string | null>(null);
  const [liquidityDollarValue, setLiquidityDollarValue] = useState<string | null>(null);
  const [currentReward, setCurrentReward] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { token0, token1, feeTier, multiplier, rewardPerBlock, apr, tvl, id } = pool;

  const tokenIds = useMemo<{ value: string }[] | undefined>(() => {
    if (userPosition) {
      return [
        ...new Set(
          userPosition.map(({ tokenId }) => ({
            value: tokenId.toString(),
          }))
        ),
      ];
    }
  }, [userPosition, locked]);

  const totalAccumulatedPilot = useMemo<number>(() => {
    return selectedFarmingNft ? selectedFarmingNft.claimedReward + Number(currentReward ?? '0') : 0;
  }, [selectedFarmingNft, currentReward]);

  const rewardDollarValue = useMemo<string>(() => {
    return new BigNumber(currentReward ?? '0').multipliedBy(pilotPrice).toString();
  }, [currentReward, pilotPrice]);

  const feesApr = useMemo(() => {
    if (poolApy) {
      const apr = poolApy[id];
      return apr ? apr : '';
    }
  }, [poolApy]);

  const isBlacklisted = useMemo(() => {
    return BLACKLISTED_POOLS.find(_id => _id === id) ? true : false;
  }, [id]);

  const hidePool = useMemo(() => {
    return (
      (selectedFarmingNft === undefined ||
        (selectedFarmingNft && !selectedFarmingNft.locked && userPosition?.length === 1) ||
        !account) &&
      isBlacklisted
    );
  }, [isBlacklisted, selectedFarmingNft, userPosition, account]);

  const clearStates = () => {
    setNftLock(false);
    setLiquidityDollarValue(null);
    setFeesDollarValue(null);
    setApproved(null);
    setCurrentReward(null);
  };

  const handleChangeDropdDown = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (userPosition) {
      const position = userPosition.filter(({ tokenId }) => tokenId === event.target.value)[0];
      setSelectedFarmingNft(position);
      clearStates();
    }
  };

  const checkNftApproval = async (tokenId: string) => {
    setApproved(await checkUnipilotNftApproval(tokenId, CONTRACT_ADDRESSES.farmingV3));
  };

  const handleUnlock = async (customCallback?: () => void) => {
    if (selectedFarmingNft && account) {
      await unlockNFT(
        selectedFarmingNft.tokenId.toString(),
        account,
        token0.symbol,
        token1.symbol,
        selectedFarmingNft.farmingVersion,
        () => {
          if (customCallback) {
            customCallback();
          } else {
            getUserLockedFarmingNftsF(farmingPools, account);
            getNFTPositionsF(account);
            setSelectedFarmingNft({ ...selectedFarmingNft, locked: false });
            setNftLock(false);
            updateFarmingTvlF(whiteListedPools);
          }
        }
      );
    }
  };

  const handleLock = async (customCallback?: () => void) => {
    if (selectedFarmingNft && account) {
      await lockNFT(
        selectedFarmingNft.tokenId.toString(),
        account,
        token0.symbol,
        token1.symbol,
        () => {
          if (customCallback) {
            customCallback();
          } else {
            setSelectedFarmingNft({
              ...selectedFarmingNft,
              locked: true,
              farmingVersion: FARMING_VERSION.V3,
            });
            setNftLock(true);
            removePositionF(selectedFarmingNft.tokenId);
            getUserLockedFarmingNftsF(farmingPools, account);
            updateFarmingTvlF(whiteListedPools);
          }
        }
      );
    }
  };

  const handleNFTApproval = async (customCallback?: () => void) => {
    if (selectedFarmingNft && account) {
      await approveUnipilotNft(
        selectedFarmingNft.tokenId.toString(),
        CONTRACT_ADDRESSES.farmingV3,
        account,
        () => {
          if (customCallback) {
            customCallback();
          } else {
            setApproved(true);
          }
        }
      );
    }
  };

  const getLiquiditDollarValue = async (nftPosition: IFarmingNFT) => {
    const fee = await colletFee(nftPosition.tokenId);
    const dollarValues = await getTokensDollarValue([token0.address, token1.address]);
    if (dollarValues) {
      const token0Dollar = new BigNumber(nftPosition.token0Reserve ?? '0').multipliedBy(
        dollarValues.token0DV
      );
      const token1Dolalr = new BigNumber(nftPosition.token1Reserve ?? '0').multipliedBy(
        dollarValues.token1DV
      );
      setLiquidityDollarValue(token0Dollar.plus(token1Dolalr).toString());
    } else {
      setLiquidityDollarValue('');
    }

    if (fee && dollarValues) {
      const token0FeeDollar = new BigNumber(
        formatAmount(fee.token0Fee.toString(), token0.decimals)
      ).multipliedBy(dollarValues.token0DV);
      const token1FeeDollar = new BigNumber(
        formatAmount(fee.token1Fee.toString(), token1.decimals)
      ).multipliedBy(dollarValues.token1DV);
      setFeesDollarValue(token0FeeDollar.plus(token1FeeDollar).toString());
    } else {
      setFeesDollarValue('');
    }

    if (fee && dollarValues) {
      const token0FeeDollar = new BigNumber(
        formatAmount(fee.token0Fee.toString(), token0.decimals)
      ).multipliedBy(dollarValues.token0DV);
      const token1FeeDollar = new BigNumber(
        formatAmount(fee.token1Fee.toString(), token1.decimals)
      ).multipliedBy(dollarValues.token1DV);
      setFeesDollarValue(token0FeeDollar.plus(token1FeeDollar).toString());
    } else {
      setFeesDollarValue('');
    }
  };

  const updateUserCurrentReward = async (tokenId: string) => {
    if (selectedFarmingNft) {
      const reward = await getCurrentReward(
        CALL_TYPE.SINGLE,
        [tokenId],
        selectedFarmingNft?.farmingVersion,
        ['pilotReward']
      );
      if (reward) {
        setCurrentReward(formatAmount(reward.pilotReward, 18));
      } else {
        setCurrentReward('');
      }
    }
  };

  const handleFeeClaim = async () => {
    if (selectedFarmingNft && account) {
      await withdrawReward(
        selectedFarmingNft.tokenId.toString(),
        account,
        token0.symbol,
        token1.symbol,
        selectedFarmingNft.farmingVersion,
        () => {
          setCurrentReward('0');
          updateFarmingNftF(id, selectedFarmingNft?.tokenId, totalAccumulatedPilot);
          setSelectedFarmingNft({
            ...selectedFarmingNft,
            claimedReward: totalAccumulatedPilot,
          });
          updateFarmingTvlF(whiteListedPools);
        }
      );
    }
  };

  const checkLockedPosition = (selectedFarmingNft: IFarmingNFT) => {
    return !nftLock && userPosition && userPosition[0]?.locked !== selectedFarmingNft?.locked;
  };

  useEffect(() => {
    if (selectedFarmingNft) {
      getLiquiditDollarValue(selectedFarmingNft);
      selectedFarmingNft.locked
        ? setNftLock(true)
        : checkNftApproval(selectedFarmingNft.tokenId.toString());
      updateUserCurrentReward(selectedFarmingNft.tokenId.toString());
    }
  }, [userPosition, selectedFarmingNft]);

  useEffect(() => {
    if (userPosition && account) {
      if (
        selectedFarmingNft === null ||
        selectedFarmingNft === undefined ||
        checkLockedPosition(selectedFarmingNft)
      ) {
        setSelectedFarmingNft(userPosition[0]);
        setCurrentReward('');
      } else {
        selectedFarmingNft &&
          selectedFarmingNft.locked &&
          updateUserCurrentReward(selectedFarmingNft.tokenId.toString());
      }
    } else {
      setSelectedFarmingNft(null);
      setCurrentReward('');
    }
  }, [userPosition, account]);

  return hidePool ? (
    <></>
  ) : (
    <>
      {selectedFarmingNft && (
        <FMigration
          open={open}
          close={() => setOpen(false)}
          tokenId={selectedFarmingNft.tokenId}
          unlockNft={handleUnlock}
          lockNft={handleLock}
          approve={handleNFTApproval}
          closeCallback={() => {
            getUserLockedFarmingNftsF(farmingPools, account);
            setSelectedFarmingNft({
              ...selectedFarmingNft,
              locked: true,
              farmingVersion: FARMING_VERSION.V3,
            });
            setNftLock(true);
          }}
        />
      )}
      <Accordion
        className={classes.bodyContent}
        expanded={expanded === pool.id}
        onChange={handleChange(pool.id)}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Box style={{ flex: !xs_ ? '30% 0 0' : 'none' }}>
            <Typography className={classes.heading}></Typography>
            <PoolInfo
              token0Address={token0.address}
              token1Address={token1.address}
              token0Symbol={token0.symbol}
              token1Symbol={token1.symbol}
              token0Uri={token0.logoURI}
              token1Uri={token1.logoURI}
              feeTier={feeTier}
            />
          </Box>
          <Common
            headValue='TVL'
            value={tvl ? formatDollarAmount(parseFloat(tvl)) : undefined}
            isMargin
          />
          <Common
            headValue='Reward Pilots / Block'
            value={'0'}
            // value={rewardPerBlock ? trunc(rewardPerBlock.toString()) : rewardPerBlock}
            showIcon
            toolTipValue={TOOLTIP_INFO.reward}
          />
          <Common
            headValue='APR'
            value={'---'}
            //value={apr && feesApr ? trunc(new BigNumber(apr).plus(feesApr).toString()) : undefined}
            subValue={
              feesApr === null ? '---' : feesApr === undefined ? feesApr : trunc(feesApr.toString())
            }
            subValue2={apr ? trunc(apr.toString()) : undefined}
            // endSymbol='%'
            endSymbol=''
            isMargin
            //showIcon
          />

          <Common
            headValue='Multiplier'
            value={multiplier ? trunc(multiplier.toString()) : undefined}
            endSymbol='x'
            showIcon
            toolTipValue={TOOLTIP_INFO.multiplier}
          />
        </AccordionSummary>
        <AccordionDetails>
          {selectedFarmingNft === null && account ? (
            <Box className={classes.loading}>
              <CircularProgress size={15} />
            </Box>
          ) : (
            <Box className={classes.bodyDetails}>
              {xs_ && (
                <Box className={classes.mobileOnly}>
                  <Typography
                    variant='h5'
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                      fontSize: '1.2rem',
                      marginTop: '5px',
                    }}
                  >
                    Pool Details
                  </Typography>
                  <Common
                    headValue='TVL'
                    value={
                      farmingPoolsTvl[id]
                        ? formatDollarAmount(parseFloat(farmingPoolsTvl[id]))
                        : undefined
                    }
                    isMobile
                  />
                  <Common
                    headValue='Reward Pilots / Block'
                    value={'0'}
                    //value={rewardPerBlock}
                    showIcon
                    isMobile
                    toolTipValue={TOOLTIP_INFO.reward}
                  />
                  <Common
                    headValue='APR'
                    // value={
                    //   apr && feesApr
                    //     ? trunc(new BigNumber(apr).plus(feesApr).toString())
                    //     : undefined
                    // }
                    value={'---'}
                    subValue={
                      feesApr === null ? '---' : feesApr === undefined ? feesApr : trunc(feesApr)
                    }
                    subValue2={apr ? trunc(apr.toString()) : undefined}
                    // endSymbol='%'
                    endSymbol=''
                    isMargin
                    //showIcon
                    isMobile
                  />

                  <Common
                    headValue='Multiplier'
                    value={multiplier ? trunc(multiplier.toString()) : undefined}
                    endSymbol='x'
                    showIcon
                    isMobile
                    toolTipValue={TOOLTIP_INFO.multiplier}
                  />
                </Box>
              )}
              <Box className={classes.bodyDetailsMainCol}>
                <Typography
                  variant='h5'
                  style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                >
                  Fee Generated
                </Typography>
                <Box style={{ border: '1px solid transparent' }}>
                  <Box className={classes.rewardCol}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: xs_ ? 'column' : 'row',
                        justifyContent: xs_ ? 'center' : 'space-between',
                        width: '100%',
                        alignItems: 'center',
                      }}
                      className={classes.rewardCol1}
                    >
                      <Box style={{ color: '#fff' }} className={classes.rewardNum}>
                        {selectedFarmingNft ? (
                          feesDollarValue === null ? (
                            <Typography className={classes.liqValue}>
                              <CircularProgress size={15} />
                            </Typography>
                          ) : feesDollarValue ? (
                            <CTooltip title={feesDollarValue}>
                              <Typography className={classes.liqValue}>{`$${trunc(
                                feesDollarValue
                              )}`}</Typography>
                            </CTooltip>
                          ) : (
                            <Typography className={classes.liqValue}>---</Typography>
                          )
                        ) : (
                          <Typography className={classes.liqValue}>---</Typography>
                        )}
                      </Box>
                      <Box>
                        {nftLock && selectedFarmingNft && !xs_ && (
                          <Typography
                            onClick={() => history.push(`/positions/${selectedFarmingNft.tokenId}`)}
                            style={{ padding: '0', fontSize: '0.8rem' }}
                            className={classes.addLiqMsg}
                          >
                            <Box
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              component='span'
                              color='primary.main'
                            >
                              {' '}
                              View position
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {nftLock && selectedFarmingNft && xs_ && (
                  <Typography
                    onClick={() => history.push(`/positions/${selectedFarmingNft.tokenId}`)}
                    style={{
                      padding: '0',
                      fontSize: '0.8rem',
                      marginBottom: '10px',
                    }}
                    className={classes.addLiqMsg}
                  >
                    <Box
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      component='span'
                      color='primary.main'
                    >
                      {' '}
                      View position
                    </Box>
                  </Typography>
                )}

                <Typography
                  variant='h5'
                  style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                >
                  Estimated Rewards
                </Typography>
                <Box style={{ border: '1px solid transparent' }}>
                  <Box className={classes.rewardCol}>
                    <Box className={classes.rewardCol1}>
                      <Box>
                        <Typography className={classes.estReward} style={{ display: 'none' }}>
                          Estimated Rewards
                        </Typography>
                        <Typography
                          style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            fontSize: '0.7rem',
                            justifyContent: xs_ ? 'center' : 'flex-start',
                          }}
                        >
                          {currentReward === null ? (
                            <Box className={classes.rewardLoader}>
                              <CircularProgress size={15} />
                            </Box>
                          ) : (
                            <Box>
                              <span className={classes.rewardNum}>${trunc(rewardDollarValue)}</span>

                              {parseFloat(currentReward) > 0 && (
                                <span className={classes.rewardDV}>{`${trunc(
                                  currentReward
                                )} PILOT`}</span>
                              )}
                            </Box>
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.rewardCol1}>
                      <ButtonIndicator
                        disableElevation
                        onClick={handleFeeClaim}
                        disabled={
                          dappLoading === `${LOADING.CLAIM_REWARD}-${selectedFarmingNft?.tokenId}`
                        }
                        onlyDisable={!(currentReward && parseFloat(currentReward) > 0)}
                        className={classes.rewardBtn}
                        variant='contained'
                        label={
                          dappLoading === `${LOADING.CLAIM_REWARD}-${selectedFarmingNft?.tokenId}`
                            ? LOADING.CLAIM_REWARD
                            : 'Claim Reward'
                        }
                        fullWidth
                        color='primary'
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className={` ${classes.bottomCol}`}></Box>
              </Box>
              <Box className={classes.bodyDetailsMainCol}>
                <Box className={classes.tokenIdCol}>
                  <Box>
                    <Typography
                      variant='h5'
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                      }}
                    >
                      Token ID
                    </Typography>

                    <Typography
                      style={{
                        fontSize: '0.8rem',
                        fontWeight: 600,
                        color: 'rgba(255,255,255,0.4)',
                      }}
                    >
                      Select the token id here
                    </Typography>
                  </Box>
                  <Box className={classes.nftCol1}>
                    <Typography className={classes.liqValue}>
                      {selectedFarmingNft && tokenIds ? (
                        tokenIds.length > 1 ? (
                          <Dropdown
                            value={selectedFarmingNft.tokenId.toString()}
                            handleChange={handleChangeDropdDown}
                            options={tokenIds}
                            formClass={classes.tokenDropdown}
                          />
                        ) : (
                          selectedFarmingNft.tokenId.toString()
                        )
                      ) : (
                        '---'
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.nftInfo}>
                  <Box style={{ width: '100%' }} className={classes.liqCol}>
                    <Typography
                      variant='h5'
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                      }}
                    >
                      Liquidity
                    </Typography>
                    <Box className={classes.liqColValue}>
                      {selectedFarmingNft ? (
                        liquidityDollarValue === null ? (
                          <Typography className={classes.liqValue}>
                            <CircularProgress size={15} />
                          </Typography>
                        ) : liquidityDollarValue ? (
                          <CTooltip title={liquidityDollarValue}>
                            <Typography className={classes.liqValue}>{`$${trunc(
                              liquidityDollarValue
                            )}`}</Typography>
                          </CTooltip>
                        ) : (
                          <Typography className={classes.liqValue}>---</Typography>
                        )
                      ) : (
                        <Typography className={classes.liqValue}>---</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                {account ? (
                  selectedFarmingNft ? (
                    //remove migration
                    selectedFarmingNft.farmingVersion !== FARMING_VERSION.V3 &&
                    !isBlacklisted &&
                    false ? (
                      <Box mt='10px'>
                        <ButtonIndicator
                          disableElevation
                          className={classes.buttonBottom}
                          style={{ margin: '0', width: '100%' }}
                          variant='contained'
                          label={'Migrate NFT'}
                          fullWidth
                          color='primary'
                          onClick={() => setOpen(true)}
                          help={'Migrate NFT to new contract for further farming'}
                        />
                      </Box>
                    ) : nftLock || approved === true ? (
                      <Box className={classes.nftBtnCol}>
                        <ButtonIndicator
                          disableElevation
                          //onlyDisable={nftLock || isBlacklisted}
                          onlyDisable={true}
                          disabled={
                            dappLoading === `${LOADING.LOCK}-${selectedFarmingNft?.tokenId}`
                          }
                          className={classes.buttonBottom}
                          variant='contained'
                          label={
                            dappLoading === `${LOADING.LOCK}-${selectedFarmingNft?.tokenId}`
                              ? LOADING.LOCK
                              : 'Stake NFT'
                          }
                          fullWidth
                          color='primary'
                          onClick={() => handleLock()}
                          help={UNIPILOT_V2_MSG}
                          //help={isBlacklisted ? BLACKLIST_MSG : undefined}
                        />
                        <ButtonIndicator
                          disableElevation
                          onlyDisable={!nftLock}
                          disabled={
                            dappLoading === `${LOADING.UNLOCK}-${selectedFarmingNft?.tokenId}`
                          }
                          className={[classes.buttonBottom].join(' ')}
                          variant='contained'
                          label={
                            dappLoading === `${LOADING.UNLOCK}-${selectedFarmingNft?.tokenId}`
                              ? LOADING.UNLOCK
                              : 'Unstake NFT'
                          }
                          color='primary'
                          fullWidth
                          onClick={() => handleUnlock()}
                        />
                      </Box>
                    ) : (
                      <Box mt='10px'>
                        {isBlacklisted ? (
                          <Typography className={classes.addLiqMsg}>{BLACKLIST_MSG}</Typography>
                        ) : (
                          <ButtonIndicator
                            disableElevation
                            onlyDisable={true}
                            // disabled={
                            //   dappLoading === `${LOADING.APPROVE}-${selectedFarmingNft?.tokenId}`
                            // }
                            className={classes.buttonBottom}
                            style={{ margin: '0', width: '100%' }}
                            variant='contained'
                            label={
                              dappLoading === `${LOADING.APPROVE}-${selectedFarmingNft?.tokenId}`
                                ? LOADING.APPROVE
                                : 'Approve Unipilot NFT'
                            }
                            fullWidth
                            color='primary'
                            onClick={() => handleNFTApproval()}
                            help={UNIPILOT_V2_MSG}
                          />
                        )}
                      </Box>
                    )
                  ) : (
                    <Box mt='10px'>
                      <Typography className={classes.addLiqMsg}>{UNIPILOT_V2_MSG}</Typography>
                      {/* {isBlacklisted ? (
                        <Typography className={classes.addLiqMsg}>{BLACKLIST_MSG}</Typography>
                      ) : (
                        <Typography className={classes.addLiqMsg}>
                          Can't locate your liquidity?
                          <Box
                            onClick={() => redirectToAddLiquidity(token0, token1, feeTier, id)}
                            style={{ cursor: 'pointer' }}
                            component='span'
                            color='primary.main'
                          >
                            {' '}
                            Add here
                          </Box>
                        </Typography>
                      )}*/}
                    </Box>
                  )
                ) : (
                  <Box mt='10px'>
                    <ConnectWalletButton />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FarmData;
