import { popsicleV3OptimizerContract } from '../getContract';
import { CALL_TYPE, MIGRATE_PROTOCOL, MIGRATE_TYPE } from '../../utils/enums';
import { PopsicleVault } from '../returnTypes';
import { DecodeType, multipleContractMultipleData, singleCall } from '../calls';
import { logError } from '../../utils/logs';
import { tokenDetail } from './erc20';
import { getPairTokenAmounts } from '../../utils/uniswapSDKFunctions';
import { IMigrateLPToken } from '../../utils/generalTypes';

const getFeeTierFromTickSpacing = (tickSpacing: string) => {
  if (tickSpacing === '200') return '10000';
  else if (tickSpacing === '60') return '3000';
  else if (tickSpacing === '10') return '500';
  else return '';
};

export const userAmounts = async <T extends keyof PopsicleVault>(
  call: CALL_TYPE,
  vaultAddresses: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'amount0',
    },
    {
      type: 'uint256',
      name: 'amount1',
    },
  ];
  if (call === CALL_TYPE.MULTI && outputs) {
    return await multipleContractMultipleData(
      [],
      popsicleV3OptimizerContract,
      vaultAddresses,
      'usersAmounts',
      types,
      'multi-usersAmounts',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = popsicleV3OptimizerContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.usersAmounts, 'single-usersAmounts');
  }
};

export const token0 = async (call: CALL_TYPE, vaultAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      popsicleV3OptimizerContract,
      vaultAddresses,
      'token0',
      types,
      'multi-popsicle-token0'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = popsicleV3OptimizerContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.token0, 'single-popsicle-token0');
  }
};

export const token1 = async (call: CALL_TYPE, vaultAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'address',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      popsicleV3OptimizerContract,
      vaultAddresses,
      'token1',
      types,
      'multi-popsicle-token1'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = popsicleV3OptimizerContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.token1, 'single-popsicle-token1');
  }
};

export const tickSpacing = async (call: CALL_TYPE, vaultAddresses: string[]) => {
  const types: DecodeType[] = [
    {
      type: 'int24',
      name: '',
    },
  ];
  if (call === CALL_TYPE.MULTI) {
    return await multipleContractMultipleData(
      [],
      popsicleV3OptimizerContract,
      vaultAddresses,
      'tickSpacing',
      types,
      'multi-popsicle-tickSpacing'
    );
  } else if (call === CALL_TYPE.SINGLE) {
    const contract = popsicleV3OptimizerContract(vaultAddresses[0]);
    return await singleCall([], contract?.methods.tickSpacing, 'single-popsicle-tickSpacing');
  }
};

export const getPopsicleVaults = async (
  data: { id: string; balance: string; totalSupply: string }[],
  account: string
): Promise<IMigrateLPToken[] | undefined> => {
  try {
    const addresses = data.map(({ id }) => id);
    const token0s = await token0(CALL_TYPE.MULTI, addresses);
    const token1s = await token1(CALL_TYPE.MULTI, addresses);
    const tickSpacings = await tickSpacing(CALL_TYPE.MULTI, addresses);
    const totalAmounts = await userAmounts(CALL_TYPE.MULTI, addresses, ['amount0', 'amount1']);
    const tokens = [...token0s, ...token1s];
    const tokenDetails = await tokenDetail(tokens, account);
    return addresses.map((address, index) => {
      const token0 = tokenDetails[token0s[index].toLowerCase()];
      const token1 = tokenDetails[token1s[index].toLowerCase()];
      const { balance, totalSupply } = data[index];
      const { amount0: total0, amount1: total1 } = totalAmounts[index];
      const { token0Reserveds, token1Reserveds } = getPairTokenAmounts(
        token0,
        token1,
        total0,
        total1,
        totalSupply,
        balance
      );
      return {
        id: address.toLowerCase(),
        token0,
        token1,
        pairAddress: address,
        liquidity: balance,
        totalSupply,
        fee: getFeeTierFromTickSpacing(tickSpacings[index]),
        protocol: MIGRATE_PROTOCOL.Popsicle,
        type: MIGRATE_TYPE.LP_TOKEN,
        token0Reserve: token0Reserveds.toSignificant(5),
        token1Reserve: token1Reserveds.toSignificant(5),
      };
    });
  } catch (e) {
    logError('getPopsicleVaults', e);
  }
};
