import { getWeb3Provider } from './getContract';
import { logError } from '../utils/logs';
import { aggregate } from './functions/multicall';

export interface DecodeType {
  type: string;
  name: string;
}

export const _singleContractMultipleData = async (
  inputs: any[],
  contractAddress: string,
  method: any,
  types: DecodeType[],
  msg: string,
  outputs?: string[]
) => {
  try {
    const web3Provider = getWeb3Provider();
    const calls = inputs.map(input => {
      return {
        target: contractAddress,
        callData: (typeof input == 'object' ? method(...input) : method(input)).encodeABI(),
      };
    });
    const encodedData = await aggregate(calls, msg);
    const decodedData = encodedData?.returnData.map(encodedValue => {
      const decodedValue = web3Provider.eth.abi.decodeParameters(
        [
          {
            component: {
              baseTickLower: 'int24',
              baseTickUpper: 'int24',
              baseLiquidity: 'uint128',
              rangeTickLower: 'int24',
              rangeTickUpper: 'int24',
              rangeLiquidity: 'uint128',
              fees0: 'uint256',
              fees1: 'uint256',
              feeGrowthGlobal0: 'uint256',
              feeGrowthGlobal1: 'uint256',
              totalLiquidity: 'uint256',
              feesInPilot: 'bool',
              oracle0: 'address',
              oracle1: 'address',
              timestamp: 'uint256',
              counter: 'uint8',
              status: 'bool',
              managed: 'bool',
            },
          },
        ],
        encodedValue
      );

      const {
        baseTickLower,
        baseTickUpper,
        rangeTickLower,
        rangeTickUpper,
        feesInPilot,
        managed,
        oracle0,
        oracle1,
      } = decodedValue[0];
      return {
        baseTickLower,
        baseTickUpper,
        rangeTickLower,
        rangeTickUpper,
        feesInPilot,
        managed,
        oracle0,
        oracle1,
      };
    });
    return decodedData;
  } catch (e) {
    logError(msg, e);
  }
};

export const singleContractMultipleData = async (
  inputs: any[],
  contractAddress: string,
  method: any,
  types: DecodeType[],
  msg: string,
  outputs?: string[]
) => {
  try {
    const web3Provider = getWeb3Provider();
    const calls = inputs.map(input => {
      return {
        target: contractAddress,
        callData: (typeof input == 'object' ? method(...input) : method(input)).encodeABI(),
      };
    });
    const encodedData = await aggregate(calls, msg);
    const decodedData = encodedData?.returnData.map(encodedValue => {
      const decodedValue = web3Provider.eth.abi.decodeParameters(types, encodedValue);
      return outputs
        ? [...outputs].reduce((a, c) => ({ ...a, [c]: decodedValue[c] }), {})
        : decodedValue[0];
    });
    return decodedData;
  } catch (e) {
    logError(msg, e);
  }
};

export const multipleContractMultipleData = async (
  inputs: any[],
  contract: any,
  contractAddresses: string[],
  methodName: string,
  types: DecodeType[],
  msg: string,
  outputs?: string[],
  catchException?: () => any
) => {
  try {
    const web3Provider = getWeb3Provider();
    const calls = contractAddresses.map((contractAddress, index) => {
      const contractInstance = contract(contractAddress);
      const method = contractInstance.methods[methodName];
      const input = inputs[index];
      if (input) {
        return {
          target: contractAddress,
          callData: (typeof input == 'object' ? method(...input) : method(input)).encodeABI(),
        };
      } else {
        return {
          target: contractAddress,
          callData: method().encodeABI(),
        };
      }
    });
    const encodedData = await aggregate(calls, 'msg');
    const decodedData = encodedData?.returnData.map(encodedValue => {
      const decodedValue = web3Provider.eth.abi.decodeParameters(types, encodedValue);
      return outputs
        ? [...outputs].reduce((a, c) => ({ ...a, [c]: decodedValue[c] }), {})
        : decodedValue[0];
    });
    return decodedData;
  } catch (e) {
    logError(msg, e);
    catchException && catchException();
  }
};

export const multipleContractSingleData = async (
  input: any,
  contract: any,
  contractAddresses: string[],
  methodName: string,
  types: DecodeType[],
  msg: string,
  outputs?: string[]
) => {
  try {
    const web3Provider = getWeb3Provider();
    const calls = contractAddresses.map((contractAddress, index) => {
      const contractInstance = contract(contractAddress);
      const method = contractInstance.methods[methodName];
      return {
        target: contractAddress,
        callData: method(input).encodeABI(),
      };
    });
    const encodedData = await aggregate(calls, 'msg');
    const decodedData = encodedData?.returnData.map(encodedValue => {
      const decodedValue = web3Provider.eth.abi.decodeParameters(types, encodedValue);
      return outputs
        ? [...outputs].reduce((a, c) => ({ ...a, [c]: decodedValue[c] }), {})
        : decodedValue[0];
    });
    return decodedData;
  } catch (e) {
    logError(msg, e);
  }
};

export const singleCall = async (input: any, method: any, msg: string) => {
  try {
    const result = await (typeof input == 'object' ? method(...input) : method(input)).call();
    return result;
  } catch (e) {
    logError(msg, e);
  }
};

export const encodeParameters = (type: string[], value: string[]): string => {
  const web3Provider = getWeb3Provider();
  return web3Provider.eth.abi.encodeParameters(type, value);
};

export const encodeParameter = (type: string, value: string): string => {
  const web3Provider = getWeb3Provider();
  return web3Provider.eth.abi.encodeParameter(type, value);
};
