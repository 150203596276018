import { Token, Rounding, Price, WETH9, CurrencyAmount } from '@uniswap/sdk-core';
import {
  tickToPrice,
  computePoolAddress,
  FACTORY_ADDRESS,
  FeeAmount,
  Pool,
  Position,
  priceToClosestTick,
  TICK_SPACINGS,
  TickMath,
} from '@uniswap/v3-sdk';
import { Pair, computePairAddress, FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk';

import { IToken, IPool, IPosition } from './generalTypes';
import { CHAIN_ID, ZERO_ADDRESS, DAI, USDC, USDT, WBTC } from '../constants';
import { parseAmount } from '../utils/formating';
import { checkRange } from '../utils/helpers';

//convert IToken into uniswap Token type
export const formatToken = (token: IToken): Token => {
  return new Token(CHAIN_ID, token.address, Number(token.decimals), token.symbol);
};

//get price from tick
export const priceFromTick = (baseToken: IToken, quoteToken: IToken, tick: number): string => {
  const _baseToken = formatToken(baseToken);
  const _quoteToken = formatToken(quoteToken);
  return tickToPrice(_baseToken, _quoteToken, tick).toSignificant(5, undefined, Rounding.ROUND_UP);
};

//convert string fee (500,3000,10000) into uniswap FeeAmount type
export const getFeeAmount = (fee: string): FeeAmount | undefined => {
  const feeAmount: FeeAmount | undefined = Object.values(FeeAmount).includes(parseFloat(fee))
    ? parseFloat(fee)
    : undefined;
  return feeAmount;
};

//compute pool address
export const findPoolAddress = (
  token0: IToken | null,
  token1: IToken | null,
  fee?: string
): string => {
  const _fee = getFeeAmount(fee ?? '0');

  if (token0 && token1 && _fee) {
    const _token0 = formatToken(token0);
    const _token1 = formatToken(token1);
    const poolAddress = computePoolAddress({
      factoryAddress: FACTORY_ADDRESS,
      tokenA: _token0,
      tokenB: _token1,
      fee: _fee,
    });

    return poolAddress;
  } else {
    return ZERO_ADDRESS;
  }
};

export const findV2PoolAddress = (token0: IToken, token1: IToken) => {
  const tokenA = formatToken(token0);
  const tokenB = formatToken(token1);
  return computePairAddress({ factoryAddress: V2_FACTORY_ADDRESS, tokenA, tokenB });
};

//convert IPool into uniswap Pool type
export const getPool = ({
  baseToken,
  quoteToken,
  feeTier,
  sqrtPrice,
  liquidity,
  tick,
}: IPool): Pool => {
  const _baseToken = formatToken(baseToken);
  const _quoteToken = formatToken(quoteToken);
  const pool = new Pool(_baseToken, _quoteToken, feeTier, sqrtPrice, liquidity, tick);
  return pool;
};

//get tokens amount w.r.t to ratio
export const getAmount = ({
  pool,
  tickLower,
  tickUpper,
  amount,
  tokenAddress,
}: IPosition): string => {
  const _pool = getPool(pool);
  if (tokenAddress === pool.baseToken.address) {
    const position = Position.fromAmount0({
      pool: _pool,
      tickLower,
      tickUpper,
      amount0: amount,
      useFullPrecision: false,
    });
    return position.amount1.toSignificant(5);
  } else {
    const _position = Position.fromAmount1({
      pool: _pool,
      tickLower,
      tickUpper,
      amount1: amount,
    });
    return _position.amount0.toSignificant(5);
  }
};

//find tickspacing w.r.t to feeTier
export const getTickSpacing = (fee: string): number => {
  const _fee = getFeeAmount(fee);
  if (_fee) {
    return TICK_SPACINGS[_fee];
  } else return TICK_SPACINGS['500']; //deafult
};

export const getPrice = (token0: IToken, token1: IToken, amount0: string, amount1: string) => {
  const baseToken = formatToken(token0);
  const quoteToken = formatToken(token1);
  const amountBase = parseAmount(amount0, baseToken.decimals);
  const amountQuote = parseAmount(amount1, quoteToken.decimals);
  const price = new Price(baseToken, quoteToken, amountBase, amountQuote);
  return price;
};

//find tick from amount/price
export const tryParseTick = (
  value: string,
  token0: IToken,
  token1: IToken,
  fee: string
): number => {
  const baseToken = formatToken(token0);
  const quoteToken = formatToken(token1);
  const amount = parseAmount(value, quoteToken.decimals);
  const amountOne = parseAmount('1', baseToken.decimals);
  const price = new Price(baseToken, quoteToken, amountOne, amount);
  const tick = priceToClosestTick(price);
  return tick;
};

//get base and quote tokens
export const getTokens = (
  token0: IToken,
  token1: IToken
): { baseToken: IToken; quoteToken: IToken } => {
  if (token0?.address > token1?.address) return { baseToken: token1, quoteToken: token0 };
  else return { baseToken: token0, quoteToken: token1 };
};

export const priceOrder = (
  _token0: IToken,
  _token1: IToken,
  tickLower: number,
  tickUpper: number
) => {
  const { baseToken, quoteToken } = getTokens(_token0, _token1);
  const token0 = formatToken(baseToken);
  const token1 = formatToken(quoteToken);

  const token0PriceLower = tickToPrice(token0, token1, tickLower);
  const token0PriceUpper = tickToPrice(token0, token1, tickUpper);

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC, USDT];
  if (stables.some(stable => stable.equals(token0))) {
    return {
      priceLower: token0PriceUpper.invert(),
      priceUpper: token0PriceLower.invert(),
      quote: token0,
      base: token1,
    };
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WETH9), WBTC];
  if (bases.some(base => base.equals(token1))) {
    return {
      priceLower: token0PriceUpper.invert(),
      priceUpper: token0PriceLower.invert(),
      quote: token0,
      base: token1,
    };
  }

  // if both prices are below 1, invert
  if (token0PriceUpper.lessThan(1)) {
    return {
      priceLower: token0PriceUpper.invert(),
      priceUpper: token0PriceLower.invert(),
      quote: token0,
      base: token1,
    };
  }

  // otherwise, just return the default
  return {
    priceLower: token0PriceLower,
    priceUpper: token0PriceUpper,
    quote: token1,
    base: token0,
  };
};

export const isValidTick = (tick: number, threshold: number = 0) => {
  return tick > TickMath.MIN_TICK + threshold && tick < TickMath.MAX_TICK - threshold;
};

export const parseCurrencyAmount = (token: Token, amount: string): CurrencyAmount<Token> => {
  return CurrencyAmount.fromRawAmount(token, amount);
};

export const getPair = (
  token0: IToken,
  token1: IToken,
  reserve0: string,
  reserve1: string
): Pair => {
  const _token0 = formatToken(token0);
  const _token1 = formatToken(token1);

  const pair = new Pair(
    parseCurrencyAmount(_token0, reserve0),
    parseCurrencyAmount(_token1, reserve1)
  );
  return pair;
};

export const getPairTokenAmounts = (
  token0: IToken,
  token1: IToken,
  reserve0: string,
  reserve1: string,
  totalSupply: string,
  userPoolBalance: string
) => {
  const pair = getPair(token0, token1, reserve0, reserve1);

  const _totalSupply = parseCurrencyAmount(pair.liquidityToken, totalSupply);
  const _userPoolBalance = parseCurrencyAmount(pair.liquidityToken, userPoolBalance);

  const token0Reserveds = pair.getLiquidityValue(
    pair.token0,
    _totalSupply,
    _userPoolBalance,
    false
  );

  const token1Reserveds = pair.getLiquidityValue(
    pair.token1,
    _totalSupply,
    _userPoolBalance,
    false
  );

  return { token0Reserveds, token1Reserveds };
};

//get nft reserves
export const getTokenReserves = (
  token0: IToken,
  token1: IToken,
  fee: string,
  liquidity: string,
  tickLower: string,
  tickUpper: string,
  poolLiquidity: string,
  sqrtPrice: string,
  tick: string
) => {
  const pool = getPool({
    baseToken: token0,
    quoteToken: token1,
    feeTier: Number(fee),
    liquidity: poolLiquidity,
    sqrtPrice,
    tick: Number(tick),
  });

  const position = new Position({
    pool,
    liquidity,
    tickLower: Number(tickLower),
    tickUpper: Number(tickUpper),
  });

  return {
    token0Reserve: parseFloat(position.amount0.toSignificant(5)),
    token1Reserve: parseFloat(position.amount1.toSignificant(5)),
    inRange: checkRange(position.tickLower, position.tickUpper, pool.tickCurrent),
  };
};
