import { useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';

import { parseAmount, formatAmount } from '../../utils/formating';
import { ButtonIndicator, WDDialog, PoolAutoPilot } from '../../components';
import { LongContentContainer, SmallContentContainer } from './Containers';
import { useNFTPositions } from '../../hooks';
import { getPilotAmountForTokens, getPilotAmountWethPair } from '../../contracts/functions/oracle';
import { WETH_ADDRESS, ZERO_ADDRESS } from '../../constants';
import { exponentialToDecimal } from '../../utils/formating';
import useStyles from './styles';

const LiquidityDetails = () => {
  const classes = useStyles();
  const { account } = useWeb3React();

  const { selectedNFTPosition } = useNFTPositions();

  const [open, setOpen] = useState<boolean>(false);
  const [pilotToken, setPilotToken] = useState<string>('');

  if (!selectedNFTPosition) {
    return null;
  }

  const {
    token0,
    token1,
    token0Reserve,
    token1Reserve,
    token0UserFees,
    token1UserFees,
    totalLiquidityDV,
    totalFeesDV,
    feesInPilot,
    owner,
    rebaseIncentive,
    oracle0,
    oracle1,
  } = selectedNFTPosition;

  const handleWithdraw = async () => {
    if (token0UserFees || token1UserFees) {
      const _token0Fee = token0UserFees ? token0UserFees.toString() : '0';
      const _token1Fee = token1UserFees ? token1UserFees.toString() : '0';
      const altOracle = oracle0 === ZERO_ADDRESS ? oracle1 : oracle0;

      let pilotTokens;
      if (token0.address === WETH_ADDRESS) {
        pilotTokens = await getPilotAmountWethPair(
          token1.address,
          parseAmount(_token1Fee, token1.decimals),
          parseAmount(_token0Fee, token0.decimals),
          altOracle
        );
      } else if (token1.address === WETH_ADDRESS) {
        pilotTokens = await getPilotAmountWethPair(
          token0.address,
          parseAmount(_token0Fee, token0.decimals),
          parseAmount(_token1Fee, token1.decimals),
          altOracle
        );
      } else {
        pilotTokens = await getPilotAmountForTokens(
          token0.address,
          token1.address,
          parseAmount(_token0Fee, token0.decimals),
          parseAmount(_token1Fee, token1.decimals),
          oracle0,
          oracle1
        );
      }
      if (pilotTokens && feesInPilot) {
        setPilotToken(formatAmount(pilotTokens, 18));
      }
      setOpen(true);
    }
  };

  return (
    <>
      <WDDialog
        open={open}
        close={() => setOpen(false)}
        modalType='Collect fee'
        pilotToken={pilotToken}
      />
      <Grid container direction='column'>
        <Grid item>
          <Container maxWidth='sm' className={classes.blackBoxContainer}>
            <LongContentContainer
              dollorSymbol
              title={'Liquidity'}
              amount={totalLiquidityDV ?? '_'}
              jsxElements={rebaseIncentive ? <PoolAutoPilot /> : <></>}
            />
            <Box className={classes.marginTop} />
            <Grid container alignItems='stretch' spacing={2}>
              <Grid item xs={6}>
                <SmallContentContainer token={token0} amount={token0Reserve} />
              </Grid>
              <Grid item xs={6}>
                <SmallContentContainer token={token1} amount={token1Reserve} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Box className={classes.marginTop} />
        <Grid item>
          <Container maxWidth='sm' className={classes.blackBoxContainer}>
            <LongContentContainer
              dollorSymbol
              title={'Unclaimed fees'}
              amount={totalFeesDV ? exponentialToDecimal(totalFeesDV, false) : '_'}
              start={false}
              jsxElements={
                owner === account &&
                (parseFloat(token0UserFees ?? '0') > 0 || parseFloat(token1UserFees ?? '0') > 0) ? (
                  <ButtonIndicator
                    onClick={handleWithdraw}
                    disableElevation
                    className={classes.buttonBottom}
                    variant='contained'
                    label='Collect'
                    color='primary'
                  />
                ) : (
                  <></>
                )
              }
            />
            <Box className={classes.marginTop} />
            <Grid container alignItems='stretch' spacing={2}>
              <Grid item xs={6}>
                <SmallContentContainer token={token0} amount={token0UserFees} />
              </Grid>
              <Grid item xs={6}>
                <SmallContentContainer token={token1} amount={token1UserFees} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default LiquidityDetails;
