import { useEffect } from 'react';
import { Input, InputAdornment, Typography } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';

import { useLiquidity, useLiquidityRatio, useLiquidityAllowance } from '../../hooks';
import useStylesInputMax from './styles';
import { formatAmount, exponentialToDecimal, toFixed } from '../../utils/formating';
import { validAmount } from '../../utils/helpers';
import { LIQUIDITY_TOKENS } from '../../utils/enums';
import { ETH_MIN_LIMIT, WETH_ADDRESS, AMOUNT_INPUT_REGEX } from '../../constants';

interface IInputMaxProps {
  type: LIQUIDITY_TOKENS;
  style?: React.CSSProperties;
  className?: string;
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const InputMax: React.FC<IInputMaxProps> = ({ type, style, className }) => {
  const classes = useStylesInputMax();

  const {
    token0,
    token1,
    amount0,
    amount1,
    tick,
    initialAmount,
    sqrtPrice,
    amount0Reserves,
    amount1Reserves,
    feeTier,
    addDataF,
  } = useLiquidity();
  const calculateRatio = useLiquidityRatio();
  const { checkAllowance } = useLiquidityAllowance();

  const calculateRespectiveValue = (
    type: LIQUIDITY_TOKENS,
    value: string,
    decimals: number,
    returnDecimals: number
  ): string => {
    if (
      checkAllowance(
        type === LIQUIDITY_TOKENS.TOKEN_0 ? LIQUIDITY_TOKENS.TOKEN_1 : LIQUIDITY_TOKENS.TOKEN_0
      ) &&
      validateAmount(value, decimals)
    ) {
      const returnedAmount = calculateRatio(type).multipliedBy(value).toPrecision(8);
      return validateAmount(returnedAmount, returnDecimals) ? returnedAmount : '0';
    } else return '';
  };

  const validateAmount = (amount: string, decimals: number) => {
    const _amount = toFixed(amount, decimals);
    return parseFloat(_amount) > 0 ? true : false;
  };

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    if (
      token0 &&
      token1 &&
      ((amount0Reserves && amount1Reserves) ||
        (parseFloat(toFixed(initialAmount ?? '0', 18)) > 0 && sqrtPrice) ||
        tick)
    ) {
      if (value === '') {
        addDataF({
          amount0: '',
          amount1: '',
        });
      } else if (value === '.') {
        addDataF({
          amount0: type === LIQUIDITY_TOKENS.TOKEN_0 ? '0.' : '',
          amount1: type === LIQUIDITY_TOKENS.TOKEN_1 ? '0.' : '',
        });
      } else if (AMOUNT_INPUT_REGEX.test(value)) {
        if (type === LIQUIDITY_TOKENS.TOKEN_0) {
          addDataF({
            amount0: value,
            amount1: calculateRespectiveValue(
              LIQUIDITY_TOKENS.TOKEN_0,
              value,
              token0.decimals,
              token1.decimals
            ),
          });
        } else {
          addDataF({
            amount0: calculateRespectiveValue(
              LIQUIDITY_TOKENS.TOKEN_1,
              value,
              token1.decimals,
              token0.decimals
            ),
            amount1: value,
          });
        }
      }
    }
  };

  const handleMax = (): void => {
    if (
      token0 &&
      token1 &&
      ((amount0Reserves && amount1Reserves) || (initialAmount && sqrtPrice) || tick)
    ) {
      if (type === LIQUIDITY_TOKENS.TOKEN_0) {
        const _amount0 = new BigNumber(formatAmount(token0?.balance ?? '', token0.decimals));
        addDataF({
          amount0:
            token0.isETH && _amount0.gt(ETH_MIN_LIMIT)
              ? _amount0.minus(ETH_MIN_LIMIT).toString()
              : _amount0.toString(),
          amount1: calculateRespectiveValue(
            LIQUIDITY_TOKENS.TOKEN_0,
            _amount0.toString(),
            token0.decimals,
            token1.decimals
          ),
        });
      } else {
        const _amount1 = new BigNumber(formatAmount(token1?.balance ?? '', token1.decimals));
        addDataF({
          amount0: calculateRespectiveValue(
            LIQUIDITY_TOKENS.TOKEN_1,
            _amount1.toString(),
            token1.decimals,
            token0.decimals
          ),
          amount1:
            token1.isETH && _amount1.gt(ETH_MIN_LIMIT)
              ? _amount1.minus(ETH_MIN_LIMIT).toString()
              : _amount1.toString(),
        });
      }
    }
  };

  const checkAmount = (): boolean | undefined => {
    if (token0 && token1 && (amount0 || amount1)) {
      if (type === LIQUIDITY_TOKENS.TOKEN_0) {
        return validAmount(token0, amount0);
      } else {
        return validAmount(token1, amount1);
      }
    }
  };

  useEffect(() => {
    if (token0 && token1 && ((amount0Reserves && amount1Reserves) || tick)) {
      addDataF({
        amount0,
        amount1: calculateRespectiveValue(
          LIQUIDITY_TOKENS.TOKEN_0,
          amount0,
          token0.decimals,
          token1.decimals
        ),
      });
    }
  }, [feeTier, amount0Reserves, amount1Reserves, tick]);

  return (
    <Input
      style={style}
      fullWidth
      className={`${className ?? classes.container} ${checkAmount() ? classes.error : ''}`}
      disableUnderline
      //id='standard-adornment-amount'
      placeholder='0.0'
      value={
        type === 'token0'
          ? exponentialToDecimal(amount0, false)
          : exponentialToDecimal(amount1, false)
      }
      onChange={handleChange}
      autoComplete='off'
      endAdornment={
        <InputAdornment position='end'>
          <Typography onClick={handleMax} className={classes.maxButton} color='primary'>
            Max
          </Typography>
        </InputAdornment>
      }
    />
  );
};

export default InputMax;
