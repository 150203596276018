import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isMobile } from 'react-device-detect';

dayjs.extend(utc);

interface ISmallChart {
  data: number[][];
}

const defaultOptions: Highcharts.Options & any = {
  chart: {
    backgroundColor: 'transparent',
    width: isMobile ? 60 : 100,
    height: isMobile ? 40 : 60,
  },
  title: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
    lineColor: 'transparent',
    tickColor: 'transparent',
    type: 'datetime',
  },
  yAxis: {
    title: {
      text: '',
    },
    gridLineWidth: 0,
    visible: false,
  },
  tooltip: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: { enableMouseTracking: false },
    line: {
      marker: {
        enabled: false,
      },
    },
  },
};

const SmallChart: React.FC<ISmallChart> = ({ data }) => {
  const options = {
    ...defaultOptions,
    series: [
      {
        data: data,
        lineColor: '#EFA618',
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SmallChart;
