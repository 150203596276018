import { useState, useMemo } from 'react';
import {
  Dialog as MuiDialog,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Grid,
  Input,
  Divider,
  ListItemAvatar,
  ListItemText,
  Button,
  Switch,
  Tooltip,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';

import { TokenLogo, Nothing } from '../';
import useStyles from './styles';
import { useTokenList, useLiquidity, useSorting } from '../../hooks';
import { CloseButton, Header } from '../index';
import { IToken, ITokenList } from '../../utils/generalTypes';
import { formatAmount, trunc, truncSymbol } from '../../utils/formating';
import { getLogoUri } from '../../utils/helpers';
import { getName, getSymbol, getDecimals, getBalance } from '../../contracts/functions/erc20';
import { WETH_ADDRESS, CHAIN_ID } from '../../constants';
import { LIQUIDITY_TOKENS, CALL_TYPE } from '../../utils/enums';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <>{value === index && children}</>
);

const sortingCheck = (accumToken: IToken, token: IToken) => {
  return (
    parseFloat(formatAmount(accumToken.balance ?? '0', accumToken.decimals)) >
    parseFloat(formatAmount(token.balance ?? '0', token.decimals))
  );
};

interface IDialog {
  open: boolean;
  close: () => void;
  setItem: (value: IToken, type?: LIQUIDITY_TOKENS) => void;
}

const Dialog: React.FC<IDialog> = ({ open, close, setItem }) => {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();

  const {
    tokens,
    activeTokenList,
    tokenList,
    addActiveTokenListF,
    addTokenListF,
    getTokenList,
    addCustomTokenF,
  } = useTokenList();
  const { token0, token1, clearDataF } = useLiquidity();
  const sortedTokens = useSorting(tokens, 'balance', sortingCheck);

  const [nav, setNav] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [tokenListInput, setTokenListInput] = useState<string>('');
  const [tokenExists, setTokenExists] = useState<boolean>(false);
  const [newTokenlist, setNewTokenlist] = useState<ITokenList | null>();
  const [newToken, setNewToken] = useState<IToken | null>(null);

  const clearFields = () => {
    setSearch('');
    setNewToken(null);
    setTokenExists(false);
  };

  const handleTokenListChange = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setTokenListInput(target.value);
    setTimeout(async () => {
      setNewTokenlist(await getTokenList(target.value));
    }, 500);
  };

  const handleNewTokenListAdd = (): void => {
    addTokenListF(newTokenlist);
    setNewTokenlist(null);
    setTokenListInput('');
  };

  const findToken = async (address: string): Promise<void> => {
    const decimals = await getDecimals(CALL_TYPE.SINGLE, [address]);
    if (decimals) {
      const name = await getName(CALL_TYPE.SINGLE, [address]);
      const symbol = await getSymbol(CALL_TYPE.SINGLE, [address]);
      const balance = await getBalance(CALL_TYPE.SINGLE, [address], account ?? '');
      const token = {
        chainId: chainId ?? CHAIN_ID,
        address,
        name,
        symbol,
        decimals,
        logoURI: getLogoUri(address),
        balance,
      };
      setNewToken(token);
    }
  };

  const onChangeSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    // if (!/^[0x0-9a-fA-F]*$/.test(value) || value.length > 42) return;
    if (Web3.utils.isAddress(value)) {
      setSearch(value);
      const _tokenExists = tokens.filter(
        (token: IToken) => token.address === value.toLowerCase() && !token.isETH
      )[0];
      if (!_tokenExists) {
        findToken(value.toLowerCase());
      } else {
        setNewToken(_tokenExists);
        setTokenExists(true);
      }
    } else {
      setSearch(value);
      setNewToken(null);
      setTokenExists(false);
    }
  };

  const handleTokenSelect = async (token: IToken): Promise<void> => {
    if (token.address === WETH_ADDRESS && token0?.address === WETH_ADDRESS) {
      setItem(token, LIQUIDITY_TOKENS.TOKEN_0);
      clearFields();
    } else if (token.address === WETH_ADDRESS && token1?.address === WETH_ADDRESS) {
      setItem(token, LIQUIDITY_TOKENS.TOKEN_1);
      clearFields();
    } else if (token.address !== token0?.address && token.address !== token1?.address) {
      setItem(token);
      clearFields();
    }
  };

  const handleTokenImport = (): void => {
    if (newToken) {
      addCustomTokenF(newToken);
      handleTokenSelect(newToken);
    }
  };

  const handleClose = (): void => {
    setTimeout(() => {
      clearFields();
    }, 0.3 * 1000);
    close();
  };

  const setActionTokenList = (val: any) => {
    addActiveTokenListF(val);
    clearDataF();
  };

  const checkSelected = (token: IToken) => {
    return (
      (token.address === token0?.address && token.isETH === token0?.isETH) ||
      (token.address === token1?.address && token.isETH === token1?.isETH)
    );
  };

  let filteredData = useMemo<IToken[]>(() => {
    return sortedTokens
      ? sortedTokens.filter(
          (token: IToken) =>
            token.symbol.toLowerCase().includes(search.toLowerCase()) ||
            token.name.toLowerCase().includes(search.toLowerCase())
        )
      : [];
  }, [search, sortedTokens]);

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      maxWidth='xl'
    >
      <TabPanel value={nav} index={0}>
        <Container className={classes.container} maxWidth='xs'>
          <Box className={classes.box}>
            <Grid container alignItems='center' justify='space-between'>
              <Grid item>
                <Typography variant='h6'>Select Token</Typography>
              </Grid>
              <Grid item>
                <CloseButton onClick={handleClose} />
              </Grid>
            </Grid>

            <Input
              className={classes.input}
              fullWidth
              placeholder='Search by token name or paste address'
              disableUnderline
              value={search}
              onChange={onChangeSearch}
            />
          </Box>
          <Divider />
          <Box className={classes.listContainer}>
            {newToken ? (
              <List>
                <ListItem
                  className={`${classes.listItem} ${
                    newToken.address === token0?.address || newToken.address === token1?.address
                      ? classes.disableListItem
                      : ''
                  }`}
                  onClick={tokenExists ? () => handleTokenSelect(newToken) : () => {}}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <TokenLogo tokenAddress={newToken.address} uri={newToken.logoURI ?? ''} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ secondary: classes.sec, primary: classes.pri }}
                    primary={truncSymbol(newToken.symbol, 10)}
                    secondary={truncSymbol(newToken.name, 22)}
                  />
                  {!tokenExists && (
                    <Button
                      style={{
                        textTransform: 'none',
                        padding: '5px 10px',
                        borderRadius: 10,
                        color: 'white',
                      }}
                      color='primary'
                      variant='contained'
                      onClick={handleTokenImport}
                    >
                      Import
                    </Button>
                  )}
                </ListItem>
              </List>
            ) : filteredData.length > 0 ? (
              <List>
                {filteredData.map((val: IToken, index: number) => (
                  <ListItem
                    key={index}
                    onClick={() => handleTokenSelect(val)}
                    className={`${classes.listItem} ${
                      checkSelected(val) ? classes.disableListItem : ''
                    }`}
                  >
                    <ListItemAvatar
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '0px 5px',
                      }}
                      className={classes.listItemAvatar}
                    >
                      <TokenLogo tokenAddress={val.address} uri={val.logoURI ?? ''} />
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ secondary: classes.sec, primary: classes.pri }}
                      primary={truncSymbol(val.symbol, 10)}
                      secondary={truncSymbol(val.name, 22)}
                    />
                    {account && (
                      <Tooltip title={formatAmount(val.balance ?? '0', val.decimals)}>
                        <Typography variant='body2'>
                          {trunc(formatAmount(val.balance ?? '0', val.decimals))}
                        </Typography>
                      </Tooltip>
                    )}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Nothing title='No results found' />
            )}
          </Box>
          <Grid className={classes.bottomSection} container direction='column' alignItems='center'>
            <Grid item>
              <Button
                disableRipple
                className={classes.BSButton}
                color='primary'
                variant='text'
                onClick={() => {
                  setNav(1);
                }}
              >
                <CreateIcon className={classes.createIcon} />
                <Typography>Manage Token Lists</Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </TabPanel>

      <TabPanel value={nav} index={1}>
        <Container className={classes.container} maxWidth='xs'>
          <Box className={classes.box}>
            <Header
              goBack={() => {
                setNav(0);
              }}
              elements={
                <Typography
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: 500,
                    marginTop: 8,
                  }}
                >
                  Manage
                </Typography>
              }
              // iconRight={<Box style={{ visibility: "hidden" }} />}
              iconRightHidden
            />

            <Input
              value={tokenListInput}
              className={classes.input}
              fullWidth
              placeholder='https:// or ipfs://'
              disableUnderline
              onChange={handleTokenListChange}
            />
            <Button
              disabled={!newTokenlist}
              onClick={handleNewTokenListAdd}
              style={{ marginTop: 10, textTransform: 'none' }}
              variant='outlined'
              color='primary'
              fullWidth
            >
              Add
            </Button>
          </Box>
          <Divider />
          <Box className={classes.listContainer02}>
            {tokenList && tokenList.length > 0 ? (
              <List>
                {tokenList.map((val, index) => (
                  <ListItem
                    onClick={() => setActionTokenList(val)}
                    key={index}
                    className={
                      activeTokenList.name === val.name
                        ? classes.selectedManage
                        : classes.listItem02
                    }
                  >
                    <ListItemAvatar className={classes.listItemAvatar}>
                      <TokenLogo tokenAddress={val.name} uri={val.logoURI} />
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ secondary: classes.sec, primary: classes.pri }}
                      primary={val.name}
                    />

                    <Switch
                      checked={activeTokenList.name === val.name}
                      onChange={() => setActionTokenList(val)}
                      name='checkedB'
                      color='primary'
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Nothing />
            )}
          </Box>
        </Container>
      </TabPanel>
    </MuiDialog>
  );
};

export default Dialog;
