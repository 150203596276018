import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  snackbarStyles: {
    // width: 250,
    alignItems: 'center',
    // alignItems: "center",
    // justifyContent: "space-between",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    //border: `1px solid ${'#333'}`,
    '& .MuiAlert-icon': {
      color: theme.palette.primary.main,
    },
    '& .MuiAlert-message': {
      fontSize: '12px',
      letterSpacing: 2,
    },
    minWidth: '250px',
  },
  etherscan: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: '4px',
    },
  },
}));
