import { BigNumber } from 'bignumber.js';

import { farmingContractV1, farmingContractV2, farmingContractV3 } from '../getContract';
import { sendTransaction } from '../sendTransaction';
import { singleContractMultipleData, singleCall, DecodeType } from '../calls';
import { CurrentReward, FarmingPoolInfo, UserLockedTokenIds } from '../returnTypes';
import { CONTRACT_ADDRESSES } from '../../constants';
import { getPositionDetails } from './uniState';
import { LOADING, CALL_TYPE, FARMING_VERSION } from '../../utils/enums';
import { IFarmingNFT, IFarmingPool } from '../../utils/generalTypes';
import { logError } from '../../utils/logs';
import { formatAmount } from '../../utils/formating';
import { fetchNftClaimedReward } from '../../data';

export const FARMING_ADDRESS = {
  [FARMING_VERSION.V1]: CONTRACT_ADDRESSES.farmingV1,
  [FARMING_VERSION.V2]: CONTRACT_ADDRESSES.farmingV2,
  [FARMING_VERSION.V3]: CONTRACT_ADDRESSES.farmingV3,
};

//farming contract w.r.t farming-version
const getFarmingContract = (farmingVersion: FARMING_VERSION) => {
  if (farmingVersion === FARMING_VERSION.V1) return farmingContractV1();
  else if (farmingVersion === FARMING_VERSION.V2) return farmingContractV2();
  else return farmingContractV3();
};

//lock NFT in Farming-v3
export const lockNFT = async (
  tokenId: string,
  walletAddress: string,
  token0Symbol: string,
  token1Symbol: string,
  callback: any = () => {}
) => {
  const contract = farmingContractV3();
  const contractFnc = contract?.methods.depositNFT(tokenId);
  const args = {
    walletAddress,
    txnMessage: `Stake ${token0Symbol}/${token1Symbol} NFT`,
    logMessage: 'lockNFT',
    dappLoading: `${LOADING.LOCK}-${tokenId}`,
    callback,
  };
  if (contractFnc) {
    await sendTransaction(contractFnc, args);
  }
};

//unlock NFT w.r.t farming-version
export const unlockNFT = async (
  tokenId: string,
  walletAddress: string,
  token0Symbol: string,
  token1Symbol: string,
  farmingVersion: FARMING_VERSION,
  callback: any = () => {}
) => {
  const contract = getFarmingContract(farmingVersion);
  const contractFnc = contract?.methods.withdrawNFT(tokenId);
  const args = {
    walletAddress,
    txnMessage: `Unstake ${token0Symbol}/${token1Symbol} NFT`,
    logMessage: 'unlockNFT',
    dappLoading: `${LOADING.UNLOCK}-${tokenId}`,
    callback,
  };
  if (contractFnc) {
    await sendTransaction(contractFnc, args);
  }
};

//whitelistedPoolInfo from Farming-v3
export const getPoolInfo = async <T extends keyof FarmingPoolInfo>(
  call: CALL_TYPE,
  poolAddresses: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'startBlock',
    },
    {
      type: 'uint256',
      name: 'globalReward',
    },
    {
      type: 'uint256',
      name: 'lastRewardBlock',
    },
    {
      type: 'uint256',
      name: 'totalLockedLiquidity',
    },
    {
      type: 'uint256',
      name: 'rewardMultiplier',
    },
    {
      type: 'uint256',
      name: 'rewardPerBlock',
    },
  ];

  const contract = farmingContractV3();
  const method = contract?.methods.poolInfo;
  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      poolAddresses,
      CONTRACT_ADDRESSES.farmingV3,
      method,
      types,
      'multi-getPoolInfo',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(poolAddresses[0], method, 'single-getPoolInfo');
  }
};

//get userLockNFT's w.r.t farming-version
export const userLockedTokenIds = async <T extends keyof UserLockedTokenIds>(
  call: CALL_TYPE,
  poolAddresses: string[],
  walletAddress: string,
  farmingVersion: FARMING_VERSION,
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'tokenCount',
    },
    {
      type: 'uint256[]',
      name: 'tokenIds',
    },
  ];
  const contract = getFarmingContract(farmingVersion);
  const method = contract?.methods.totalUserNftWRTPool;
  const inputs = poolAddresses.map(poolAddress => [walletAddress, poolAddress]);
  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      inputs,
      FARMING_ADDRESS[farmingVersion],
      method,
      types,
      'multi-userLockedTokenIds',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(inputs[0], method, 'single-userLockedTokenIds');
  }
};

//modifity lockNFTs by adding farming-verion with them
const lockedPositions = async (
  poolAddresses: string[],
  walletAddress: string,
  farmingVersion: FARMING_VERSION,
  contractAddress: string
) => {
  const lockTokenCount = await userLockedTokenIds(
    CALL_TYPE.MULTI,
    poolAddresses,
    walletAddress,
    farmingVersion,
    ['tokenCount', 'tokenIds']
  );

  const tokenIds = lockTokenCount.flatMap(({ tokenIds }: any) => [...tokenIds]);

  if (tokenIds) {
    const lockedTokenIds = tokenIds.map((tokenId: any) => ({
      tokenId: tokenId.toString(),
      contract: contractAddress,
      farmingVersion,
    }));

    return lockedTokenIds;
  }
};

//get complete data of lockNFTs
export const getUserLockedNfts = async (
  farmingPools: IFarmingPool[],
  walletAddress: string,
  modalOpen?: () => void
) => {
  try {
    const poolAddresses = farmingPools.map(({ id }) => id);

    const v1LockedPositions =
      (await lockedPositions(
        poolAddresses,
        walletAddress,
        FARMING_VERSION.V1,
        CONTRACT_ADDRESSES.farmingV1
      )) ?? [];

    const v2LockedPositions =
      (await lockedPositions(
        poolAddresses,
        walletAddress,
        FARMING_VERSION.V2,
        CONTRACT_ADDRESSES.farmingV2
      )) ?? [];

    const v3LockedPositions =
      (await lockedPositions(
        poolAddresses,
        walletAddress,
        FARMING_VERSION.V3,
        CONTRACT_ADDRESSES.farmingV3
      )) ?? [];

    if (modalOpen && (v1LockedPositions.length > 0 || v2LockedPositions.length > 0)) {
      const sessionAddress = sessionStorage.getItem('farmingInfo') ?? '';
      if (sessionAddress !== walletAddress.toLowerCase()) {
        sessionStorage.setItem('farmingInfo', walletAddress.toLowerCase());
        modalOpen();
      }
    }

    let totalLockedPositions = [...v1LockedPositions, ...v2LockedPositions, ...v3LockedPositions];

    totalLockedPositions.filter(({ tokenId }) => Number(tokenId) > 0);

    let tokenIds = totalLockedPositions.map(({ tokenId }) => tokenId);

    const positions =
      (await getPositionDetails(CALL_TYPE.MULTI, tokenIds, [
        'amount0',
        'amount1',
        'pool',
        'liquidity',
        'totalLiquidity',
      ])) ?? [];
    const rewards = await fetchNftClaimedReward(tokenIds);

    let userLockedPositions: any = {};
    positions.forEach(
      ({ amount0, amount1, pool, liquidity, totalLiquidity }: any, index: number) => {
        let _pool = pool.toLowerCase();
        const farmingPool = farmingPools.filter(({ id }) => id.toLowerCase() === _pool)[0];
        const { token0, token1 } = farmingPool;
        const { tokenId, contract, farmingVersion } = totalLockedPositions[index];
        const userShare = new BigNumber(liquidity).dividedBy(totalLiquidity);

        const position: IFarmingNFT = {
          tokenId,
          poolAddress: _pool,
          token0Reserve: new BigNumber(formatAmount(amount0, token0.decimals))
            .multipliedBy(userShare)
            .toString(),
          token1Reserve: new BigNumber(formatAmount(amount1, token1.decimals))
            .multipliedBy(userShare)
            .toString(),
          liquidity,
          totalLiquidity,
          locked: true,
          lockedContract: contract,
          farmingVersion,
          claimedReward: rewards ? Number(formatAmount(rewards[tokenId], 18)) : 0,
        };
        userLockedPositions[_pool] = [...(userLockedPositions[_pool] ?? []), position];
      }
    );

    return userLockedPositions;
  } catch (e) {
    logError('getUserLockedNfts', e);
  }
};

//farming unlcaimed reward w.r.t farming-version
export const getCurrentReward = async <T extends keyof CurrentReward>(
  call: CALL_TYPE,
  inputs: string[],
  farmingVersion: FARMING_VERSION,
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: 'pilotReward',
    },
    {
      type: 'uint256',
      name: 'globalReward',
    },
  ];
  const contract = getFarmingContract(farmingVersion);
  const method = contract?.methods.currentReward;
  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      inputs,
      FARMING_ADDRESS[farmingVersion],
      method,
      types,
      'multi-getCurrentReward',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(inputs[0], method, 'single-getCurrentReward');
  }
};

//collect farming reward w.r.t farming-version
export const withdrawReward = async (
  tokenId: string,
  walletAddress: string,
  token0Symbol: string,
  token1Symbol: string,
  farmingVersion: FARMING_VERSION,
  callback: any = () => {}
) => {
  const contract = getFarmingContract(farmingVersion);
  const contractFnc = contract?.methods.withdrawReward(tokenId);
  const args = {
    walletAddress,
    txnMessage: `Withdraw ${token0Symbol}/${token1Symbol} reward`,
    logMessage: 'withdrawReward',
    dappLoading: `${LOADING.CLAIM_REWARD}-${tokenId}`,
    addToken: true,
    callback,
  };
  if (contractFnc) {
    await sendTransaction(contractFnc, args);
  }
};

//global pilot reward per block in farming-v3
export const getPilotPerBlock = async () => {
  try {
    const contract = farmingContractV3();
    const pilotPerBlock = await contract?.methods.pilotPerBlock().call();
    return pilotPerBlock;
  } catch (e) {
    logError('pilotPerBlock', e);
  }
};
