import useStyles from "./styles";

import { classesJoin } from "../../utils/helpers";
import loud from "../../assets/others/loud.png";
import metaMask from "../../assets/wallets/metamask.png";
import coinbase from "../../assets/wallets/coinbase.png";
import fortmatic from "../../assets/wallets/fortmatic.png";
import injected from "../../assets/wallets/injected.png";
import portis from "../../assets/wallets/portis.png";
import walletconnect from "../../assets/wallets/walletconnect.png";
import check from "../../assets/others/check.png";
import remove from "../../assets/others/remove.png";
import block from "../../assets/others/block.png";
import logo from "../../assets/others/logo.png";
import logoTick from "../../assets/others/logo-tick.svg";
import warning from "../../assets/others/warning.png";
import confirmSelected from "../../assets/others/confirm-selected.png";
import confirmUnselected from "../../assets/others/confirm-unselected.png";
import unipilotToken from "../../assets/tokens/pilotToken.svg";
import drop from "../../assets/svgs/dollarDrop.svg";
import chart from "../../assets/svgs/chart.svg";
import uniswapLogo from "../../assets/svgs/uniswapLogo.svg";
import sushiswapLogo from "../../assets/others/sushiswap.png";
import visorLogo from "../../assets/others/visor.png";
import lixirLogo from "../../assets/svgs/lixir.svg";
import pilotIcon from "../../assets/svgs/pilot.svg";
import Gas from "../../assets/svgs/Gas.svg";
import propsicleLogo from "../../assets/others/propsicle.jpg";

interface Image {
  className?: string;
}

export const PilotToken: React.FC<Image> = ({ className }) => {
  const classes = useStyles();
  return (
    <img
      className={classesJoin([classes.pilotToken, className ?? ""])}
      src={unipilotToken}
    />
  );
};

export const Loud: React.FC<Image> = ({ className }) => {
  return <img className={className} src={loud} />;
};

export const MetaMask: React.FC<Image> = ({ className }) => {
  return <img className={className} src={metaMask} />;
};

export const CoinBase: React.FC<Image> = ({ className }) => {
  return <img className={className} src={coinbase} />;
};

export const FortMatic: React.FC<Image> = ({ className }) => {
  return <img className={className} src={fortmatic} />;
};

export const Injected: React.FC<Image> = ({ className }) => {
  return <img className={className} src={injected} />;
};

export const Portis: React.FC<Image> = ({ className }) => {
  return <img className={className} src={portis} />;
};

export const WalletConnect: React.FC<Image> = ({ className }) => {
  return <img className={className} src={walletconnect} />;
};

export const Check: React.FC<Image> = ({ className }) => {
  return <img className={className} src={check} />;
};

export const Remove: React.FC<Image> = ({ className }) => {
  return <img className={className} src={remove} />;
};

export const Block: React.FC<Image> = ({ className }) => {
  return <img className={className} src={block} />;
};

export const Logo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={logo} />;
};

export const LogoTick: React.FC<Image> = ({ className }) => {
  return <img className={className} src={logoTick} />;
};

export const ConfirmSelected: React.FC<Image> = ({ className }) => {
  return <img className={className} src={confirmSelected} />;
};

export const ConfirmUnselected: React.FC<Image> = ({ className }) => {
  return <img className={className} src={confirmUnselected} />;
};

export const Warning: React.FC<Image> = ({ className }) => {
  return <img className={className} src={warning} />;
};

export const DollarDrop: React.FC<Image> = ({ className }) => {
  return <img className={className} src={drop} />;
};

export const LiquidityChart: React.FC<Image> = ({ className }) => {
  return <img className={className} src={chart} />;
};

export const UniswapLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={uniswapLogo} alt="uniswapLogo" />;
};

export const SushiswapLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={sushiswapLogo} alt="sushiswapLogo" />;
};

export const VisorLogo: React.FC<Image> = ({ className }) => {
  return (
    <img
      className={className}
      style={{ borderRadius: "50%" }}
      src={visorLogo}
      alt="visorLogo"
    />
  );
};

export const LixirLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={lixirLogo} alt="visorLogo" />;
};

export const GasLogo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={Gas} alt="GasLogo" />;
};

export const PopsicleLogo: React.FC<Image> = ({ className }) => {
  return (
    <img
      className={className}
      style={{ borderRadius: "50%" }}
      src={propsicleLogo}
      alt="popsicleLogo"
    />
  );
};

export const PilotIcon: React.FC<Image> = ({ className }) => {
  const classes = useStyles();
  return (
    <img
      className={[classes.pilotIcon, className].join(" ")}
      style={{ position: "absolute", left: 0, top: 0 }}
      src={pilotIcon}
      alt="visorLogo"
    />
  );
};
