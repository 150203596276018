import Web3 from 'web3';
import { provider } from 'web3-core';
import { BaseContract } from './types/types';

import { logError } from '../utils/logs';
import { CONTRACT_ADDRESSES, NETWORK_URL, ALCHEMY_URL } from '../constants';

import erc20Abi from './abis/ERC20.json';
import balanceAbi from './abis/BalanceContract.json';
import multicallAbi from './abis/Multicall.json';
import unipilotAbi from './abis/Unipilot.json';
import liquidityManagerAbi from './abis/LiquidityManager.json';
import uniStateAbi from './abis/UniState.json';
import oracleAbi from './abis/Oracle.json';
import uniswapV2PairAbi from './abis/UniswapV2Pair.json';
import uniswapV3Factory from './abis/UniswapV3Factory.json';
import nonFungiblePositionManagerAbi from './abis/NonFungiblePositionManager.json';
import poolAbi from './abis/Pool.json';
import liquidityMigratorAbi from './abis/LiquidityMigrator.json';
import sushiFactoryAbi from './abis/SushiFactory.json';
import farmingAbi from './abis/Farming.json';
import farming2Abi from './abis/Farming2.json';
import farming3Abi from './abis/Farming3.json';
import hypervisorFactoryAbi from './abis/HypervisorFactory.json';
import hypervisorAbi from './abis/Hypervisor.json';
import lixirFactoryAbi from './abis/LixirFactory.json';
import lixirVaultAbi from './abis/LixirVault.json';
import popsicleV3OptimizerAbi from './abis/PopsicleV3Optimizer.json';

import { ERC20 } from './types/ERC20';
import { BalanceContract } from './types/BalanceContract';
import { Multicall } from './types/Multicall';
import { Unipilot } from './types/Unipilot';
import { LiquidityManager } from './types/LiquidityManager';
import { UniState } from './types/UniState';
import { Oracle } from './types/Oracle';
import { UniswapV2Pair } from './types/UniswapV2Pair';
import { UniswapV3Factory } from './types/UniswapV3Factory';
import { NonFungiblePositionManager } from './types/NonFungiblePositionManager';
import { Pool } from './types/Pool';
import { LiquidityMigrator } from './types/LiquidityMigrator';
import { SushiFactory } from './types/SushiFactory';
import { Farming } from './types/Farming';
import { Farming2 } from './types/Farming2';
import { Farming3 } from './types/Farming3';
import { HypervisorFactory } from './types/HypervisorFactory';
import { Hypervisor } from './types/Hypervisor';
import { LixirFactory } from './types/LixirFactory';
import { LixirVault } from './types/LixirVault';
import { PopsicleV3Optimizer } from './types/PopsicleV3Optimizer';

let web3: any;
let web3Infura: any;
let web3Alchemy: any;

try {
  web3 = new Web3(window?.web3?.currentProvider);
  web3Infura = new Web3(NETWORK_URL);
  web3Alchemy = new Web3(ALCHEMY_URL);
} catch (e) {
  logError('Connect Web3', e);
}

export const setWeb3Provider = (provider: provider): void => {
  web3 = new Web3(provider);
};

const getContract = <T extends BaseContract>(
  abi: any,
  contractAddress: string,
  logMsg: string,
  withArchiveNode?: boolean
): T | undefined => {
  let contract: T;
  try {
    if (window?.web3?.currentProvider || web3.provider) {
      contract = new web3.eth.Contract(abi, contractAddress);
    } else {
      contract = withArchiveNode
        ? new web3Alchemy.eth.Contract(abi, contractAddress)
        : new web3Infura.eth.Contract(abi, contractAddress);
    }
    return contract as any as T;
  } catch (e) {
    logError(logMsg, e);
  }
};

export const erc20TokenContract = (tokenAddress: string): ERC20 | undefined => {
  return getContract(erc20Abi, tokenAddress, 'erc20TokenContract');
};

export const balanceContract = (): BalanceContract | undefined => {
  return getContract(balanceAbi, CONTRACT_ADDRESSES.balance, 'balanceContract');
};

export const multicallContract = (): Multicall | undefined => {
  return getContract(multicallAbi, CONTRACT_ADDRESSES.multicall, 'multicallContract');
};

export const unipilotContract = (): Unipilot | undefined => {
  return getContract(unipilotAbi, CONTRACT_ADDRESSES.unipilot, 'unipilotContract');
};

export const liquidityManagerContract = (
  withArchiveNode?: boolean
): LiquidityManager | undefined => {
  return getContract(
    liquidityManagerAbi,
    CONTRACT_ADDRESSES.liquidityManager,
    'liquidityManagerContract',
    withArchiveNode
  );
};

export const uniStateContract = (): UniState | undefined => {
  return getContract(uniStateAbi, CONTRACT_ADDRESSES.uniState, 'uniStateContract');
};

export const oracleContract = (): Oracle | undefined => {
  return getContract(oracleAbi, CONTRACT_ADDRESSES.oracle, 'oracleContract');
};

export const uniswapV2PairContract = (pairAddress: string): UniswapV2Pair | undefined => {
  return getContract(uniswapV2PairAbi, pairAddress, 'uniswapV2PairContract');
};

export const uniswapV3FactoryContract = (): UniswapV3Factory | undefined => {
  return getContract(
    uniswapV3Factory,
    CONTRACT_ADDRESSES.uniswapV3Factory,
    'uniswapV3FactoryContract'
  );
};

export const nonFungiblePositionManager = (): NonFungiblePositionManager | undefined => {
  return getContract(
    nonFungiblePositionManagerAbi,
    CONTRACT_ADDRESSES.nonfungiblePositionManager,
    'nonFungiblePositionManager'
  );
};

export const poolContract = (poolAddress: string): Pool | undefined => {
  return getContract(poolAbi, poolAddress, 'poolContract');
};

export const liquidityMigratorContract = (): LiquidityMigrator | undefined => {
  return getContract(
    liquidityMigratorAbi,
    CONTRACT_ADDRESSES.liquidityMigrator,
    'liquidityMigratorContract'
  );
};

export const sushiFactoryContract = (): SushiFactory | undefined => {
  return getContract(sushiFactoryAbi, CONTRACT_ADDRESSES.sushiFactory, 'sushiFactoryContract');
};

export const farmingContractV1 = (): Farming | undefined => {
  return getContract(farmingAbi, CONTRACT_ADDRESSES.farmingV1, 'farmingContractV1');
};

export const farmingContractV2 = (): Farming2 | undefined => {
  return getContract(farming2Abi, CONTRACT_ADDRESSES.farmingV2, 'farmingContractV2');
};

export const farmingContractV3 = (): Farming2 | undefined => {
  return getContract(farming3Abi, CONTRACT_ADDRESSES.farmingV3, 'farmingContractV3');
};

export const hypervisorFactoryContract = (): HypervisorFactory | undefined => {
  return getContract(
    hypervisorFactoryAbi,
    CONTRACT_ADDRESSES.hypervisorFactory,
    'hypervisorFactoryContract'
  );
};

export const hypervisorContract = (hypervisorAddress: string): Hypervisor | undefined => {
  return getContract(hypervisorAbi, hypervisorAddress, 'hypervisorContract');
};

export const lixirFactoryContract = (): LixirFactory | undefined => {
  return getContract(lixirFactoryAbi, CONTRACT_ADDRESSES.lixirFactory, 'lixirFactoryContract');
};

export const lixirVaultContract = (vaultAddress: string): LixirVault | undefined => {
  return getContract(lixirVaultAbi, vaultAddress, 'lixirVaultContract');
};

export const popsicleV3OptimizerContract = (
  vaultAddress: string
): PopsicleV3Optimizer | undefined => {
  return getContract(popsicleV3OptimizerAbi, vaultAddress, 'popsicleV3OptimizerContract');
};

export const getWeb3Provider = () => {
  if (window?.web3?.currentProvider || web3) return web3;
  else return web3Infura;
};

export const getETHBalance = (wallet: string) => {
  if (window?.web3?.currentProvider || web3.provider) return web3.eth.getBalance(wallet);
  else return web3Infura.eth.getBalance(wallet);
};

export const getCurrentBlock = async () => {
  const web3Provider = getWeb3Provider();
  return await web3Provider.eth.getBlockNumber();
};
