import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -8,
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 9px)",
    top: -21,
    fontSize: "0.8rem",
    "& *": {
      background: "transparent",
      color: theme.palette.primary.main,
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  tooltip: {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
  },
  sliderPerc: {
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
  },
  arrow: {
    height: 0,
  },
}));
