import axios from 'axios';

import { uniswapV3Client as client } from '../apollo/client';
import { GET_TOKENS, GET_TOKENS_DERIVED_ETH } from '../apollo/queries';
import { UN_KNOWN_LOGO } from '../constants';
import { logError } from '../utils/logs';
import { IToken } from '../utils/generalTypes';
import { typeCastSubgraphToken } from '../utils/helpers';

export const getTokenLogo = async (uri: string): Promise<string> => {
  let _uri = uri;
  try {
    if (uri.startsWith('ipfs')) {
      const _val = uri?.split('//');
      _uri = 'https://ipfs.io/ipfs/' + _val[1];
    }
    const res = await axios.get(_uri);
    if (res) {
      return _uri;
    } else {
      return UN_KNOWN_LOGO;
    }
  } catch (error) {
    logError('Token Logo', error);
    return UN_KNOWN_LOGO;
  }
};
export interface IDollarValue {
  [key: string]: { usd: number };
}

export const fetchTokensDollarValue = async (tokenAddresses: string[]) => {
  try {
    const uri = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${tokenAddresses}&vs_currencies=USD`;
    const res = await axios.get<IDollarValue>(uri);
    if (res) {
      return res.data;
    }
  } catch (e) {
    logError('fetchTokenDollarValue', e);
  }
};
interface TokensData {
  tokens: {
    id: string;
    name: string;
    symbol: string;
    decimals: string;
  }[];
}

export const fetchTokensFromUniswapSubgraph = async (tokenAddresses: string[]) => {
  try {
    const { data, error, loading } = await client.query<TokensData>({
      query: GET_TOKENS,
      variables: {
        tokenAddresses,
      },
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      const formattedTokens = tokenAddresses.reduce(
        (accum: { [key: string]: IToken }, address: string) => {
          const subgraphToken = data.tokens.find(({ id }) => id === address);
          if (subgraphToken) {
            accum[address] = typeCastSubgraphToken(subgraphToken);
          }
          return accum;
        },
        {}
      );

      return formattedTokens;
    }
    logError('fetchTokensFromUniswapSubgraph-e', error);
  } catch (e) {
    logError('fetchTokensFromUniswapSubgraph', e);
  }
};

interface TokenDerivedEth {
  tokens: {
    id: string;
    derivedETH: string;
  }[];
  bundles: {
    ethPriceUSD: string;
  }[];
}

export const fetchTokensDerivedETH = async (tokenAddresses: string[]) => {
  try {
    const { data, error, loading } = await client.query<TokenDerivedEth>({
      query: GET_TOKENS_DERIVED_ETH,
      variables: {
        tokenAddresses,
      },
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      const ethPrice = parseFloat(data.bundles[0]?.ethPriceUSD);
      const tokenDV = data.tokens.reduce(
        (accum: { [key: string]: number }, token: { id: string; derivedETH: string }) => {
          accum[token.id] = ethPrice * parseFloat(token.derivedETH);
          return accum;
        },
        {}
      );
    }
    logError('fetchTokensDerivedETH-e', error);
  } catch (e) {
    logError('fetchTokensDerivedETH => ', e);
  }
};
