import { useState } from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useWeb3React } from "@web3-react/core";

import {
  IMDialog,
  Header,
  CloseButton,
  RowAlignCenter,
  MetaMask,
  CoinBase,
  FortMatic,
  WalletConnect,
  Portis,
  ButtonIndicator,
  Injected,
} from "../";
import useStyles from "./styles";
import { useActivateWallet, useEagerConnect } from "../../hooks/walletConnect";
import { walletList } from "../../utils/web3Connectors";

interface IWDialog {
  open: boolean;
  close?: () => void;
}

interface WalletIcons {
  Metamask: JSX.Element;
  WalletConnect: JSX.Element;
  Coinbase: JSX.Element;
  Fortmatic: JSX.Element;
  Portis: JSX.Element;
  Injected: JSX.Element;
}

const WDialog: React.FC<IWDialog> = ({ open, close = () => {} }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<string>("");
  const activateWallet = useActivateWallet();
  const triedEager = useEagerConnect();
  const { connector } = useWeb3React();

  const walletIcons: WalletIcons = {
    Metamask: <MetaMask className={classes.wIcon} />,
    WalletConnect: <WalletConnect className={classes.wIcon} />,
    Coinbase: <CoinBase className={classes.wIcon} />,
    Fortmatic: <FortMatic className={classes.wIcon} />,
    Portis: <Portis className={classes.wIcon} />,
    Injected: <Injected className={classes.wIcon} />,
  };

  const connectWallet = async (
    connector: AbstractConnector,
    wallet: string
  ): Promise<void> => {
    setLoading(wallet);
    await activateWallet(connector, () => {
      close();
    });
    setLoading("");
  };

  const walletsReady = walletList.map((wallet, idx) => (
    <Grid key={idx} item>
      <ButtonIndicator
        style={{
          paddingLeft: loading === wallet.name ? "40px" : undefined, // used for loading
        }}
        disabled={loading === wallet.name}
        variant="outlined"
        className={`${classes.WContainer} ${
          connector instanceof wallet.connectorType ? classes.active : ""
        }`}
        onClick={() => {
          connectWallet(wallet.connector, wallet.name);
        }}
        fullWidth
        loaderSize={15}
        progressStyle={{ left: "8%", top: "64%" }}
      >
        <RowAlignCenter
          elements01={<Typography>{wallet.name}</Typography>}
          elements02={walletIcons[wallet.name as keyof WalletIcons]}
        />
      </ButtonIndicator>
    </Grid>
  ));

  return (
    <IMDialog open={open} onClose={close}>
      <Container className={classes.rootContainer} maxWidth="xs">
        <Header
          disableGoBack={true}
          elements={
            <Typography style={{ marginTop: 10 }} variant="h5">
              Wallets
            </Typography>
          }
          iconRight={<CloseButton onClick={close} />}
        />
        <Box className={classes.marginTop} />
        <Grid container className={classes.rootWContainer} direction="column">
          {walletsReady}
        </Grid>
      </Container>
    </IMDialog>
  );
};

export default WDialog;
