import { balanceContract } from '../getContract';
import { logError } from '../../utils/logs';

export const getBalances = async (
  walletAddress: string,
  tokenAddresses: string[]
): Promise<string[] | undefined> => {
  try {
    const contract = balanceContract();
    const balances = await contract?.methods.getBalances(walletAddress, tokenAddresses).call();
    return balances;
  } catch (e) {
    logError('getBalances', e);
  }
};
