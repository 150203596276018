import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { LP_TOKENS } from '../apollo/queries';
import { logError } from '../utils/logs';
import { ISubgraphToken } from '../utils/generalTypes';

interface LPTokenDetails {
  liquidityPositions: {
    pair: {
      id: string;
      token0: ISubgraphToken;
      token1: ISubgraphToken;
      totalSupply: string;
      reserve0: string;
      reserve1: string;
    };
  }[];
}

export const fetchLpTokens = async (
  client: ApolloClient<NormalizedCacheObject>,
  account: string
) => {
  try {
    const { data, error, loading } = await client.query<LPTokenDetails>({
      query: LP_TOKENS,
      variables: {
        user: account.toLowerCase(),
      },
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      return data.liquidityPositions;
    }
    logError('fetchLpTokens-e', error);
  } catch (e) {
    logError('fetchLpTokens', e);
  }
};
