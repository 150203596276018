import { useMemo } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  BoxProps,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import AddIcon from '@material-ui/icons/Add';

import { useNFTPositions } from '../../hooks';
import { INFTPosition, IToken } from '../../utils/generalTypes';
import {
  Nothing,
  Header,
  ContainerLoader,
  WithPagination,
  ButtonIndicator,
  PoolInfo,
  RangeInfo,
  RowAlignCenter,
  PoolAutoPilot,
} from '../../components';
import useStyles from './styles';
import Amount from './Amount';
import { priceOrder } from '../../utils/uniswapSDKFunctions';
import { truncSymbol } from '../../utils/formating';

function LiquidityPosition() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  const { nftPositions, pageCount, setPageCountF } = useNFTPositions();
  const { account } = useWeb3React();

  return (
    <Container className={classes.mainRoot} maxWidth={false}>
      <Box className={classes.topBtnWrapper}>
        <ButtonIndicator
          onClick={() => {
            history.push('/');
          }}
          disableElevation
          className={classes.buttonTop}
          variant='contained'
          fullWidth
          color='primary'
        >
          <AddIcon style={{ fontSize: '0.9rem' }} />
          <Typography style={{ fontSize: '0.9rem', fontWeight: 500, marginRight: '5px' }}>
            New Position
          </Typography>
        </ButtonIndicator>
      </Box>

      <Box className={classes.marginTop} />

      <Box className={classes.conatiner}>
        <Header
          elements={
            <Typography style={{ marginTop: 8 }} variant='h5'>
              {xs_ ? 'Positions' : 'Liquidity Positions'}
            </Typography>
          }
          disableGoBack
        />
        <Box className={classes.marginTop} />

        {nftPositions ? (
          <WithPagination
            containerClassName={classes.parent}
            nothingContainerClassName={classes.parent}
            rowsPerPage={xs_ ? 3 : 4}
            page={pageCount}
            items={[...nftPositions].reverse()}
            setPage={setPageCountF}
            func={(nftPosition: INFTPosition) => {
              return <ContainerBox key={nftPosition.tokenId} nftPosition={nftPosition} />;
            }}
          />
        ) : (
          <Nothing containerClassName={classes.parent}>{account && <ContainerLoader />}</Nothing>
        )}
      </Box>

      <Box className={classes.marginTop} />
    </Container>
  );
}

interface IContainerBox {
  nftPosition: INFTPosition;
}

const ContainerBox: React.FC<IContainerBox> = ({ nftPosition }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const _xs = useMediaQuery(theme.breakpoints.down('xs'));

  const { selectNFTPositionF } = useNFTPositions();
  const {
    tokenId,
    token0,
    token1,
    fee,
    inRange,
    baseTickLower,
    baseTickUpper,
    liquidity,
    rebaseIncentive,
  } = nftPosition;

  const handleSelect = () => {
    selectNFTPositionF(nftPosition);
    history.push(`/positions/${tokenId}`);
  };

  return (
    <Button
      className={classes.buttonContainer}
      variant='outlined'
      onClick={handleSelect}
      disableRipple={true}
      fullWidth
    >
      <Grid container direction='column'>
        <Grid item>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <RowAlignCenter
                elements01={
                  <PoolInfo
                    token0Address={token0.address}
                    token1Address={token1.address}
                    token0Symbol={token0.symbol}
                    token1Symbol={token1.symbol}
                    token0Uri={token0.logoURI}
                    token1Uri={token1.logoURI}
                    feeTier={fee}
                  />
                }
                elements02={Number(rebaseIncentive) > 0 ? <PoolAutoPilot /> : null}
              />
            </Grid>
            <Grid item>
              <RangeInfo closed={Number(liquidity) === 0} inRange={inRange} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box mt={'5px'} />
          <MinMaxContainer
            data={{
              tokenId,
              token0,
              token1,
              baseTickLower,
              baseTickUpper,
            }}
            justify='flex-start'
          />
          {_xs && (
            <Box>
              <Typography className={[classes.price, classes.amount].join(' ')}>
                <span style={{ color: theme.palette.primary.main }}>Token ID: </span>
                {tokenId}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Button>
  );
};

export default LiquidityPosition;

interface IMinMaxContainer {
  data: {
    tokenId: number;
    token0: IToken;
    token1: IToken;
    baseTickLower: string;
    baseTickUpper: string;
  };
  justify?: BoxProps['justifyContent'];
}

const MinMaxContainer: React.FC<IMinMaxContainer> = ({ data, justify = 'space-between' }) => {
  const { tokenId, token0, token1, baseTickLower, baseTickUpper } = data;

  const theme = useTheme();
  const _xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const { priceLower, priceUpper, quote, base } = useMemo(() => {
    return priceOrder(token0, token1, Number(baseTickLower), Number(baseTickUpper));
  }, [token0, token1, baseTickLower, baseTickUpper]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: _xs ? 'space-between' : 'flex-start',
        marginTop: _xs ? '10px' : '0',
      }}
    >
      <Box className={[classes.price, classes.amount].join(' ')}>
        {!_xs && <span style={{ color: theme.palette.primary.main }}>Min: </span>}
        <Amount
          amount={priceLower.toSignificant(5)}
          dollorSymbol={false}
          len={10}
          endSymbol=''
          className={classes.amountClass}
        />
        <Typography style={{ fontSize: '0.8rem' }}>
          {truncSymbol(quote.symbol ?? '', 6)} per {truncSymbol(base.symbol ?? '', 6)}
        </Typography>
      </Box>
      <Box
        style={{
          borderRight: '1px solid rgba(255,255,255,0.4)',
          height: '12px',
        }}
      />
      <Box className={[classes.price, classes.amount].join(' ')}>
        {!_xs && <span style={{ color: theme.palette.primary.main }}>Max: </span>}
        <Amount
          amount={priceUpper.toSignificant(5)}
          dollorSymbol={false}
          len={10}
          endSymbol=''
          className={classes.amountClass}
        />
        <Typography style={{ fontSize: '0.8rem' }}>
          {truncSymbol(quote.symbol ?? '', 6)} per {truncSymbol(base.symbol ?? '', 6)}
        </Typography>
      </Box>
      {!_xs && (
        <>
          <Box
            style={{
              borderRight: '1px solid rgba(255,255,255,0.4)',
              height: '12px',
            }}
          />
          <Typography className={[classes.price, classes.amount].join(' ')}>
            <span style={{ color: theme.palette.primary.main, marginRight: '2px' }}>
              Token ID:{' '}
            </span>
            {tokenId}
          </Typography>
        </>
      )}
    </Box>
  );
};
