import { makeStyles } from "@material-ui/core";
import { transparent } from "material-ui/styles/colors";

const useStyles = makeStyles((theme: any) => ({
  dialogPaperTrans: {
    maxWidth: 410,
    // maxWidth: 410,
    width: "100vw",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  dialogPaperTransMigration: {
    maxWidth: 600,
    // maxWidth: 410,
    width: "100vw",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  dialogPaperTransDisclaimer: {
    maxWidth: 700,
    // maxWidth: 410,
    width: "100vw",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  paperWidthSm: {
    maxWidth: 400,
  },
  dialogPaper: {
    width: "100vw",
    maxWidth: 400,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  dialodPaperHeight: {
    height: "475px",
    // overflow: "hidden",
  },
  listItem: {
    padding: "0px 1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: "pointer",
    },
    marginTop: 5,
  },
  listItemIm: {
    padding: "10px 1rem !important",
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: "pointer",
    },
    marginTop: 5,
  },
  listItem02: {
    // padding: "0px 1rem",
    border: `1px solid rgba(255,255,255,0.15)`,

    "&:hover": {
      // backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
    },
    backgroundColor: `${theme.palette.secondary.main} !important`,
    marginTop: 10,
    padding: "10px 15px",
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
  },
  selected: {
    backgroundColor: theme.palette.common.hoverBC01,
  },
  listItemAvatar: {
    minWidth: "40px !important",
  },
  input: {
    backgroundColor: `${theme.palette.secondary.main} !important`, //"#fafafa",
    border: "1px solid rgba(255,255,255,0.15)",
    padding: "5px 15px",
    borderRadius: theme.shape.borderRadius,
    marginTop: 15,
    fontSize: "0.8rem !important",
  },
  container: {
    padding: 0,
  },
  box: {
    padding: "0px 20px",
    margin: "20px 0px",
  },
  box02: {
    padding: "0px 20px",
    marginTop: "20px",
    marginBottom: 10,
  },
  sec: {
    fontSize: "0.7rem",
    color: "#B9B9B9",
  },
  pri: {
    fontSize: "0.9rem",
    fontWeight: 500,
  },
  num: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  avatarRoot: {
    width: 25,
    height: 25,
    backgroundColor: "transparent",
  },
  MUIContainer: {
    // filter: "blur(4px)",
    // filter: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='blur10'><feGaussianBlur  stdDeviation='10' /></filter></svg>#blur10")`,
    backdropFilter: "blur(10px)",

    backgroundColor: "rgba(221, 221, 255,0.2) !important",
  },
  marginTop: {
    marginTop: 15,
  },
  WContainer: {
    // backgroundColor: theme.palette.secondary.main,
    border: `1px solid rgba(255,255,255,0.2)`,
    padding: "15px 20px",
    borderRadius: theme.shape.borderRadius,
    marginTop: 10,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    textTransform: "none",
    "&:before": {
      borderColor: theme.palette.primary.main,
    },
    color: "white",
    // borderColor: "transparent",
  },
  wIcon: {
    width: 25,
    height: 25,
  },
  rootWContainer: {
    paddingBottom: "20px",
    // backgroundColor: "red",
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  rootContainer: {
    paddingTop: 20,
  },
  bottomSection: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    padding: "10px 0px",
    borderTop: "1px solid rgba(225,255,255,0.15)",
  },
  BSButton: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  createIcon: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    marginRight: "0.5rem",
  },
  check: {
    width: 120,
    height: 120,
  },
  listContainer: {
    // backgroundColor: "red",
    maxHeight: "20rem",
    overflow: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
    // "&::-webkit-scrollbar-thumb:hover": {
    //   background: "#b30000",
    // },
  },
  listContainer02: {
    // backgroundColor: "red",
    maxHeight: "20rem",
    overflow: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
    // "&::-webkit-scrollbar-thumb:hover": {
    //   background: "#b30000",
    // },
    padding: "0px 10px",
  },
  disableListItem: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    padding: "0px 1rem",
    "&:hover": {
      cursor: "context-menu",
    },
  },
  selectedManage: {
    // border: `1px solid transparent`,
    color: theme.palette.common.white,
    cursor: "pointer",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    marginTop: 10,
    padding: "10px 15px",
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
  },
  linkTrans: {
    textDecoration: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15rem",
    textTransform: "uppercase",
    fontSize: "0.8rem",
  },
  confirmIcon: {
    width: 20,
    height: 20,
  },
  ethIcon: {
    margin: "0px 4px",
    // height: 13,
    width: 20,
    height: 20,
  },
  strFont: {
    fontSize: "1.2rem",
    ["@media (max-width:400px)"]: {
      fontSize: "0.9rem",
    },
  },
  buttonContent: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
  },
  selectedBigBtn: {
    padding: "10px",
    // width: 160,
    // height: 100,
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   height: undefined,
    // },
    height: "100%",
  },
  unselectedBigBtn: {
    // padding: "10px 30px",
    color: "#C9C9C9",
    borderColor: "#C9C9C9",
    padding: "10px",
    height: "100%",

    // width: 160,
    // height: 100,
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   height: undefined,
    // },
  },
  trx: {
    padding: "5px 0",
    // backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    // marginTop: 8,
  },
  trxLink: {
    fontSize: "0.8rem",
    textDecoration: "none",
    color: theme.palette.common.white,
  },
  txnBox: {
    maxHeight: "240px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  txnMsg: {
    textAlign: "center",
    padding: "0 5px",
  },
  commonTokensContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  secondaryText: {
    color: theme.palette.common.lightText,
    fontSize: "0.8rem",
  },
  acBtn: {
    fontSize: "12px",
    textTransform: "none",
    padding: "2px",
  },
  userAddressContainer: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "28px",
      marginLeft: "10px",
      fontWeight: "500",
    },
  },
  accWalletIcon: {
    width: "25px",
    height: "25px",
  },
  accContainer: {
    padding: "0 15px 15px 15px",
    "@media(max-width:400px)": {
      padding: "0 6px 15px 6px",
    },
  },
  addrSec: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:400px)": {
      marginTop: "5px",
    },
  },
  txMsg: {
    fontSize: "12px",
  },
  addTokenToMetamask: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    background: "rgb(239, 166, 23,0.15)",
    padding: "6px 0",
    color: theme.palette.primary.main,
    borderRadius: "15px",
    width: "220px",
    margin: "0 auto",
    cursor: "pointer",
    "& img": {
      width: 18,
      height: 18,
      margin: "0 0 3px 10px",
    },
    "&:hover": {
      background: "rgb(239, 166, 23,0.1)",
    },
  },
  hereLink: {
    color: theme.palette.primary.main,
  },
  boxBody: {
    minHeight: "450px !important",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px",
  },
  bodyContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",

    "& .MuiButton-root:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
  },
  mainLogo: {
    width: "185px !important",
  },
  text: {
    fontSize: "0.8rem !important",
    textAlign: "center",
    width: "90%",
    lineHeight: "1.6",
    marginTop: "15px",
    "& .MuiLink-underlineHover": {
      textDecoration: "underline",
      marginLeft: "2px",
    },
  },
  redirectBtn: {
    background: theme.palette.primary.main,
    padding: "10px 20px",
    textTransform: "none",
    color: "white",
    [theme.breakpoints.up("xs")]: {
      width: "230px !important",
    },
  },
  calcHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 20px 5px",
  },
  calcBody: {
    padding: "10px 20px 20px",
  },
  calcHeading: {
    fontWeight: 600,
    fontSize: "13px",
  },
  priceCalc: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10px",
    padding: "5px 10px",
    border: "1px solid rgba(255,255,255,0.4)",
    borderRadius: "15px",
    background: "rgba(0,0,0,0.7)",
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
  },
  priceCalcCol1: {},
  priceCalcCol2: {
    "& .MuiSvgIcon-root": {
      transform: "rotate(90deg)",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
    "& .MuiIconButton-root:hover": {
      background: "transparent",
    },
  },
  calcPrices: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    padding: "0 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  calcPrice: {
    background: "#1E2426",
    padding: "7px",
    width: "75px",
    textAlign: "center",
    borderRadius: "15px",
    fontWeight: 600,
    cursor: "pointer",
    color: "rgba(255,255,255,0.5)",
    "&:hover": {
      color: "#fff",
      background: theme.palette.primary.main,
      transition: "0.5s",
    },
    [theme.breakpoints.down("xs")]: {
      width: "56px",
      padding: "5px",
      fontSize: "12px",
    },
  },
  activeClass: {
    color: "#fff",
    background: theme.palette.primary.main,
  },
  daysInput: {
    background: "rgba(0,0,0,0.7)",
    border: "1px solid rgba(255,255,255,0.4)",
    borderRadius: "15px",
    padding: "5px 10px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  calceDay: {
    width: "60px",
    [theme.breakpoints.down("xs")]: {
      width: "44px",
    },
  },
  calcePriceShow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& p": {
      fontSize: "22px",
      fontWeight: 600,
    },
  },
  daysBox: {
    display: "flex",
    flexDirection: "column",
  },
  daysBoxTyp: {
    fontWeight: 600,
    fontSize: "10px",
    letterSpacing: "2px",
    margin: "1px",
  },
  daysBoxTypForMax: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "12px",
    cursor: "pointer",
  },
  daysClass: {
    "& .MuiInput-underline:before, .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-root": {
      fontSize: "25px",
      fontWeight: 600,
    },
    "& .MuiInputBase-input": {
      padding: "0 !important",
    },
  },
  bodyContentLiquidity: {
    padding: "20px",
  },
  tokenBox: {
    "& *": {
      fontWeight: 600,
    },
  },
  btnBox: {},
  stakedBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "26px",
    fontWeight: 600,
  },
  listItemActive: {
    background: "#0F1825",
  },
  listItemShow: {
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: "lowercase",
    width: "100% !important",
    margin: "10px 0 0 0 !important",
    borderRadius: 7,
    color: theme.palette.primary.main,
  },
  hidden: {
    display: "none !important",
  },
  searchBar: {
    marginTop: "10px",
    "& > div": {
      width: "100% !important",
      margin: "auto !important",
    },
    "&.MuiPaper-root": {
      background: "transparent",
    },
    "& .MuiInputBase-root": {
      border: "1px solid rgba(255,255,255,0.15)",
      padding: "5px 15px",
      fontSize: "0.8rem",
      borderRadius: "10px",
      background: "rgba(0, 0, 0, 0.6) !important",
    },
    "& .MuiIconButton-root": {
      display: "none",
    },
  },
  selectItem: {
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "& .MuiButton-root": {
      border: "1px solid rgba(255,255,255,0.4)",
      padding: "10px",
      width: "100%",
      justifyContent: "space-between",
      "&:hover": {
        background: "transparent",
      },
    },
  },
  listItemPool: {
    padding: "1rem",
    "&:hover": {
      background: "#0F1825",
      cursor: "pointer",
    },
  },
  poolInfoClass: {
    padding: "15px",
    cursor: "pointer",
    "&:hover": {
      background: "#0F1825",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "240px",
    width: "100%",
  },
  tooltipc: {
    backgroundColor: "rgb(44, 47, 51)",
    padding: "10px",
    borderRadius: theme.shape.borderRadius,
    maxWidth: "200px !important",
  },
  finalRoiTooltip2: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  customClass: {
    margin: "0 !important",
  },
  button: {
    background: "#ffa500",
    color: "#fff",
    textTransform: "none",
    padding: "8px 0px",
    fontSize: "16px",
    marginTop: "10px",
    "&:hover": {
      background: "rgb(255, 165, 0, 0.4)",
    },
  },
  tooltipPool: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0",
    },
  },
  boxContainerMig: {},
  nftId: {},
}));

export default useStyles;
