import { PositionTypes } from '../actionTypes';
import { PositionAction } from '../actions/positionActions';
import { INFTPosition } from '../../utils/generalTypes';

export interface PositionState {
  nftPositions: INFTPosition[] | null;
  selectedNFTPosition: INFTPosition | undefined | null;
  pageCount: number;
}

const initialState: PositionState = {
  nftPositions: null,
  selectedNFTPosition: null,
  pageCount: 0,
};

let data;

const positionReducer = (state: PositionState = initialState, action: PositionAction) => {
  switch (action.type) {
    case PositionTypes.GET_NFT_POSITIONS:
      data = action.payload.filter(position => Number(position.liquidity) > 0);
      return {
        ...state,
        nftPositions: data,
        pageCount: 0,
      };

    case PositionTypes.REMOVE_POSITION:
      if (state.nftPositions) {
        return {
          ...state,
          nftPositions: state.nftPositions.filter(
            position => position.tokenId.toString() !== action.payload.toString()
          ),
        };
      } else return state;

    case PositionTypes.SELECT_POSITION:
      return { ...state, selectedNFTPosition: action.payload };

    case PositionTypes.CLEAR_POSITIONS:
      return { ...state, nftPositions: null, selectedNFTPosition: null };

    case PositionTypes.ADD_TOKEN_DETAIL:
      data = state.nftPositions ?? [];
      const index = data.findIndex(position => position.tokenId === action.payload.tokenId);
      data[index] = {
        ...data[index],
        ...action.payload,
      };
      return {
        ...state,
        nftPositions: data,
        selectedNFTPosition: state.selectedNFTPosition
          ? { ...state.selectedNFTPosition, ...action.payload }
          : null,
      };

    case PositionTypes.UPDATE_SELECTED_POSITION:
      return {
        ...state,
        selectedNFTPosition: state.selectedNFTPosition
          ? { ...state.selectedNFTPosition, ...action.payload }
          : null,
      };

    case PositionTypes.UPDATE_POSITIONS:
      let tempSelectedPosition = state.selectedNFTPosition;
      const updatedSelectedPosition = action.payload.filter(
        ({ tokenId }) => tokenId == state.selectedNFTPosition?.tokenId
      );

      return {
        ...state,
        nftPositions: action.payload,
        selectedNFTPosition: { ...tempSelectedPosition, ...updatedSelectedPosition[0] },
      };

    case PositionTypes.SET_PAGE_COUNT:
      return { ...state, pageCount: action.payload };

    default:
      return state;
  }
};

export default positionReducer;
