import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    padding: "15px 15px",
  },
  rootPaper: {
    backgroundColor: "#02172e !important",
  },
  input: {
    maxWidth: 80,
    backgroundColor: theme.palette.secondary.main,
    padding: "0px 10px",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 1,
  },
  button: {
    borderColor: theme.palette.primary.main,
    padding: "4px 7px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    minWidth: 240,
    padding: "10px 20px",
    backgroundColor: theme.palette.secondary.main,
  },
  marginTop: {
    marginTop: 20,
  },
  buttonBottom: {
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
  },
  textMarginTop: {
    marginTop: 5,
  },
}));

export default useStyles;
