import { useState, useRef } from 'react';

import useStyles from './styles';
import { FileCopyButton, TickButton } from '../';

interface ICopy {
  value: string | any;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Copy: React.FC<ICopy> = ({ value = 'testing', onClick = () => {} }) => {
  const classes = useStyles();
  const refInput: React.RefObject<HTMLInputElement> = useRef(null);

  const [wait, setWait] = useState<boolean>(false);

  const handlerCopy = () => {
    refInput.current?.select();
    document.execCommand('copy');
    setWait(true);
    setTimeout(() => {
      setWait(false);
    }, 800);
  };

  return (
    <>
      <input
        ref={refInput}
        type='text'
        value={value}
        style={{ position: 'absolute', left: 0, opacity: 0 }}
      />
      {wait ? (
        <TickButton
          classNameIcon={classes.fileCopyStyle}
          classNameContainer={classes.fileCopyContainer}
        />
      ) : (
        <FileCopyButton
          classNameIcon={classes.fileCopyStyle}
          onClick={handlerCopy}
          classNameContainer={classes.fileCopyContainer}
        />
      )}
    </>
  );
};

export default Copy;
