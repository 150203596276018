import { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  ListItem,
  ListItemText,
  List,
  Drawer,
  IconButton,
  Link as LinkMui,
} from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { Skeleton } from '@material-ui/lab';

import {
  Logo,
  LogoTick,
  ADialog,
  WDialog,
  CloseButton,
  TokenLogo,
  AccountIcon,
  RowAlignCenter,
} from '../';
import useStyles from './styles';
import { conciseAddress } from '../../utils/formating';
import { getNetworkName } from '../../utils/helpers';
import { CHAIN_ID, PILOT_LOGO, PILOT_ADDRESS } from '../../constants';
import { useApp } from '../../hooks';
import { ROIDialog } from '../../components';

interface IMainHeader {
  bottom?: boolean;
}

const navs = [
  {
    label: 'Liquidity',
    to: '/',
    hrefs: ['/'],
  },
  {
    label: 'Positions',
    to: '/positions',
    hrefs: ['/positions'],
  },
  // {
  //   label: 'Migrate',
  //   to: '/migrate',
  //   hrefs: ['/migrate', '/migrate/import-liquidity'],
  // },
  {
    label: 'Farming',
    to: '/farming',
    hrefs: ['/farming'],
  },
  {
    label: 'Stats',
    to: '/stats',
    hrefs: ['/stats'],
  },
];

const MainHeader: React.FC<IMainHeader> = ({ bottom = false }) => {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();
  const { pathname } = useLocation();
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down('sm'));
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  const { pilotPrice } = useApp();

  const [open, setOpen] = useState<boolean>(false);
  const [openRoiDialog, setOpenRoiDialog] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);

  const handleOpen = () => {
    setOpen(true);
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickRoiDialog = () => {
    setOpenRoiDialog(true);
  };

  const tabs = (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTab}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'
        classes={{ indicator: classes.indicator }}
      >
        {navs.map((nav, idx) => (
          <Tab
            component={Link}
            key={nav.label}
            disableRipple
            classes={{
              root: `${classes.tabRoot}`,
            }}
            label={nav.label}
            {...a11yProps(idx)}
            to={nav.to}
          />
        ))}
      </Tabs>
    </>
  );

  const drawer = (
    <Drawer
      variant='temporary'
      anchor='bottom'
      open={openDrawer}
      classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
      onClose={handleDrawerClose}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
    >
      <List>
        <ListItem button className={classes.drawerListItemHoverNone} disableRipple>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <Logo className={classes.logo} />
            </Grid>
            <Grid item>
              <CloseButton onClick={handleDrawerClose} />
            </Grid>
          </Grid>
        </ListItem>

        {navs.map((nav, idx) => (
          <ListItem
            onClick={handleDrawerClose}
            key={nav.label}
            button
            className={[
              classes.drawerListItem,
              nav.hrefs.includes(`/${pathname.split('/')[1]}`) ? classes.drawerListItemActive : '',
            ].join(' ')}
            component={Link}
            to={nav.to}
          >
            <ListItemText>{nav.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  useEffect(() => {
    let idx = navs.findIndex(nav => {
      return nav.hrefs.includes(`/${pathname.split('/')[1]}`);
    });

    if (idx !== -1) setTabValue(idx);
  }, [pathname]);

  return (
    <>
      {/* <ROIDialog open={openRoiDialog} close={() => setOpenRoiDialog(false)} /> */}
      <ADialog
        addr={account ? account : ''}
        Component={WDialog}
        open={open}
        close={() => setOpen(false)}
      />
      <AppBar
        className={`${bottom ? classes.goToBottom : classes.appBar} ${classes.bottomBorder}`}
        elevation={0}
        color={'transparent'}
        position={bottom ? 'fixed' : 'static'}
      >
        <Container className={classes.container} maxWidth='lg'>
          <Toolbar className={bottom ? classes.toolBarAfter : classes.toolBarBefore}>
            {sm_ ? (
              <Link to='/'>
                <LogoTick className={xs_ ? classes.logoTick_xs : classes.logoTick} />
              </Link>
            ) : (
              <Link to='/' style={{ display: 'flex', justifyContent: 'center' }}>
                <Logo className={classes.logo} />
              </Link>
            )}

            {sm_ ? drawer : tabs}

            <Box marginLeft='auto' display='flex' alignItems='center'>
              {/* <IconButton aria-label='calculator' onClick={handleClickRoiDialog}>
                <i
                  className={['fas fa-calculator', classes.Ico].join(' ')}
                  style={{ color: theme.palette.primary.main }}
                />
              </IconButton> */}

              {!xs_ && (
                <>
                  <LinkMui
                    href={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${PILOT_ADDRESS}`}
                    target='_blank'
                    className={classes.pilotPrice}
                  >
                    <RowAlignCenter
                      element01ClassName={classes.item01Center}
                      justify='flex-start'
                      elements01={
                        <TokenLogo
                          tokenAddress={PILOT_ADDRESS}
                          className={classes.pilotLogo}
                          uri={PILOT_LOGO}
                        />
                      }
                      elements02={
                        <Typography className={classes.pilotText} variant='body2'>
                          Pilot
                        </Typography>
                      }
                    />
                    {pilotPrice !== '' ? (
                      <Typography
                        variant='body2'
                        style={{ minWidth: 60, textAlign: 'right' }}
                      >{`$ ${pilotPrice}`}</Typography>
                    ) : (
                      <Skeleton width={80} height={30} />
                    )}
                  </LinkMui>
                </>
              )}

              {account && !xs_ && (
                <Typography
                  className={`${classes.specialContent} ${
                    chainId !== CHAIN_ID ? classes.inValidChain : ''
                  }`}
                  color='primary'
                >
                  {getNetworkName(chainId)}
                </Typography>
              )}

              <Button
                onClick={handleOpen}
                style={{
                  padding: 0,
                  marginLeft: 15,
                  textTransform: 'capitalize',
                }}
              >
                <Grid
                  container
                  className={[classes.contentContainer, account ? '' : classes.walletConnect].join(
                    ' '
                  )}
                  alignItems='center'
                >
                  <AccountIcon />
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: 500,
                        textTransform: 'none',
                        fontSize: '0.9rem',
                        marginLeft: account ? '5px' : '0',
                      }}
                    >
                      {account ? conciseAddress(account) : 'Connect Wallet'}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>

              {sm_ && (
                <Box marginLeft='20px'>
                  <IconButton
                    color='primary'
                    aria-label='open drawer'
                    onClick={handleDrawerOpen}
                    edge='start'
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default MainHeader;
