import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    backgroundColor: theme.palette.secondary.main,
    // theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    borderRadius: 15,
    // padding: "1.5rem",
  },
  paddingContainer: {
    padding: "1.5rem",
  },
  tokenPair: {
    margin: "15px 0px",
  },
  marginTop: {
    marginTop: 20,
  },
  marginTopZero: {
    marginTop: 0,
  },
  marginTopFive: {
    marginTop: 5,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    // backgroundColor: "red",
    textTransform: "none",
    color: "white",
    padding: "8px 0px",
    minWidth: "120px",
    borderRadius: theme.shape.borderRadius,
    // minHeight: 45,
  },
  buttonsTop: {
    // backgroundColor: "red",
    textTransform: "none",

    padding: "5px 10px",
    minWidth: "120px",
    borderRadius: theme.shape.borderRadius,
    // minHeight: 40,
  },
  textMarginTop: {
    marginTop: 5,
  },

  strFont: {
    fontSize: "1.2rem",
  },
  ethIcon: {
    margin: "0px 4px",
    // height: 13,
    width: 20,
    height: 20,
  },

  buttonContent: {
    fontSize: "1rem",
    fontWeight: 500,
  },

  layoutContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:550px)": {
      flexDirection: "column",
    },
  },
  nftContainer: {
    width: "50%",
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  nftDetailContainer: {
    width: "48%",
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  paper: {
    borderRadius: 15,
    width: "100%",
    padding: "1.5rem",
  },
  paper02: {
    backgroundColor: "#030509",
    width: "100%",
    padding: "0.9rem 1.5rem",
  },
  pilotCoin: {
    minHeight: "260px",
    margin: "15px 0px",
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "63%",
    },
    "@media(max-width:550px)": {
      minHeight: "200px",
    },
  },
  priceBoxContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  greenInfo: {
    fontSize: "12px",
    width: "120px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    margin: "8px auto 0 auto",
  },
  range: {
    fontSize: "12px",
  },
  dollarString: {
    color: theme.palette.primary.main,
    fontSize: "28px",
    "@media(max-width:550px)": {
      fontSize: "24px",
    },
  },
  feeBtnWrapper: {
    height: "49px",
    "@media(max-width:550px)": {
      height: "auto",
    },
  },
  prices: {
    height: "290px",
    marginTop: "35px",
    "@media(max-width:550px)": {
      marginTop: "20px",
    },
  },
  blackBoxContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    boxShadow: "0px 4px 8px rgba(27, 27, 27, 0.16)",
    padding: "20px",
    borderRadius: theme.shape.borderRadius * 2,
    border: "1px solid rgba(255, 255, 255, 0.12) !important",
    // border: "1px solid rgba(225,225,255,0.1)",
  },
  rightContentContainer: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: theme.shape.borderRadius * 2,
    overflow: "hidden",
  },
  font0P9Rem: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  font1P2RemPri600: {
    fontSize: "1.5rem",
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  CGridContainer: {
    marginTop: 2,
  },
  amountFuncTypo: {
    fontSize: "2rem",
    fontWeight: 500,
    marginTop: 5,
  },
  amountFuncTypoSm: {
    fontSize: "1.2rem",
    fontWeight: 500,
    marginTop: 5,
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  tokenLogo: {
    width: 35,
    height: 35,
  },
  tooltipc: {
    backgroundColor: "rgb(44, 47, 51)",
    padding: "10px",
    borderRadius: theme.shape.borderRadius,
  },
  fontSize: {
    fontSize: "0.85rem",
    fontWeight: 500,
  },
  owner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  tableContainer: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiTableCell-root": {
      padding: "6px",
      fontSize: "0.6rem",
      background: "transparent",
    },
  },
  tableTooltip: {
    position: "absolute",
    bottom: "-10px",
    overflow: "hidden",
  },
}));

export default useStyles;
