import { useSelector } from 'react-redux';
import { useDispatchWrap } from './utilHooks';
import {
  State,
  hideModal,
  showSnackbar,
  hideSnackbar,
  showSnackbarInDeep,
  updatePilotPrice,
} from '../redux';
import { DAPP_CURRENT_VERSION } from '../constants';

export const useModal = () => {
  const { modal } = useSelector((state: State) => state.ui);
  const hideModalF = useDispatchWrap(hideModal);
  return { ...modal, hideModalF };
};

export const useLoading = () => {
  const { dappLoading, isApproved } = useSelector((state: State) => state.ui);
  return { dappLoading, isApproved };
};

export const useSnackbar = () => {
  const snackbar = useSelector((state: any) => state.ui.snackbar);
  const showSnackbarF = useDispatchWrap(showSnackbar);
  const hideSnackbarF = useDispatchWrap(hideSnackbar);
  const showSnackbarInDeepF = useDispatchWrap(showSnackbarInDeep);

  return { ...snackbar, showSnackbarF, hideSnackbarF, showSnackbarInDeepF };
};

export const useApp = () => {
  const app = useSelector((state: State) => state.ui);
  const updatePilotPriceF = useDispatchWrap(updatePilotPrice);

  const updateAppVersion = () => {
    const dappVerison = localStorage.getItem('version');
    if (dappVerison !== DAPP_CURRENT_VERSION) {
      localStorage.setItem('version', DAPP_CURRENT_VERSION);
      const localKeys = ['customTokens', 'transactions', 'activeToken', 'lpTokens'];
      localKeys.forEach(key => {
        localStorage.removeItem(key);
      });
    }
  };

  return { ...app, updatePilotPriceF, updateAppVersion };
};
