import { Box, Typography, CircularProgress } from '@material-ui/core';

import { BoxContainer } from '../';
import useStyles from './styles';
import position from '../../assets/svgs/position.svg';

interface IEmpty {
  msg: string;
  loading?: boolean;
}

const Empty: React.FC<IEmpty> = ({ msg, loading = false }) => {
  const classes = useStyles();

  return (
    <BoxContainer
      style={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
      className={classes.right}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          style={{
            padding: '20px',
            opacity: 0.3,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={position} alt='position image' />
          <Box mt='10px' />
          <Typography variant='h6'>{msg}</Typography>
        </Box>
      )}
    </BoxContainer>
  );
};

export default Empty;
