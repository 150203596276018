import { combineReducers } from 'redux';

import uiReducer from './uiReducer';
import tokenReducer from './tokenReducer';
import transactionReducer from './transactionReducer';
import liquidityReducer from './liquidityReducer';
import positionReducer from './positionReducer';
import statsReducer from './statsReducer';
import migrateLiquidityReducer from './migrateLiquidityReducer';
import farmingReducer from './farmingReducer';

export const rootReducer = combineReducers({
  ui: uiReducer,
  token: tokenReducer,
  transaction: transactionReducer,
  liquidity: liquidityReducer,
  position: positionReducer,
  stats: statsReducer,
  migrateLiquidity: migrateLiquidityReducer,
  farming: farmingReducer,
});

export default rootReducer;

export type State = ReturnType<typeof rootReducer>;
