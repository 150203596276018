import axios from 'axios';

import { logError } from '../utils/logs';
import { SERVER_ENDPOINTS } from '../constants';

export const fectPoolApy = async (address: string) => {
  try {
    const res = await axios.get(
      `${SERVER_ENDPOINTS.unipilot}/poolApr?poolAddress=${address.toLowerCase()}`
    );

    if (res?.data?.message === 'Success') {
      return res.data.doc && res.data.doc[address.toLowerCase()];
    }
  } catch (e) {
    logError('fectPoolApy', e);
  }
};
