import { nonFungiblePositionManager } from '../getContract';
import { logError } from '../../utils/logs';
import { CONTRACT_ADDRESSES } from '../../constants';
import { CALL_TYPE, MIGRATE_PROTOCOL, MIGRATE_TYPE, LOADING } from '../../utils/enums';
import { singleContractMultipleData, singleCall, DecodeType } from '../calls';
import { UniswapV3Position } from '../returnTypes';
import { findPoolAddress, getTokenReserves } from '../../utils/uniswapSDKFunctions';
import { tokenDetail } from './erc20';
import { slot0, poolLiquidity } from './pool';
import { IMigrateNFT } from '../../utils/generalTypes';
import { sendTransaction } from '../sendTransaction';

export const balanceOfUniV3 = async (walletAddress: string): Promise<string | undefined> => {
  try {
    const contract = nonFungiblePositionManager();
    const balance = await contract?.methods.balanceOf(walletAddress).call();
    return balance;
  } catch (e) {
    logError('balanceOfUniV3Nft', e);
  }
};

export const tokenOfOwnerByIndexUniV3 = async (
  call: CALL_TYPE,
  [balance, walletAddress]: [number, string]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint256',
      name: '',
    },
  ];
  const contract = nonFungiblePositionManager();
  const method = contract?.methods.tokenOfOwnerByIndex;
  if (call === CALL_TYPE.MULTI) {
    const indexes = [...Array(balance).keys()].map(index => [walletAddress, index]);
    return await singleContractMultipleData(
      indexes,
      CONTRACT_ADDRESSES.nonfungiblePositionManager,
      method,
      types,
      'multi-tokenOfOwnerByIndexUniV3 '
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall([walletAddress, balance], method, 'single-tokenOfOwnerByIndexUniV3');
  }
};

export const liquidityPositionsUniV3 = async <T extends keyof UniswapV3Position>(
  call: CALL_TYPE,
  tokenIds: string[],
  outputs?: T[]
) => {
  const types: DecodeType[] = [
    {
      type: 'uint96',
      name: 'nonce',
    },
    {
      type: 'address',
      name: 'operator',
    },
    {
      type: 'address',
      name: 'token0',
    },
    {
      type: 'address',
      name: 'token1',
    },
    {
      type: 'uint24',
      name: 'fee',
    },
    {
      type: 'int24',
      name: 'tickLower',
    },
    {
      type: 'int24',
      name: 'tickUpper',
    },
    {
      type: 'uint128',
      name: 'liquidity',
    },
    {
      type: 'uint256',
      name: 'feeGrowthInside0LastX128',
    },
    {
      type: 'uint256',
      name: 'feeGrowthInside1LastX128',
    },
    {
      type: 'uint128',
      name: 'tokensOwed0',
    },
    {
      type: 'uint128',
      name: 'tokensOwed1',
    },
  ];
  const contract = nonFungiblePositionManager();
  const method = contract?.methods.positions;
  if (call === CALL_TYPE.MULTI && outputs) {
    return await singleContractMultipleData(
      tokenIds,
      CONTRACT_ADDRESSES.nonfungiblePositionManager,
      method,
      types,
      'multi-liquidityPositionsUniV3',
      outputs
    );
  } else if (call === CALL_TYPE.SINGLE) {
    return await singleCall(tokenIds[0], method, 'single-liquidityPositionsUniV3');
  }
};

export const findNFTPositionsUniV3 = async (walletAddress: string) => {
  try {
    //get user nft balance
    const balance = await balanceOfUniV3(walletAddress);

    //get nft ids w.r.t index
    const tokenIds = await tokenOfOwnerByIndexUniV3(CALL_TYPE.MULTI, [
      Number(balance ?? '0'),
      walletAddress,
    ]);

    //get positions from nft id's
    const positions =
      (await liquidityPositionsUniV3(CALL_TYPE.MULTI, tokenIds, [
        'token0',
        'token1',
        'fee',
        'liquidity',
        'tickLower',
        'tickUpper',
      ])) ?? [];

    //extract tokenAddresses from positions
    const tokenAddresses = positions.flatMap(({ token0, token1 }: any) => [token0, token1]);

    //get tokens details from tokenAddresses
    const tokens = await tokenDetail(tokenAddresses, walletAddress);

    //find pool addresses from tokens
    const poolAddresses = positions.map(({ token0, token1, fee }: any) =>
      findPoolAddress(tokens[token0.toLowerCase()], tokens[token1.toLowerCase()], fee)
    );

    //get sqrtPrices and ticks from pool Addresses by using slot0
    const slot0s = await slot0(CALL_TYPE.MULTI, poolAddresses, ['sqrtPriceX96', 'tick']);

    //get liquidities from pool addresses
    const liquidities = await poolLiquidity(CALL_TYPE.MULTI, poolAddresses);

    //arrange all data into single uniV3 position
    let uniV3Positions: IMigrateNFT[] = [];
    positions.forEach(
      (
        { token0: token0Address, token1: token1Address, fee, liquidity, tickLower, tickUpper }: any,
        index: number
      ) => {
        if (Number(liquidity) > 0) {
          const token0 = tokens[token0Address.toLowerCase()];
          const token1 = tokens[token1Address.toLowerCase()];
          const tokenId = tokenIds[index];
          const poolLiquidity = liquidities[index];
          const pairAddress = poolAddresses[index];
          const { sqrtPriceX96, tick } = slot0s[index];
          const { token0Reserve, token1Reserve, inRange } = getTokenReserves(
            token0,
            token1,
            fee,
            liquidity,
            tickLower,
            tickUpper,
            poolLiquidity,
            sqrtPriceX96,
            tick
          );

          if (inRange) {
            uniV3Positions.push({
              id: tokenId.toString(),
              pairAddress,
              token0,
              token1,
              fee,
              liquidity,
              protocol: MIGRATE_PROTOCOL.UniswapV3,
              type: MIGRATE_TYPE.NFT,
              token0Reserve: token0Reserve.toString(),
              token1Reserve: token1Reserve.toString(),
              tickLower,
              tickUpper,
            });
          }
        }
      }
    );

    return uniV3Positions;
  } catch (e) {
    logError('findNFTPositionsUniV3', e);
  }
};

export const isUniV3NftApprovedAll = async (
  walletAddress: string
): Promise<boolean | undefined> => {
  try {
    const contract = nonFungiblePositionManager();
    const approved = await contract?.methods
      .isApprovedForAll(walletAddress, CONTRACT_ADDRESSES.liquidityMigrator)
      .call();
    return approved;
  } catch (e) {
    logError('isUniV3NftApproved', e);
  }
};

export const isUniV3NftApproved = async (tokenId: string): Promise<boolean> => {
  try {
    const contract = nonFungiblePositionManager();
    const approvedAddress = await contract?.methods.getApproved(tokenId).call();
    return approvedAddress?.toLowerCase() == CONTRACT_ADDRESSES.liquidityMigrator.toLowerCase();
  } catch (e) {
    logError('isUniV3NftApproved', e);
    return false;
  }
};

export const approveUniV3NftAll = async (walletAddress: string, callback: any = () => {}) => {
  try {
    const contract = nonFungiblePositionManager();
    const contractFnc = contract?.methods.setApprovalForAll(
      CONTRACT_ADDRESSES.liquidityMigrator,
      true
    );
    const args = {
      walletAddress,
      txnMessage: `Approved ${MIGRATE_PROTOCOL.UniswapV3}`,
      logMessage: 'approveUniV3Nft',
      dappLoading: LOADING.APPROVE,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('approveUniV3Nft', e);
  }
};

export const approveUniV3Nft = async (
  tokenId: string,
  walletAddress: string,
  callback: any = () => {}
) => {
  try {
    const contract = nonFungiblePositionManager();
    const contractFnc = contract?.methods.approve(CONTRACT_ADDRESSES.liquidityMigrator, tokenId);
    const args = {
      walletAddress,
      txnMessage: `Approved Uniswap V3 NFT`,
      logMessage: 'approveUniV3Nft',
      dappLoading: LOADING.APPROVE,
      callback,
    };
    if (contractFnc) {
      await sendTransaction(contractFnc, args);
    }
  } catch (e) {
    logError('approveUniV3Nft', e);
  }
};
