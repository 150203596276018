import Countdown from 'react-countdown';

import Layout from './Layout';
import { CountDown } from '../pages';

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return <Layout />;
  } else return <CountDown days={days} hours={hours} minutes={minutes} seconds={seconds} />;
};

//1637341200000

const Render = () => {
  return <Countdown date={1637341200000} renderer={renderer} />;
};

export default Render;
