import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';

import useStyles from './styles';
import { DisableElements, GroupButton } from '../';

interface IFeeTierSelection {
  loading?: boolean;
}

const FeeTierSelection: React.FC<IFeeTierSelection> = ({ loading = false }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction='column' className={classes.marginTop}>
        <Grid item>
          <Box className={classes.feeTier}>
            <Typography variant='h6'>Select Fee Tier</Typography>
            {loading && <CircularProgress className={classes.feeTierLoader} size={15} />}
          </Box>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: '0.8rem' }} variant='caption'>
            Select a pool type based on your preferred liquidity provider fee.
          </Typography>
        </Grid>
      </Grid>

      <Box className={classes.marginTop} />
      <DisableElements disabled={loading}>
        <GroupButton />
      </DisableElements>
    </>
  );
};

export default FeeTierSelection;
