import { Box, Typography, CircularProgress } from '@material-ui/core';

import { RowAlignCenter, CTooltip } from '../../components';
import useStyles from './styles';
import InfoOutlinedIcon from '@material-ui/icons/Error';

interface ITotal {
  text: string;
  amount: string;
  loading?: boolean;
  icon: JSX.Element;
  error?: boolean;
}

const FAIL_MSG =
  'Value is not available due to subgraph failure and are being worked on. It will be live again shortly.';

const Total: React.FC<ITotal> = ({ text, amount, icon, loading = false, error = false }) => {
  const classes = useStyles();

  return (
    <RowAlignCenter
      containerClassName={classes.container}
      elements01={
        <Box className={classes.box}>
          {error ? (
            <RowAlignCenter
              elements01={
                <Typography variant='body2' style={{ marginRight: '5px' }}>
                  {text}
                </Typography>
              }
              elements02={
                <CTooltip title={FAIL_MSG}>
                  <InfoOutlinedIcon />
                </CTooltip>
              }
            ></RowAlignCenter>
          ) : (
            <Typography variant='body2'>{text}</Typography>
          )}

          {loading ? (
            <CircularProgress style={{ marginTop: '16px' }} size={15} />
          ) : (
            <Typography variant='h4'>{amount}</Typography>
          )}
        </Box>
      }
      elements02={icon}
    />
  );
};

export default Total;
