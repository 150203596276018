import React, { useState, Dispatch, SetStateAction } from 'react';
import { Box, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';

import useStyles from './styles';
import { classesJoin } from '../../utils/helpers';

interface IOptions {
  label?: string;
  value: string;
}

interface IDropdown {
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  title?: string;
  value: string;
  formClass?: string;
  options: IOptions[];
}

const Dropdown: React.FC<IDropdown> = ({ title, value, handleChange, options, formClass = '' }) => {
  const classes = useStyles();

  const selectArray = options.map((item, index) => {
    return (
      <MenuItem key={index} classes={{ selected: classes.select }} value={item.value}>
        {item.label ? item.label : item.value}
      </MenuItem>
    );
  });
  return (
    <Box className={classes.root}>
      <FormControl className={formClass ? formClass : classes.formControl}>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          value={value}
          onChange={handleChange}
        >
          {selectArray}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Dropdown;
